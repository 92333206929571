import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/media', request).then(result => result.response);
  },
  find(id) {
    return apiGet(`/magenta/media/${id}`).then(result => result.response);
  },
  generateUploadUrl(request) {
    return apiPost('/magenta/media/upload-url', request).then(result => result.response);
  },
};
