<template lang="pug">
el-submenu(index="marketing")
  template(slot="title")
    i.icon
        font-awesome-icon(icon="store")
    span Marketing

  router-link(v-if="shouldShow(['marketing'])" :to="{ name: 'coupon-list' }")
    el-menu-item(index="coupon-list")
        | Cupons

  router-link(v-if="shouldShow(['marketing'])" :to="{ name: 'influencer-list' }")
    el-menu-item(index="influencer-list")
        | Influenciadores

  router-link(v-if="shouldShow(['marketing', 'operations-lead'])" :to="{ name: 'reward-list' }")
    el-menu-item(index="reward-list")
        | Programa de pontos
</template>
<script setup>
import Storage from '../../storage'

const authRoles = () => {
  const encodedRole = Storage.get(Storage.keys.auth.role)
  const encodedRoles = Storage.get(Storage.keys.auth.roles)

  return encodedRoles ? JSON.parse(atob(encodedRoles)) : (encodedRole ? [atob(encodedRole)] : [])
}

const shouldShow = (authorizedRoles = []) => {
  const roles = authRoles()

  if (roles.indexOf('owner') >= 0 || roles.indexOf('admin') >= 0) return true

  if (authorizedRoles) {
    for (var i = 0; i < authorizedRoles.length; i += 1) {
      if (roles.indexOf(authorizedRoles[i]) >= 0) return true
    }
  }

  return false
}
</script>