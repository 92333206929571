import { apiDelete, apiGet, apiPost, apiPut } from '../../_common';

export default {
  search(searchOptions) {
    return apiGet(`/magenta/ecommerce/inventory/transaction`, searchOptions).then(result => result.response);
  },
  create(request) {
    return apiPost(`/magenta/ecommerce/inventory/transaction`, request).then(result => result.response);
  },
  delete(id) {
    return apiDelete(`/magenta/ecommerce/inventory/transaction/${id}`).then(result => result.response);
  }
};
