<template lang="pug">
section.subscription-box-order-list
  h1 Revisão de fraude - Box de Assinatura

  SnowflakeSearchList(
    context="subscription-box-order-fraud-review",
    :service="SubscriptionBoxOrderService",
    :action="false",
    :search-options="searchOptions",
    :detail-link-to="fraudReviewLink"
  )
    template(slot="tableRows")

      el-table-column(prop="id", label="ID", width="80", sortable)

      el-table-column(prop="customerName", label="Cliente")

      el-table-column(prop="edition", label="Edição", width="100", sortable)

      el-table-column(prop="status", label="Status", width="280", :show-overflow-tooltip="true", sortable)
        template(slot-scope="scope")
          | {{ SubscriptionBoxOrderService.statusTranslation[scope.row.status] }}

      el-table-column(prop="createdAt", label="Criado em", width="140", sortable)
        template(slot-scope="scope")
          span {{ toDate(scope.row.createdAt) }}

</template>
<script setup>
import SnowflakeSearchList from '@/components/list/SearchList.vue'
import SubscriptionBoxOrderService from '@/services/fulfillment/SubscriptionBoxOrderService'
import moment from 'moment'

const searchOptions = {
  filters: [ `status:SUSPECTED_FRAUD` ]
}

const fraudReviewLink = (row) => {
  return { name: 'subscription-box-order-fraud-review-detail', params: { id: row.id } }
}

const toDate = (dateString) => {
  return moment.utc(dateString, 'YYYY-MM-DDTHH:mm:ss').tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')
}
</script>
<style lang="scss" scoped>
.subscription-box-order-list {
  h1 {
    font-size: 22px;
    margin-bottom: 24px;
    text-align: left;
  }
}
</style>
