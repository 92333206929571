<template lang="pug">
.html-editor
  VueQuillEditor.vue-quill-editor(
    ref="editor",
    :options="quillOptions", 
    :content="value", 
    @change="contentChanged"
  )
</template>
<script setup>
import { ref } from 'vue'

import { quillEditor as VueQuillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme

const props = defineProps({
  value: String
})

const emit = defineEmits(['input', 'input:text', 'input:html'])

const editor = ref()

const quillOptions = ref({
  theme: "snow",
  modules: {
    toolbar: [
      [{ 'header': [ 3, 4, 5, false ] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'color': [], 'background': [] }],
      ['link'],
      ['clean']
    ]
  }
})

const contentChanged = (value) => {
  const htmlContent = value.html
  const textContent = value.text.trim()

  if (htmlContent == "") {
    emit('input', null)
    emit('input:text', null)
    emit('input:html', null)
  } else {
    emit('input', htmlContent)
    emit('input:text', textContent)
    emit('input:html', htmlContent)
  }
}

const getText = () => {
  return editor.value?.quill?.getText()
}

defineExpose({
  getText
})
</script>
<style lang="scss">
@import "@/theme";

.html-editor {
  position: relative;
  height: 320px;
  border: 1px solid $--border-color-base;
  border-radius: $--border-radius-base;

  > .vue-quill-editor {
    display: flex;
    flex-direction: column;
    height: 100%;

    > .ql-toolbar {
      flex: 0;
    }

    > .ql-container {
      flex: 1;

      > .ql-editor {
        color: #000;
      }
    }
  }

  &:focus {
    border-color: $--color-primary;
  }

  .ql-snow {
    .ql-tooltip {
      line-height: normal;
    }

    .ql-picker {
      line-height: 22px;
    }
  }

  .ql-toolbar.ql-snow {
    border-width: 0 0 1px;

    .ql-active {
      color: $--color-primary;

      .ql-stroke {
        stroke: $--color-primary;
      }
    }
  }

  .ql-container.ql-snow {
    border-width: 0;
  }

  .ql-editor {
    height: 263px;

    strong {
      font-weight: bold !important;
    }

    em {
      font-style: italic !important;
    }

    u {
      text-decoration: underline !important;
    }

    s {
      text-decoration: line-through !important;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
    }
  }
}
</style>