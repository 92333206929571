<template lang="pug">
el-dialog(
  :title="addressId ? `Editar endereço #${addressId}` : 'Novo endereço'"
  :visible="visible",
  @update:visible="updateVisible"
)

  el-form(ref="form", :model="data", :rules="rules", label-width="120px", v-loading="loading")

    el-row
      el-col(:span="24")
        el-form-item(label="CEP", prop="postalCode")
          el-input(v-model="data.postalCode", placeholder="CEP", v-mask="'#####-###'", style="width: 100%")

    el-row
      el-col(:span="16")
        el-form-item(label="Rua", prop="street")
          el-input(v-model="data.street", placeholder="Rua", maxlength="140", show-word-limit, style="width: 100%")

      el-col(:span="8")
        el-form-item(label="Número", prop="number")
          el-input(v-model="data.number", placeholder="Número", maxlength="5", show-word-limit, style="width: 100%")

    el-row
      el-col(:span="24")
      el-form-item(label="Complemento", prop="complement")
        el-input(v-model="data.complement", placeholder="Complemento", maxlength="50", show-word-limit, style="width: 100%")

    el-row
      el-col(:span="24")
        el-form-item(label="Bairro", prop="district")
          el-input(v-model="data.district", placeholder="Bairro", maxlength="50", show-word-limit, style="width: 100%")

    el-row
      el-col(:span="12")
        el-form-item(label="Cidade", prop="city")
          el-input(v-model="data.city", placeholder="Cidade", maxlength="140", show-word-limit, style="width: 100%")

      el-col(:span="12")
        el-form-item(label="Estado", prop="state")
          el-select(v-model="data.state", placeholder="Estado", style="width: 100%")
            el-option(
              v-for="state in states"
              :key="state.value"
              :label="state.label"
              :value="state.value"
            )

  template(slot="footer", style="text-align: right")
    el-button(type="primary", @click="save", :loading="saving") Salvar

</template>
<script setup>
import { useMessage } from '@/utils/Message';
import { computed, ref, watch } from 'vue';
import ShippingAddressService from '@/services/ShippingAddressService';

const message = useMessage()

const emit = defineEmits(['close', 'save', 'update:visible'])

const props = defineProps({
  customerId: {
    type: Number,
    required: true
  },
  addressId: {
    type: Number
  },
  visible: {
    type: Boolean,
    required: true
  },
})

const form = ref()
const rules = ref({
  street: [
    { required: true, message: 'Por favor, informe a rua', trigger: 'blur' }
  ],
  number: [
    { required: true, message: 'Por favor, informe o número', trigger: 'blur' },
  ],
  district: [
    { required: true, message: 'Por favor, informe o bairro', trigger: 'blur' }
  ],
  city: [
    { required: true, message: 'Por favor, informe a cidade', trigger: 'blur' }
  ],
  state: [
    { required: true, message: 'Por favor, informe o estado', trigger: 'blur' }
  ],
  postalCode: [
    { required: true, message: 'Por favor, informe o CEP', trigger: 'blur' }
  ]
})

const states = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' }
]

const data = ref({
  street: '',
  number: '',
  complement: null,
  district: '',
  city: '',
  state: '',
  postalCode: ''
})

const loading = ref(false)
const saving = ref(false)

const isNew = computed(() => !props.addressId)

const updateVisible = (value) => {
  emit('update:visible', value)
}

const load = async () => {
  if (!isNew.value) {
    loading.value = true
    try {
      const response = await ShippingAddressService.getShippingAddressById(props.addressId)

      data.value = {
        street: response.street,
        number: response.number,
        complement: response.complement ? response.complement : null,
        district: response.district,
        city: response.city,
        state: response.state,
        postalCode: response.postalCode
      }
    } catch(error) {
      message.error('Erro ao carregar endereço')
    } finally {
      loading.value = false
    }
  } else {
    data.value = {
      street: '',
      number: '',
      complement: null,
      district: '',
      city: '',
      state: '',
      postalCode: ''
    }
  }
}

const save = async () => {
  try {
    await form.value.validate()
  } catch (error) {
    message.error('Por favor, verifique os campos')
  }

  const address = {
    street: data.value.street,
    number: data.value.number,
    complement: data.value.complement,
    district: data.value.district,
    city: data.value.city,
    state: data.value.state,
    postalCode: data.value.postalCode
  }

  try {
    if (isNew.value) {
      await ShippingAddressService.create({ customerId: props.customerId, ...address })

      updateVisible(false)
    } else {
      await ShippingAddressService.update(props.addressId, address)
    }

    message.success('Endereço salvo com sucesso')
    emit('save')
  } catch (error) {
    message.error('Erro ao salvar endereço')
  }
}

watch(() => props.visible, async (value) => {
  if (value) {
    await load()

    form.value.clearValidate()
  }
})
</script>
