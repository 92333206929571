import { getCurrentInstance } from 'vue';

export function useMessage() {
  const inst = getCurrentInstance();

  if (!inst) {
      console.warn("Out of Vue scope");

      return undefined;
  }

  return inst.proxy.$message;
}