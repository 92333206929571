<template lang="pug">
.bling-purchase-orde-expoter

  section
    el-header
      el-col(:span="16")
        el-page-header(@back="back" content="Gerar pedidos de compra")

      el-col(:span="8" style="text-align: right")
        el-button(type="primary", @click="exportPurchaseOrders", :loading="exporting") Gerar

    el-form(ref="form", :model="details", :rules="rules", label-width="160px")

      el-form-item(prop="internalNote", label="Observação interna")
        el-input(v-model="details.internalNote")

      el-form-item(prop="purchaseOrderItems", label="Pedidos")
        el-input(type="textarea", :rows="10", v-model="details.purchaseOrderItems")


</template>
<script setup>
import { useMessage } from '@/utils/Message';
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router';

import BlingService from '../../../../services/stock/BlingService'

const router = useRouter()
const message = useMessage()

const exporting = ref(false)

const details = ref({
  internalNote: '',
  purchaseOrderItems: '',
})

const form = ref()
const rules = ref({
  internalNote: [
    { required: true, message: 'Campo obrigatório', trigger: 'blur' },
  ],
  purchaseOrderItems: [
    { required: true, message: 'Campo obrigatório', trigger: 'blur' },
    { validator: (rule, value, callback) => {
      try {
        parsePurchaseOrderItems()
        callback()
      } catch (error) {
        callback(error)
      }
    }, trigger: 'blur'},
  ],
})

const reset = () => {
  details.value = {
    internalNote: '',
    purchaseOrderItems: '',
  }
}

const exportPurchaseOrders = async () => {
  try {
    await form.value.validate()
  } catch (error) {
    return
  }

  exporting.value = true

  try {
    const request = {
      internalNote: details.value.internalNote,
      items: parsePurchaseOrderItems(),
    }

    const response = await BlingService.exportPurchaseOrders(request)

    if (response.successful) {
      message.success("Pedidos de compra gerados com sucesso")

      reset()
    } else {
      message.error(`Erro ao gerar pedidos de compra: ${response.errorMessage}`)
    }
  } catch (error) {
    message.error("Erro ao gerar pedidos de compra. Por favor, verifique quais pedidos foram criados e tente novamente.")

    console.error(error)
  } finally {
    exporting.value = false
  }
}

// Parse details.purchaseOrderItems as a TSV extracting columns 'Id', 'Quantidade Comprada', 'Nome' and 'Preço unitário'
// and return a list of object with properties 'productId', 'quantity', 'description' and 'unitPrice' respectively
const parsePurchaseOrderItems = () => {
  const lines = details.value.purchaseOrderItems.split('\n')

  const headers = lines[0].split('\t')

  const productIdIndex = headers.indexOf('Id')
  const quantityIndex = headers.indexOf('Quantidade Comprada')
  const descriptionIndex = headers.indexOf('Nome')
  const unitPriceIndex = headers.indexOf('Preço unitário')

  // Check if any of the columns are missing, show a message with the missing columns and throw an error
  const missingColumns = []

  if (productIdIndex === -1) {
    missingColumns.push('Id')
  }

  if (quantityIndex === -1) {
    missingColumns.push('Quantidade Comprada')
  }

  if (descriptionIndex === -1) {
    missingColumns.push('Nome')
  }

  if (unitPriceIndex === -1) {
    missingColumns.push('Preço unitário')
  }

  if (missingColumns.length > 0) {
    throw new Error(`Arquivo inválido. Por favor, verifique se o texto está no formato TSV e se as colunas ${missingColumns.join(', ')} estão presentes.`)
  }

  const purchaseOrderItems = []

  for (let i = 1; i < lines.length; i++) {
    const line = lines[i]

    const columns = line.split('\t')

    const productId = parseInt(columns[productIdIndex])
    const quantity = parseInt(columns[quantityIndex])
    const description = columns[descriptionIndex]
    const unitPriceText = columns[unitPriceIndex]

    const unitPrice = parseFloat(unitPriceText.replace('R$', ''))

    // Check if any of the values is a NaN
    if (isNaN(productId)) {
      throw new Error(`Arquivo inválido. Por favor, verifique se o texto está no formato TSV e se a coluna 'Id' está preenchida corretamente.`)
    }

    if (isNaN(quantity)) {
      throw new Error(`Arquivo inválido. Por favor, verifique se o texto está no formato TSV e se a coluna 'Quantidade Comprada' está preenchida corretamente.`)
    }

    if (isNaN(unitPrice)) {
      throw new Error(`Arquivo inválido. Por favor, verifique se o texto está no formato TSV e se a coluna 'Preço unitário' está preenchida corretamente.`)
    }

    if (quantity > 0) {
      purchaseOrderItems.push({
        productId,
        quantity,
        description,
        unitPrice,
      })
    }
  }

  return purchaseOrderItems
}

const back = () => {
  router.back()
}
</script>