import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/stock/product', request).then(result => result.response);
  },
  update(id, request) {
    return apiPut(`/magenta/stock/product/${id}`, request).then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/stock/product', query).then(result => result.response);
  },
  getProductById(id) {
    return apiGet(`/magenta/stock/product/${id}`).then(result => result.response);
  },
};
