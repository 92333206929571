<template>
  <div class="locale-changer">
    <el-dropdown placement="top" @command="handleLocaleSelect" trigger="click">
      <span class="el-dropdown-link">
        <img :src="currentLanguage.icon" style="width: 35px;" /><i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(lang, i) in languages"
          :key="`Lang${i}`"
          :command="lang.locale"
          style="height: 35px; line-height: 35px"
        >
          <img :src="lang.icon" style="width: 35px;" />
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import Storage from '../storage';
import brIcon from '../assets/languages/brazil.svg';
import enIcon from '../assets/languages/united-states.svg';

const languages = [
  { icon: brIcon, locale: 'pt' },
  { icon: enIcon, locale: 'en' },
];

export default {
  name: 'locale-changer',
  data() {
    return {
      currentLanguage: languages[0],
      languages,
    };
  },
  created() {
    this.$i18n.locale = Storage.get(Storage.keys.locale);
  },
  watch: {
    '$i18n.locale': function localeWatcher(val) {
      this.currentLanguage = languages.find(language => language.locale === val) || languages[0];
    },
  },
  methods: {
    handleLocaleSelect(locale) {
      Storage.add(Storage.keys.locale, locale);
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss" scoped>
.locale-changer {
  z-index: 1000;
  position: absolute;
  bottom: 20px;
  right: 20px;

  .el-dropdown-link {
    display: flex;
    align-items: center;
  }
}
</style>
