import Vue from 'vue';
import Router from 'vue-router';
import { Message } from 'element-ui';

import Storage from './storage';
import i18n from './i18n';

import Home from './views/Home.vue';
import Login from './views/Login.vue';

// Customer
import CustomerList from './views/customer/List.vue';
import CustomerDetail from './views/customer/Detail.vue';

// Order
import SubscriptionBoxOrderDetail from './views/fulfillment/subscription-box-order/SubscriptionBoxOrderDetail.vue';
import SubscriptionBoxOrderList from './views/fulfillment/subscription-box-order/SubscriptionBoxOrderList.vue';
import SubscriptionBoxOrderFraudReview from './views/fulfillment/subscription-box-order/fraud-review/SubscriptionBoxOrderFraudReview.vue';
import SubscriptionBoxOrderFraudReviewDetail from './views/fulfillment/subscription-box-order/fraud-review/SubscriptionBoxOrderFraudReviewDetail.vue';
import SubscriptionBoxOrderFraudReviewList from './views/fulfillment/subscription-box-order/fraud-review/SubscriptionBoxOrderFraudReviewList.vue';

// Fulfillment
import SubscriptionBoxEditionList from './views/fulfillment/subscription-box-edition/List.vue';
import SubscriptionBoxEditionDetail from './views/fulfillment/subscription-box-edition/Detail.vue';
import SubscriptionBoxProductionBatchList from './views/fulfillment/subscription-box-production-batch/List.vue';
import SubscriptionBoxProductionBatchDetail from './views/fulfillment/subscription-box-production-batch/Detail.vue';
import RegisterReturn from './views/fulfillment/subscription-box-order-return/RegisterReturn.vue';

// Stock
import ProductBrandList from './views/stock/product-brand/List.vue';
import ProductBrandDetail from './views/stock/product-brand/Detail.vue';
import ProductList from './views/stock/product/List.vue';
import ProductDetail from './views/stock/product/Detail.vue';
import StockEntryList from './views/stock/stock-entry/List.vue';
import StockEntryDetail from './views/stock/stock-entry/Detail.vue';
import StockSummaryList from './views/stock/stock-summary/List.vue';
import StockPhysicalAddress from './views/stock/stock-physical-address/StockPhysicalAddress.vue';
import ProductArrivalReportList from './views/stock/product-arrival-report/List.vue';

// Bling
import BlingPurchaseOrderExporter from './views/stock/bling/bling-purchase-order/BlingPurchaseOrderExporter.vue';

// Billing
import SubscriptionDetail from './views/billing/subscription/Detail.vue';
import ChargeList from './views/billing/charge/List.vue';

import ecommerceRoutes from './views/ecommerce/routes.js';
import marketingRoutes from './views/marketing/marketing-routes.js';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: { noAuth: true },
      component: Login,
    },
    {
      path: '/logout',
      name: 'logout',
      meta: { noAuth: true },
      component: Login,
    },
    {
      path: '/subscription-box-orders/:id/fraud-review',
      name: 'subscription-box-order-fraud-review',
      props: true,
      meta: {
        authorizedRoles: ['financial', 'customer-happiness', 'operations-lead'],
      },
      component: SubscriptionBoxOrderFraudReview,
    },
    {
      path: '/',
      name: 'home',
      meta: { noAuth: true },
      component: Home,
      children: [
        // Customer
        {
          path: '/customers',
          name: 'customer-list',
          meta: {
            authorizedRoles: ['operations-lead', 'financial', 'customer-happiness'],
          },
          component: CustomerList,
        },
        {
          path: '/customers/new',
          name: 'customer-new',
          meta: {
            authorizedRoles: ['operations-lead', 'financial', 'customer-happiness'],
          },
          component: CustomerDetail,
        },
        {
          path: '/customers/:id',
          name: 'customer-detail',
          meta: {
            authorizedRoles: ['operations-lead', 'financial', 'customer-happiness'],
          },
          props: true,
          component: CustomerDetail,
        },
        {
          path: '/subscription-box-orders/:id/fraud-review-detail',
          name: 'subscription-box-order-fraud-review-detail',
          props: true,
          meta: {
            authorizedRoles: ['financial', 'customer-happiness', 'operations-lead'],
          },
          component: SubscriptionBoxOrderFraudReviewDetail,
        },
        // Subscription Box Order
        {
          path: '/subscription-box-orders',
          name: 'subscription-box-order-list',
          meta: {
            authorizedRoles: ['financial', 'customer-happiness', 'operations-lead'],
          },
          component: SubscriptionBoxOrderList,
        },
        {
          path: '/subscription-box-orders/fraud-review',
          name: 'subscription-box-order-fraud-review-list',
          meta: {
            authorizedRoles: ['financial', 'customer-happiness', 'operations-lead'],
          },
          component: SubscriptionBoxOrderFraudReviewList,
        },
        {
          path: '/subscription-box-orders/:id',
          name: 'subscription-box-order-detail',
          props: true,
          meta: {
            authorizedRoles: ['financial', 'customer-happiness', 'operations-lead'],
          },
          component: SubscriptionBoxOrderDetail,
        },
        // Subscription Box Edition
        {
          path: '/subscription-box-editions',
          name: 'subscription-box-edition-list',
          meta: {
            authorizedRoles: ['financial'],
          },
          component: SubscriptionBoxEditionList,
        },
        {
          path: '/subscription-box-editions/new',
          name: 'subscription-box-edition-new',
          meta: {
            authorizedRoles: ['financial'],
          },
          component: SubscriptionBoxEditionDetail,
        },
        {
          path: '/subscription-box-editions/:id',
          name: 'subscription-box-edition-detail',
          meta: {
            authorizedRoles: ['financial'],
          },
          component: SubscriptionBoxEditionDetail,
        },
        // Subscription Box Production Batch
        {
          path: '/subscription-box-production-batch',
          name: 'subscription-box-production-batch-list',
          meta: {
            authorizedRoles: ['operations-lead', 'financial', 'warehouse-reception'],
          },
          component: SubscriptionBoxProductionBatchList,
        },
        {
          path: '/subscription-box-production-batch/new',
          name: 'subscription-box-production-batch-new',
          meta: {
            authorizedRoles: ['operations-lead', 'financial', 'warehouse-reception'],
          },
          component: SubscriptionBoxProductionBatchDetail,
        },
        {
          path: '/subscription-box-production-batch/:id',
          name: 'subscription-box-production-batch-detail',
          meta: {
            authorizedRoles: ['operations-lead', 'financial', 'warehouse-reception'],
          },
          component: SubscriptionBoxProductionBatchDetail,
        },
        // Stock
        {
          path: '/product-brands',
          name: 'product-brand-list',
          meta: {
            authorizedRoles: ['financial'],
          },
          component: ProductBrandList,
        },
        {
          path: '/product-brands/new',
          name: 'product-brand-new',
          meta: {
            authorizedRoles: ['financial'],
          },
          component: ProductBrandDetail,
        },
        {
          path: '/product-brands/:id',
          name: 'product-brand-detail',
          meta: {
            authorizedRoles: ['financial'],
          },
          component: ProductBrandDetail,
        },
        {
          path: '/products',
          name: 'product-list',
          meta: {
            authorizedRoles: ['financial', 'customer-happiness', 'marketing'],
          },
          component: ProductList,
        },
        {
          path: '/products/new',
          name: 'product-new',
          meta: {
            authorizedRoles: ['financial'],
          },
          component: ProductDetail,
        },
        {
          path: '/products/:id',
          name: 'product-detail',
          meta: {
            authorizedRoles: ['financial', 'customer-happiness', 'marketing'],
          },
          component: ProductDetail,
        },
        // Stock Entry
        {
          path: '/stock-entries',
          name: 'stock-entry-list',
          meta: {
            authorizedRoles: ['operations-lead', 'financial'],
          },
          component: StockEntryList,
        },
        {
          path: '/stock-entries/new',
          name: 'stock-entry-new',
          meta: {
            authorizedRoles: ['operations-lead', 'financial'],
          },
          component: StockEntryDetail,
        },
        {
          path: '/stock-entries/:id',
          name: 'stock-entry-detail',
          meta: {
            authorizedRoles: ['operations-lead', 'financial'],
          },
          component: StockEntryDetail,
        },
        // Stock Summary
        {
          path: '/stock-summary',
          name: 'stock-summary',
          meta: {
            authorizedRoles: ['operations-lead', 'financial', 'customer-happiness'],
          },
          component: StockSummaryList,
        },

        // Bling - Purchase order exporter
        {
          path: '/bling/purchase-order-exporter',
          name: 'bling-purchase-order-exporter',
          meta: {
            authorizedRoles: ['financial'],
          },
          component: BlingPurchaseOrderExporter,
        },
        // Subscription
        {
          path: '/subscriptions/:id',
          name: 'subscription-detail',
          props: true,
          meta: {
            authorizedRoles: ['customer-happiness'],
          },
          component: SubscriptionDetail,
        },
        // Register Exchange
        {
          path: '/register-return',
          name: 'register-return',
          component: RegisterReturn,
          meta: {
            authorizedRoles: ['operations-lead', 'warehouse-reception'],
          },
        },

        // Stock Physical Address Search
        {
          path: '/stock-physical-address',
          name: 'stock-physical-address',
          component: StockPhysicalAddress,
          meta: {
            authorizedRoles: ['operations-lead', 'warehouse-reception'],
          },
        },

        // Product Arrival Report List
        {
          path: '/product-arrival-report',
          name: 'product-arrival-report-list',
          component: ProductArrivalReportList,
          meta: {
            authorizedRoles: ['operations-lead', 'warehouse-reception', 'financial'],
          },
        },

        // Charge List
        {
          path: '/billing/charges',
          name: 'charge-list',
          component: ChargeList,
          meta: {
            authorizedRoles: ['customer-happiness', 'financial'],
          },
        },

        ...ecommerceRoutes,
        ...marketingRoutes
      ],
    },
  ],
});

const isLoggedIn = () => !!Storage.get(Storage.keys.auth.token);
const authRoles = () => {
  const encodedRole = Storage.get(Storage.keys.auth.role);
  const encodedRoles = Storage.get(Storage.keys.auth.roles);

  return encodedRoles ? JSON.parse(atob(encodedRoles)) : (encodedRole ? [atob(encodedRole)] : []);
}

const isAuthorized = (route) => {
  const roles = authRoles();

  if (route.meta.noAuth || roles.indexOf('owner') >= 0 || roles.indexOf('admin') >= 0) return true;

  if (route.meta.authorizedRoles) {
    for (var i = 0; i < route.meta.authorizedRoles.length; i += 1) {
      if (roles.indexOf(route.meta.authorizedRoles[i]) >= 0) return true;
    }
  }

  return false;
};

router.beforeEach((to, from, next) => {
  if (to.name === 'login') { next(); return; }

  if (to.name === 'home') {
    const roles = authRoles();
    if (roles.indexOf('customer-happiness') >= 0 || roles.indexOf('owner') >= 0 || roles.indexOf('admin') >= 0) {
      next('/customers');
      return;
    } else if (roles.indexOf('operations-lead') >= 0) {
      next('/subscription-box-production-batch');
      return;
    } else if (roles.indexOf('warehouse-reception') >= 0) {
      next('/product-arrival-report');
      return;
    } else if (roles.indexOf('financial') >= 0) {
      next('/products');
      return;
    }
  }

  if (!isLoggedIn()) {
    Message({
      message: i18n.getInstance().t('error.login'),
      type: 'warning',
      center: true,
      showClose: true,
    });

    next(`/login?resumeTo=${window.encodeURIComponent(to.fullPath)}`);
    return;
  }

  if (!isAuthorized(to)) {
    Message({
      message: i18n.getInstance().t('error.unauthorized'),
      type: 'warning',
      center: true,
      showClose: true,
    });

    next(`/login?resumeTo=${window.encodeURIComponent(to.fullPath)}`);
    return;
  }

  next();
});

export default router;
