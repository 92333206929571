<template>
  <section class="product-brand-list">
    <snowflake-search-list context="product-brand" :service="productBrandService">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" :label="$t('brand.id')" width="80" sortable></el-table-column>

        <!-- Name -->
        <el-table-column prop="name" :label="$t('brand.name')" :show-overflow-tooltip="true" sortable></el-table-column>

      </template>
    </snowflake-search-list>

  </section>
</template>

<script>
import ProductBrandService from '@/services/stock/ProductBrandService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'product-brand-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      productBrandService: ProductBrandService,
    };
  },
};
</script>
