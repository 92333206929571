<template lang="pug">
.inventory-transaction

  el-form(ref="form", :rules="formRules", :model="inventoryTransaction", label-width="160px", label-position="top")

    el-row
      el-col(:span="8")
        el-form-item(prop="type", label="Tipo de movimentação")
          el-radio-group(v-model="inventoryTransaction.type")
            el-radio-button(label="INBOUND") Entrada
            el-radio-button(label="OUTBOUND") Saída

    el-row(:gutter="20")
      el-col(:span="inventoryTransaction.type == 'INBOUND' ? 12 : 24")
        el-form-item(prop="quantity", label="Quantidade")
          el-input(
            v-model="inventoryTransaction.quantity",
            type="number",
            :precision="0",
            :step="1",
            :min="0"
          )
            template(slot="append") unidades

      el-col(v-show="inventoryTransaction.type == 'INBOUND'", :span="12")
        el-form-item(prop="unitCost", label="Custo unitário")
          el-input-number.stretched(
            :value="unitCost",
            @change="setUnitCost",
            type="number",
            :precision="2",
            :step="0.1",
            :min="0",
            :controls="false"
          )

    el-form-item(label="Descrição")
      el-input(v-model="inventoryTransaction.description", type="textarea", :rows="3", maxlength="250", show-word-limit)

    el-form-item(style="text-align: right")
      el-popconfirm.delete-confirm(title="Tem certeza de que deseja criar esta movimentação?", @confirm="create")
        el-button(type="primary", slot="reference", :loading="saving") Incluir movimentação
</template>
<script setup>
import { computed, ref } from 'vue';

import InventoryTransactionService from '@/services/ecommerce/inventory/InventoryTransactionService'
import { useMessage } from '@/utils/Message';

const message = useMessage()

const props = defineProps({
  productVariantId: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['created'])

const saving = ref(false)

const form = ref()
const formRules = ref({
  type: [ { required: true, trigger: 'blur', message: 'Escolha um tipo' } ],
  quantity: [
    { required: true, trigger: 'blur', message: 'Quantidade obrigatória' },
    { min: 1, trigger: 'blur', message: 'Quantidade obrigatória' }
  ],
  unitCost: [
    {
      validator: (rule, value, callback, source) => {
        if (inventoryTransaction.value.type == 'INBOUND' && (!value || value <= 0)) {
          callback(new Error('Custo unitário obrigatório'))
        } else {
          callback()
        }
      },
      trigger: 'blur',
      message: 'Custo unitário obrigatório'
    }
  ],
})

const initialValue = {
  type: null,
  quantity: 0,
  unitCost: null,
  description: null,
  reference: null
}

const unitCost = computed(() => {
  return inventoryTransaction.value.unitCost ? inventoryTransaction.value.unitCost / 100.0 : null
})

const setUnitCost = (value) => {
  inventoryTransaction.value.unitCost = value ? value * 100 : null
}

const inventoryTransaction = ref(initialValue)

const create = async () => {
  saving.value = true

  try {
    await form.value.validate();
  } catch(e) {
    saving.value = false
    message.warning("Verifique os dados")

    return
  }

  try {
    const request = {
      productVariantId: props.productVariantId,
      type: inventoryTransaction.value.type,
      quantity: inventoryTransaction.value.quantity,
      unitCost: inventoryTransaction.value.unitCost,
      description: inventoryTransaction.value.description,
      reference: inventoryTransaction.value.reference
    }

    const transaction = await InventoryTransactionService.create(request)

    message.success("Movimentação de estoque criada com sucesso")

    emit('created', transaction)
  } catch(e) {
    message.error("Falha ao criar movimentação de estoque")
  } finally {
    saving.value = false
  }
}

const reset = () => {
  inventoryTransaction.value = initialValue
  form.value?.resetFields()
}

defineExpose({
  reset
})
</script>