<template lang="pug">
el-dialog(
  title="Nova assinatura",
  :visible="visible",
  @update:visible="emit('update:visible', $event)"
)

  el-form(ref="form", :model="data", :rules="rules", label-width="160px")

    el-form-item(label="Plano de assinatura", prop="planId")
      el-select(v-model="data.planId", placeholder="Plano de assinatura", style="width: 100%")
        el-option(
          v-for="plan in availablePlans"
          :key="plan.planId"
          :label="plan.name"
          :value="plan.planId"
        )

    el-form-item(prop="shippingAddressId", label="Endereço de entrega")
      RemoteSearchSelect(
        v-model="data.shippingAddressId",
        :search-function="ShippingAddressService.search",
        :find-function="ShippingAddressService.getShippingAddressById",
        :label-function="(item) => `${item.street}, ${item.number} - ${item.district} - ${item.city}/${item.state} (${item.postalCode})`"
        :value-function="(item) => item.id",
        :custom-options="{ filters: [ `customerId:${customerId}` ] }"
        placeholder="Selecione o endereço de entrega"
      )

    el-form-item(prop="paymentProfileId", label="Perfil de pagamento")
      RemoteSearchSelect(
        v-model="data.paymentProfileId",
        :search-function="VindiService.searchVindiPaymentProfile",
        :find-function="findPaymentProfile",
        :label-function="(item) => paymentProfileDescription(item)"
        :value-function="(item) => item.paymentProfileId",
        :custom-options="{ filters: [ `customerId:${customerId}` ] }"
        placeholder="Selecione o perfil de pagamento"
      )

    el-form-item(label="Data de início", prop="startAt")
      el-date-picker(
        type="date",
        v-model="data.startAt",
        format="dd/MM/yyyy",
        value-format="yyyy-MM-ddT12:00:00.000Z")

  template(slot="footer", style="text-align: right")
    el-button(type="primary", @click="promptSave", :loading="saving") Salvar

</template>
<script setup>
import RemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue'

import { default as SubscriptionService, availablePlans } from '@/services/billing/SubscriptionService'
import ShippingAddressService from '@/services/ShippingAddressService'
import SnowflakeService from '@/services/SnowflakeService'
import VindiService from '@/services/billing/VindiService'

import { useMessage } from '@/utils/Message'
import { useConfirm } from '@/utils/MessageBox'
import { ref, reactive, watch } from 'vue'

const props = defineProps({
  customerId: {
    type: Number,
    required: true
  },
  visible: {
    type: Boolean,
    required: true
  },
  startAt: [
    {
      validator: (rule, value, callback) => {
        const now = new Date()
        const date = new Date(value)

        if (Math.floor(date.getTime() /(1000 * 60 * 60 * 24)) < Math.floor((now.getTime() / (1000 * 60 * 60 * 24)))) {
          callback(new Error("Deve ser uma data futura"))
        } else {
          callback()
        }
      },
      trigger: 'change'
    }
  ],
})

const emit = defineEmits(['update:visible', 'created'])

const confirm = useConfirm()
const message = useMessage()

const form = ref()
const rules = ref({
  planId: [
    { required: true, message: 'Por favor, selecione o plano de assinatura', trigger: 'blur' }
  ],
  paymentProfileId: [
    { required: true, message: 'Por favor, selecione o perfil de pagamento', trigger: 'blur' }
  ],
  shippingAddressId: [
    { required: true, message: 'Por favor, selecione o endereço de entrega', trigger: 'blur' }
  ]
})

const data = reactive({
  planId: null,
  paymentProfileId: null,
  shippingAddressId: null,
  startAt: null,
})

const findPaymentProfile = async (id) => {
  return await VindiService.getVindiPaymentProfile(2, id)
}

const paymentProfileDescription = (paymentProfile) => {
  if (paymentProfile.vindiPaymentType === 'CREDIT_CARD') {
    return `Cartão de Crédito - ${paymentProfile.vindiCreditCardInformation.brand} - ${paymentProfile.vindiCreditCardInformation.displayNumber}`;
  } else if (paymentProfile.vindiPaymentType === 'BANK_SLIP') {
    return 'Boleto Bancário'
  } else if (paymentProfile.vindiPaymentType === 'PIX') {
    return 'PIX'
  } else {
    return 'Tipo de pagamento desconhecido'
  }
}

const saving = ref(false)
const promptSave = async () => {
  try {
    await form.value.validate()
  } catch(e) {
    message.warning("Verifique os dados preenchidos")

    return
  }

  await confirm("Você tem certeza de que deseja criar esta assinatura? Esta operação não pode ser desfeita", "Criar nova assinatura", {
    confirmButtonText: 'Sim, criar assinatura',
    cancelButtonText: 'Cancelar',
    type: 'warning'
  }).then(async () => {
    return await save()
  })
}

const save = async () => {
  saving.value = true

  try {
    const startAt = data.startAt == null || new Date(data.startAt).getTime() > new Date().getTime() ? data.startAt : null

    await SnowflakeService.createSubscription({
      billingPlatformId: 2,
      customerId: props.customerId,
      shippingAddressId: data.shippingAddressId,
      paymentProfile: {
        paymentProfileId: data.paymentProfileId,
      },
      subscription: {
        planId: data.planId,
        startAt: startAt,
      }
    })

    message.success("Assinatura criada com sucesso")

    emit('created')
    emit('update:visible', false)
  } catch(e) {
    message.error("Não foi possível criar a assinatura. Por favor, tente novamente mais tarde")

    throw e
  } finally {
    saving.value = false
  }
}

watch(() => props.visible, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    data.planId = null
    data.paymentProfileId = null
    data.shippingAddressId = null
    data.startAt = null
  }
})
</script>
