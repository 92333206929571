<template lang="pug">
.subscription-box-order--add-to-production-batch

  el-dialog(
    title="Adicionar a um lote de montagem"
    :visible="visible"
    @update:visible="updateVisible($event)",
  )

    el-form(ref="form", :rules="rules", :model="data")

      p Selecione o lote ao qual deseja adicionar este pedido:

      el-form-item(prop="batchId")
        RemoteSearchSelect(
          v-model="data.batchId"
          placeholder="Lote de montagem"
          :search-function="SubscriptionBoxProductionBatchService.search"
          :find-function="SubscriptionBoxProductionBatchService.get"
          :value-function="(item) => item.id",
          :label-function="(item) => `${item.name} (#${item.id})`"
        )

    .footer(slot="footer")
      el-popconfirm(title="Tem certeza de que deseja adicionar o pedido a este lote?", @confirm="save")
        el-button(
          slot="reference",
          title="Adicionar pedido a este lote",
          type="primary",
          icon="el-icon-plus",
          :loading="loading"
        ) Adicionar

</template>
<script setup>
import SubscriptionBoxProductionBatchService from '@/services/fulfillment/SubscriptionBoxProductionBatchService';
import { ref, watch } from 'vue';

import RemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue'
import { useMessage } from '@/utils/Message';

const props = defineProps({
  subscriptionBoxOrderId: {
    type: Number,
    required: true
  },
  visible: Boolean
})

const emit = defineEmits(['update:visible', 'executed'])

const updateVisible = (value) => {
  emit('update:visible', value)
}

const message = useMessage()

const data = ref({ batchId: null })
const form = ref()
const rules = ref({
  batchId: {
    required: true, message: "Escolha um lote de montagem"
  }
})

const loading = ref(false)

const save = async () => {
  loading.value = true

  try {
    await form.value.validate()
  } catch(w) {
    loading.value = false
    return
  }

  try {
    await SubscriptionBoxProductionBatchService.addOrder(data.value.batchId, props.subscriptionBoxOrderId)

    message.success(`Pedido adicionado ao lote de montagem #${data.value.batchId} com sucesso`)

    updateVisible(false)

    emit('executed')
  } catch (e) {
    message.error("Falha ao adicionar pedido a lote de montagem. Por favor, tente novamente")

    throw e
  } finally {
    loading.value = false
  }
}

watch(() => props.visible, (value) => {
  if (!value) {
    data.value = { batchId: null }
    loading.value = false
    form.value.resetFields()
  }
})
</script>