<template lang="pug">
.product-media-input
  ImageUpload(
    :beforeUpload="beforeUpload",
    :onUpload="onUpload",
    :value="fileUrl",
    @success="onSuccess",
    @clear="onClear",
    :width="width",
    :height="height",
    :fit="fit",
    :clearable="clearable"
  )
</template>
<script setup>
import ImageUpload from '../ImageUpload.vue';

import MediaService from '@/services/media/MediaService';
import { useMessage } from '@/utils/Message';
import { computed, ref, onMounted, watch } from 'vue';

import { v4 as uuidv4 } from 'uuid'

const STORAGE_PREFIX = process.env.VUE_APP_MEDIA_STORAGE_PREFIX;

const message = useMessage()

const props = defineProps({
  filePrefix: String,
  beforeUpload: Function,
  value: String,
  thumbnailUrl: String,
  clearable: {
    type: Boolean,
    default: () => true
  },
  width: {
    type: Number,
    required: false,
    default: () => 200
  },
  height: {
    type: Number,
    required: false,
    default: () => 200
  },
  fit: {
    type: String,
    required: false,
    default: () => 'contain',
    validator: (value) => ['cover', 'contain', 'fill', 'none', 'scale-down'].indexOf(value) !== -1
  }
})

const emit = defineEmits([ 'input', 'update:media' ])

const media = ref()

const fileUrl = computed(() => {
  if (props.thumbnailUrl) {
    return props.thumbnailUrl
  } else if (media.value) {
    const fitIn = props.fit == 'contain' ? 'fit-in/' : ''
    const fill = props.fit == 'contain' ? 'filters:fill(fff)/' : ''
    const w = props.width ? props.width : 200
    const h = props.height ? props.height : 200

    return `https://images.boxmagenta.com.br/${fitIn}${w}x${h}/filters:quality(75)/filters:background_color(fff)/${fill}filters:format(jpeg)/${STORAGE_PREFIX + media.value.fileName}`
  }

  return null
})

const nextUUID = ref(uuidv4())

const fullFileName = (fileName) => {
  return (props.filePrefix ? props.filePrefix : '') + nextUUID.value + '-' + fileName
}

const onUpload = async (file) => {
  const fileName = fullFileName(file.name)
  const uploadUrlResponse = await MediaService.generateUploadUrl({ fileName, contentType: file.type })

  return { url: uploadUrlResponse.url, headers: uploadUrlResponse.headers }
}

const onSuccess = async ({ file }) => {
  const mediaId = nextUUID.value
  const fileName = fullFileName(file.name)

  try {
    const media = await MediaService.create({
      id: mediaId,
      fileName,
      contentType: file.type
    })

    emit("input", mediaId)
    emit("update:media", media)
  } finally {
    nextUUID.value = uuidv4()
  }
}

const onClear = () => {
  emit("input", null)
  emit("update:media", null)
}

const refreshMedia = async () => {
  const mediaId = props.value

  if (mediaId) {
    try {
      media.value = await MediaService.find(mediaId)
      emit("update:media", media.value)
    } catch (e) {
      message.error(`Falha ao carregar imagem: ${e}`)

      console.error(e)
    }
  } else {
    media.value = null
  }
}

onMounted(() => {
  watch(() => props.value, refreshMedia, { immediate: true })
})
</script>