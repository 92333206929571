<template lang="pug">
.create-reward-transaction
  el-form(:model="data" :rules="rules" ref="form" label-width="160px")
    el-form-item(label="Tipo da transação" prop="type")
      el-radio-group(v-model="data.type" size="small")
        el-radio-button(:label="'CREDIT'") Crédito
        el-radio-button(:label="'DEBIT'") Débito

    el-form-item(label="Qtd. de pontos" prop="amount" size="small")
      el-input-number(v-model="data.amount" controls-position="right" :min="1" :max="9999999999" :step="100" :precision="0" placeholder="Número de pontos")

    el-form-item(label="Razão" prop="reason" size="small")
      el-input(v-model="data.reason" placeholder="Razão da transação")

    el-form-item(style="text-align: right")
      el-button(type="primary" @click="createTransaction", :loading="creating")
        i.el-icon-plus
        span Criar transação
</template>
<script setup>
import { useMessage } from '@/utils/Message';
import { useUser } from '@/utils/User';
import { ref } from 'vue';

import RewardService from '@/services/promotions/RewardService';

const props = defineProps({
  customerId: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['created'])

const message = useMessage()

const user = useUser()

const form = ref()

const rules = ref({
  type: [
    { required: true, message: 'Selecione um tipo', trigger: 'change' }
  ],
  amount: [
    { required: true, message: 'Informe um valor', trigger: 'change' }
  ],
  reason: [
    { required: true, message: 'Informe uma razão', trigger: 'change' }
  ]
})

const creating = ref(false)

const data = ref({
  type: null,
  amount: 100,
  reason: ''
})

const createTransaction = async () => {
  try {
    await form.value.validate()
  } catch (e) {
    message.error('Verifique os dados informados')
  }

  creating.value = true

  try {
    const request = {
      customerId: props.customerId,
      type: data.value.type,
      amount: data.value.amount,
      origin: 'backoffice',
      details: {
        'userId': user.user.value,
        'reason': data.value.reason
      }
    }

    const response = await RewardService.createTransaction(request)

    if (response.successful) {
      message.success('Transação criada com sucesso')

      emit('created', response.transaction)
    } else {
      message.error('Transação não permitida. Verifique o valor da transação')
    }
  } catch (e) {
    message.error(`Erro ao criar transação: ${e}`)
  } finally {
    creating.value = false
  }
}

const reset = () => {
  data.value = {
    type: null,
    amount: 100,
    reason: ''
  }

  form.value?.resetFields()
}

defineExpose({
  reset
})
</script>