<template>
  <section class="stock-summary-list">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">{{ $t('homeTitle') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t('stockSummary.list') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <snowflake-search-list
      context="stock-summary"
      :service="stockSummaryService"
      defaultSortColumn="productId"
      :action="false"
      :operations="false"
      :details="false"
      :highlightCurrentRow="false"
    >
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="productId" :label="$t('stockSummary.productId')" width="140" sortable>
        </el-table-column>

        <!-- Product Name -->
        <el-table-column prop="productName" :label="$t('stockSummary.productName')" :show-overflow-tooltip="true" sortable>
        </el-table-column>

        <!-- Quantity -->
        <el-table-column prop="quantity" :label="$t('stockSummary.quantity')" sortable>
        </el-table-column>

        <!-- Last updated -->
        <el-table-column prop="updatedAt" :label="$t('stockSummary.lastUpdated')" sortable>
          <template slot-scope="scope">
            {{parseDate(scope)}}
          </template>
        </el-table-column>

      </template>
    </snowflake-search-list>
  </section>
</template>

<script>
import moment from 'moment';

import StockSummaryService from '@/services/stock/StockSummaryService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'stock-summary-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      stockSummaryService: StockSummaryService,
    };
  },
  methods: {
    parseDate(value) {
      const { updatedAt } = value.store.table.data[value.$index];
      return moment(updatedAt).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>
