<template lang="pug">
.ecommerce-product-variant-item

  el-tabs(tab-position="left", v-model="selectedTab", @tab-click="tabChanged")
    el-tab-pane(name="basic-info", label="Info. básicas")
      .tab-pane-content
        ProductVariantBasicInfo(
          ref="basicInfoForm",
          :value="productVariant",
          @input="updateProductVariant"
        )

    el-tab-pane(name="price", label="Precificação")
      .tab-pane-content
        ProductVariantPricing(
          ref="priceForm",
          :value="productVariant",
          @input="updateProductVariant"
        )

    el-tab-pane(name="shipping", label="Envio")
      .tab-pane-content
        ProductVariantShipping(
          ref="shippingForm",
          :value="productVariant",
          @input="updateProductVariant"
        )

    el-tab-pane(name="inventory", label="Estoque", :lazy="true")
      .tab-pane-content
        ProductVariantInventory(
          :value="productVariant",
          @input="updateProductVariant"
        )

    el-tab-pane(name="attributes", label="Atributos")
      .tab-pane-content
        h2 Atributos

        p Selecione as opções que descrevem as características do produto:

        ProductAttributeForm.height-limited(
          :value="productVariant.attributes",
          :attributes="productCategoryAttributes",
          @input="setAttributes"
        )

    el-tab-pane(name="media", label="Imagens", :lazy="true")
      .tab-pane-content
        ProductVariantMedia(:value="productVariant", @input="updateProductVariant")

</template>
<script setup>
/* eslint-disable vue/no-mutating-props */

import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'

import ProductAttributeForm from '@/components/input/ecommerce/product-attribute/ProductAttributeForm.vue'
import ProductVariantBasicInfo from './tabs/ProductVariantBasicInfo.vue'
import ProductVariantInventory from './tabs/ProductVariantInventory.vue'
import ProductVariantMedia from './tabs/ProductVariantMedia.vue'
import ProductVariantPricing from './tabs/ProductVariantPricing.vue'
import ProductVariantShipping from './tabs/ProductVariantShipping.vue'

// Component
const props = defineProps({
  productCategory: {
    type: Object
  },
  value: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['input'])

const router = useRouter()
const route = useRoute()

const productVariant = computed(() => props.value)

// Tabs
const selectedTab = ref("basic-info")

// Form
const basicInfoForm = ref()
const priceForm = ref()
const shippingForm = ref()

// Entity data
const productCategoryAttributes = computed(() => {
  if (!props.productCategory) return []

  return props.productCategory.hierarchyAttributes
})

// UI actions
const tabChanged = () => {
  router.replace({ query: { ...route.query, tab: selectedTab.value } })
}

const validate = async () => {
  var hasValidBasicInfo
  var hasValidPrice
  var hasValidShipping

  try {
    await basicInfoForm.value.validate()

    hasValidBasicInfo = true
  } catch(e) {
    hasValidBasicInfo = false
  }

  try {
    await priceForm.value.validate()

    hasValidPrice = true
  } catch(e) {
    hasValidPrice = false
  }

  try {
    await shippingForm.value.validate()

    hasValidShipping = true
  } catch(e) {
    hasValidShipping = false
  }

  if (!hasValidBasicInfo) {
    selectedTab.value = "basic-info"
  } else if (!hasValidPrice) {
    selectedTab.value = "price"
  } else if (!hasValidShipping) {
    selectedTab.value = "shipping"
  }

  const valid = hasValidBasicInfo && hasValidPrice && hasValidShipping

  if (!valid) {
    tabChanged()
  }

  return valid
}

const updateProductVariant = (productVariant) => {
  emit('input', productVariant)
}

const setAttributes = (attributes) => {
  updateProductVariant({ ...productVariant.value, attributes })
}

onMounted(() => {
  selectedTab.value = route.query['tab'] || selectedTab.value;
})

defineExpose({
  validate
})
</script>
<style lang="scss">
.ecommerce-product-variant-item {
  min-height: 60vh;

  .tab-pane-content {
    padding: 0 20px;
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;

    h2 {
      margin-top: 0;
    }

    .el-form--label-top .el-form-item__label {
      padding-bottom: 0;
    }
  }
}
</style>