<template>
  <section class="stock-physical-address" v-loading.fullscreen.lock="loading">

    <!-- Search -->
    <snowflake-remote-search-select
      v-model="product"
      placeholder="Product"
      :search-function="productService.search"
      :find-function="productService.getProductById"
      :value-function="onSelect"
      :object-value="true"
      :default-first-option="false"
      :clearable="true"
      @change="onChange">
    </snowflake-remote-search-select>

    <div class="product-information" v-if="product != null && product.id != null">

      <!-- ID -->
      <el-row :gutter="10">
        <el-col class="item" :span="6">
          <span class="item-label">ID</span>
          <el-input clear="item-input" v-model="product.id" disabled></el-input>
        </el-col>

        <!-- Name -->
        <el-col class="item" :span="18">
          <span class="item-label">Nome</span>
          <el-input clear="item-input" v-model="product.name" disabled></el-input>
        </el-col>

      </el-row>

      <!-- Barcode -->
      <el-row :gutter="10">
        <el-col class="item" :span="24">
          <span class="item-label">Códigos de Barra</span>
          <el-input @keyup.enter.native="addBarcode" v-model="barcodeInput">
            <el-button slot="append" icon="el-icon-plus" @click="addBarcode"></el-button>
          </el-input>

          <div class="barcodes-container">
            <div v-if="product.barcodes && product.barcodes.length > 0">
              <el-tag
                v-for="(barcode, index) in product.barcodes"
                class="spaced"
                type="primary"
                :key="barcode"
                :disable-transitions="true">
                {{ barcode }}
                <i class="el-tag__close el-icon-close" @click.stop="removeBarcode(index)" />
              </el-tag>
            </div>
            <div v-else>
              <div class="no-barcodes">Nenhum código registrado</div>
            </div>
          </div>
        </el-col>
      </el-row>

      <div>
        <el-row>
          <el-col class="item" :span="24">
            <span class="item-label">Endereço Físico</span>
            <el-select v-model="stockPhysicalAddressId" placeholder="Endereço físico" filterable class="stretched">
              <el-option-group key="stock-physical-addresses" label="Novos endereços">
                <el-option
                  v-for="address in stockPhysicalAddresses"
                  :key="address.id"
                  :label="addressDescription(address)"
                  :value="address.id">
                </el-option>
              </el-option-group>
              <el-option-group key="old-stock-physical-addresses" label="Endereços antigos (area - rua - lado - coluna - sequencia)">
                <el-option
                  v-for="address in oldStockPhysicalAddresses"
                  :key="address.id"
                  :label="oldAddressDescription(address)"
                  :value="address.id">
                </el-option>
              </el-option-group>
            </el-select>
          </el-col>
        </el-row>
      </div>

      <!-- Actions -->
      <div>
        <el-button type="primary" @click="save">Salvar</el-button>
        <el-button @click="cancel">Cancelar</el-button>
      </div>
    </div>
  </section>
</template>

<script>
import ProductService from '@/services/stock/ProductService';
import StockPhysicalAddressService from '@/services/stock/StockPhysicalAddressService';

import SnowflakeRemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue';

const NEW_STOCK_PHYSICAL_ADDRESS_AREA = 'S';

export default {
  name: 'stock-physical-address',
  components: {
    SnowflakeRemoteSearchSelect,
  },
  data() {
    return {
      loading: true,
      productService: ProductService,
      product: null,
      barcodeInput: null,
      stockPhysicalAddressId: null,
      oldStockPhysicalAddresses: [],
      stockPhysicalAddresses: [],
      stockPhysicalAddress: {
        area: [],
        street: [],
        side: [],
        module: [],
        level: [],
        sequence: [],
      },
    };
  },
  async created() {
    const stockPhysicalAddresses =
      (await StockPhysicalAddressService.getAll())
        .sort((a, b) => a.sequence.charCodeAt(0) - b.sequence.charCodeAt(0))
        .sort((a, b) => a.level.charCodeAt(0) - b.level.charCodeAt(0))
        .sort((a, b) => a.module.charCodeAt(0) - b.module.charCodeAt(0))
        .sort((a, b) => a.side.charCodeAt(0) - b.side.charCodeAt(0))
        .sort((a, b) => a.street.charCodeAt(0) - b.street.charCodeAt(0))
        .sort((a, b) => a.area.charCodeAt(0) - b.area.charCodeAt(0));

    this.oldStockPhysicalAddresses = stockPhysicalAddresses.filter(address => address.area !== NEW_STOCK_PHYSICAL_ADDRESS_AREA);
    this.stockPhysicalAddresses = stockPhysicalAddresses.filter(address => address.area === NEW_STOCK_PHYSICAL_ADDRESS_AREA);

    this.loading = false;
  },
  methods: {
    oldAddressDescription(address) {
      return `${address.area} - ${address.street} - ${address.side} - ${address.module} - ${address.level}- ${address.sequence}`
    },
    addressDescription(address) {
      return `${address.street}${address.module}${address.level}`
    },
    onChange() {
      if (this.product && this.product.physicalAddressId) {
        this.stockPhysicalAddressId = this.product.physicalAddressId;
      }

      document.activeElement.blur();
    },
    onSelect(product) {
      if (!product.physicalAddress) {
        return {
          ...product,
          physicalAddress: {
            area: null,
            street: null,
            side: null,
            module: null,
            level: null,
            sequence: null,
          },
        };
      }

      return product;
    },
    removeBarcode(index) {
      this.product.barcodes.splice(index, 1);
    },
    addBarcode() {
      if (!this.product.barcodes) {
        this.product.barcodes = [];
      }

      if (this.product.barcodes.indexOf(this.barcodeInput) >= 0) {
        this.$message.warning('Código de barras já inserido.');
      } else {
        this.product.barcodes.push(this.barcodeInput);
      }

      this.barcodeInput = null;
    },
    async save() {
      this.loading = true;

      const request = {
        barcodes: this.product.barcodes,
        stockPhysicalAddressId: this.stockPhysicalAddressId,
      };

      try {
        this.product = await ProductService.update(this.product.id, request);

        this.$message.success('Salvo com sucesso.');
      } catch (e) {
        this.$message.error(e.message || 'Falha ao salvar');
      }

      this.loading = false;
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
  .stock-physical-address {

    .product-information {
      margin-top: 20px;

      .el-row {
        margin-bottom: 20px;
      }

      .item {
        text-align: left;

        .item-label {
          font-weight: 700;
          margin-bottom: 5px;
          display: block;
        }
      }

      .product-address {
        margin-top: 20px;
        text-align: center;
      }

      div.barcodes-container {
        text-align: left;
        border: #c1c1c1 dashed 1.5px;
        border-radius: 4px;
        margin-top: 8px;
        padding: 8px;

        div.no-barcodes {
          text-align: center;
          font-size: 12;
          line-height: 40px;
          color: #5e7382;
        }

        .el-tag {
          margin: 4px;
        }
      }
    }
  }
</style>
