<template lang="pug">
.image-upload(:style="style")
  el-upload.image-uploader(
    :accept="acceptedTypes",
    action="https://backoffice-api.boxmagenta.com.br/"
    :beforeUpload="beforeUpload"
    :showFileList="false",
    :httpRequest="onHttpRequest",
    :onSuccess="onSuccess",
    :onError="onError"
  )
    .image-upload-progress(v-if="uploading")
      el-progress(type="circle", :percentage="progress", :status="progressStatus", :width="width * .75")

    img.image-preview(v-if="value && !uploading", :src="value", :style="previewStyle")

    i.el-icon-plus.image-uploader-icon(v-if="!value && !uploading")

  .image-uploader-controls(v-if="clearable && value && !uploading")
    i.el-icon-close.image-upload-controls-button(@click="clearImage")
</template>
<script setup>
import { useMessage } from '@/utils/Message';
import { computed, ref } from 'vue';

const message = useMessage()

const props = defineProps({
  acceptedTypes: {
    type: String,
    required: false,
    default: () => 'image/jpg, image/jpeg, image/png, image/webp'
  },
  maxSize: {
    type: Number,
    required: false,
    default: () => 20 * 1024 * 1024 // 20 MB
  },
  beforeUpload: {
    type: Function,
    required: false,
  },
  onUpload: {
    type: Function,
    required: true
  },
  clearable: {
    type: Boolean,
    default: () => true
  },
  value: {
    type: String
  },
  width: {
    type: Number,
    default: () => 200
  },
  height: {
    type: Number,
    default: () => 200
  },
  fit: {
    type: String,
    default: () => 'contain',
    validator: (value) => ['cover', 'contain', 'fill', 'none', 'scale-down'].indexOf(value) !== -1
  }
})

const emit = defineEmits([ 'success', 'error', 'clear' ])

const uploading = ref(false)
const progress = ref(0)
const progressStatus = ref(null)

const style = computed(() => {
  return {
    width: props.width ? props.width + 'px' : null,
    height: props.height ? props.height + 'px' : null
  }
})

const previewStyle = computed(() => {
  return {
    width: props.width ? props.width + 'px' : null,
    height: props.height ? props.height + 'px' : null,
    objectFit: props.fit
  }
})

const clearImage = () => {
  emit('clear')
}

const beforeUpload = async (file) => {
  if (props.acceptedTypes && props.acceptedTypes.indexOf(file.type) == -1) {
    message.error("Tipo de arquivo não suportado")

    throw new Error("Tipo de arquivo não suportado")
  }

  if (props.maxSize && file.size > props.maxSize) {
    message.error("Arquivo deve ter no máximo 5MB")

    throw new Error("Arquivo deve ter no máximo 5MB")
  }

  if (props.beforeUpload) {
    try {
      uploading.value = true;

      return await props.beforeUpload(file)
    } catch (e) {
      uploading.value = false;

      throw e
    }
  } else {
    return true
  }
}

const onHttpRequest = (option) => {
  uploading.value = true;
  progress.value = 0;
  progressStatus.value = null;

  return props.onUpload(option.file)
    .then(({ url, headers }) => {
      return upload({ ...option, action: url, headers })
    })
    .then((result) => {
      progress.value = 100
      progressStatus.value = "success"

      setTimeout(() => {
        uploading.value = false
        progress.value = 0
        progressStatus.value = null
      }, 500)

      return result
    })
    .catch((reason) => {
      message.error("Falha ao carregar arquivo")

      progress.value = 100
      progressStatus.value = "exception"

      setTimeout(() => {
        uploading.value = false
        progress.value = 0
        progressStatus.value = null
       }, 3000)

      throw reason
    })
}

const onError = (error) => {
  emit('error', error)
}

const onSuccess = (response, file) => {
  emit('success', { response, file: file.raw })
}

// AJAX - copied from Element with some minor modifications
function getError(action, option, xhr) {
  let msg;
  if (xhr.response) {
    msg = `${xhr.response.error || xhr.response}`;
  } else if (xhr.responseText) {
    msg = `${xhr.responseText}`;
  } else {
    msg = `fail to post ${action} ${xhr.status}`;
  }

  const err = new Error(msg);
  err.status = xhr.status;
  err.method = 'put';
  err.url = action;

  return err;
}

function getBody(xhr) {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

function upload(option) {
  if (typeof XMLHttpRequest === 'undefined') {
    return;
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const action = option.action;

    if (xhr.upload) {
      xhr.upload.onprogress = (e) => {
        if (e.total > 0) {
          e.percent = e.loaded / e.total * 100;
        }

        progress.value = Math.floor(e.percent);

        option.onProgress(e);
      };
    }

    xhr.onerror = function error(e) {
      reject(e);
    };

    xhr.onload = function onload() {
      if (xhr.status < 200 || xhr.status >= 300) {
        return option.onError(getError(action, option, xhr));
      }

      resolve(getBody(xhr));
    };

    xhr.open('put', action, true);

    if (option.withCredentials && 'withCredentials' in xhr) {
      xhr.withCredentials = true;
    }

    const headers = option.headers || {};

    for (var item in headers) {
      if (headers[item] !== null && item != 'host') {
        const headerValue = headers[item]

        if (Array.isArray(headerValue)) {
          headerValue.forEach((v) => {
            xhr.setRequestHeader(item, v);
          })
        } else {
          xhr.setRequestHeader(item, headerValue);
        }
      }
    }

    xhr.send(option.file);
  });
}
</script>
<style lang="scss">
.image-upload {
  display: block;
  position: relative;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  user-select: none;

  &:hover{
    border-color: #409EFF;
  }

  .image-uploader {
    width: 100%;
    height: 100%;

    .el-upload {
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      overflow: hidden;
      text-align: center;
    }

    .image-upload-progress {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: middle;
    }

    .image-preview {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: middle;
    }

    .image-uploader-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      color: #8c939d;
      width: 100%;
      height: 100%;
      line-height: 100%;
    }
  }

  .image-uploader-controls {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: border-box;
    pointer-events: none;

    &:hover {
      opacity: 1;
    }

    .image-upload-controls-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      font-size: 12px;
      margin-left: 4px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.7);
      transition: background-color 0.16s ease-in-out;
      color: #8c939d;
      cursor: pointer;
      pointer-events: auto;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
        color: #409EFF;
      }
    }
  }
}

</style>