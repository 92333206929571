<template>
  <section class="order-production-batch-detail" v-if="!loading.productionBatch" v-loading="saving || printing">

    <el-header>
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isNew ? 'Novo lote de montagem' : `Lote de montagem #${orderProductionBatch.id}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-form
      ref="form"
      :model="orderProductionBatch"
      :rules="rules"
       label-width="90px">

      <!-- Name -->
      <el-form-item label="Nome" prop="name">
        <el-input
          v-model="orderProductionBatch.name"
          placeholder="Name"
          class="stretched">
        </el-input>
      </el-form-item>
    </el-form>

    <!-- Packages -->
    <el-table
      ref="packagesTable"
      height="480"
      :data="packages"
      style="margin-bottom: 22px"
      v-loading="loading.packages"
      @selection-change="onPackageSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>

      <el-table-column
        prop="id"
        label="ID"
        sortable
        width="96"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.id }}
          <router-link :to="`/packages/${scope.row.id}`" target='_blank'>
              <i class="el-icon-edit-outline link"></i>
            </router-link>
        </template>
      </el-table-column>

      <el-table-column
        prop="customerId"
        label="ID Cliente"
        sortable
        width="120"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.customerId }}
          <router-link :to="`/customers/${scope.row.customerId}`" target='_blank'>
            <i class="el-icon-edit-outline link"></i>
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
        prop="status"
        label="Status"
        width="200"
        :filters="statusFilter"
        :filter-method="filterHandler"
        sortable
      >
        <template slot-scope="scope">
          {{ orderPackageStatus[scope.row.status] }}
        </template>
      </el-table-column>

      <el-table-column prop="nfe" width="120" label="NF" sortable>
        <template slot-scope="scope">
          {{ scope.row.nfe ? scope.row.nfe.number : '' }}
        </template>
      </el-table-column>

      <el-table-column
        prop="shippingCompany"
        label="Transportadora"
        :filters="shippingCompanyFilter"
        :filter-method="filterHandler"
        sortable
      >

        <template slot-scope="scope">
          {{ scope.row.shipping.shippingService }}
        </template>

      </el-table-column>

      <el-table-column
        prop="trackingCode"
        label="Cód. de Rastreamento"
        :show-overflow-tooltip="true">
      </el-table-column>

      <el-table-column width="140" align="center">
        <!-- eslint-disable-next-line -->
        <template slot="header" slot-scope="scope">
          <el-dropdown trigger="click" @command="handleOrderPackageCommand">
            <el-button size="medium" class="el-dropdown-link">
              {{ "Ações" }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">

              <el-dropdown-item command="add-packages" :disabled="isNew">
                Adicionar pacotes
              </el-dropdown-item>

              <el-dropdown-item command="generate-nfe" divided :disabled="isNew">
                Gerar notas fiscais
              </el-dropdown-item>

              <el-dropdown-item command="sync-nfe" divided :disabled="isNew">
                Sincronizar pacotes (NFe e Rastreamento)
              </el-dropdown-item>

              <el-dropdown-item command="print-bom" divided :disabled="isNew">
                Imprimir lista de materiais
              </el-dropdown-item>

              <el-dropdown-item command="print-danfes-correios" divided :disabled="isNew">
                Correios - Imprimir notas fiscais
              </el-dropdown-item>

              <el-dropdown-item command="print-shipping-labels-correios" :disabled="isNew">
                Correios - Imprimir etiquetas de transporte
              </el-dropdown-item>

              <el-dropdown-item command="print-danfes-total-express" divided :disabled="isNew">
                Total Express - Imprimir notas fiscais
              </el-dropdown-item>

              <el-dropdown-item command="print-shipping-labels-total-express" :disabled="isNew">
                Total Express - Imprimir etiquetas de transporte
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <el-popconfirm
            title="Deseja realmente remover este pacote?"
            confirm-button-text="Sim"
            cancel-button-text="Não"
            @confirm="doRemovePackage(scope.$index, scope.row)"
          >
            <el-button slot="reference" size="mini" type="danger">Remover</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center">{{ packageSelection.length}} de {{ packages.length }} pedidos selecionados</div>

    <br/>

    <!--- Dialogs -->
    <!-- Dialog - Add Packages -->
    <el-dialog
      class="text-left-aligned"
      title="Adicionar pacotes"
      :visible.sync="addPackagesDialogVisible"
      v-loading="loading.addPackages">

      <el-form
        ref="addPackagesForm"
        label-width="180px"
        label-position="top"
        :rules="addPackagesRules"
        :model="addPackages" >

        <!-- Number of orders -->
        <el-form-item label="Quantidade de pacotes a adicionar" prop="next">
          <el-input-number
            v-model="addPackages.next"
            controls-position="right"
            :precision="0"
            :step="1"
            :min="1"
            :max="1000"
            class="stretched"
            style="text-align: left;">
          </el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addPackakgesDialogVisible = false">{{ "Voltar" }}</el-button>
        <el-button
          type="primary"
          @click="doAddPackages">
          {{ 'Adicionar Pedidos' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- Dialog - Generate NFes -->
    <el-dialog
      class="text-left-aligned"
      title="Gerar Notas Fiscais"
      :visible.sync="generateNFeDialogVisible">

      <span v-if="!loading.generateNFe">
        Tem certeza de que deseja gerar notas fiscais para os pacotes selecionados?
      </span>

      <div class="text-center-aligned" v-else>

       <el-progress type="circle" :percentage="generateNFeProgress" :status="generateNFeStatus" ></el-progress>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="generateNFeDialogVisible = false">Fechar</el-button>
        <el-button
          type="primary"
          :disabled="loading.generateNFe"
          @click="doGenerateNFe">
          {{ 'Gerar Notas Fiscais' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- Dialog - Sync NFes -->
    <el-dialog
      class="text-left-aligned"
      title="Sincronizar Pedidos"
      :visible.sync="syncNFeDialogVisible">

      <span v-if="!loading.syncNFe">
        Tem certeza de que deseja gerar notas fiscais para os pacotes selecionados?
      </span>

      <div class="text-center-aligned" v-else>

       <el-progress type="circle" :percentage="syncNFeProgress" :status="syncNFeStatus" ></el-progress>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="syncNFeDialogVisible = false">Fechar</el-button>
        <el-button
          type="primary"
          :disabled="loading.syncNFe"
          @click="doSyncNFe">
          {{ 'Sincronizar Pedidos' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- iFrame -->
    <iframe id="printing-frame" ref="printingFrame" :srcdoc="printingFrameSrcDoc"></iframe>

  </section>
</template>
<script>
import lodash from 'lodash';
import OrderProductionBatchService from '@/services/ecommerce/order/OrderProductionBatchService';

import MagentaIcon from '@/assets/logo.png'

const orderPackageStatus = {
  PLANNED: 'Planejado',
  PENDING: 'Pendente',
  IN_FULFILLMENT: 'Em montagem',
  READY_TO_POST: 'Pronto para postagem',
  IN_TRANSIT: 'Em trânsito',
  AWAITING_CUSTOMER_PICKUP: 'Aguardando retirada',
  DELIVERED: 'Entregue',
  CANCELED: 'Cancelado',
};

export default {
  name: 'order-production-batch-detail',
  data() {
    return {
      orderPackageStatus,

      rules: {
        name: [
          { required: true, trigger: 'blur', message: 'Preencha o nome deste lote' },
        ],
      },

      loading: {
        productionBatch: false,
        packages: false,
        addPackages: false,
        generateNFe: false,
        syncNFe: false,
      },

      saving: false,
      printing: false,

      orderProductionBatch: {},

      packages: [],
      packageSelection: [],

      statusFilter: (() => {
        const options = [];
        Object.keys(orderPackageStatus).forEach((key) => {
          options.push({ text: orderPackageStatus[key], value: key });
        });

        return options;
      })(),
      shippingCompanyFilter: [
        { text: 'Correios', value: 'CORREIOS' },
        { text: 'Total Express', value: 'TOTAL_EXPRESS' },
      ],

      addPackages: { next: 10 },
      addPackagesDialogVisible: false,
      addPackagesRules: {
        next: [
          { required: true, trigger: 'blur' },
        ],
      },

      generateNFeDialogVisible: false,
      generateNFeProgress: 0,
      generateNFeStatus: null,

      syncNFeDialogVisible: false,
      syncNFeProgress: 0,
      syncNFeStatus: null,

      printingFrameSrcDoc: '',

      showNotifications: false
    };
  },
  async created() {
    if (this.isNew) {
      this.orderProductionBatch = {};
      this.loading.productionBatch = false;

      return;
    }

    this.loading.productionBatch = true;

    this.orderProductionBatch = await OrderProductionBatchService.get(this.$route.params.id);
    await this.loadPackages();

    this.loading.productionBatch = false;

    if (Notification.permission == "default") {
      (await Notification.requestPermission()) == "granted"
    }

    this.showNotifications = Notification.permission == "granted"
  },
  computed: {
    isNew() {
      return this.$route.name === 'ecommerce-order-production-batch-new';
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) return;

      this.saving = true;

      if (this.isNew) {
        this.orderProductionBatch = await this.create();

        this.$router.replace(
          {
            name: 'ecommerce-order-production-batch-detail',
            params: { id: this.orderProductionBatch.id },
          },
        );
        this.saving = false;
      } else {
        this.orderProductionBatch = await this.update();
        this.saving = false;
      }

      this.$message.success('Lote salvo com sucesso.');
    },
    cancel() {
      this.$router.back();
    },
    create() {
      return OrderProductionBatchService.create(this.orderProductionBatch);
    },
    update() {
      return OrderProductionBatchService.update(
        this.orderProductionBatch.id,
        this.orderProductionBatch,
      );
    },
    async loadPackages() {
      this.loading.packages = true;

      const selectedPackageIds = lodash.map(this.packageSelection, o => o.id);

      const packages = await OrderProductionBatchService.getPackages(this.orderProductionBatch.id);

      this.packages = lodash.sortBy(
        packages,
        [
          o => o.status !== 'PENDING',
          o => o.status !== 'IN_FULFILLMENT',
          'status',
          'id',
        ],
      );

      setTimeout(() => {
        const selectedPackages = lodash.filter(this.packages, o => selectedPackageIds.includes(o.id));
        this.$refs.packagesTable.clearSelection();
        selectedPackages.forEach(o => this.$refs.packagesTable.toggleRowSelection(o, true));
      }, 100);

      this.loading.packages = false;
    },
    onPackageSelectionChange(val) {
      this.packageSelection = val;
    },
    // Actions
    handleOrderPackageCommand(command) {
      switch (command) {
        case 'add-packages':
          this.showAddPackagesDialog();
          break;
        case 'generate-nfe':
          this.showGenerateNFeDialog();
          break;
        case 'sync-nfe':
          this.showSyncNFeDialog();
          break;
        case 'print-bom':
          this.printBillOfMaterials();
          break;
        case 'print-danfes-total-express':
          this.printDANFes('TOTAL_EXPRESS');
          break;
        case 'print-shipping-labels-total-express':
          this.printShippingLabels('TOTAL_EXPRESS');
          break;
        case 'print-danfes-correios':
          this.printDANFes('CORREIOS');
          break;
        case 'print-shipping-labels-correios':
          this.printShippingLabels('CORREIOS');
          break;
        default: break;
      }
    },
    showAddPackagesDialog() {
      this.addPackagesDialogVisible = true;
      this.addPackages = { next: 50 };
    },
    async doAddPackages() {
      try {
        await this.$refs.addPackagesForm.validate();
      } catch (e) {
        throw new Error('Selecione uma quantidade válida de pacotes a serem adicionados');
      }

      this.loading.addPackages = true;

      try {
        const oldProductionBatch = this.orderProductionBatch;

        this.orderProductionBatch = await OrderProductionBatchService.addPackages(
          this.orderProductionBatch.id,
          { next: this.addPackages.next },
        );

        const addedCount = this.orderProductionBatch.orderPackages.length - oldProductionBatch.orderPackages.length;

        this.$message.success(`${addedCount} pacotes adicionados com sucesso!`);
      } catch (e) {
        this.$message.error(`Falha ao adicionar pacotes: ${e.message}`);
      } finally {
        this.loading.addPackages = false;
        this.addPackagesDialogVisible = false;
        this.loadPackages();
      }
    },
    async doRemovePackage(index, orderPackage) {
      this.saving = true;

      this.orderProductionBatch = await OrderProductionBatchService.removePackage(
        this.$route.params.id,
        { orderPackageId: orderPackage.id },
      );
      await this.loadPackages();

      this.$message.success('Pacote removido com sucesso');

      this.saving = false;
    },
    showGenerateNFeDialog() {
      this.generateNFeProgress = 0;
      this.generateNFeStatus = null;
      this.loading.generateNFe = false;
      this.generateNFeDialogVisible = true;
    },
    async doGenerateNFe() {
      this.generateNFeProgress = 0;
      this.loading.generateNFe = true;

      const selectedOrderPackages = this.packageSelection.filter(orderPackage => orderPackage.status === 'PENDING');
      const chunks = lodash.chunk(selectedOrderPackages, 10);

      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
          const orderPackageIds = chunk.map(orderPackage => orderPackage.id);

          // eslint-disable-next-line no-await-in-loop
          const results = await OrderProductionBatchService.generateNFes({ orderPackageIds });
          const failedResults = lodash.filter(results, r => !r.successful);

          if (failedResults.length > 0) {
            this.generateNFeStatus = 'exception';
            this.$message.error(`Falha ao gerar notas fiscais: ${failedResults[0].failureCause}`);

            return;
          }

          this.generateNFeProgress += Math.round((chunk.length / selectedOrderPackages.length) * 100);
        }

        this.generateNFeProgress = 100;
        this.generateNFeStatus = 'success';

        this.notify('Notas fiscais geradas com sucesso');
      } catch (e) {
        this.generateNFeStatus = 'exception';
        this.$message.error(`Falha ao gerar notas fiscais: ${e.message}`);

        this.notify('Falha ao gerar notas fiscais');
      } finally {
        this.loadPackages();
      }
    },
    showSyncNFeDialog() {
      this.syncNFeProgress = 0;
      this.syncNFeStatus = null;
      this.loading.syncNFe = false;
      this.syncNFeDialogVisible = true;
    },
    async doSyncNFe() {
      this.syncNFeProgress = 0;
      this.loading.syncNFe = true;

      const selectedOrderPackages = this.packageSelection.filter(orderPackage => orderPackage.status !== 'PENDING');
      const chunks = lodash.chunk(selectedOrderPackages, 10);

      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
          const orderPackageIds = chunk.map(orderPackage => orderPackage.id);

          // eslint-disable-next-line no-await-in-loop
          await OrderProductionBatchService.syncNFes({ orderPackageIds });

          this.syncNFeProgress += Math.round((chunk.length / selectedOrderPackages.length) * 100);
        }

        this.syncNFeProgress = 100;
        this.syncNFeStatus = 'success';
      } catch (e) {
        this.syncNFeStatus = 'exception';
      } finally {
        this.loadPackages();
      }
    },
    async printBillOfMaterials() {
      this.printingFrameSrcDoc = '';

      const selectedOrderPackageIds = this.packageSelection.map(order => order.id);

      try {
        this.printing = true;
        const billOfMaterials = await OrderProductionBatchService.generateBillOfMaterials({ orderPackageIds: selectedOrderPackageIds });

        this.printHtml(billOfMaterials.html);
      } catch (e) {
        this.$message.error(`Falha ao imprimir lista de materiais: ${e.message}`);
      } finally {
        this.printing = false;
      }
    },
    async printDANFes(shippingCompany) {
      this.printingFrameSrcDoc = '';

      const allNFeAccessKeysPresent = this.packageSelection.every(orderPackage => orderPackage.nfe && orderPackage.nfe.nfeAccessKey);
      if (!allNFeAccessKeysPresent) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pacotes selecionados ainda não possuem chave de acesso para NFe.
                      <br/>
                      <strong>Gere as notas fiscais e sincronize esses pedidos</strong>
                    </span>`,
          center: true,
        });

        return;
      }

      const selectedOrderPackageIds = this.packageSelection
        .filter(orderPackage =>
          orderPackage.nfe &&
          orderPackage.nfe.accessKey &&
          (!shippingCompany || orderPackage.shipping.shippingCompany === shippingCompany)
        )
        .map(orderPackage => orderPackage.id);

      try {
        this.printing = true;
        const danfe = await OrderProductionBatchService.generateDANFes({ orderPackageIds: selectedOrderPackageIds });

        this.printHtml(danfe.html);
      } catch (e) {
        this.$message.error(`Falha ao imprimir notas fiscais: ${e.message}`);
      } finally {
        this.printing = false;
      }
    },
    async printShippingLabels(shippingCompany) {
      this.printingFrameSrcDoc = '';

      const allNFePresent = this.packageSelection.every(orderPackage => orderPackage.nfe);
      const allTrackingCodePresent = this.packageSelection.every(orderPackage => orderPackage.shipping && orderPackage.shipping.trackingCode);
      if (!allNFePresent) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem NFe.
                      <br/>
                      <strong>Gere as notas fiscais e sincronize esses pedidos</strong>
                    </span>`,
          center: true,
        });

        return;
      }

      if (!allTrackingCodePresent) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem código de rastreamento.
                      <br/>
                      <strong>Sincronize esses pedidos</strong>
                    </span>`,
          center: true,
        });

        return;
      }

      const selectedOrderPackageIds = this.packageSelection
        .filter(orderPackage => (!shippingCompany || orderPackage.shipping.shippingCompany === shippingCompany))
        .map(orderPackage => orderPackage.id);

      try {
        this.printing = true;
        const shippingLabels = await OrderProductionBatchService.generateShippingLabels({ orderPackageIds: selectedOrderPackageIds});

        this.printHtml(shippingLabels.html);
      } catch (e) {
        this.$message.error(`Falha ao imprimir etiquetas de transporte: ${e.message}`);
      } finally {
        this.printing = false;
      }
    },
    printHtml(html) {
      this.printingFrameSrcDoc = html;
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
    notify(message) {
      if (this.showNotifications && !document.hasFocus()) {
        const notification = new Notification(message, { icon: '/favicon-96x96.png' })
        notification.onclick = () => {
          window.focus()
          window.parent.focus()
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#printing-frame {
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: 1px;
  height: 1px;
}

.order-production-batch-detail {
  .text-left-aligned {
    text-align: left;
  }

  .text-center-aligned {
    text-align: center;
  }

  .selection-state {
    div::before {
      content: ' \25CF';
      height: 8px;
      width: 8px;
    }

    .nothing-selected {
      color: #FFF;
    }

    .has-undesired {
      color: orange;
    }

    .no-undesired {
      color: green;
    }

    .selection-failed {
      color: red;
    }
  }

  i.link {
    color: #606266;
    text-decoration: none;
  }
}
</style>
