import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/stock/entry', request).then(result => result.response);
  },
  update(id, request) {
    return apiPut(`/magenta/stock/entry/${id}`, request).then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/stock/entry', query).then(result => result.response);
  },
  getStockEntryById(id) {
    return apiGet(`/magenta/stock/entry/${id}`).then(result => result.response);
  },
};
