import 'whatwg-fetch';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/pt-br';
import locale from 'element-ui/lib/locale';
import VueTheMask from 'vue-the-mask';

import './theme.scss';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import EventBus from './event-bus';
import Storage from './storage';
import FontAwesome from './fontawesome';

Vue.config.productionTip = false;

Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus;
    },
  },
  $storage: {
    get() {
      return Storage;
    },
  },
});

locale.use(lang);

Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.use(FontAwesome);
Vue.use(VueTheMask);

new Vue({
  i18n: i18n.initialize(),
  router,
  render: h => h(App),
}).$mount('#app');
