import { apiGet, apiPost } from '../_common';

export const availablePlans = [
  { name: "Plano Mensal - R$ 169,90", planId: "br.com.magenta.vindi.basic.monthly.16990" },
  { name: "Plano Semestral - 6x R$ 159,90", planId: "br.com.magenta.vindi.basic.biannually.95940" },
  { name: "Plano Anual - 12x R$ 149,90", planId: "br.com.magenta.vindi.basic.annually.179880" },

  { name: "Plano Semestral com cobrança mensal - 6x R$ 159,90", planId: "br.com.magenta.vindi.basic.biannually.95940.fallback.monthly.15990" },
  { name: "Plano Anual com cobrança mensal - 12x R$ 149,90", planId: "br.com.magenta.vindi.basic.annually.179880.fallback.monthly.14990" },
]

export const oldMigrationPlans = [
  { name: "Plano Mensal - R$ 159,90", planId: "br.com.magenta.vindi.basic.monthly.15990" },
  { name: "Plano Semestral - 6x R$ 149,90", planId: "br.com.magenta.vindi.basic.biannually.89940" },
  { name: "Plano Anual - 12x R$ 139,90", planId: "br.com.magenta.vindi.basic.annually.167880" },

  { name: "Plano Semestral com cobrança mensal - 6x R$ 149,90", planId: "br.com.magenta.vindi.basic.biannually.89940.fallback.monthly.14990" },
  { name: "Plano Anual com cobrança mensal - 12x R$ 139,90", planId: "br.com.magenta.vindi.basic.annually.167880.fallback.monthly.13990" },
]

export default {
  getSubscriptionById(id) {
    return apiGet(`/magenta/billing/subscription/${id}`).then(result => result.response);
  },
  getDiscounts(id) {
    return apiGet(`/magenta/billing/subscription/${id}/discounts`).then(result => result.response);
  },
  getInvoices(id) {
    return apiGet(`/magenta/billing/subscription/${id}/invoices`).then(result => result.response);
  },
  getCancellationInfo(id) {
    return apiGet(`/magenta/billing/subscription/${id}/cancellation-fee`)
      .then(result => result.response);
  },
  cancel(id, request) {
    return apiPost(`/magenta/billing/subscription/${id}/cancel`, request).then(result => result.response);
  },
  migrate(id, request) {
    return apiPost(`/magenta/billing/subscription/${id}/migrate`, request).then(result => result.response);
  },
  reactivate(id) {
    return apiPost(`/magenta/billing/subscription/${id}/reactivate`).then(result => result.response);
  }
};
