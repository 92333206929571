<template lang="pug">
.media-gallery-input(:style="galleryStyle")

  .media-gallery-item(v-for="mediaId, index in value", :key="mediaId", :style="previewStyle")
    .media-gallery-item--movable(
      @mousedown="mouseDown($event, index)",
      @mouseover="mouseOver($event, index)"
    )
      MediaGalleryImage(
        :mediaId="mediaId",
        :width="width",
        :height="height",
        :fit="fit"
      )

      .media-gallery-item-controls
        .control-button.expand-button(@click="showViewer(index)")
          i.el-icon-view

        el-popconfirm(title="Tem certeza de que deseja excluir?", @confirm="deleteMedia(index)")
          .control-button.delete-button(slot="reference")
            i.el-icon-delete

  MediaInput(
    :filePrefix="filePrefix",
    :minWidth="500",
    :minHeight="500",
    :width="width",
    :height="height",
    :fit="fit",
    @input="appendMedia",
    :clearable="false"
  )
</template>
<script setup>
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from "v-viewer"

import { computed, ref } from 'vue';
import MediaInput from '../MediaInput.vue';
import MediaGalleryImage from '../gallery/MediaGalleryImage.vue';
import MediaService from '@/services/media/MediaService';

const STORAGE_PREFIX = process.env.VUE_APP_MEDIA_STORAGE_PREFIX;

const props = defineProps({
  filePrefix: String,
  value: {
    type: Array,
    default: () => []
  },
  width: {
    type: Number,
    default: () => 200
  },
  height: {
    type: Number,
    default: () => 200
  },
  fit: {
    type: String,
    default: () => 'contain',
    validator: (value) => ['cover', 'contain', 'fill', 'none', 'scale-down'].indexOf(value) !== -1
  }
})

const emit = defineEmits(['input'])

const galleryStyle = computed(() => {
  return {
    "grid-template-columns": `repeat(auto-fill, ${props.width}px)`
  }
})

const previewStyle = computed(() => {
  return {
    width: props.width ? props.width + 'px' : null,
    height: props.height ? props.height + 'px' : null
  }
})

const fileNameToFullsizeUrl = (fileName) => {
  const imageSize = window.devicePixelRatio * 1080
  const aspectRatio = props.width / props.height

  const fitIn = props.fit == 'contain' ? 'fit-in/' : ''
  const fill = props.fit == 'contain' ? 'filters:fill(fff)/' : ''
  const w = imageSize
  const h = Math.round(imageSize / aspectRatio)

  return `https://images.boxmagenta.com.br/${fitIn}${w}x${h}/filters:quality(75)/filters:background_color(fff)/${fill}filters:format(jpeg)/${STORAGE_PREFIX + fileName}`
}

const appendMedia = (mediaId) => {
  const copy = props.value.slice()
  copy.push(mediaId)

  emit('input', copy)
}

const deleteMedia = (index) => {
  const copy = props.value.slice()
  copy.splice(index, 1)

  emit('input', copy)
}

const loadMedias = async () => {
  const findPromises = props.value.map((mediaId) => MediaService.find(mediaId))

  return await Promise.all(findPromises)
}

const showViewer = async (index) => {
  const medias = await loadMedias()

  viewerApi({
    options: {
      initialViewIndex: index
    },
    images: medias.map((media) => fileNameToFullsizeUrl(media.fileName))
  })
}

// DND
const isMouseDown = ref(false)

const draggingItemIndex = ref()

const initialX = ref(0)
const initialY = ref(0)
const dragOffsetX = ref(0)
const dragOffsetY = ref(0)
const draggingElement = ref(null)

const mouseDown = (event, index) => {
  draggingItemIndex.value = index

  isMouseDown.value = true
  initialX.value = event.clientX
  initialY.value = event.clientY
  dragOffsetX.value = event.offsetX
  dragOffsetY.value = event.offsetY
  draggingElement.value = event.target

  draggingElement.value.style.left = `${initialX.value - dragOffsetX.value}px`
  draggingElement.value.style.top = `${initialY.value - dragOffsetY.value}px`

  draggingElement.value.classList.add("dragging")

  window.addEventListener("mousemove", mouseMove)
  window.addEventListener("mouseup", mouseUp)
}

const mouseUp = (event) => {
  if (!isMouseDown.value) return

  draggingItemIndex.value = null

  draggingElement.value.classList.remove("dragging")

  draggingElement.value.style.left = ""
  draggingElement.value.style.top = ""

  isMouseDown.value = false
  draggingElement.value = null

  window.removeEventListener("mousemove", mouseMove)
  window.removeEventListener("mouseup", mouseUp)
}

const mouseMove = (event) => {
  if (!isMouseDown.value) return

  draggingElement.value.style.left = `${event.clientX - dragOffsetX.value}px`
  draggingElement.value.style.top = `${event.clientY - dragOffsetY.value}px`
}

const mouseOver = (event, index) => {
  if (!isMouseDown.value || draggingItemIndex.value == index) return

  const copy = props.value.slice()
  copy.splice(index, 0, copy.splice(draggingItemIndex.value, 1)[0])

  draggingItemIndex.value = index

  emit('input', copy)
}
</script>
<style lang="scss" scoped>
.media-gallery-input {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, 196px);
  grid-template-rows: auto;
  gap: 10px;

  .media-gallery-item {
    display: block;
    border-radius: 6px;
    background-color: #efefef;

    .media-gallery-item--movable {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      box-sizing: border-box;
      transform: translateZ(0);
      background-color: #fff;
      overflow: hidden;
      user-select: none;
      cursor: grab;

      &.dragging {
        position: fixed;
        z-index: 1000;
        pointer-events: none;
      }

      &:hover {
        .media-gallery-item-controls {
          opacity: 1;
        }
      }
    }

    .media-gallery-item-controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity .2s ease;
      opacity: 0;
      pointer-events: none;

      * {
        pointer-events: all;
      }

      .control-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.7);
        transition: background-color 0.16s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 255, 255, 1);
          border-color: #409EFF;
          color: #409EFF;
        }
      }

      .expand-button {
        position: absolute;
        top: 4px;
        left: 4px;
      }

      .delete-button {
        position: absolute;
        top: 4px;
        right: 4px;
      }
    }
  }

}
</style>