<template lang="pug">
.subscription-box-order--recreate-order

  el-dialog(
    title="Recriar pedido"
    :visible="visible"
    @update:visible="updateVisible($event)"
  )

    .step-1(v-show="step == 0")
      p Ao recriar este pedido, você estará criando um novo pedido baseado neste e&#32;
        b este pedido será cancelado.&#32;
        | Caso este pedido já esteja em produção, uma nota fiscal de devolução será criada.

      p É possível manter os produtos que já foram selecionados ou criar um novo pedido em branco, sem produtos selecionados:

      el-row(type="flex", justify="space-around", style="margin: 48px 0")
        el-col(:span="12", style="text-align: center")
          el-button(type="primary", title="Manter produtos selecionados", @click="initiate(true)") Manter produtos selecionados

        el-col(:span="12", style="text-align: center")
          el-button(type="primary", title="Selecionar novos produtos", @click="initiate(false)") Selecionar novos produtos

    .step-2(v-show="step == 1")
      p Selecione os produtos deste a serem devolvidos ao estoque.&#32;
        b Certifique-se de que os produtos selecionados estão em boas condições, caso esta box esteja sendo retornada.

      el-table(
        ref="productsTable",
        :data="subscriptionBoxOrderProducts",
        @selection-change="changedProductSelection",
        size="mini",
        height="280",
        style="margin-bottom: 48px"
      )
        el-table-column(label="Produtos a serem devolvidos ao estoque")
          el-table-column(type="selection")
          el-table-column(prop="id" label="ID", width="120")
          el-table-column(prop="name" label="Nome")

    .step-3(v-show="step == 2")
      p Selecione os produtos adicionais que deseja copiar para o novo pedido.&#32;
        | Brindes e produtos resgatados pelo programa de pontos devem ser mantidos,&#32;
        | desde disponíveis em estoque.

      el-table(
        ref="additionalProductsTable",
        :data="subscriptionBoxOrderAdditionalProducts",
        @selection-change="changedAdditionalProductSelection",
        size="mini",
        height="320"
      )
        el-table-column(label="Produtos adicionais a serem mantidos")
          el-table-column(type="selection")
          el-table-column(prop="id" label="ID")
          el-table-column(prop="name" label="Nome")

    el-steps(:active="step")
      el-step
      el-step
      el-step


    .footer(slot="footer")
      el-button(v-if="step > 0", title="Voltar para o passo anterior", @click="back") Voltar

      el-button(v-if="step == 1", type="primary", title="Ir para o próximo passo", @click="next") Próximo

      el-popconfirm(v-show="step==2", title="Tem certeza de que deseja recriar este pedido?", @confirm="recreate")
        el-button(
          slot="reference",
          title="Recriar pedido",
          type="primary",
          icon="el-icon-document-copy",
          :loading="loading",
          :disabled="disabled",
          style="margin-left: 10px"
        ) Recriar

</template>
<script setup>
import SubscriptionBoxOrderService from '@/services/fulfillment/SubscriptionBoxOrderService'
import { ref, watch } from 'vue'

import { useMessage } from '@/utils/Message'
import { useRouter } from 'vue2-helpers/vue-router';
import { setMaxListeners } from 'events';


const props = defineProps({
  subscriptionBoxOrderId: {
    type: Number,
    required: true
  },
  subscriptionBoxOrderProducts: {
    type: Array,
    default: () => []
  },
  subscriptionBoxOrderAdditionalProducts: {
    type: Array,
    default: () => []
  },
  visible: Boolean
})

const emit = defineEmits(['update:visible', 'executed'])

const updateVisible = (value) => {
  emit('update:visible', value)
}

const router = useRouter()
const message = useMessage()

const keepSelectedProducts = ref(false)
const form = ref()
const rules = ref({})

const loading = ref(false)
const disabled = ref(false)

const step = ref(0)

const productsTable = ref()
const additionalProductsTable = ref()

const selectedProducts = ref([])
const selectedAdditionalProducts = ref([])

const initiate = (keepProducts) => {
  keepSelectedProducts.value = keepProducts

  if (keepProducts) {
    step.value = 2
  } else {
    step.value = 1
  }

  productsTable.value.clearSelection()
  additionalProductsTable.value.clearSelection()

  props.subscriptionBoxOrderProducts.forEach(p => { productsTable.value.toggleRowSelection(p, true)})
  props.subscriptionBoxOrderAdditionalProducts.forEach(p => { additionalProductsTable.value.toggleRowSelection(p, true)})

  selectedProducts.value = [ ...props.subscriptionBoxOrderProducts ]
  selectedAdditionalProducts.value = [ ...props.subscriptionBoxOrderAdditionalProducts ]
}

const next = () => [
  step.value = step.value + 1
]

const back = () => {
  step.value = step.value - 1

  if (keepSelectedProducts.value && step.value == 1) {
    step.value = 0
  }
}

const changedProductSelection = (val) => {
  selectedProducts.value = val
}

const changedAdditionalProductSelection = (val) => {
  selectedAdditionalProducts.value = val
}

const recreate = async () => {
  loading.value = true

  try {
    const request = {
      keepSelectedProducts: keepSelectedProducts.value,
      cancelOriginalOrder: true,
      productsToReturn: selectedProducts.value.map((p) => p.id),
      additionalProductsToKeep: selectedAdditionalProducts.value.map((p) => p.id)
    }

    const newOrder = await SubscriptionBoxOrderService.recreateSubscriptionBoxOrder(props.subscriptionBoxOrderId, request)

    message.success(`Pedido recriado com sucesso. Novo pedido: #${newOrder.id}`)

    const { href } = router.resolve({ name: "subscription-box-order-detail", params: { id: newOrder.id } })

    setTimeout(() => {
      updateVisible(false)

      emit("executed")

      window.open(href, "_blank").focus()
    }, 2000)

    disabled.value = true
  } catch (e) {
    message.error("Falha ao recriar pedido. Por favor, tente novamente")

    throw e
  } finally {
    loading.value = false
  }
}

watch(() => props.visible, (value) => {
  if (!value) {
    keepSelectedProducts.value = false
    loading.value = false
    disabled.value = false
    step.value = 0

    selectedProducts.value = []
    selectedAdditionalProducts.value = []

    form.value.resetFields()
  }
})
</script>
<style lang="scss" scoped>
.subscription-box-order--recreate-order {

  .el-dialog__body {
    p {
      word-break: normal;
    }
  }

}
</style>