<template lang="pug">
.customer-addresses

  SearchList(
    ref="addressSearchList",
    context="address",
    :showSearchBar="false",
    :service="ShippingAddressService",
    :searchOptions="searchOptions",
    :action="false",
    :details="false"
    :operations="false",
    :keepStateOnUrl="false"
  )

    template(slot="tableRows")

      el-table-column(prop="id", label="ID", width="80")
        template(slot-scope="scope")
          span # {{ scope.row.id }}

      el-table-column(prop="street", label="Endereço", show-overflow-tooltip)

      el-table-column(prop="number", label="Número", width="72")

      el-table-column(prop="complement", label="Complemento", width="140", show-overflow-tooltip)

      el-table-column(prop="city", label="Cidade", width="120", show-overflow-tooltip)

      el-table-column(prop="state", label="Estado", width="72")

      el-table-column(prop="postalCode", label="CEP", width="100")

      el-table-column(width="68")
        template(slot="header")
          el-button(
            type="primary"
            plain
            size="small"
            icon="el-icon-plus"
            @click="showCreateAddressDialog"
          )

        template(slot-scope="scope")
          el-button(
            type="primary"
            plain
            size="small"
            icon="el-icon-edit"
            @click="showEditAddressDialog(scope.row)"
          )

  EditCustomerAddress(
    ref="editCustomerAddress",
    :customerId="props.customerId",
    :addressId="addressId",
    :visible.sync="editCustomerAddressVisible",
    @save="onSave"
  )
</template>
<script setup>
import ShippingAddressService from '@/services/ShippingAddressService';

import SearchList from '@/components/list/SearchList.vue';
import { useMessage } from '@/utils/Message';
import { computed, ref } from 'vue';

import EditCustomerAddress from './EditCustomerAddress.vue';

const message = useMessage()

const props = defineProps({
  customerId: {
    type: Number,
    required: true
  }
})

const addressSearchList = ref()

const addressId = ref(null)
const editCustomerAddressVisible = ref(false)

const searchOptions = computed(() => ({
  filters: [ `customerId:${props.customerId}` ]
}))

const showCreateAddressDialog = () => {
  addressId.value = null
  editCustomerAddressVisible.value = true
}

const showEditAddressDialog = (address) => {
  addressId.value = address.id
  editCustomerAddressVisible.value = true
}

const onSave = () => {
  addressSearchList.value.reload()
}
</script>