<template lang="pug">
section.product-brand-edit(v-if="!loading", v-loading="saving")
  el-header
    el-col(:span="16")
      el-page-header(@back="back", :content="productBrand && productBrand.name ? productBrand.name : 'Nova marca'")

    el-col(:span="8", style="text-align: right")
      el-button(@click="save", type="primary", :loading="saving") Salvar

  el-form(ref="form", :model="productBrand", :rules="rules" label-width="160px")

    el-form-item(label="ID", prop="id")
      el-input(v-model="productBrand.id", disabled)
        template(slot="prepend")
          span(v-if="productBrand.id") #

    el-form-item(label="Nome", prop="name")
      el-input(v-model="productBrand.name", maxlength="250", show-word-limit)

    el-form-item(label="Descrição", prop="description")
      HtmlEditor(
        v-model="productBrand.htmlDescription",
        @input:text="value => productBrand.description = value",
      )

    el-form-item(label="Logo", prop="thumbnail")
      MediaInput(
        v-model="productBrand.thumbnail",
        filePrefix="product-brand/thumbnail/",
        :beforeUpload="beforeThumbnailUpload"
      )

</template>
<script setup>
import HtmlEditor from '@/components/input/HtmlEditor.vue';
import MediaInput from '@/components/input/media/MediaInput.vue';

import productBrandService from '@/services/ecommerce/product/ProductBrandService'

import { onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useMessage } from '@/utils/Message'

const route = useRoute()
const router = useRouter()

const message = useMessage()

const productBrand = ref({})
const loading = ref(false)
const saving = ref(false)

const brandId = computed(() => route.params["id"])
const isNew = computed(() => !brandId.value)

const form = ref()
const rules = {
  name: [ { required: true, trigger: 'blur', message: 'Nome obrigatório' } ],
}

const beforeThumbnailUpload = async (file) => {
  const { width, height } = await (new Promise((resolve) => {
    const image = new Image()
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height
      })

      URL.revokeObjectURL(image.src)
    }

    image.src = URL.createObjectURL(file)
  }))

  if (width < 500 && height < 500) {
    message.error(`Imagem deve ter pelo menos 500px de altura ou largura`)

    throw new Error(`Imagem deve ter pelo menos 500px de altura ou largura`)
  }

  return true
}

const loadBrand = async () => {
  if (!brandId.value) return

  loading.value = true

  try {
    productBrand.value = (await productBrandService.find(brandId.value)) ?? {}
  } catch(e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const save = async () => {
  try {
    await form.value.validate();
  } catch(e) {
    message.warning("Verifique os dados")

    return
  }

  saving.value = true

  try {
    if (isNew.value) {
      productBrand.value = await productBrandService.create({
        name: productBrand.value.name,
        description: productBrand.value.description,
        htmlDescription: productBrand.value.htmlDescription,
        thumbnail: productBrand.value.thumbnail
      })

      router.push({ name: "ecommerce-product-brand-detail", params: { id: productBrand.value.id }})
    } else {
      productBrand.value = await productBrandService.update(brandId.value, {
        name: productBrand.value.name,
        description: productBrand.value.description,
        htmlDescription: productBrand.value.htmlDescription,
        thumbnail: productBrand.value.thumbnail,
      })
    }

    message.success("Salvo com sucesso")
  } catch (e) {
    message.error(`Falha ao salvar: ${e}`)
  } finally {
    saving.value = false
  }
}

const back = () => {
  router.push({ name: "ecommerce-product-brand-list" })
}

onMounted(async () => {
  loadBrand()
})
</script>