export default {
  homeTitle: 'Início',

  // Actions
  actions: 'Ações',
  save: 'Salvar',
  cancel: 'Cancelar',
  logout: 'Sair',
  back: 'Voltar',

  copied: 'Copiado!',
  copiedFailed: 'Falha ao copiar',

  // Utils
  businessDays: 'dias úteis',
  days: 'dias',

  subscriptionStatus: {
    WAITING: 'Esperando pagamento',
    ACTIVE: 'Ativa',
    PENDING: 'Pagamento pendente',
    UNPAID: 'Inadimplente',
    SUSPENDED: 'Suspensa',
    CANCELED: 'Cancelada',
    MIGRATED: 'Migrada',
  },

  orderStatus: {
    CANCELED: 'Cancelado',
    NEW: 'Novo',
    PRODUCT_SELECTION_FAILED: 'Seleção de produtos falhou',
    PRODUCTS_SELECTED: 'Produtos selecionados',
    IN_PRODUCTION: 'Em produção',
    READY_TO_POST: 'Pronto para postagem',
    IN_TRANSIT: 'Em trânsito',
    AWAITING_CUSTOMER_PICKUP: 'Aguardando cliente coletar',
    DELIVERED: 'Entregue',
    RETURNED: 'Retornado',
    MISPLACED: 'Extraviado',
  },

  invoiceStatus: {
    WAITING: 'Aguardando cobrança',
    PENDING: 'Aguardando pagamento',
    PAID: 'Paga pelo cliente',
    FORGIVEN: 'Paga pela Magenta',
    UNPAID: 'Não paga',
    DISPUTED: 'Disputada',
    REFUNDED: 'Ressarcida',
    CLOSED: 'Fechada',
  },

  // Search Bar
  searchBar: {
    // Fields
    search: 'Buscar',
    new: 'Novo',

    // Messages
    message: {
      deleteConfirmation: 'Você tem certeza que deseja deletar {context} \'{itemName}\'?',
      deleteSuccess: '{context} deletado com sucesso!',
      deleteFail: 'Falha ao deletar {context}: {description}',
      loadingFailed: 'Falha ao carregar {context}: {description}',
    },
  },

  // Tag Select
  tag: {
    add: 'Adicionar marcação',
    alreadyAdded: 'Marcação {tag} já adicionada.',
    loadFailed: 'Falha ao carregar marcações: {description}',
    noTag: 'Nenhuma marcação definida',
  },

  // Search Select
  searchSelect: {
    loadFailed: 'Falha ao carregar a lista: {description}',
    findFailed: 'Falha ao encontrar o item {id}: {description}',
  },

  // App errors
  error: {
    default: 'Alguma coisa deu ruim. Avise o Lucas ou o Diego que eles resolvem :D',
    expiredSession: 'A sua sessão expirou.',
    login: 'Hum, você precisa se logar primeiro.',
    unauthorized: 'Você não está autorizado a acessar essa página!',
  },

  // Login
  login: {
    // Fields
    user: 'Usuário',
    password: 'Senha',

    // Errors
    error: {
      invalidInformation: 'Usuário e/ou senha não parecem corretos.',
    },
  },

  // Menu
  menu: {
    customers: 'Clientes',
    editions: 'Edições',
    productionBatches: 'Remessas de produção',
    productBrands: 'Marcas',
    products: 'Produtos',
    stock: 'Estoque',
    stockSummary: 'Sumário de Estoque',
    coupon: 'Cupons',
    influencer: 'Influenciadoras',
  },

  // Customer
  customer: {
    // Titles
    list: 'Clientes',
    new: 'Adicionar Cliente',
    details: 'Editar Cliente',

    // Tabs
    basicInformation: 'Informações Básicas',
    shipping: 'Entrega',
    profile: 'Perfil',
    subscriptions: 'Assinaturas',
    orders: 'Pedidos',

    // Basic information fields
    id: 'ID',
    userId: 'ID de usuário',
    name: 'Nome',
    email: 'E-mail',
    cpf: 'CPF',
    active: 'Ativo',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    birthdate: 'Data de nascimento',

    // Shipping fields
    postalCode: 'CEP',
    street: 'Logradouro',
    number: 'Número',
    complement: 'Complemento',
    district: 'Bairro',
    city: 'Cidade',
    state: 'Estado',

    // Subscription fields
    subscriptionId: 'ID',
    subscriptionPlan: 'Plano',
    subscriptionCreatedAt: 'Data de assinatura',
    subscriptionStatus: 'Status',

    // Order fields
    orderId: 'ID',
    orderEdition: 'Edição',
    orderStatus: 'Status',
    orderNf: 'NF',
    orderTracking: 'Rastreamento',

    // Messages
    message: {
      successfulSave: 'Salvo com sucesso!',
      unsuccessfulSave: 'Falha ao salvar :(',
    },

    // Customer errors
    error: {
      noProfile: 'O cliente não tem perfil cadastrado.',
      invalidBasicInformation: 'Informações básicas inválidas.',
      invalidShipping: 'Entrega inválida.',

      // Form Validation
      firstNameRequired: 'O Nome é obrigatório',
      lastNameRequired: 'O Sobrenome é obrigatório',
      cpfRequired: 'O CPF é obrigatório',
      emailRequired: 'O E-mail é obrigatório',
      birthdateRequired: 'A Data de Nascimento é obrigatória',
      invalidBirthdate: 'Data inválida, use dia/mês/ano (01/12/2000)',
      postalCodeRequired: 'O CEP é obrigatório',
      streetRequired: 'O Logradouro é obrigatório',
      numberRequired: 'O Número é obrigatório',
      districtRequired: 'O Bairro é obrigatório',
      cityRequired: 'A Cidade é obrigatória',
      stateRequired: 'O Estado é obrigatório',
    },
  },

  subscription: {
    details: 'Informações da Assinatura',

    // Actions
    actions: {
      addDiscount: 'Adicionar desconto',
      changeBillingDate: 'Alterar data de pagamento',
      cancel: 'Cancelar assinatura',

      addDiscountValidation: {
        noType: 'Tipo de desconto obrigatório',
        noValue: 'Valor do desconto obrigatório',
        percentageMax100: 'Porcentagem não pode exceder 100%',
      },

      cancelValidation: {
        noCancelReason: 'Razão de cancelamento obrigatória',
        noCancelComment: 'Comentário sobre cancelamento obrigatório',
      },
    },


    // Tabs
    tabs: {
      basicInformation: 'Informações Básicas',
      paymentProfile: 'Perfil de Pagamento',
      discounts: 'Descontos',
      invoices: 'Faturas',
    },

    // Basic Information Fields
    id: 'ID',
    customerId: 'ID do Cliente',
    planId: 'Plano',
    status: 'Status',
    shippingCost: 'Frete',
    startAt: 'Iniciada em',
    canceledAt: 'Cancelada em',
    cancelReason: 'Razão de cancelamento',
    cancelReasonComment: 'Comentário sobre cancelamento',

    // Payment Profile
    paymentProfile: {
      id: 'ID',
      creditCardBrand: 'Bandeira do Cartão',
      creditCardNumber: 'Número do Cartão',
      creditCardHolder: 'Titular',
      creditCardExpiration: 'Expiração',
    },

    discount: {
      id: 'ID',
      coupon: 'Cupom',
      createdAt: 'Data de aplicação',
      amount: 'Valor (R$)',
      percentage: 'Porcentagem',
      cycles: 'Ciclos',
      type: 'Tipo de desconto',
      value: 'Valor do desconto',
    },

    invoice: {
      id: 'ID',
      status: 'Status',
      total: 'Valor',
      createdAt: 'Criada em',
      paidAt: 'Paga em',
    },

    message: {
      successfulAddDiscount: 'Desconto adicionado com sucesso',
      unsuccessfulAddDiscount: 'Falha ao adicionar desconto',
      successfulCancel: 'Assinatura cancelada com sucesso',
      unsuccessfulCancel: 'Falha ao cancelar assinatura',
    },
  },

  // Editions
  edition: {
    // Titles
    list: 'Edições',
    new: 'Adicionar Edição',
    details: 'Editar Edição',

    // Fields
    edition: 'Edição',
    basePrice: 'Preço Base (R$)',
    requiredProducts: 'Produtos Obrigatórios',

    // Messages
    message: {
      successfulSave: 'Salvo com sucesso!',
    },
  },

  // Brands
  brand: {
    // Titles
    list: 'Marcas',
    new: 'Adicionar Marca',
    details: 'Editar Marca',

    // Fields
    id: 'ID',
    name: 'Nome',

    // Messages
    message: {
      successfulSave: 'Salvo com sucesso!',
    },
  },

  // Products
  product: {
    // Titles
    list: 'Produtos',
    new: 'Adicionar Produto',
    details: 'Editar Produto',

    // Fields
    id: 'ID',
    barcode: 'Código de Barras',
    name: 'Nome',
    baseScore: 'Pontuação Base (R$)',
    cost: 'Custo (R$)',
    marketPrice: 'Preço de mercado (R$)',
    perceivedValue: 'Valor percebido (R$)',
    brand: 'Marca',
    brandPlaceholder: 'Insira a marca do produto',

    // Messages
    message: {
      successfulSave: 'Salvo com sucesso!',
    },
  },

  // Stock
  stock: {
    // Titles
    list: 'Estoque',
    new: 'Adicionar Entrada ao Estoque',
    details: 'Editar Entrada no Estoque',
  },

  // Order
  order: {
    // Titles
    details: 'Detalhes do pedido',

    // Tabs
    basicInformation: 'Informações Básicas',
    shipping: 'Envio',
    profile: 'Perfil',
    productSelection: 'Seleção de Produtos',
    invoice: 'Fatura',

    // Basic information fields
    id: 'ID',
    edition: 'Edição',
    status: 'Status',
    nf: 'NF',
    inProductionAt: 'Entrada em produção',
    readyToPostAt: 'Pronta para postagem em',
    inTransitAt: 'Postado em',
    deliveredAt: 'Entregue em',
    canceledAt: 'Cancelado em',

    // Shipping fields
    trackingCode: 'Rastreamento',
    shippingCompany: 'Transportadora',
    trackingUrl: 'Link de Rastreamento',
    estimatedDeliveryTime: 'Tempo de Entrega Est.',
    postalCode: 'CEP',
    street: 'Logradouro',
    number: 'Número',
    complement: 'Complemento',
    district: 'Bairro',
    city: 'Cidade',
    state: 'Estado',

    // Selected Products
    cost: 'Custo (R$)',
    marketPrice: 'Valor de mercado (R$)',
    perceivedValue: 'Valor Percebido (R$)',
    desiredProducts: 'Produtos desejados',
    desiredProductId: 'ID',
    desiredProductName: 'Nome',
    desiredProductCost: 'Custo',
    desiredProductMarketPrice: 'Valor de mercado',
    undesiredProducts: 'Produtos indesejados',
    undesiredProductId: 'ID',
    undesiredProductName: 'Nome',
    undesiredProductCost: 'Custo',
    undesiredProductMarketPrice: 'Valor de mercado',
    selectedProducts: 'Produtos selecionados',
    selectedProductId: 'ID',
    selectedProductName: 'Nome',
    selectedProductCost: 'Custo',
    selectedProductMarketPrice: 'Valor de mercado',
    additionalProducts: 'Produtos adicionais',
    additionalProductId: 'ID',
    additionalProductName: 'Nome',

    // Invoice
    invoiceSection: {
      id: 'ID',
      status: 'Status',
      amount: 'Valor (R$)',
      total: 'Total (R$)',
      paidAmount: 'Valor Pago (R$)',
      dueAmount: 'Valor Devido (R$)',
      chargeAt: 'Cobrado em',
      paidAt: 'Pago em',
      items: 'Itens',
      sku: 'SKU',
      itemAmount: 'Preço (R$)',
      discounts: 'Descontos',
      coupom: 'Cupom',
      discountAmount: 'Valor (R$)',
      discountPercentage: 'Porcentagem',
    },

    emptyMessages: {
      noProfile: 'Os produtos não foram selecionados ainda, então não foi definido o perfil desse pedido',
      noProducts: 'Nenhum produto selecionado ainda',
      noAdditionalProducts: 'Nenhum produto adicional',
      noInvoice: 'Nenhuma fatura definida pra esse pedido',
    },

  },

  // Coupon
  coupon: {
    // Titles
    list: 'Cupons',
    new: 'Novo Cupom',
    details: 'Detalhes do cupom',

    // Fields
    id: 'ID',
    amount: 'Valor (R$)',
    percentage: 'Porcentagem',
    active: 'Ativo',

    activeTrue: 'Sim',
    activeFalse: 'Não',

    // Errors
    error: {
      idRequired: 'ID é obrigatório',
      amountPercentageRequired: 'É necessário informar ou o Valor ou a Porcentagem.',
    },

    // Messages
    message: {
      successfulSave: 'Salvo com sucesso!',
      failedToSave: 'Falha ao salvar!',
    },
  },

  // Stock Entry
  stockEntry: {
    // Titles
    list: 'Registros de estoque',
    new: 'Novo registro',
    details: 'Detalhes do registro',

    // Fields
    id: 'ID',
    productName: 'Produto',
    quantity: 'Quantidade',
    type: 'Tipo',
    lastUpdated: 'Última atualização',

    // Errors
    error: {
      productRequired: 'Produto é obrigatório',
      typeRequired: 'Tipo é obrigatório',
      quantityRequired: 'Quantidade é obrigatória',
    },

    // Messages
    message: {
      successfulSave: 'Salvo com sucesso!',
      failedToSave: 'Falha ao salvar!',
      stockViolation: 'Não foi possível salvar por violação do estoque.',
    },
  },

  // Stock Summary
  stockSummary: {
    // Titles
    list: 'Sumário de estoque',

    // Fields
    productId: 'ID do produto',
    productName: 'Produto',
    quantity: 'Quantidade',
    lastUpdated: 'Última atualização',
  },

  // Influencer
  influencer: {
    list: 'Influenciadoras',
    new: 'Nova Influenciadora',
    details: 'Detalhes da Influenciadora',

    // Fields
    id: 'ID',
    promotedSku: 'SKU promovido',
    name: 'Nome',
    email: 'E-mail',
    cpf: 'CPF',
    customer: 'Cliente',
    coupons: 'Cupons',
    coupon: 'Cupom',
    active: 'Ativa',
    affiliate: 'Afiliada',

    // Errors
    error: {
      customerRequired: 'Cliente é obrigatório',
      promotedSkuRequired: 'SKU promovido é obrigatório',
    },

    // Messages
    message: {
      successfulSave: 'Salvo com sucesso!',
      failedToSave: 'Falha ao salvar!',
      noShippingAddress: 'Cliente não tem endereço cadastrado.',
    },
  },

  // Charges
  charge: {
    // Titles
    list: 'Cobranças',

    // Fields
    id: 'ID',
    customerName: 'Cliente',
    amount: 'Valor (R$)',
    successful: 'Sucesso',
    invoicePaidAt: 'Data Pagamento',

    successfulTrue: 'Sim',
    successfulFalse: 'Não',
  },
};
