<template lang="pug">
.ecommerce-product-category-attribute-item(style="padding: 20px")

  el-form(ref="form", label-width="80px", :model="attribute", :rules="rules")

    el-form-item(label="Atributo", prop="name", size="small")
      el-input(v-model="attribute.name", @input="setDefaultSlug", :disabled="readonly", maxlength="140", show-word-limit)

    el-form-item(label="Opções", prop="options", size="small")
      .options
        el-tag(v-for="(option, index) in attribute.options", :key="option.slug", :closable="option.new", @close="removeOption(index)") {{ option.name }}

        el-button.new-option-button(v-show="!optionInputVisible && !readonly", @click="showNewOptionInput") + Nova opção

        el-input.new-option-input(v-show="optionInputVisible && !readonly", ref="optionInput", v-model="newOptionName" @blur="includeOption", @keyup.enter.native="includeOption(false)")

</template>
<script setup>
import { ref, nextTick, onMounted, watch } from 'vue';

import { textToSlug } from '@/utils/StringUtils'

const props = defineProps({
  attribute: {
    type: Object,
    required: true
  },
  readonly: {
    type: Boolean,
    required: true,
    default: () => false
  },
  validateImmediately: {
    type: Boolean,
    required: true,
    default: () => false
  }
})

const form = ref()
const rules = ref({
  name: [ { required: true, trigger: 'blur', message: 'Nome obrigatório' } ],
})

const defaultSlug = ref("")
const optionInput = ref()
const optionInputVisible = ref(false)
const newOptionName = ref("")

const setDefaultSlug = () => {
  const attribute = props.attribute

  if (!attribute.new) return

  const newDefaultSlug = textToSlug(attribute.name)

  if (!attribute.slug || attribute.slug == defaultSlug.value) {
    attribute.slug = newDefaultSlug;
  }

  defaultSlug.value = newDefaultSlug
}

const showNewOptionInput = () => {
  optionInputVisible.value = true;

  nextTick(() => {
    optionInput.value.$refs.input.focus()
  })
}
const includeOption = (includeAndClose = true) => {
  if (newOptionName.value) {
    const slug = textToSlug(newOptionName.value)

    if (!props.attribute.options.find((option) => option.slug == slug)) {
      // eslint-disable-next-line
      props.attribute.options.push({ slug: slug, name: newOptionName.value, new: true })
    }
  }

  newOptionName.value = ""
  optionInputVisible.value = !includeAndClose
}

const removeOption = (index) => {
  // eslint-disable-next-line
  props.attribute.options.splice(index, 1)
}

watch(() => props.validateImmediately, () => {
  if (props.validateImmediately) {
    nextTick(async () => {
      try {
        await form.value.validate()
      } catch(e) {
        console.warn(e)
      }
    })
  }
}, { immediate: true })
</script>
<style lang="scss">
.ecommerce-product-category-attribute-item {
  padding: 12px;

  .el-tag {
    margin: 0 10px 10px 0;
  }

  .new-option-input {
    width: 104px;
    vertical-align: top;
  }

}
</style>