import { apiPost, apiGet, apiPut } from './_common';

export default {
  createCustomer(request) {
    return apiPost('/magenta/snowflake/customer', request).then(result => result.response);
  },
  getCustomerById(customerId) {
    return apiGet(`/magenta/snowflake/customer/${customerId}`).then(result => result.response);
  },
  updateCustomer(customerId, request) {
    return apiPut(`/magenta/snowflake/customer/${customerId}`, request)
      .then(result => result.response);
  },

  createSubscription(request) {
    return apiPost('/magenta/snowflake/subscription', request).then(result => result.response);
  }
};
