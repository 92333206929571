<template>
  <section class="subscription-box-edition-detail" v-if="!loading" v-loading="saving">
    <el-header>
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isAdding ? 'Nova edição' : `Edição ${subscriptionBoxEdition.id}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-form ref="form" :model="subscriptionBoxEdition" :rules="rules" label-width="160px">

      <!-- Edition -->
      <el-form-item :label="$t('edition.edition')" prop="id">
        <el-date-picker
          v-model="subscriptionBoxEdition.id"
          type="month"
          placeholder="Pick a month"
          value-format="yyyyMM"
          :disabled="$route.name !== 'subscription-box-edition-new'"
          class="stretched">
        </el-date-picker>
      </el-form-item>

      <!-- Base Price -->
      <el-form-item :label="$t('edition.basePrice')" prop="basePrice">
        <el-input-number
          v-model="basePrice"
          controls-position="right"
          :precision="2"
          :step="0.1"
          :min="0"
          class="stretched">
        </el-input-number>
      </el-form-item>

      <!-- Required Products -->
      <el-form-item :label="$t('edition.requiredProducts')" prop="requiredProducts">
        <snowflake-tag-select
          v-model="subscriptionBoxEdition.requiredProducts"
          :search-function="productService.search"
          :value-function="item => item.id">
        </snowflake-tag-select>
      </el-form-item>

    </el-form>

  </section>
</template>
<script>
import SubscriptionBoxEditionService from '@/services/fulfillment/SubscriptionBoxEditionService';
import ProductService from '@/services/stock/ProductService';
import SnowflakeTagSelect from '@/components/input/TagSelect.vue';

export default {
  name: 'subscription-box-edition-detail',
  components: {
    SnowflakeTagSelect,
  },
  data() {
    return {
      rules: {
        id: [
          { required: true, trigger: 'blur' },
        ],
        basePrice: [
          { required: true, trigger: 'blur' },
        ],
      },
      loading: true,
      saving: false,
      subscriptionBoxEdition: {},
      productService: ProductService,
    };
  },
  async created() {
    if (this.$route.name === 'subscription-box-edition-new') {
      this.subscriptionBoxEdition = {};
      this.loading = false;

      return;
    }

    this.subscriptionBoxEdition = await SubscriptionBoxEditionService
      .getSubscriptionBoxEditionById(this.$route.params.id);

    this.loading = false;
  },
  computed: {
    basePrice: {
      get() {
        return this.subscriptionBoxEdition.basePrice / 100;
      },
      set(value) {
        this.subscriptionBoxEdition.basePrice = Math.round(value * 100);
      },
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) return;

      this.saving = true;

      if (this.$route.name === 'subscription-box-edition-new') {
        this.subscriptionBoxEdition = await this.create();

        this.$router.push({ name: 'subscription-box-edition-detail', params: { id: this.subscriptionBoxEdition.id } });
        this.saving = false;
      } else {
        this.subscriptionBoxEdition = await this.update();
        this.saving = false;
      }

      this.$message.success(this.$t('edition.message.successfulSave'));
    },
    cancel() {
      this.$router.back();
    },
    create() {
      this.subscriptionBoxEdition.requiredProducts = [];

      return SubscriptionBoxEditionService.create(this.subscriptionBoxEdition);
    },
    update() {
      return SubscriptionBoxEditionService.update(this.subscriptionBoxEdition.id, this.subscriptionBoxEdition);
    },
  },
};
</script>
