import { apiGet, apiPatch, apiPost } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/fulfillment/subscription-box-production-batch', request).then(result => result.response);
  },
  get(id) {
    return apiGet(`/magenta/fulfillment/subscription-box-production-batch/${id}`)
      .then(result => result.response);
  },
  getAvailableOrdersChartUrl() {
    return apiGet('/magenta/fulfillment/subscription-box-production-batch/available-orders-chart')
      .then(result => result.response);
  },
  update(id, request) {
    return apiPatch(`/magenta/fulfillment/subscription-box-production-batch/${id}`, request)
      .then(result => result.response);
  },
  addOrders(id, request) {
    return apiPost(`/magenta/fulfillment/subscription-box-production-batch/${id}/add-orders`, request)
      .then(result => result.response);
  },
  addOrder(id, orderId) {
    return apiPost(`/magenta/fulfillment/subscription-box-production-batch/${id}/add-order`, { orderId })
      .then(result => result.response);
  },
  removeOrder(id, request) {
    return apiPost(`/magenta/fulfillment/subscription-box-production-batch/${id}/remove-order`, request)
      .then(result => result.response);
  },
  getOrders(id) {
    return apiGet(`/magenta/fulfillment/subscription-box-production-batch/${id}/orders`)
      .then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/fulfillment/subscription-box-production-batch', query).then(result => result.response);
  },
};
