<template lang="pug">
el-dialog(
  class="text-left-aligned"
  title="Migrar assinatura"
  :visible.sync="isVisible")

  p
    | Ao migrar esta assinatura, ela ficará com o status de 'Migrada' e uma nova assinatura será criada. &#32;
    | Está nova assinatura pode ser iniciada em uma data futura e com qualquer um dos planos disponíveis.

  p Preencha os dados da nova assinatura:

  el-form(ref="form", :rules="rules", :model="data", label-width="200px")

    el-form-item(label="Plano", prop="planId")
      el-select(v-model="data.planId")
        el-option-group(key="current", label="Planos atuais")
          el-option(
            v-for="plan in availablePlans"
            :label="`${plan.name} (${plan.planId})`"
            :value="plan.planId"
          )

        el-option-group(key="old", label="Planos antigos")
          el-option(
            v-for="plan in oldMigrationPlans"
            :label="`${plan.name} (${plan.planId})`"
            :value="plan.planId"
          )

    el-form-item(label="Data de início", prop="startAt")
      el-date-picker(
        type="date",
        v-model="data.startAt",
        format="dd/MM/yyyy",
        value-format="yyyy-MM-ddT12:00:00.000Z")

    el-form-item(label="Cancelar assinatura atual", prop="cancelCurrentSubscriptionImmediately")
      el-radio-group(v-model="data.cancelCurrentSubscriptionImmediately", size="small")
        el-radio-button(:label="false") Não
        el-radio-button(:label="true") Sim

  p
    b Aviso:&#32;
    | Antes de realizar a migração, certifique-se de que o início da nova assinatura&#32;
    | ocorrerá após o fim dos períodos pagos pela assinatura atual.

  .footer(slot="footer")
    el-button(@click="close") Voltar

    el-button(type="primary", @click="promptMigration", :loading="isMigrating") Migrar assinatura

</template>
<script setup>
import { default as SubscriptionService, availablePlans, oldMigrationPlans } from '@/services/billing/SubscriptionService';
import { useMessage } from '@/utils/Message';
import { useConfirm } from '@/utils/MessageBox';

import { nextTick, ref } from 'vue';

const confirm = useConfirm()
const message = useMessage()

const props = defineProps({
  subscription: {
    type: Object,
    required: true
  },
  cancellationInfo: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['migrated'])

const form = ref()
const rules = ref({
  planId: [ { required: true, trigger: 'change', message: "Campo obrigatório" } ],
  startAt: [
    { required: true, trigger: 'change', message: "Campo obrigatório" },
    {
      validator: (rule, value, callback) => {
        const now = new Date()
        const date = new Date(value)

        if (Math.floor(date.getTime() /(1000 * 60 * 60 * 24)) < Math.floor((now.getTime() / (1000 * 60 * 60 * 24)))) {
          callback(new Error("Deve ser uma data futura"))
        } else {
          callback()
        }
      },
      trigger: 'change'
    }
  ],
  cancelCurrentSubscriptionImmediately: [ { required: true, trigger: 'change', message: "Campo obrigatório" } ],
})

const isVisible = ref(false)
const isMigrating = ref(false)

const data = ref({})

const open = () => {
  const planId = availablePlans.find((plan) => plan.planId == props.subscription.planId)?.planId ?? null

  const startAt =
    props.cancellationInfo.nextRenewalAt ? new Date(`${props.cancellationInfo.nextRenewalAt}T12:00:00.000Z`) : new Date()

  data.value = {
    planId: planId,
    startAt: startAt,
    cancelCurrentSubscriptionImmediately: null
  }

  isVisible.value = true

  nextTick(() => {
    form.value.clearValidate()
  })
}

const close = () => {
  isVisible.value = false
}

const promptMigration = async () => {
  var isValid = false

  try {
    await form.value.validate()

    isValid = true
  } catch(e) {
    message.warning("Verifique os dados preenchidos")

    return
  }

  confirm("Você tem certeza de que deseja migrar esta assinatura? Esta operação não pode ser desfeita", "Migrar assinatura", {
    confirmButtonText: 'Sim, migrar assinatura',
    cancelButtonText: 'Cancelar',
    type: 'warning'
  }).then(() => {
    return migrate()
  })
}

const migrate = async () => {
  isMigrating.value = true

  try {
    const newSubscription = await SubscriptionService.migrate(props.subscription.id, {
      planId: data.value.planId,
      startAt: data.value.startAt,
      bypassPlanSupportCheck: true,
      cancelCurrentSubscriptionImmediately: data.value.cancelCurrentSubscriptionImmediately
    })

    emit('migrated', newSubscription)

    message.success(`Assinatura migrada com sucesso!`)

    return newSubscription
  } catch(e) {
    message.error(`Houve um problema ao migrar assinatura: ${e}`)

    console.error(e)
  } finally {
    isMigrating.value = false

    close()
  }
}

defineExpose({
  open
})
</script>
<style lang="sass" scoped>
p
  word-break: break-word

.el-select
  width: 320px
</style>
