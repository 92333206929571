<template lang="pug">
.add-cart-item

  el-form(:model="state", :rules="rules", ref="form", label-width="100px")

    el-form-item(prop="productVariantId", label="Produto")
      RemoteSearchSelect(
        v-model="state.productVariantId",
        :search-function="ProductVariantService.search",
        :find-function="ProductVariantService.find",
        :label-function="(item) => `${item.product.productBrand.name} - ${item.product.name} ${item.name} (#${item.id})`"
        :value-function="(item) => item.id",
        placeholder="Selecione um produto"
      )

    el-form-item(prop="quantity", label="Quantidade")
      el-input-number(v-model="state.quantity", :min="1", :max="99")

    el-form-item(style="text-align: right")
      el-button(type="primary", @click="save", :loading="saving")
        i.el-icon-plus
        span Adicionar item
</template>
<script setup>
import { computed, ref } from 'vue';
import CartService from '@/services/ecommerce/cart/CartService';
import { useMessage } from '@/utils/Message';
import RemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue';
import ProductVariantService from '@/services/ecommerce/product/ProductVariantService';

const message = useMessage()

const props = defineProps({
  cartId: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['itemAdded'])

const saving = ref(false)

const form = ref()
const rules = ref({
  productVariantId: [
    { required: true, message: 'Selecione um produto', trigger: 'change' }
  ],
  quantity: [
    { required: true, message: 'Informe a quantidade', trigger: 'change' }
  ]
})

const state = ref({
  productVariantId: null,
  quantity: 1
})

const save = async () => {
  try {
    await form.value.validate()
  } catch (error) {
    return
  }

  saving.value = true

  try {
    const request = {
      productVariantId: state.value.productVariantId,
      quantity: state.value.quantity
    }

    const cartItem = await CartService.addItem(props.cartId, request)

    message.success("Item adicionado ao carrinho")

    emit('itemAdded', cartItem)
  } catch (error) {
    message.error("Falha ao adicionar item ao carrinho")

    console.error(error)
  } finally {
    saving.value = false
  }
}

const reset = () => {
  state.value = {
    productVariantId: null,
    quantity: 1
  }

  form.value?.resetFields()
}

defineExpose({
  reset
})
</script>