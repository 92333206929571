import { apiGet, apiPost, apiPut } from '../../_common';

export default {
  create(request) {
    return apiPost('/magenta/ecommerce/product/product-category', request).then(result => result.response);
  },
  update(id, request) {
    return apiPut(`/magenta/ecommerce/product/product-category/${id}`, request).then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/ecommerce/product/product-category', query).then(result => result.response);
  },
  find(id) {
    return apiGet(`/magenta/ecommerce/product/product-category/${id}`).then(result => result.response);
  },
};
