<template lang="pug">
.customer-rewards

  el-row
    el-col(:span="8")
      .balance
        div(style="font-size: 14px")
          b Saldo de pontos:

        div(style="margin-top: 8px; font-size: 18px; font-weight: bold")
          i.icon
            font-awesome-icon(icon="coins")

          | &#32;{{ formattedBalance }}&#32;

  el-tabs(type="border-card")
    el-tab-pane(label="Prêmios resgatados")

      SearchList(
        ref="redeemSearchList",
        context="redeem",
        :service="redeemSearchService",
        :searchOptions="searchOptions",
        :action="false",
        :details="false"
        :operations="false",
        :keepStateOnUrl="false"
      )

        template(slot="tableRows")

          el-table-column(prop="redeemedAt" label="Resgatado em", width="160")
            template(slot-scope="scope")
              span {{ toDate(scope.row.redeemedAt) }}

          el-table-column(prop="title" label="Prêmio resgatado")

    el-tab-pane(label="Transações" :lazy="true")

      SearchList(
        ref="transactionSearchList",
        context="transaction",
        :showSearchBar="false",
        :service="transactionSearchService",
        :searchOptions="searchOptions",
        :action="false",
        :details="false"
        :operations="false",
        :keepStateOnUrl="false"
      )

        template(slot="tableRows")

          el-table-column(prop="executedAt" label="Realizada em", width="160")
            template(slot-scope="scope")
              span {{ toDate(scope.row.executedAt) }}

          el-table-column(prop="type" label="Tipo", width="80")
            template(slot-scope="scope")
              span(v-if="scope.row.type == 'CREDIT'" style="color: #67C23A") {{ translateTransactionType(scope.row.type) }}
              span(v-else style="color: #F56C6C") {{ translateTransactionType(scope.row.type) }}

          el-table-column(prop="id" label="ID", width="260")

          el-table-column(label="Qtd. de pontos" width="120")
            template(slot-scope="scope")
              span(v-if="scope.row.type == 'CREDIT'" style="color: #67C23A") + {{ scope.row.amount.toLocaleString() }} pontos
              span(v-else style="color: #F56C6C") - {{ scope.row.amount.toLocaleString() }} pontos

          el-table-column(label="Descrição")
            template(slot-scope="scope")
              span {{ transactionDescription(scope.row) }}

          el-table-column(width="68")
            template(slot="header")
              el-button(
                type="primary"
                plain
                size="small"
                icon="el-icon-plus"
                @click="showCreateTransactionDialog"
              )

  el-dialog(
    title="Criar transação"
    :visible.sync="createTransactionDialogVisible"
    width="40%"
  )
    CreateRewardTransaction(
      ref="createTransactionDialog",
      :customerId="customerId",
      @created="reload"
    )

</template>
<script setup>
import moment from 'moment'
import 'moment-timezone'

import { computed, defineProps, onMounted, ref } from 'vue'

import RewardService from '@/services/promotions/RewardService';

import SearchList from '@/components/list/SearchList.vue';

import CreateRewardTransaction from '../actions/CreateRewardTransaction.vue';

const props = defineProps({
  customerId: {
    type: Number,
    required: true
  }
})

const searchOptions = computed(() => ({
  filters: [ `customerId:${props.customerId}` ]
}))

const balance = ref(null)

const redeemSearchList = ref()
const transactionSearchList = ref()
const createTransactionDialog = ref()

const formattedBalance = computed(() => {
  const b = balance.value
  if (!b) {
    return '0 pontos'
  } else if (b == 1) {
    return '1 ponto'
  } else {
    return `${b.toLocaleString()} pontos`
  }
})

const redeemSearchService = {
  search(options) {
    return RewardService.searchRedeems(options)
  }
}

const transactionSearchService = {
  search(options) {
    return RewardService.searchTransactions(options)
  }
}

const createTransactionDialogVisible = ref(false)

const toDate = (dateString) => {
  return moment.utc(dateString, 'YYYY-MM-DDTHH:mm:ss').tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')
}

const translateTransactionType = (type) => {
  switch(type) {
    case "CREDIT":
      return "Crédito"
    case "DEBIT":
      return "Débito"
    default:
      return type
  }
}

const transactionDescription = (transaction) => {
  switch(transaction.origin) {
    case "member-get-member-redeem":
      return "Indicação de assinatura"
    case "subscription-box-delivered-redeem":
    case "subscription-box-delivered":
      return `Box recebida`
    case "redeem-reward":
      return "Prêmio resgatado"
    case "backoffice":
      return `Usuário #${transaction.details.userId}: ${transaction.details.reason}`
  }
}

const showCreateTransactionDialog = () => {
  createTransactionDialog.value?.reset()

  createTransactionDialogVisible.value = true
}

const load = async () => {
  balance.value = (await RewardService.getBalance(props.customerId)).balance
}

const reload = async () => {
  await load()

  redeemSearchList.value?.reload()
  transactionSearchList.value?.reload()

  createTransactionDialogVisible.value = false
}

onMounted(async () => {
  load()
})
</script>
<style lang="sass" scoped>
@import '@/variables.scss'

.customer-rewards
  .balance
    position: relative
    display: inline-block
    color: $--color-primary
    border: 1px dashed $--color-primary
    border-radius: 10px
    padding: 16px
    margin-bottom: 16px
</style>