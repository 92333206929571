<template>
  <div id="home">

    <div class="container">

      <!-- Side Menu -->
      <snowflake-side-menu></snowflake-side-menu>

      <div class="page">

        <!-- Header -->
        <el-header>
          <snowflake-header :title="headerTitle" />
        </el-header>

        <!-- Body -->
        <el-main>
          <router-view class="content"/>
        </el-main>
      </div>

    </div>

  </div>
</template>

<script>
import SnowflakeHeader from '@/components/Header.vue';
import SnowflakeSideMenu from '@/components/SideMenu.vue';

export default {
  name: 'snowflake-home',
  components: {
    SnowflakeHeader,
    SnowflakeSideMenu,
  },
  computed: {
    headerTitle() {
      return (this.$route.meta && this.$route.meta.header)
        ? this.$route.meta.header.title
        : 'Magenta';
    },
  },
};
</script>
<style lang="scss">
#home {
  margin: 0;
  padding: 0;
  height: 100%;

  .el-aside {
    width: 240px;
  }

  .el-header {
    padding: 0;
    width: 100%;
    flex-shrink: 1;
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .page {
    position: relative;
    flex: 1;
    overflow-y: auto;
  }

  .content {
    display: block;
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    max-width: 1200px;
    text-align: left;
  }
}
</style>
