<template>
  <section v-if="!loading" v-loading="saving">
    <el-header>
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isNew ? 'Nova marca' : `${productBrand.name}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-form ref="form" :model="productBrand" :rules="rules" label-width="120px">

      <!-- ID -->
      <el-form-item :label="$t('brand.id')" prop="id">
        <el-input v-model="productBrand.id" disabled></el-input>
      </el-form-item>

      <!-- Name -->
      <el-form-item :label="$t('brand.name')" prop="name">
        <el-input v-model="productBrand.name"></el-input>
      </el-form-item>

      <el-divider content-position="left">Bling</el-divider>

      <!-- two element ui columns for bling supplier -->
      <el-row>
        <el-col :span="12">
          <!-- Bling Supplier -->
          <el-form-item label="Fornecedor" prop="blingSupplier">
            <el-input v-model="productBrand.blingSupplier"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- Bling Supplier CNPJ -->
          <el-form-item label="CNPJ" prop="blingSupplierCNPJ">
            <el-input v-model="productBrand.blingSupplierCNPJ"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>

  </section>
</template>
<script>
import ProductBrandService from '@/services/stock/ProductBrandService';

export default {
  name: 'product-brand-detail',
  data() {
    return {
      rules: {
        name: [
          { required: true, trigger: 'blur' },
        ],
      },
      loading: true,
      saving: false,
      productBrand: {},
    };
  },
  async created() {
    if (this.$route.name === 'product-brand-new') {
      this.productBrand = {};
      this.loading = false;

      return;
    }

    this.productBrand = await ProductBrandService.getProductBrandById(this.$route.params.id);
    this.loading = false;
  },
  computed: {
    isNew() {
      return this.$route.name === 'product-brand-new';
    }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) return;

      this.saving = true;

      if (this.$route.name === 'product-brand-new') {
        this.productBrand = await this.create();

        this.$router.push({ name: 'product-brand-detail', params: { id: this.productBrand.id } });
        this.saving = false;
      } else {
        this.productBrand = await this.update();
        this.saving = false;
      }

      this.$message.success(this.$t('brand.message.successfulSave'));
    },
    cancel() {
      this.$router.back();
    },
    create() {
      return ProductBrandService.create(this.productBrand);
    },
    update() {
      return ProductBrandService.update(this.productBrand.id, this.productBrand);
    },
  },
};
</script>
