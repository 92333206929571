<template lang="pug">
.product-attribute-form
  el-form(label-position="top")
    el-form-item(v-for="attribute in dataAttributes", :label="attribute.name", size="small")
      el-checkbox-group(:value="data[attribute.slug]", @input="(value) => onChange(attribute.slug, value)")
        el-checkbox(v-for="option in attribute.options", :label="option.slug", :key="option.slug")
          | {{ option.name }}
</template>
<script setup>
import { ref, watch } from "vue"

const props = defineProps({
  attributes: {
    type: Array,
    default: () => []
  },
  value: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(["input"])

const dataAttributes = ref([])
const data = ref({})

const onChange = (attributeSlug, value) => {
  const copy = { ...props.value }

  if (value.length > 0) {
    copy[attributeSlug] = value
  } else {
    copy[attributeSlug] = undefined
  }

  emit("input", copy)
}

const initData = () => {
  const refreshedData = {}

  props.attributes.forEach((attribute) => {
    refreshedData[attribute.slug] = props.value[attribute.slug] ?? []
  })

  data.value = refreshedData
  dataAttributes.value = props.attributes
}

watch(() => props.attributes, initData, { immediate: true })
watch(() => props.value, initData)
</script>
<style lang="scss">
.product-attribute-form {
  height: 100%;
  overflow-y: auto;
}
</style>