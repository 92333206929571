<template>
  <div class="customer-detail" v-if="!loading.customerInfo">

    <el-header v-if="showHeader">
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isAdding ? 'Novo cliente' : (customerInfo.firstName ? `${customerInfo.firstName} ${customerInfo.lastName}` : '')"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-tabs
      v-model="activeTab"
      :stretch="true"
      @tab-click="tabChanged"
      :tab-position="tabPosition"
    >
      <!-- Customer -->
      <el-tab-pane
        label="Informações básicas"
        :name="tabs.BASIC_INFORMATION"
        v-loading="saving.customerInfo"
      >
        <el-form
          ref="customerForm"
          :model="customerInfo"
          :rules="rules.customerInfo"
          label-width="160px"
        >
          <el-row>
            <!-- ID -->
            <el-col :span="12">
              <el-form-item label="ID do cliente" prop="id">
                <el-input v-model="customerInfo.id" disabled></el-input>
              </el-form-item>
            </el-col>

            <!-- User ID -->
            <el-col :span="12">
              <el-form-item label="ID do usuário" prop="userId">
                <el-input v-model="customerInfo.userId" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <!-- First name -->
            <el-col :span="12">
              <el-form-item label="Nome" prop="firstName">
                <el-input v-model="customerInfo.firstName"></el-input>
              </el-form-item>
            </el-col>

            <!-- Last name -->
            <el-col :span="12">
              <el-form-item label="Sobrenome" prop="lastName">
                <el-input v-model="customerInfo.lastName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <!-- E-mail -->
            <el-col :span="18">
              <el-form-item label="E-mail de contato" prop="email">
                <el-input v-model="customerInfo.email"></el-input>
              </el-form-item>
            </el-col>

            <!-- Confirme -->
            <el-col :span="6">
              <el-form-item label="Ativo" prop="confirmed" style="text-align: left;">
                <el-switch
                  v-model="customerInfo.confirmed"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Birthdate and CPF -->
          <el-row>
            <!-- CPF -->
            <el-col :span="12">
              <el-form-item label="CPF" prop="cpf">
                <el-input v-model="customerInfo.cpf" v-mask="'###.###.###-##'"></el-input>
              </el-form-item>
            </el-col>

            <!-- Birthdate -->
            <el-col :span="12">
              <el-form-item label="Data de nascimento" prop="birthdate">
                <el-input v-model="customerInfo.birthdate" v-mask="'##/##/####'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-divider content-position="left">Anotações</el-divider>

        <CustomerInternalNotes v-if="customerInfo.id" :customerId="customerInfo.id"></CustomerInternalNotes>

      </el-tab-pane>

      <!-- Shipping Address -->
      <el-tab-pane
        label="Endereços"
        :name="tabs.SHIPPING"
        :disabled="isAdding"
        :lazy="true"
      >
        <CustomerAddressList v-if="customerInfo.id" :customerId="customerInfo.id"></CustomerAddressList>
      </el-tab-pane>


      <!-- Payment profile -->
      <el-tab-pane
        label="Dados de pagamento"
        :name="tabs.PAYMENT_PROFILE"
        :disabled="isAdding"
        :lazy="true"
      >
        <PaymentProfileList v-if="customerInfo.id" :customerId="customerInfo.id"></PaymentProfileList>
      </el-tab-pane>

      <!-- Profile -->
      <el-tab-pane
        label="Perfil de beleza"
        :name="tabs.PROFILE"
        v-loading="saving.profile"
        :disabled="isAdding"
      >
        <div class="profile-questionnaire">
          <div
            class="profile__question"
            v-for="question in questionnaire"
            :key="question.id"
          >
            <el-row>
              <el-col :span="24">
                <p class="title">{{question.title}}</p>
              </el-col>
            </el-row>
            <el-row>
              <section v-if="question.type === 'SINGLE_CHOICE'">
                <el-radio-group v-model="profile[question.id]">
                  <el-radio
                    v-for="option in question.options"
                    :key="option.value"
                    :label="option.value"
                    class="option"
                  >
                    <span class="option__label">{{option.name}}</span>
                  </el-radio>
                </el-radio-group>
              </section>
              <section v-else>
                <el-checkbox-group v-model="profile[question.id]">
                  <el-checkbox
                    v-for="option in question.options"
                    :key="option.value"
                    :label="option.value"
                    class="option"
                  >
                    <span class="option__label">{{option.name}}</span>
                  </el-checkbox>
                </el-checkbox-group>
              </section>
            </el-row>
          </div>
        </div>
      </el-tab-pane>

      <!-- Subscriptions -->
      <el-tab-pane
        label="Assinaturas"
        :name="tabs.SUBSCRIPTIONS"
        :disabled="isAdding"
      >
        <el-table
          border
          :data="subscriptions"
          style="margin-bottom: 22px"
        >

          <el-table-column prop="id" label="ID" width="72" align="center">
          </el-table-column>
          <el-table-column
            prop="planId"
            label="ID do Plano"
            align="center"
          >
          </el-table-column>
          <el-table-column label="Status" width="200">
            <template slot-scope="scope">
              {{ subscriptionStatus[subscriptions[scope.$index].status] }}
              <span v-if="subscriptions[scope.$index].status == 'ACTIVE' && subscriptions[scope.$index].endAt">(renovação cancelada)</span>
            </template>
          </el-table-column>
          <el-table-column prop="startAt" label="Início" width="96">
            <template slot-scope="scope">
              {{fromSystemDate(subscriptions[scope.$index].startAt)}}
            </template>
          </el-table-column>
          <el-table-column prop="endAt" label="Fim" width="96">
            <template slot-scope="scope">
              {{fromSystemDate(subscriptions[scope.$index].endAt)}}
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="Criada em" width="96">
            <template slot-scope="scope">
              {{fromSystemDate(subscriptions[scope.$index].createdAt)}}
            </template>
          </el-table-column>
          <el-table-column width="120" align="center">

            <template slot="header">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="showCreateSubscriptionDialog"
                :disabled="!allowCreatingSubscription"
              ></el-button>
            </template>

            <template slot-scope="scope">
              <router-link :to="{ path: `/subscriptions/${subscriptions[scope.$index].id}` }">
                <el-button size="small" type="primary" plain icon="el-icon-more"></el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <!-- Orders -->
      <el-tab-pane
        label="Pedidos"
        :name="tabs.ORDERS"
        :disabled="isAdding"
      >
        <el-table
          border
          :data="orders"
          style="margin-bottom: 22px"
        >
          <el-table-column prop="id" label="ID" width="96" align="center">
          </el-table-column>
          <el-table-column
            prop="edition"
            label="Edição"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column label="Status">
            <template slot-scope="scope">
              {{ orderStatus[orders[scope.$index].status] }}
            </template>
          </el-table-column>
          <el-table-column prop="nfe" label="NFe"></el-table-column>
          <el-table-column label="Rastreamento">
            <template slot-scope="scope">
              <a
                v-if="orders[scope.$index].shippingCompany == 'CARRIERS'"
                target="_blank"
                rel="noopener noreferrer"
                :href="`https://www.carriers.com.br/portal/localizador.php?l=${orders[scope.$index].trackingCode}`">
                {{orders[scope.$index].trackingCode}}
              </a>

              <!-- eslint-disable-next-line max-len -->
              <a
                v-else-if="orders[scope.$index].shippingCompany == 'TOTAL_EXPRESS'"
                target="_blank"
                rel="noopener noreferrer"
                :href="`https://tracking.totalexpress.com.br/poupup_track.php?reid=14252&pedido=${orders[scope.$index].nfe}&nfiscal=${orders[scope.$index].nfe}`"
              >
                {{orders[scope.$index].trackingCode}}
              </a>

              <!-- eslint-disable-next-line max-len -->
              <a
                v-else-if="orders[scope.$index].shippingCompany == 'LOGGI'"
                target="_blank"
                rel="noopener noreferrer"
                :href="`https://envios.loggi.com/empresas/1270536/pacotes/${orders[scope.$index].trackingCode}`"
              >
                {{orders[scope.$index].trackingCode}}
              </a>

              <a v-else
                target="_blank"
                rel="noopener noreferrer"
                :href="`https://portalpostal.com.br/sro.jsp?sro=${orders[scope.$index].trackingCode}`">
                {{orders[scope.$index].trackingCode}}
              </a>
              <button
                v-if="orders[scope.$index].trackingUrl"
                class="copy-button"
                @click="copyToClipboard(orders[scope.$index].trackingUrl)"
              >
                <font-awesome-icon :icon="['far', 'copy']" />
              </button>
            </template>
          </el-table-column>
          <el-table-column width="120" align="center">
            <template slot-scope="scope">
              <router-link :to="{ path: `/subscription-box-orders/${orders[scope.$index].id}` }">
                <el-button size="small" type="primary" plain icon="el-icon-more"></el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <!-- Cart -->
      <el-tab-pane
        v-if="ecommerceEnabled"
        label="Carrinho"
        :name="tabs.CART"
        :disabled="isAdding"
        :lazy="true">
        <CustomerCart v-if="customerInfo.id" :customerId="customerInfo.id"></CustomerCart>
      </el-tab-pane>

      <!-- Rewards -->
      <el-tab-pane
        label="Programa de pontos"
        :name="tabs.REWARDS"
        :disabled="isAdding"
        :lazy="true"
      >
        <CustomerRewards v-if="customerInfo.id" :customerId="customerInfo.id"></CustomerRewards>
      </el-tab-pane>

      <!-- Additional Rules -->
      <el-tab-pane
        label="Regras adicionais"
        :name="tabs.ADDITIONAL_RULES"
        v-loading="saving.additionalRules"
        :disabled="isAdding"
      >
        <div v-if="additionalRules === null && !loading.additionalRules">
          <h2>Falha ao carregar as regras adicionais. Recarrege a página ou tente novamente mais tarde.</h2>
        </div>
        <div v-else>

          <el-form
            ref="additionalRulesForm"
            label-width="180px"
            style="text-align: left;"
            :model="additionalRules" >

            <!-- Product Credits -->
            <el-form-item label="Crédito na próxima caixa" prop="productCredits">
              <el-input-number
                style="width: 90px;"
                v-model="additionalRules.productCredits"
                controls-position="right"
                :min="0"
                :max="3"
              />
            </el-form-item>

            <!-- Blocked Products -->
            <el-form-item label="Produtos bloqueados" prop="blockedProducts">
              <snowflake-remote-search-multi-select
                v-model="additionalRules.blockedProducts"
                placeholder="Bloquear produto"
                :search-function="productService.search"
                :find-function="productService.getProductById">
              </snowflake-remote-search-multi-select>
            </el-form-item>

            <!-- Blocked Brands -->
            <el-form-item label="Marcas bloqueados" prop="blockedBrands">
              <snowflake-remote-search-multi-select
                v-model="additionalRules.blockedBrands"
                placeholder="Bloquear marca"
                :search-function="productBrandService.search"
                :find-function="productBrandService.getProductBrandById">
              </snowflake-remote-search-multi-select>
            </el-form-item>

            <el-form-item label="Transportadora preferida" prop="preferredShippingCompany">
              <el-select
                v-model="additionalRules.preferredShippingCompany"
                v-loading="loading.availableShippingCompanies"
              >
                <el-option label="Sem preferência" value="" />
                <el-option
                  v-for="(shippingCompany, index) in availableShippingCompanies"
                  :key="index"
                  :label="shippingCompany"
                  :value="shippingCompany"
                />
              </el-select>
            </el-form-item>

            <el-table :data="additionalRules.pendingAdditionalProducts" size="small">
              <el-table-column label="Produtos adicionais a serem enviados na próxima box">

                <el-table-column prop="id" label="ID" width="160"></el-table-column>

                <el-table-column prop="name" label="Produto"></el-table-column>

                <el-table-column v-if="showProductionActions" width="80">
                  <template slot="header">
                    <el-button
                      type="primary"
                      plain
                      size="small"
                      icon="el-icon-plus"
                      @click="showAddAdditionalProductDialog"
                    ></el-button>
                  </template>

                  <template slot-scope="scope">
                    <el-popconfirm title="Tem certeza de que deseja remover este produto?" @confirm="removePendingAdditionalProduct(scope.row.id)">
                      <el-button
                        slot="reference"
                        title="Remover produto adicional"
                        size="small"
                        type="danger"
                        plain
                        icon="el-icon-delete"
                      ></el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>

          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!--- Dialogs -->
    <AddAdditionalProduct
      v-if="additionalRules.id"
      :additionalRulesId="additionalRules.id"
      :visible.sync="addAdditionalProductDialogVisible"
      @executed="loadAdditionalRules"
    ></AddAdditionalProduct>

    <CreateSubscription
      v-if="customerInfo.id"
      :customerId="customerInfo.id"
      :visible.sync="createSubscriptionDialogVisible"
      @created="loadSubscriptions"
    ></CreateSubscription>
  </div>
</template>

<script>
import lodash from 'lodash';
import moment from 'moment';

import Storage from '@/storage'

import SnowflakeRemoteSearchMultiSelect from '@/components/input/RemoteSearchMultiSelect.vue';
import SnowflakeService from '@/services/SnowflakeService';
import CustomerService from '@/services/CustomerService';
import ShippingAddressService from '@/services/ShippingAddressService';
import ProductService from '@/services/stock/ProductService';
import ProductBrandService from '@/services/stock/ProductBrandService';
import ProfileService from '@/services/ProfileService';
import QuestionnaireService from '@/services/QuestionnaireService';
import SubscriptionBoxOrderAdditionalRulesService
  from '@/services/fulfillment/SubscriptionBoxOrderAdditionalRulesService';

import AddAdditionalProduct from "./actions/AddPendingAdditionalProduct.vue"
import CreateSubscription from './actions/CreateSubscription.vue'
import CustomerRewards from "./tabs/CustomerRewards.vue"
import CustomerCart from './tabs/CustomerCart.vue';
import CustomerInternalNotes from './tabs/CustomerInternalNotes.vue';
import CustomerAddressList from './address/CustomerAddressList.vue';

import PaymentProfileList from './payment-profile/PaymentProfileList.vue';

const ecommerceEnabled = process.env.VUE_APP_ECOMMERCE_ENABLED === 'true';

const states = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG',
  'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RO', 'RS', 'RR', 'SC', 'SE', 'SP', 'TO'];

const tabs = {
  BASIC_INFORMATION: 'basic-information',
  SHIPPING: 'shipping',
  PROFILE: 'profile',
  SUBSCRIPTIONS: 'subscriptions',
  ORDERS: 'orders',
  ADDITIONAL_RULES: 'additional_rules',
  REWARDS: 'rewards',
  CART: 'cart',
};

const subscriptionStatus = {
  WAITING: 'Esperando pagamento',
  ACTIVE: 'Ativa',
  PENDING: 'Pagamento pendente',
  UNPAID: 'Inadimplente',
  SUSPENDED: 'Suspensa',
  CANCELED: 'Cancelada',
  MIGRATED: 'Migrada',
};

const orderStatus = {
  CANCELED: 'Cancelado',
  NEW: 'Novo',
  PRODUCT_SELECTION_FAILED: 'Seleção de produtos falhou',
  PRODUCTS_SELECTED: 'Produtos selecionados',
  IN_PRODUCTION: 'Em produção',
  READY_TO_POST: 'Pronto para postagem',
  IN_TRANSIT: 'Em trânsito',
  AWAITING_CUSTOMER_PICKUP: 'Aguardando cliente coletar',
  DELIVERED: 'Entregue',
  RETURNED: 'Retornado',
  MISPLACED: 'Extraviado',
  FRAUD: 'Fraude',
  SUSPECTED_FRAUD: 'Suspeita de Fraude - Aguardando Análise Manual',
};

export default {
  name: 'customer-detail',
  components: {
    SnowflakeRemoteSearchMultiSelect,
    CustomerRewards,
    AddAdditionalProduct,
    CustomerCart,
    CustomerAddressList,
    PaymentProfileList,
    CreateSubscription,
    CustomerInternalNotes
  },
  props: {
    id: {
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    tabPosition: {
      type: String,
      default: 'top',
    },
  },
  data() {
    return {
      states,
      productService: ProductService,
      productBrandService: ProductBrandService,
      loading: {
        customerInfo: false,
        shipping: false,
        profile: false,
        orders: false,
        additionalRules: false,
        internalNotes: false,
        internalNoteDialog: false,
        availableShippingCompanies: false,
      },
      saving: {
        customerInfo: false,
        shipping: false,
        profile: false,
        orders: false,
        additionalRules: false,
        internalNotes: false,
        internalNoteDialog: false,
        availableShippingCompanies: false,
      },
      rules: {
        customerInfo: {
          firstName: [
            { required: true, message: 'Nome é obrigatório', trigger: 'blur' },
          ],
          lastName: [
            { required: true, message: 'Sobrenome é obrigatório', trigger: 'blur' },
          ],
          cpf: [
            { required: true, message: 'CPF é obrigatório', trigger: 'blur' },
          ],
          email: [
            { required: true, message: 'E-mail é obrigatório', trigger: 'blur' },
          ],
          birthdate: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                if (!value) {
                  return callback(new Error('Data de nascimento é obrigatória.'));
                }

                if (value.length < 10 || !moment(value, 'DD/MM/YYYY').isValid()) {
                  return callback(new Error('Data de nascimento inválida.'));
                }

                return callback();
              },
            },
          ],
        },
        shipping: {
          postalCode: [
            { required: true, message: 'CEP é obrigatório.', trigger: 'blur' },
          ],
          street: [
            { required: true, message: 'Rua é obrigatório.', trigger: 'blur' },
          ],
          number: [
            { required: true, message: 'Número é obrigatório.', trigger: 'blur' },
          ],
          district: [
            { required: true, message: 'Bairro é obrigatório.', trigger: 'blur' },
          ],
          city: [
            { required: true, message: 'Cidade é obrigatório.', trigger: 'blur' },
          ],
          state: [
            { required: true, message: 'Estado é obrigatório.', trigger: 'blur' },
          ],
        },
      },
      customerInfo: {
        confirmed: false,
      },
      availableShippingCompanies: [],
      shipping: {},
      profileId: null,
      profile: {},
      questionnaire: [],
      subscriptions: [],
      orders: [],
      additionalRules: {
        blockedBrands: [],
        blockedProducts: [],
        productCredits: 0,
        preferredShippingCompany: '',
      },
      customerHasAdditionalRules: null,
      activeTab: tabs.BASIC_INFORMATION,
      tabs,
      subscriptionStatus,
      orderStatus,
      addAdditionalProductDialogVisible: false,
      createSubscriptionDialogVisible: false,
      ecommerceEnabled: ecommerceEnabled,
    };
  },
  computed: {
    isAdding() {
      return this.$route.name === 'customer-new';
    },
    showProductionActions() {
      const roles = this.authRoles();

      return roles.includes('admin') || roles.includes('owner') || roles.includes('operations-lead');
    },
    allowCreatingSubscription() {
      const activeSubscriptionStatus = ['WAITING', 'ACTIVE', 'PENDING', 'UNPAID', 'SUSPENDED']
      const hasActiveSubscription =
        lodash.some(this.subscriptions, subscription =>
          activeSubscriptionStatus.includes(subscription.status) &&
            subscription.endAt == null &&
            subscription.canceledAt == null
        );

      return !hasActiveSubscription
    }
  },
  async created() {
    const {
      id: questionnaireId,
      questions: questionnaire,
    } = await QuestionnaireService.getCurrentQuestionnaire();

    this.questionnaireId = questionnaireId;
    this.questionnaire = questionnaire;
    this.profile = this.questionnaire.reduce((acc, curr) => ({
      ...acc,
      [curr.id]: curr.type === 'SINGLE_CHOICE' ? null : [],
    }), {});

    if (this.isAdding) {
      return;
    }

    this.loadBasicInformation();
    this.loadShipping();
    this.loadProfile();
    this.loadSubscriptions();
    this.loadOrders();
    this.loadAdditionalRules();
    this.loadAvailableShippingCompanies();
  },
  mounted() {
    this.activeTab = this.$route.query['customerTab'] || this.activeTab;
  },
  methods: {
    authRoles() {
      const encodedRole = Storage.get(Storage.keys.auth.role);
      const encodedRoles = Storage.get(Storage.keys.auth.roles);

      return encodedRoles ? JSON.parse(atob(encodedRoles)) : (encodedRole ? [atob(encodedRole)] : []);
    },
    tabChanged() {
      this.$router.replace({ query: { customerTab: this.activeTab } })
    },
    showAddAdditionalProductDialog() {
      this.addAdditionalProductDialogVisible = true;
    },
    showCreateSubscriptionDialog() {
      this.createSubscriptionDialogVisible = true;
    },
    async loadBasicInformation() {
      this.loading.customerInfo = true;

      const customer = await SnowflakeService.getCustomerById(this.id);
      this.customerInfo = {
        ...customer,
        birthdate: moment(customer.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      };

      this.loading.customerInfo = false;
    },
    async loadShipping() {
      this.loading.shipping = true;

      const [shipping] = await CustomerService.getCustomerShippingAddress(this.id);
      this.shipping = shipping || {};

      this.loading.shipping = false;
    },
    async loadProfile() {
      this.loading.profile = true;

      try {
        const {
          id: profileId,
          choicesByQuestion,
        } = await CustomerService.getCustomerProfile(this.id);

        this.profileId = profileId;
        this.profile = this.questionnaire.reduce((acc, curr) => {
          const answer = choicesByQuestion[curr.id] || [];

          return {
            ...acc,
            [curr.id]: curr.type === 'SINGLE_CHOICE' ? answer[0] : answer,
          };
        }, {});
      } catch (e) {
        this.$message.warning('Cliente não tem perfil definido.');
      }

      this.loading.profile = false;
    },
    async loadOrders() {
      this.loading.orders = true;

      this.orders = await CustomerService.getCustomerOrders(this.id);

      this.loading.orders = false;
    },
    async loadSubscriptions() {
      this.loading.subscriptions = true;

      this.subscriptions = await CustomerService.getCustomerSubscriptions(this.id);

      this.subscriptions.sort((a, b) => b.id - a.id);

      this.loading.subscriptions = false;
    },
    async loadAdditionalRules() {
      this.loading.additionalRules = true;

      try {
        const rules = await SubscriptionBoxOrderAdditionalRulesService.getByCustomerId(this.id);
        this.additionalRules = {
          ...rules,
          preferredShippingCompany: rules.preferredShippingCompany || '',
        };
        this.customerHasAdditionalRules = true;
      } catch (e) {
        if (e.status === 404) {
          this.customerHasAdditionalRules = false;
        }
      }

      this.loading.additionalRules = false;
    },
    async loadAvailableShippingCompanies() {
      this.loading.availableShippingCompanies = true;

      try {
        this.availableShippingCompanies = await CustomerService.getAvailableShippingComapanies(this.id);
      } catch (e) {
        this.availableShippingCompanies = [];
        this.$message.error('Falha ao carregar transportadoras.');
      }

      this.loading.availableShippingCompanies = false;
    },
    async removePendingAdditionalProduct(productId) {
      this.saving.additionalRules = true;

      try {
        await SubscriptionBoxOrderAdditionalRulesService.removePendingAdditionalProduct(this.additionalRules.id, { productId });

        await this.loadAdditionalRules();
      } finally {
        this.saving.additionalRules = false;
      }
    },
    async save() {
      if (this.isAdding) {
        await this.create();
      } else {
        await this.update();
      }
    },
    async create() {
      this.saving.customerInfo = true;
      this.saving.shipping = true;

      try {
        try {
          await this.$refs.customerForm.validate();
        } catch (e) {
          this.activeTab = tabs.BASIC_INFORMATION;
          throw new Error('Informações básicas inválidas.');
        }

        const phoneNumber = this.customerInfo.phoneNumber
          ? this.customerInfo.phoneNumber.replace(/[^0-9+]/g, '')
          : undefined;

        const payload = {
          customer: {
            ...this.customerInfo,
            phoneNumber,
            birthdate: moment(this.customerInfo.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          },
        };

        const customer = await SnowflakeService.createCustomer(payload);
        this.customerInfo = {
          ...customer,
          birthdate: moment(customer.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        };

        this.$router.replace({ name: 'customer-detail', params: { id: customer.id } });
      } catch (e) {
        this.$message.error(e.message || 'Falha ao salvar.');
      }

      this.saving.customerInfo = false;
      this.saving.shipping = false;
    },
    async update() {
      this.saving.customerInfo = true;
      this.saving.profile = true;

      try {
        try {
          await this.$refs.customerForm.validate();
        } catch (e) {
          this.activeTab = tabs.BASIC_INFORMATION;
          throw new Error('Informações básicas inválidas.');
        }

        const phoneNumber = this.customerInfo.phoneNumber
          ? this.customerInfo.phoneNumber.replace(/[^0-9+]/g, '')
          : undefined;

        const customerPayload = {
          ...this.customerInfo,
          phoneNumber,
          birthdate: moment(this.customerInfo.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        };

        const [customer, profile, additionalRules] = await Promise.all([
          SnowflakeService.updateCustomer(this.customerInfo.id, customerPayload),
          this.saveProfile(),
          this.saveAdditionalRules(),
        ]);

        this.customerInfo = {
          ...customer,
          birthdate: moment(customer.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        };

        this.profileId = profile.id;
        this.additionalRules = additionalRules;

        this.$message.success('Salvo com sucesso.');
      } catch (e) {
        this.$message.error(e.message || 'Falha ao salvar.');
      }

      this.saving.customerInfo = false;
      this.saving.profile = false;
    },
    async saveProfile() {
      const answers = Object.entries(this.profile).map(([key, value]) => ({
        questionId: key,
        choices: value ? [].concat(value) : [],
      }));

      const profilePayload = {
        customerId: this.customerInfo.id,
        questionnaireId: this.questionnaireId,
        answers,
      };

      if (this.profileId) {
        return ProfileService.update(this.profileId, profilePayload);
      }

      return ProfileService.create(profilePayload);
    },
    async saveAdditionalRules() {
      if (this.customerHasAdditionalRules === null) {
        return {
          blockedBrands: [],
          blockedProducts: [],
          productCredits: 0,
        }
      }

      const preferredShippingCompany = this.additionalRules.preferredShippingCompany === ''
        ? null
        : this.additionalRules.preferredShippingCompany;

      const body = {
        customerId: this.customerInfo.id,
        blockedBrands: this.additionalRules.blockedBrands,
        blockedProducts: this.additionalRules.blockedProducts,
        productCredits: this.additionalRules.productCredits,
        preferredShippingCompany,
      };

      let returnedValue;
      if (this.customerHasAdditionalRules) {
        returnedValue = await SubscriptionBoxOrderAdditionalRulesService.update(this.additionalRules.id, body);
      } else {
        returnedValue = await SubscriptionBoxOrderAdditionalRulesService.create(body);
        this.customerHasAdditionalRules = true;
      }

      const updatedValue = {
        ...returnedValue,
        preferredShippingCompany: returnedValue.preferredShippingCompany || '',
      };

      return updatedValue;
    },
    fromSystemDate(value) {
      return value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
    },
    copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        window.clipboardData.setData('Text', text);
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.$message.success('Copiado!');
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          this.$message.error('Falha ao copiar.');
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-detail {
  font-size: 20px;

  .actions {
    text-align: center;
  }

  .information__row {
    margin-top: 30px;

    .name {
      font-size: 25px;
    }

    label {
      font-size: 16px;
    }

    .text {
      font-size: 23px;
    }
  }

  .profile__question {
    margin-top: 30px;
    margin-bottom: 25px;
    text-align: center;

    .title {
      font-size: 18px;
      text-align: left;
      width: 410px;
      margin: auto;
      margin-bottom: 10px;
    }

    .option {
      display: block;
      text-align: left;
      width: 300px;
      margin: auto;

      .option__label {
        font-size: 14px;
      }
    }
  }

  .copy-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-left: 8px;
  }

  .text-left-aligned {
    text-align: left;
  }
}
</style>
