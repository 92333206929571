import { apiGet, apiPost, apiPut } from '../../_common';

export default {
  search(options) {
    return apiGet('/magenta/ecommerce/product/product-variant', options).then(result => result.response);
  },
  create(request) {
    return apiPost('/magenta/ecommerce/product/product-variant', request).then(result => result.response);
  },
  update(id, request) {
    return apiPut(`/magenta/ecommerce/product/product-variant/${id}`, request).then(result => result.response);
  },
  find(id) {
    return apiGet(`/magenta/ecommerce/product/product-variant/${id}`).then(result => result.response);
  },
  findByProductId(productId) {
    return apiGet(`/magenta/ecommerce/product/${productId}/product-variants`).then(result => result.response);
  },
};
