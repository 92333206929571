<template>
  <section class="subscription-box-production-batch-list">

    <snowflake-search-list context="subscription-box-production-batch"
      :service="subscriptionBoxProductionBatchService"
      defaultSortColumn="id"
      defaultSortDirection="DESC">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>

        <!-- Name -->
        <el-table-column prop="name" label="Nome" :show-overflow-tooltip="true" sortable></el-table-column>

        <!-- Edition -->
        <el-table-column prop="edition" label="Edição" width="120" sortable></el-table-column>

        <!-- Pedidos -->
        <el-table-column prop="orders" label="Nº de Pedidos" width="160">
          <template slot-scope="scope">
            {{ scope.row.orders.length }}
          </template>
        </el-table-column>

        <!-- Created At -->
        <el-table-column prop="createdAt" label="Data de Criação" width="160" sortable>
          <template slot-scope="scope">
            <span>{{ fromSystemDate(scope.row.createdAt) }}</span>
          </template>
        </el-table-column>

      </template>
    </snowflake-search-list>


    <iframe
        :src="availableOrdersChartUrl"
        frameborder="0"
        height="480"
        style="width: 100%; margin-top: 32px"
        allowtransparency
    ></iframe>

  </section>
</template>

<script>
import moment from 'moment';

import SubscriptionBoxProductionBatchService from '@/services/fulfillment/SubscriptionBoxProductionBatchService';

import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'subscription-box-production-batch-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      subscriptionBoxProductionBatchService: SubscriptionBoxProductionBatchService,

      loading: {
        availableOrders: true,
      },

      availableOrders: 0,

      availableOrdersChartUrl: null,
    };
  },
  async created() {
    this.availableOrdersChartUrl = (await SubscriptionBoxProductionBatchService.getAvailableOrdersChartUrl()).url;


    this.loading.availableOrders = false;
  },
  methods: {
    fromSystemDate(value) {
      return value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : null;
    },
  },
};
</script>
