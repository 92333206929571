import { Message } from 'element-ui';

import Storage from '../storage';
import eventBus from '../event-bus';
import i18n from '../i18n';
import ServiceError from '../errors/ServiceError';
import ExpiredSessionError from '../errors/ExpiredSessionError';

const API_ADDRESS = process.env.VUE_APP_API_ADDRESS;

const parseJSON = response => response.text()
  .then(text => (text ? JSON.parse(text) : {}))
  .then(json => ({ response: json }));

const checkResponse = (response) => {
  if (response.ok) return response;

  if ((response.status === 401 || response.status === 403)
        && (response.url.indexOf('/auth/sign-in') === -1)) {
    eventBus.$emit('logout');
    Storage.removeAll();

    if (window.location.hash) {
      const resumeTo = `${window.location.hash.replace('#', '')}`;
      window.location = `/#/login?resumeTo=${window.encodeURIComponent(resumeTo)}`;
    } else {
      window.location = '/#/login';
    }

    Message({
      message: i18n.getInstance().t('error.expiredSession'),
      type: 'warning',
      center: true,
      showClose: true,
    });
    return Promise.reject(new ExpiredSessionError(i18n.getInstance().t('error.expiredSession')));
  }

  return response.text()
    .then((errorText) => {
      if (errorText) {
        try {
          const error = JSON.parse(errorText);

          const errorMessage = error.description || error.message || error.error || errorText;

          return Promise.reject(new ServiceError(response.status, errorMessage, error));
        } catch (e) {
          return Promise.reject(new ServiceError(response.status, errorText));
        }
      }

      return Promise
        .reject(new ServiceError(response.status, i18n.getInstance().t('error.default')));
    });
};

const queryParams = (params) => {
  const queryParamsString = Object
    .keys(params)
    .filter(k => params[k] !== null && params[k] !== undefined)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  if (queryParamsString) {
    return `?${queryParamsString}`;
  }

  return '';
};

export const callFetch = (
  endpoint,
  useBaseUrl,
  options,
) => fetch(useBaseUrl ? API_ADDRESS + endpoint : endpoint, options)
  .then(checkResponse)
  .then(parseJSON);

export const apiGet = (
  endpoint,
  params = {},
  useBaseUrl = true,
) => callFetch(`${endpoint}${queryParams(params)}`, useBaseUrl, {
  headers: {
    Authorization: `Bearer ${Storage.get(Storage.keys.auth.token)}`,
  },
});

export const apiPost = (
  endpoint,
  payload,
  useBaseUrl = true,
  headers = {},
) => callFetch(endpoint, useBaseUrl,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Storage.get(Storage.keys.auth.token)}`,
      ...headers,
    },
    body: JSON.stringify(payload),
  });

export const apiPut = (endpoint, payload, useBaseUrl = true) => callFetch(endpoint, useBaseUrl, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Storage.get(Storage.keys.auth.token)}`,
  },
  body: JSON.stringify(payload),
});

export const apiPatch = (endpoint, payload, useBaseUrl = true) => callFetch(endpoint, useBaseUrl, {
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Storage.get(Storage.keys.auth.token)}`,
  },
  body: JSON.stringify(payload),
});

export const apiDelete = (endpoint, useBaseUrl = true) => callFetch(endpoint, useBaseUrl, {
  method: 'DELETE',
  headers: {
    Authorization: `Bearer ${Storage.get(Storage.keys.auth.token)}`,
  },
});
