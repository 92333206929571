import { apiPost } from '../_common';

export default {
  generateShippingLabels(shipmentId, orderIds) {
    const request = {
      shipmentId,
      orderIds,
    };

    return apiPost('/magenta/fulfillment/shipping-label', request)
      .then(result => result.response);
  },
};
