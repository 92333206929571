
import VindiService from '@/services/billing/VindiService';

<template lang="pug">
.customer-payment-profile-list

  SearchList(
    ref="paymentProfileList",
    context="paymentProfile",
    :showSearchBar="false",
    :service="searchService",
    :searchOptions="searchOptions",
    :action="false",
    :details="false"
    :operations="false",
    :keepStateOnUrl="false"
  )

    template(slot="tableRows")

      //- expand row with payment profile billing address and credit card information, if available, using el-descriptions
      el-table-column(type="expand")
        template(slot-scope="scope")
          .details

            el-descriptions(v-if="scope.row.vindiPaymentType === 'CREDIT_CARD'", title="Cartão de Crédito", border, style="margin-bottom: 20px")
              el-descriptions-item(label="Bandeira", :span="2") {{ scope.row.vindiCreditCardInformation.brand }}
              el-descriptions-item(label="Número", :span="2") {{ scope.row.vindiCreditCardInformation.displayNumber }}
              el-descriptions-item(label="Nome do Titular", :span="2") {{ scope.row.vindiCreditCardInformation.holderName }}
              el-descriptions-item(label="Validade", :span="2")
                | {{ scope.row.vindiCreditCardInformation.month.toString().padStart(2, '0') }}/{{ scope.row.vindiCreditCardInformation.year }}

            el-descriptions(title="Endereço de Cobrança", border)
              el-descriptions-item(label="CEP", :span="2") {{ scope.row.vindiBillingAddress.zipCode }}
              el-descriptions-item(label="Endereço", :span="2") {{ scope.row.vindiBillingAddress.street }}
              el-descriptions-item(label="Número", :span="2") {{ scope.row.vindiBillingAddress.number }}
              el-descriptions-item(label="Complemento", :span="2") {{ scope.row.vindiBillingAddress.complement }}
              el-descriptions-item(label="Cidade", :span="2") {{ scope.row.vindiBillingAddress.city }}
              el-descriptions-item(label="Bairro", :span="2") {{ scope.row.vindiBillingAddress.district }}
              el-descriptions-item(label="Estado", :span="2") {{ scope.row.vindiBillingAddress.state }}


      el-table-column(prop="paymentProfileId", label="ID", width="260")

      //- description
      el-table-column(label="Descrição", show-overflow-tooltip)
        template(slot-scope="scope")
          span {{ paymentProfileDescription(scope.row) }}

      //- createdAt
      el-table-column(prop="createdAt", label="Criado em", width="140")
        template(slot-scope="scope")
          span {{ toDate(scope.row.createdAt) }}

      //- updatedAt
      el-table-column(prop="updatedAt", label="Atualizado em", width="140")
        template(slot-scope="scope")
          span {{ toDate(scope.row.updatedAt) }}



</template>
<script setup>
import moment from 'moment'
import 'moment-timezone'

import { ref, computed } from 'vue'
import VindiService from '@/services/billing/VindiService'
import SearchList from '@/components/list/SearchList.vue'

const props = defineProps({
  customerId: {
    type: Number,
    required: true,
  },
});

const paymentProfileList = ref();

const searchOptions = computed(() => ({
  filters: [ `customerId:${props.customerId}` ]
}));

const searchService = ref({
  search: VindiService.searchVindiPaymentProfile
})

const paymentProfileDescription = (paymentProfile) => {
  if (paymentProfile.vindiPaymentType === 'CREDIT_CARD') {
    return `Cartão de Crédito - ${paymentProfile.vindiCreditCardInformation.brand} - ${paymentProfile.vindiCreditCardInformation.displayNumber}`;
  } else if (paymentProfile.vindiPaymentType === 'BANK_SLIP') {
    return 'Boleto Bancário'
  } else if (paymentProfile.vindiPaymentType === 'PIX') {
    return 'PIX'
  } else {
    return 'Tipo de pagamento desconhecido'
  }
}

const toDate = (dateString) => {
  return moment.utc(dateString, 'YYYY-MM-DDTHH:mm:ss').tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')
}
</script>
<style lang="sass">
.customer-payment-profile-list
  .details
    padding: 10px
</style>