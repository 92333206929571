<template lang="pug">
.subscription-action-interrupt-subscription
  el-dialog(
    top="0",
    class="text-left-aligned"
    title="Interromper assinatura"
    :visible.sync="isVisible")

    el-form(ref="form", :rules="rules", :model="data", label-position="top", label-width="120px")

      p
        | A assinatura será interrompida imediatamente, não será gerada mais nenhuma box e&#32;
        | também não será feita mais nenhuma cobrança.
        br
        br
        b Caso haja alguma valor a ser estornado, ele deve ser feito manualmente. Caso haja algum valor pendente, ele deve ser cobrado antes de prosseguir com a interrupção das assinatura.

      el-card(shadow="never")

        h3 Dados da assinatura

        el-row
          el-col(:span="8")
            el-statistic(title="Mês do período atual")
              template(slot="formatter")
                span.small {{ cancellationInfo.monthsIntoPeriod }}ᵒ mês de {{ cancellationInfo.monthsPerPeriod }}&#32;
                  | {{ cancellationInfo.monthsPerPeriod == 1 ? "mês" : "meses" }}

          el-col(:span="8")
            el-statistic(title="Última renovação")
              template(slot="formatter")
                span.small {{ fromSystemDate(cancellationInfo.lastRenewalAt) }}

          el-col(:span="8")
            el-statistic(title="Próxima renovação")
              template(slot="formatter")
               span.small {{ fromSystemDate(cancellationInfo.nextRenewalAt) }}

        el-divider

        h4 Multa de cancelamento

        el-row
          el-col(:span="8")
            el-form-item(label="Valor da multa", size="mini")
                | R$ {{ fromCents(cancellationInfo.fee) }}

          el-col(:span="8")
            el-form-item(label="Valor a ser estornado", size="mini")
              | R$ {{ fromCents(cancellationInfo.refund) }}

          el-col(:span="8")
            el-form-item(label="Valor pendente", size="mini")
              | R$ {{fromCents(cancellationInfo.dueAmount) }}

      el-row
        el-col

          h3 Razão de cancelamento

          el-form-item(label="Razão de cancelamento", prop="cancelReason", size="small")
            el-select(v-model="data.cancelReason")
                el-option(
                  v-for="reason in reasons"
                  :label="reason.title"
                  :value="reason.id"
                )

          el-form-item(label="Comentário sobre cancelamento", prop="cancelReasonComment", size="small")
            el-input(type="textarea" v-model="data.cancelReasonComment" rows="5")

    .footer(slot="footer")
      el-button(@click="close") Voltar

      el-button(type="danger", @click="promptInterruption", :loading="isInterrupting") Interromper assinatura

</template>
<script setup>
import SubscriptionService from '@/services/billing/SubscriptionService';
import { useMessage } from '@/utils/Message';
import { useConfirm } from '@/utils/MessageBox';
import moment from 'moment';

import { computed, nextTick, ref } from 'vue';

const reasons = [
  { id: 'TOO_MANY_PRODUCTS', title: 'Acúmulo de produtos' },
  { id: 'SINGLE_BOX', title: 'Box Avulsa/Presente' },
  { id: 'TRAVEL_OR_TRIP', title: 'Mudança/Viagem' },
  { id: 'DID_NOT_AUTHORIZE_RENEWAL', title: 'Não autorizei a renovação automática' },
  { id: 'DID_NOT_RECEIVE_GIFT', title: 'Não recebi o presente da promoção/cupom' },
  { id: 'LOGISTICAL_ISSUE', title: 'Questões logísticas' },
  { id: 'PRODUCT_SELECTION_ISSUE', title: 'Questões com a seleção de produtos' },
  { id: 'HEALTH_PROBLEMS', title: 'Questões de saúde' },
  { id: 'FINANCIAL_PROBLEMS', title: 'Questões financeiras' },
  { id: 'TRY_COMPETITOR', title: 'Testar outras assinaturas' },
  { id: 'NOT_INTERESTED', title: 'Não tenho mais interesse' },

  { id: 'CHANGE_BILLING', title: 'Alterar forma de pagamento' },
  { id: 'SUBSCRIPTION_TOO_EXPENSIVE', title: 'Assinatura muito cara' },
  { id: 'DUPLICATED_SUBSCRIPTION', title: 'Assinatura duplicada' },
  { id: 'DELIVERY_TOOK_TOO_LONG', title: 'Demora na entrega' },
  { id: 'GIVE_UP', title: 'Desisti da compra' },
  { id: 'SHIPPING_TOO_EXPENSIVE', title: 'Frete muito caro' },
  { id: 'CARD_HAS_NO_LIMIT', title: 'Cartão sem limite' },
  { id: 'PRODUCTS_DID_NOT_MATCH_PROFILE', title: 'Produtos não combinam com meu perfil' },

  { id: 'OTHER', title: 'Outra razão' },
]

const confirm = useConfirm()
const message = useMessage()

const props = defineProps({
  subscription: {
    type: Object,
    required: true
  },
  cancellationInfo: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['interrupted'])

const form = ref()
const rules = ref({
  cancelReason: [ { required: true, trigger: 'change', message: "Campo obrigatório" }],
  cancelReasonComment: [ { required: true, trigger: 'change', message: "Campo obrigatório" }],
})

const isVisible = ref(false)
const isInterrupting = ref(false)

const data = ref({})

const fromCents = (value) => {
  return value ? (value / 100).toFixed(2).replace(".", ",") : "0,00";
}

const fromSystemDate = (value) => {
  return value ? moment(value, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
}

const open = () => {
  data.value = {
    cancelReason: null,
    cancelReasonComment: ""
  }

  isVisible.value = true

  nextTick(() => {
    form.value.clearValidate()
  })
}

const close = () => {
  isVisible.value = false
}

const promptInterruption = async () => {
  var isValid = false

  try {
    await form.value.validate()

    isValid = true
  } catch(e) {
    message.warning("Verifique os dados preenchidos")

    return
  }

  confirm("Você tem certeza de que deseja interromper esta assinatura? Esta operação não pode ser desfeita", "Interromper assinatura", {
    confirmButtonText: 'Sim, interromper assinatura',
    cancelButtonText: 'Não interromper',
    type: 'warning'
  })
  .then(() => {
    if (props.cancellationInfo.dueAmount > 0) {
      return confirm("Esta assinatura possuí um valor pendente a ser pago. Este valor já foi pago?", "Interromper assinatura", {
        confirmButtonText: 'Sim, já foi pago',
        cancelButtonText: 'Não, ainda não foi pago',
        type: 'warning'
      })
    } else {
      return Promise.resolve()
    }
  })
  .then(() => {
    return interrupt()
  })
}

const interrupt = async () => {
  isInterrupting.value = true

  try {
    await SubscriptionService.cancel(props.subscription.id, {
      cancelReason: data.value.cancelReason,
      cancelReasonComment: data.value.cancelReasonComment.trim(),
      cancelImmediately: true,

      refundAllPeriod: false,
      refundRemainingPeriod: false,
      dueAmountAlreadyPaid: true
    })

    emit('interrupted')

    message.success(`Assinatura interrompida com sucesso!`)
  } catch(e) {
    message.error(`Houve um problema ao interromper esta assinatura: ${e}`)

    console.error(e)
  } finally {
    isInterrupting.value = false

    close()
  }
}

defineExpose({
  open
})
</script>
<style lang="scss" scoped>
p {
  word-break: break-word
}
</style>
<style lang="scss">
  .subscription-action-interrupt-subscription {

    .delete-confirm {
      .el-button {
        margin-left: 10px;
      }
    }

    .el-dialog__wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 48px;

      .el-dialog {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-height: 98vh;
        overflow: auto;
        width: 1056px;

        .el-dialog__header, .el-dialog__footer {
          flex: 0;
        }

        .el-dialog__body {
          flex: 1;
          overflow: auto;
          padding: 16px 20px;

          .el-card__body {
            h3 {
              margin-top: 0;
            }
          }

          span.small {
            font-size: 14px;
          }
        }

        .el-dialog__footer {
          padding: 10px 20px;
        }

        .editor-footer {
          .page {
            padding: 0 8px;
            font-size: 14px;
          }
        }
      }
    }
  }
  </style>
