<template lang="pug">
.product-variant-options
  el-row
    el-col(:span="24")
      el-table(ref="table", :data="value", row-key="key", border, max-height="960", size="small")
        el-table-column(type="expand")
          template(slot-scope="props")
            ProductVariantOptions(
              :value="props.row",
              :validateImmediately="validateImmediately",
              :readonly="props.row.new != true"
              @input="option => updateOptionType(props.$index, option)",
              :optionTypes="availableOptionTypes"
            )

        el-table-column(label="Tipo", prop="type")
          template(slot-scope="props")
            span {{ translateOptionType(props.row.type) }}

        el-table-column(label="Opções", prop="options", width="200")
          template(slot-scope="props")
            span(v-if="props.row.options.length == 0") Nenhum valor
            span(v-else-if="props.row.options.length == 1") {{ props.row.options.length }} valor
            span(v-else) {{ props.row.options.length }} valores

        el-table-column(width="66")
          template(slot="header")
            el-button(title="Adicionar nova opção", type="primary", size="small", icon="el-icon-plus", @click="appendOptionType")

          template(slot-scope="props")
            el-popconfirm(v-if="props.row.new", title="Tem certeza de que deseja excluir?", @confirm="deleteNewOptionType(props.$index)")
              el-button(slot="reference", plain, type="danger", title="Excluir", size="small", icon="el-icon-delete")

            el-button(
              v-if="!props.row.new",
              plain,
              type="danger",
              title="Não é possível excluir uma opção já salva",
              size="small",
              icon="el-icon-delete",
              :disabled="true"
            )
</template>
<script setup>
import { computed, ref, nextTick, defineProps, defineEmits, defineExpose } from 'vue'

import ProductVariantOptions from './ProductVariantOptions.vue'

const optionTypes = [
  { value: "SCENT", label: "Aroma" },
  { value: "COLOR", label: "Cor" },
  { value: "SIZE", label: "Tamanho" }
]

const props = defineProps({
  value: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['input'])

const table = ref()
const validateImmediately = ref(false)

const availableOptionTypes = ((currentType) => {
  return optionTypes.filter((optionType) => {
    return !props.value.find((option) => option.type == optionType.value && option.type != currentType)
  })
})

const appendOptionType = () => {
  if (props.value.length >= optionTypes.length) {
    return
  }

  const row = { key: props.value.length, type: null, options: [], new: true }

  emit("input", [ ...props.value, row ])

  nextTick(() => {
    table.value.toggleRowExpansion(row, true)
  })
}

const deleteNewOptionType = (index) => {
  const optionTypes = props.value.slice()

  optionTypes.splice(index, 1)

  emit("input", optionTypes)
}

const updateOptionType = (index, option) => {
  const optionTypes = props.value.slice()

  optionTypes.splice(index, 1, option)

  emit("input", optionTypes)
}

const validate = async () => {
  const validationErrors = props.value.map((optionType) => {
    if (!optionType.type || optionType.options.length == 0) {
      table.value.toggleRowExpansion(optionType, true)

      return true
    }

    return false

  })

  const hasErrors = validationErrors.find((b) => b == true) != undefined

  if (hasErrors) {
    validateImmediately.value = true

    throw new Error("Has errors on options list")
  } else {
    validateImmediately.value = false
  }
}

const translateOptionType = (type) => {
  const optionType = optionTypes.find((optionType) => optionType.value == type)

  return optionType ? optionType.label : null
}

defineExpose({
  validate
})
</script>
<style scoped lang="sass">
</style>