<template>
  <div class="subscription-box-order-detail" v-if="!loading.orderInfo">
    <el-header v-if="showHeader">
      <el-col :span="16">
        <el-page-header @back="back" :content="`Pedido #${order.id}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-dropdown trigger="click" @command="handleActionMenuCommand">
        <el-button type="primary" size="medium" class="el-dropdown-link">
          Ações<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            command="cancel-order"
            :disabled="loading.orderInfo || !cancelOrderEnabled"
          >
            Cancelar pedido
          </el-dropdown-item>
          <el-dropdown-item
            command="create-occurrence"
            :disabled="loading.orderInfo"
            v-if="!this.occurrence || !this.occurrence.id"
          >
            Registrar ocorrência
          </el-dropdown-item>
          <el-dropdown-item command="update-occurrence" :disabled="loading.orderInfo" v-else>
            Atualizar ocorrência
          </el-dropdown-item>
          <el-dropdown-item
            v-if="showProductionActions"
            command="recreate-order"
            :disabled="(loading.orderInfo || order.status == 'CANCELED' || !showProductionActions)">
            Recriar pedido
          </el-dropdown-item>
          <el-dropdown-item
            v-if="showProductionActions"
            command="add-to-production-batch"
            :disabled="(loading.orderInfo || order.status == 'CANCELED' || !showProductionActions)">
            Adicionar a um lote de montagem
          </el-dropdown-item>
          <el-dropdown-item
            command="approve-fraud-review"
            :disabled="(loading.orderInfo || order.status != 'SUSPECTED_FRAUD')">
            Aprovar revisão de fraude
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      </el-col>
    </el-header>

    <el-tabs v-model="activeTab" :stretch="true" @tab-click="tabChanged" :tab-position="tabPosition" >
      <el-tab-pane
        label="Informações básicas"
        :name="tabs.BASIC_INFORMATION"
        v-loading="loading.orderInfo"
      >
        <el-form
          :model="order"
          label-width="160px"
        >

          <el-row>
            <el-col :span="12">
              <!-- ID -->
              <el-form-item :label="$t('order.id')" prop="id">
                <el-input :value="order.id" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- Edition -->
              <el-form-item :label="$t('order.edition')" prop="edition">
                <el-input :value="order.edition" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div v-if="order && order.status">
            <el-row>
              <el-col :span="12">
                <!-- Status -->
                <el-form-item :label="$t('order.status')" prop="status">
                  <el-input :value="$t(`orderStatus.${order.status}`)" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- NF -->
                <el-form-item :label="$t('order.nf')" prop="nfe">
                  <el-input :value="order.nfe" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-row>
            <el-col :span="12">
              <!-- In Production At -->
              <el-form-item :label="$t('order.inProductionAt')" prop="inProductionAt">
                <el-input :value="fromSystemDate(order.inProductionAt)" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- Ready to Post At -->
              <el-form-item :label="$t('order.readyToPostAt')" prop="readyToPostAt">
                <el-input :value="fromSystemDate(order.readyToPostAt)" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- In Transit At -->
              <el-form-item :label="$t('order.inTransitAt')" prop="inTransitAt">
                <el-input :value="fromSystemDate(order.inTransitAt)" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- Delivered At -->
              <el-form-item :label="$t('order.deliveredAt')" prop="deliveredAt">
                <el-input :value="fromSystemDate(order.deliveredAt)" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- Canceled At -->
              <el-form-item :label="$t('order.canceledAt')" prop="canceledAt">
                <el-input :value="fromSystemDate(order.canceledAt)" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- Created At -->
              <el-form-item label="Data da criação" prop="createdAt">
                <el-input :value="fromSystemDate(order.createdAt)" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane
        label="Endereço de entrega"
        :name="tabs.SHIPPING"
        v-loading="loading.shipping"
      >

        <el-form
          :model="order"
          label-width="160px"
        >
          <el-row>
            <el-col :span="8">
              <!-- Shipping Company -->
              <el-form-item :label="$t('order.shippingCompany')" prop="shippingCompany">
                <el-input :value="order.shippingCompany" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <!-- Tracking Code -->
              <el-form-item :label="$t('order.estimatedDeliveryTime')" prop="eta">
                <el-input :value="`${shippingCost.days || ''} ${$t('businessDays')}`" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <!-- Tracking Code -->
              <el-form-item :label="$t('order.trackingCode')" prop="trackingCode">
                <el-input :value="order.trackingCode" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <!-- Tracking URL -->
              <el-form-item :label="$t('order.trackingUrl')" prop="trackingUrl">
                <el-input :value="order.trackingUrl" disabled>
                  <el-button slot="append" @click="copyToClipboard(order.trackingUrl)">
                    <font-awesome-icon :icon="['far', 'copy']" />
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-form :model="shipping" label-width="160px">

          <!-- Postal Code -->
          <el-form-item :label="$t('order.postalCode')" prop="postalCode">
            <el-input :value="shipping.postalCode" v-mask="'#####-###'" disabled></el-input>
          </el-form-item>

          <!-- Steet -->
          <el-form-item :label="$t('order.street')" prop="street">
            <el-input :value="shipping.street" disabled></el-input>
          </el-form-item>

          <!-- Number -->
          <el-form-item :label="$t('order.number')" prop="number">
            <el-input :value="shipping.number" disabled></el-input>
          </el-form-item>

          <!-- Complement -->
          <el-form-item :label="$t('order.complement')" prop="complement">
            <el-input :value="shipping.complement" disabled></el-input>
          </el-form-item>

          <!-- District -->
          <el-form-item :label="$t('order.district')" prop="district">
            <el-input :value="shipping.district" disabled></el-input>
          </el-form-item>

          <!-- City -->
          <el-form-item :label="$t('order.city')" prop="city">
            <el-input :value="shipping.city" disabled></el-input>
          </el-form-item>

          <!-- State -->
          <el-form-item :label="$t('order.state')" prop="state">
            <el-input :value="shipping.state" disabled></el-input>
          </el-form-item>

        </el-form>
      </el-tab-pane>
      <el-tab-pane
        label="Perfil de beleza"
        :name="tabs.PROFILE"
        v-loading="loading.profile"
      >
        <div v-if="order && order.productSelection">
          <div
            class="profile__question"
            v-for="question in questionnaire"
            :key="question.id"
          >
            <el-row>
              <el-col :span="24">
                <p class="title">{{question.title}}</p>
              </el-col>
            </el-row>
            <el-row>
              <section v-if="question.type === 'SINGLE_CHOICE'">
                <el-radio-group v-model="profile[question.id]">
                  <el-radio
                    v-for="option in question.options"
                    :key="option.value"
                    :label="option.value"
                    class="option"
                    disabled
                  >
                    <span class="option__label">{{option.name}}</span>
                  </el-radio>
                </el-radio-group>
              </section>
              <section v-else>
                <el-checkbox-group v-model="profile[question.id]">
                  <el-checkbox
                    v-for="option in question.options"
                    :key="option.value"
                    :label="option.value"
                    class="option"
                    disabled
                  >
                    <span class="option__label">{{option.name}}</span>
                  </el-checkbox>
                </el-checkbox-group>
              </section>
            </el-row>
          </div>
        </div>

        <div v-else class="centered">
          {{$t('order.emptyMessages.noProfile')}}
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="Produtos selecionados"
        :name="tabs.PRODUCT_SELECTION"
        v-loading="loading.orderInfo"
      >
        <div v-if="order && order.productSelection">
          <el-form :model="order" label-width="160px">
            <el-row>
              <el-col :span="8">
                <!-- Cost -->
                <el-form-item :label="$t('order.cost')" prop="productSelection.cost" size="small">
                  <el-input :value="fromCents(order.productSelection.cost)" disabled></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <!-- Market Price -->
                <el-form-item :label="$t('order.marketPrice')" prop="productSelection.marketPrice"  size="small">
                  <el-input :value="fromCents(order.productSelection.marketPrice)" disabled></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <!-- Perceived Value -->
                <el-form-item :label="$t('order.perceivedValue')" prop="productSelection.perceivedValue" size="small">
                  <el-input :value="fromCents(order.productSelection.perceivedValue)" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>

          <!-- Selected Products -->
          <el-table
            border
            :data="products"
            style="margin-bottom: 22px"
            v-loading="loading.products"
            :row-class-name="undesiredRowClassName"
            max-height="520"
            size="small"
          >
            <el-table-column :label="$t('order.selectedProducts')">

              <!-- Id -->
              <el-table-column prop="id" :label="$t('order.selectedProductId')" width="80" fixed></el-table-column>

              <!-- Brand -->
              <el-table-column prop="brand" label="Marca" width="140">
                <template slot-scope="scope">
                  {{ scope.row.brand.name }}
                </template>
              </el-table-column>

              <!-- Name -->
              <el-table-column prop="displayName" :label="$t('order.selectedProductName')"></el-table-column>

              <!-- Cost -->
              <el-table-column prop="cost" :label="$t('order.selectedProductCost')" width="140">
                <template slot-scope="scope">
                  R$ {{ fromCents(scope.row.cost) }}
                </template>
              </el-table-column>

              <!-- Market Price -->
              <el-table-column prop="marketPrice" :label="$t('order.selectedProductMarketPrice')" width="140">
                <template slot-scope="scope">
                  R$ {{ fromCents(scope.row.marketPrice) }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>

          <!-- Desired Products -->
          <el-table
            border
            :data="desiredProducts"
            style="margin-bottom: 22px"
            v-loading="loading.desiredProducts"
            max-height="520"
            size="small"
          >
            <el-table-column :label="$t('order.desiredProducts')">

              <!-- Id -->
              <el-table-column prop="id" :label="$t('order.desiredProductId')" width="80" fixed></el-table-column>

              <!-- Brand -->
              <el-table-column prop="brand" label="Marca" width="140">
                <template slot-scope="scope">
                  {{ scope.row.brand.name }}
                </template>
              </el-table-column>

              <!-- Name -->
              <el-table-column prop="displayName" :label="$t('order.desiredProductName')"></el-table-column>

              <!-- Cost -->
              <el-table-column prop="cost" :label="$t('order.desiredProductCost')" width="140">
                <template slot-scope="scope">
                  R$ {{ fromCents(scope.row.cost) }}
                </template>
              </el-table-column>

              <!-- Market Price -->
              <el-table-column prop="marketPrice" :label="$t('order.desiredProductMarketPrice')" width="140">
              <template slot-scope="scope">
                  R$ {{ fromCents(scope.row.marketPrice) }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>

          <!-- Undesired Products -->
          <el-table
            border
            :data="undesiredProducts"
            style="margin-bottom: 22px"
            v-loading="loading.undesiredProducts"
            height="520"
            size="small"
          >
            <el-table-column :label="$t('order.undesiredProducts')">

              <!-- Id -->
              <el-table-column fixed prop="id" :label="$t('order.undesiredProductId')" width="80"></el-table-column>

              <!-- Brand -->
              <el-table-column prop="brand" label="Marca" width="140">
                <template slot-scope="scope">
                  {{ scope.row.brand.name }}
                </template>
              </el-table-column>

              <!-- Name -->
              <el-table-column prop="displayName" :label="$t('order.undesiredProductName')"></el-table-column>

              <!-- Cost -->
              <el-table-column prop="cost" :label="$t('order.undesiredProductCost')" width="140">
                <template slot-scope="scope">
                  R$ {{ fromCents(scope.row.cost) }}
                </template>
              </el-table-column>

              <!-- Market Price -->
              <el-table-column prop="marketPrice" :label="$t('order.undesiredProductMarketPrice')" width="140">
              <template slot-scope="scope">
                  R$ {{ fromCents(scope.row.marketPrice) }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>

        </div>

        <div v-else class="centered">
          {{$t('order.emptyMessages.noProducts')}}
        </div>

      </el-tab-pane>
      <el-tab-pane
        label="Produtos adicionais"
        :name="tabs.ADDITIONAL_PRODUCTS"
        v-loading="loading.additionalProducts">

        <div>
          <!-- Additional Products -->
          <el-table
            border
            :data="additionalProducts"
            style="margin-bottom: 22px"
            v-loading="loading.additionalProducts"
            height="520"
            size="small"
          >
            <el-table-column :label="$t('order.additionalProducts')">

              <!-- Id -->
              <el-table-column fixed prop="id" :label="$t('order.additionalProductId')" width="80"></el-table-column>

              <!-- Name -->
              <el-table-column prop="name" :label="$t('order.additionalProductName')"></el-table-column>

              <!-- Actions -->
              <el-table-column v-if="showProductionActions" width="80">
                <template slot="header">
                  <el-button
                    title="Acrescentar produto adicional"
                    type="primary"
                    size="small"
                    plain
                    icon="el-icon-plus"
                    @click="showAddAdditionalProductDialog"
                    :disabled="(loading.orderInfo || order.status == 'CANCELED' || nfeEmitted || !showProductionActions)"
                  ></el-button>
                </template>

                <template slot-scope="scope">
                  <el-popconfirm title="Tem certeza de que deseja remover este produto?" @confirm="removeAdditionalProduct(scope.row.id)">
                    <el-button
                      slot="reference"
                      title="Remover produto adicional"
                      size="small"
                      type="danger"
                      plain
                      icon="el-icon-delete"
                      :disabled="nfeEmitted || order.status == 'CANCELED'"
                    ></el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>

      </el-tab-pane>
      <el-tab-pane
        :label="$t('order.invoice')"
        :name="tabs.INVOICE"
        v-loading="loading.invoice"
      >
        <div v-if="invoice">
          <el-form :model="invoice" label-width="160px">
            <!-- Id -->
            <el-form-item :label="$t('order.invoiceSection.id')" prop="id">
              <el-input :value="invoice.id" disabled></el-input>
            </el-form-item>

            <div v-if="invoice && invoice.status">
              <!-- Status -->
              <el-form-item :label="$t('order.invoiceSection.status')" prop="status">
                <el-input :value="$t(`invoiceStatus.${invoice.status}`)" disabled></el-input>
              </el-form-item>
            </div>

            <el-row>
              <el-col :span="12">
                <!-- Amount -->
                <el-form-item :label="$t('order.invoiceSection.amount')" prop="amount">
                  <el-input :value="fromCents(invoice.amount)" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- Total -->
                <el-form-item :label="$t('order.invoiceSection.total')" prop="total">
                  <el-input :value="fromCents(invoice.total)" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <!-- Paid Amount -->
                <el-form-item :label="$t('order.invoiceSection.paidAmount')" prop="paidAmount">
                  <el-input :value="fromCents(invoice.paidAmount)" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- Due Amount -->
                <el-form-item :label="$t('order.invoiceSection.dueAmount')" prop="dueAmount">
                  <el-input :value="fromCents(invoice.dueAmount)" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <!-- Charged At -->
                <el-form-item :label="$t('order.invoiceSection.chargeAt')" prop="chargeAt">
                  <el-input :value="fromSystemDate(invoice.chargeAt)" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- Paid At -->
                <el-form-item :label="$t('order.invoiceSection.paidAt')" prop="paidAt">
                  <el-input :value="fromSystemDate(invoice.paidAt)" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <!-- Items -->
          <el-table
            border
            :data="invoiceItems"
            style="margin-bottom: 22px"
            v-loading="loading.invoiceItems"
          >
            <el-table-column :label="$t('order.invoiceSection.items')">

              <!-- SKU -->
              <el-table-column prop="skuId" :label="$t('order.invoiceSection.sku')"></el-table-column>

              <!-- Amount -->
              <el-table-column :label="$t('order.invoiceSection.itemAmount')">
                <template slot-scope="scope">
                  {{fromCents(invoiceItems[scope.$index].amount)}}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>

          <!-- Discounts -->
          <el-table
            border
            :data="invoiceDiscounts"
            style="margin-bottom: 22px"
            v-loading="loading.invoiceDiscounts"
          >
            <el-table-column :label="$t('order.invoiceSection.discounts')">

              <!-- Coupon -->
              <el-table-column prop="couponId" :label="$t('order.invoiceSection.coupom')"></el-table-column>

              <!-- Amount -->
              <el-table-column :label="$t('order.invoiceSection.discountAmount')">
                <template slot-scope="scope">
                  {{fromCents(invoiceDiscounts[scope.$index].amount)}}
                </template>
              </el-table-column>

              <!-- Percentage -->
              <el-table-column :label="$t('order.invoiceSection.discountPercentage')">
                <template slot-scope="scope">
                  {{toPercentage(invoiceDiscounts[scope.$index].percentage)}}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>

        </div>
        <div v-else class="centered">
          {{$t('order.emptyMessages.noInvoice')}}
        </div>
      </el-tab-pane>

      <el-tab-pane v-if="showProductionActions" label="Lotes de montagem" :name="tabs.PRODUCTION_BATCHES" :lazy="true">
        <SubscriptionBoxOrderProductionBatches
          v-if="order.id"
          ref="productionBatches"
          :subscription-box-order-id="order.id"
        ></SubscriptionBoxOrderProductionBatches>
      </el-tab-pane>
    </el-tabs>

    <!--- Dialogs -->
    <!-- Dialog - Cancel Order -->
    <el-dialog
      class="text-left-aligned"
      title="Cancelar Pedido"
      :visible.sync="cancelOrderDialogVisible"
      v-loading="loading.cancelOrder">

      <span>Tem certeza de que deseja cancelar esse pedido? Essa operação não pode ser desfeita.</span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelOrderDialogVisible = false">{{ $t('back') }}</el-button>
        <el-button
          type="primary"
          @click="doCancelOrder">
          {{ 'Cancelar Pedido' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- Dialog - Create Occurrence -->
    <el-dialog
      class="text-left-aligned"
      title="Registrar Ocorrência"
      :visible.sync="createOccurrenceDialogVisible"
      v-loading="loading.createOccurrence">

        <el-form
          ref="createOccurrenceForm"
          label-width="180px"
          label-position="top"
          :rules="createOccurrenceRules"
          :model="createOccurrence" >

        <!-- Type -->
        <el-form-item label="Tipo" prop="type">
          <el-select v-model="createOccurrence.type" class="stretched" @change="onChangeOccurenceType()">
            <el-option label="Garantia de caixa" value="BOX_WARRANTY"></el-option>
            <el-option label="Troca de produtos" value="PRODUCT_EXCHANGE"></el-option>
          </el-select>
        </el-form-item>

        <!-- Status -->
        <el-form-item label="Status" prop="status">
          <el-select v-model="createOccurrence.status" class="stretched">
            <el-option label="Aguardando atendimento" value="PENDING_CUSTOMER_HAPPINESS"></el-option>
            <el-option label="Aguardando chegada" value="PENDING_ARRIVAL"></el-option>
            <el-option label="Pronto para gerar nova caixa" value="PENDING_BOX_GENERATION"></el-option>
            <el-option label="Finalizado" value="FINALIZED"></el-option>
          </el-select>
        </el-form-item>

        <!-- Is Returning -->
        <el-form-item label="Terá algo retornando?" prop="productsReturning">
          <el-switch v-model="createOccurrence.productsReturning" @change="onHasProductsReturning()" />
        </el-form-item>

        <!-- Incoming Products -->
        <el-form-item label="Produtos retornando" prop="incomingProducts" v-if="createOccurrence.productsReturning">
          <snowflake-remote-search-multi-select
            v-model="createOccurrence.incomingProducts"
            placeholder="Buscar produtos"
            :search-function="productService.search"
            :find-function="productService.getProductById">
          </snowflake-remote-search-multi-select>
        </el-form-item>

        <!-- Has Replacements -->
        <el-form-item label="Definir produtos" prop="hasReplacements">
          <el-switch v-model="createOccurrence.hasReplacements" />
        </el-form-item>

        <!-- Replacement Products -->
        <el-form-item label="Produtos para enviar" prop="replacementProducts" v-if="createOccurrence.hasReplacements">
          <snowflake-remote-search-multi-select
            v-model="createOccurrence.replacementProducts"
            placeholder="Buscar produtos"
            :search-function="productService.search"
            :find-function="productService.getProductById">
          </snowflake-remote-search-multi-select>
        </el-form-item>

      </el-form>

      <el-collapse>
        <el-collapse-item title="Buscar possíveis trocas" name="possible_exchanges">
          <el-input-number
            v-model="occurrenceExchangeHint.ammount"
            :min="1"
            :max="10"
            label="Quantidade para consulta" />
          <el-button style="margin-left: 10px;" :span="8" @click="onSearchPossibleReplacements">
            Buscar
          </el-button>

          <el-table
            :data="occurrenceExchangeHint.results"
            style="width: 100%"
            v-loading="occurrenceExchangeHint.loading"
          >
            <template slot="empty">
              Realize a busca para encontrar possíveis trocas.
            </template>
            <el-table-column
              prop="id"
              label="Id"
              width="180">
            </el-table-column>
            <el-table-column
              prop="name"
              label="Nome"
              >
            </el-table-column>
            <el-table-column
              prop="marketPrice"
              label="Valor de mercado"
              width="180">
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>

      <span slot="footer" class="dialog-footer">
        <el-button @click="createOccurrenceDialogVisible = false">Voltar</el-button>
        <el-button
          type="primary"
          @click="doCreateOccurrence">
          Registrar
        </el-button>
      </span>
    </el-dialog>

    <!-- Dialog - Update Occurrence -->
    <el-dialog
      class="text-left-aligned"
      title="Atualizar Ocorrência"
      :visible.sync="updateOccurrenceDialogVisible"
      v-loading="loading.updateOccurrence">

        <el-form
          ref="updateOccurrenceForm"
          label-width="180px"
          label-position="top"
          :rules="updateOccurrenceRules"
          :model="updateOccurrence" >

        <!-- Type -->
        <el-form-item label="Tipo" prop="type">
          <el-select v-model="occurrence.type" class="stretched" disabled>
            <el-option label="Garantia de caixa" value="BOX_WARRANTY"></el-option>
            <el-option label="Troca de produtos" value="PRODUCT_EXCHANGE"></el-option>
          </el-select>
        </el-form-item>

        <!-- Status -->
        <el-form-item label="Status" prop="status">
          <el-select v-model="updateOccurrence.status" class="stretched">
            <el-option label="Aguardando atendimento" value="PENDING_CUSTOMER_HAPPINESS"></el-option>
            <el-option label="Aguardando chegada" value="PENDING_ARRIVAL"></el-option>
            <el-option label="Pronto para gerar nova caixa" value="PENDING_BOX_GENERATION"></el-option>
            <el-option label="Finalizado" value="FINALIZED"></el-option>
          </el-select>
        </el-form-item>

        <!-- Is Returning -->
        <el-form-item label="Terá algo retornando?" prop="isReturning">
          <el-switch v-model="occurrence.productsReturning" disabled />
        </el-form-item>

        <!-- Incoming Products -->
        <el-form-item label="Produtos retornando" prop="incomingProducts" v-if="occurrence.productsReturning">
          <snowflake-remote-search-multi-select
            v-model="occurrence.incomingProducts"
            placeholder="Buscar produtos"
            :search-function="productService.search"
            :find-function="productService.getProductById"
            :disabled="true">
          </snowflake-remote-search-multi-select>
        </el-form-item>

        <!-- Has replacements -->
        <el-form-item label="Definir produtos" prop="hasReplacements">
          <el-switch
            :value="occurrence.replacementProducts && occurrence.replacementProducts.length > 0"
            disabled
          />
        </el-form-item>

        <!-- Replacement Products -->
        <el-form-item
          label="Produtos para enviar"
          prop="replacementProducts"
          v-if="occurrence.replacementProducts && occurrence.replacementProducts.length > 0"
        >
          <snowflake-remote-search-multi-select
            v-model="occurrence.replacementProducts"
            placeholder="Buscar produtos"
            :search-function="productService.search"
            :find-function="productService.getProductById"
            :disabled="true">
          </snowflake-remote-search-multi-select>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="updateOccurrenceDialogVisible = false">Voltar</el-button>
        <el-button
          type="primary"
          @click="doUpdateOccurrence">
          Atualizar
        </el-button>
      </span>
    </el-dialog>

    <!-- Recreate order dialog -->
    <RecreateOrder
      v-if="order.id"
      :subscription-box-order-id="order.id"
      :subscription-box-order-products="products"
      :subscription-box-order-additional-products="additionalProducts"
      :visible.sync="recreateOrderDialogVisible"
      @executed="loadSubscriptionBoxOrder"
      >
    </RecreateOrder>

    <!-- Add to production batch dialog -->
    <AddToProductionBatch
      v-if="order.id"
      :subscription-box-order-id="order.id"
      :visible.sync="addToProductionBatchDialogVisible"
      @executed="reloadProductionBatches"
    ></AddToProductionBatch>

    <!-- Add additional product dialog-->
    <AddAdditionalProduct
      v-if="order.id"
      :subscription-box-order-id="order.id"
      :visible.sync="addAdditionalProductDialogVisible"
      @executed="loadProducts"
    ></AddAdditionalProduct>

  </div>
</template>

<script>
import moment from 'moment';

import Storage from '@/storage';

import SnowflakeRemoteSearchMultiSelect from '@/components/input/RemoteSearchMultiSelect.vue';
import SubscriptionBoxOrderService from '@/services/fulfillment/SubscriptionBoxOrderService';
import ShippingAddressService from '@/services/ShippingAddressService';
import InvoiceService from '@/services/billing/InvoiceService';
import ItemService from '@/services/billing/ItemService';
import DiscountService from '@/services/billing/DiscountService';
import ProductService from '@/services/stock/ProductService';
import ProfileService from '@/services/ProfileService';
import QuestionnaireService from '@/services/QuestionnaireService';
import OccurrenceService from '@/services/fulfillment/OccurrenceService';
import ProductSelecionService from '@/services/fulfillment/ProductSelectionService';

import AddToProductionBatch from './actions/AddToProductionBatch.vue'
import RecreateOrder from './actions/RecreateOrder.vue'
import SubscriptionBoxOrderProductionBatches from './tabs/SubscriptionBoxOrderProductionBatches.vue';
import AddAdditionalProduct from './actions/AddAdditionalProduct.vue';

const tabs = {
  BASIC_INFORMATION: 'basic-information',
  SHIPPING: 'shipping',
  PROFILE: 'profile',
  PRODUCT_SELECTION: 'product-selection',
  ADDITIONAL_PRODUCTS: 'additional-products',
  INVOICE: 'invoice',
  PRODUCTION_BATCHES: 'production-batches'
};

const initialProductExchangeHint = (() => ({
  ammount: 1,
  results: [],
  loading: false,
}));

export default {
  name: 'subscription-box-order-detail',
  components: {
    AddToProductionBatch,
    RecreateOrder,
    SnowflakeRemoteSearchMultiSelect,
    SubscriptionBoxOrderProductionBatches,
    AddAdditionalProduct
  },
  props: {
    id: {
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    tabPosition: {
      type: String,
      default: 'top',
    },
  },
  data() {
    return {
      loading: {
        orderInfo: false,
        shipping: false,
        profile: false,
        productSelection: false,
        invoice: false,
        invoiceItems: false,
        invoiceDiscounts: false,
        products: false,
        desiredProducts: false,
        undesiredProducts: false,
        additionalProducts: false,
        cancelOrder: false,
        createOccurrence: false,
        updateOccurrence: false,
      },
      activeTab: tabs.BASIC_INFORMATION,
      tabs,
      occurrence: {},
      order: {},
      shipping: {},
      shippingCost: {},
      profile: {},
      questionnaire: [],
      invoice: {},
      invoiceItems: [],
      invoiceDiscounts: [],
      products: [],
      additionalProducts: [],
      desiredProducts: [],
      undesiredProducts: [],
      productService: ProductService,
      occurrenceExchangeHint: initialProductExchangeHint(),
      cancelOrderDialogVisible: false,
      createOccurrenceDialogVisible: false,
      addToProductionBatchDialogVisible: false,
      addAdditionalProductDialogVisible: false,
      recreateOrderDialogVisible: false,
      createOccurrenceRules: {
        type: { required: true, message: 'Informe o tipo da ocorrência.' },
        status: { required: true, message: 'Informe status da ocorrência.' },
      },
      createOccurrence: {},
      updateOccurrenceDialogVisible: false,
      updateOccurrence: {},
      updateOccurrenceRules: {
        status: { required: true, message: 'Informe status da ocorrência.' },
      },
    };
  },
  async created() {
    const {
      id: questionnaireId,
      questions: questionnaire,
    } = await QuestionnaireService.getCurrentQuestionnaire();

    this.questionnaireId = questionnaireId;
    this.questionnaire = questionnaire;
    this.profile = this.questionnaire.reduce((acc, curr) => ({
      ...acc,
      [curr.id]: curr.type === 'SINGLE_CHOICE' ? null : [],
    }), {});

    await this.loadSubscriptionBoxOrder();

    this.loadProfile();
    this.loadShippingAddress();
    this.loadInvoice();
  },
  mounted() {
    this.activeTab = this.$route.query['orderTab'] || this.activeTab;
  },
  computed: {
    cancelOrderEnabled() {
      if (!this.order || !this.order.status) return false;

      if (this.showProductionActions) {
        return this.order.status != 'CANCELED'
      } else {
        return ['SCHEDULED', 'NEW', 'PRODUCT_SELECTION_FAILED', 'PRODUCTS_SELECTED', 'IN_PRODUCTION', 'SUSPECTED_FRAUD']
          .indexOf(this.order.status) >= 0
      }
    },
    showProductionActions() {
      const roles = this.authRoles();

      return roles.includes('admin') || roles.includes('owner') || roles.includes('operations-lead');
    },
    nfeEmitted() {
      return this.order.nfe ? true : false
    },
    isAdmin() {
      const roles = this.authRoles();

      return roles.includes('admin') || roles.includes('owner');
    },
  },
  methods: {
    authRoles() {
      const encodedRole = Storage.get(Storage.keys.auth.role);
      const encodedRoles = Storage.get(Storage.keys.auth.roles);

      return encodedRoles ? JSON.parse(atob(encodedRoles)) : (encodedRole ? [atob(encodedRole)] : null);
    },
    tabChanged() {
      this.$router.replace({ query: { orderTab: this.activeTab } })
    },
    async loadSubscriptionBoxOrder() {
      this.loading.orderInfo = true;

      this.order = await SubscriptionBoxOrderService.getSubscriptionBoxOrderById(this.id);

      try {
        this.occurrence = await OccurrenceService.findByBoxOrderId(this.id);
      } catch {
        this.occurrence = {};
      }

      this.loading.orderInfo = false;

      await this.loadProducts();
    },
    async loadProducts() {
      if (this.order) {
        this.loading.products = true;

        const subscriptionBoxOrderProducts = await SubscriptionBoxOrderService.getSubscriptionBoxOrderProducts(this.order.id);

        this.products = subscriptionBoxOrderProducts.selectedProducts;
        this.desiredProducts = subscriptionBoxOrderProducts.desiredProducts;
        this.undesiredProducts = subscriptionBoxOrderProducts.undesiredProducts;
        this.additionalProducts = subscriptionBoxOrderProducts.additionalProducts;

        this.loading.products = false;
      }
    },
    async loadShippingAddress() {
      this.loading.shipping = true;

      const shippingAddressPromise = ShippingAddressService.getShippingAddressById(this.order.shippingAddressId);
      const shippingCostPromise = ShippingAddressService.getShippingCost(this.order.shippingAddressId);

      this.shipping = await shippingAddressPromise;
      this.shippingCost = await shippingCostPromise;

      this.loading.shipping = false;
    },
    async loadProfile() {
      if (this.order && this.order.productSelection) {
        this.loading.profile = true;
        try {
          const {
            profileId,
            profileRevision,
          } = this.order.productSelection;

          const { choicesByQuestion } = await ProfileService.getProfileByIdAndRevision(profileId, profileRevision);

          this.profile = this.questionnaire.reduce((acc, curr) => {
            const answer = choicesByQuestion[curr.id] || [];

            return {
              ...acc,
              [curr.id]: curr.type === 'SINGLE_CHOICE' ? answer[0] : answer,
            };
          }, {});
        } catch (e) {
          this.$message.warning(this.$t('customer.error.noProfile'));
        }

        this.loading.profile = false;
      }
    },
    async loadInvoice() {
      if (this.order.invoiceId) {
        this.loading.invoice = true;
        this.invoice = await InvoiceService.getInvoiceById(this.order.invoiceId);
        this.loading.invoice = false;

        this.loadInvoiceItems();
        this.loadInvoiceDiscounts();
      } else {
        this.invoice = null;
      }
    },
    async loadInvoiceItems() {
      this.loading.invoiceItems = true;
      this.invoiceItems = await Promise.all(this.invoice.items.map(itemId => ItemService.getItemById(itemId)));
      this.loading.invoiceItems = false;
    },
    async loadInvoiceDiscounts() {
      this.loading.invoiceDiscounts = true;
      const promises = this.invoice.discounts.map(discountId => DiscountService.getDiscountById(discountId));
      this.invoiceDiscounts = await Promise.all(promises);
      this.loading.invoiceDiscounts = false;
    },
    async doCancelOrder() {
      this.loading.cancelOrder = true;

      try {
        this.order = await SubscriptionBoxOrderService.cancelSubscriptionBoxOrder(this.order.id);

        this.$message.success('Pedido cancelado com sucesso!');
      } catch (e) {
        this.$message.error(e.message || 'Falha ao cancelar pedido');
      } finally {
        this.loading.cancelOrder = false;
        this.cancelOrderDialogVisible = false;
      }
    },
    async doCreateOccurrence() {
      try {
        await this.$refs.createOccurrenceForm.validate();
      } catch (e) {
        throw new Error('Ocorrência inválida');
      }

      if (!this.$storage.get(this.$storage.keys.auth.user)) {
        this.$message.error('Refaça o login.');
        return;
      }

      this.loading.createOccurrence = true;

      try {
        const request = {
          boxOrderId: this.id,
          type: this.createOccurrence.type,
          status: this.createOccurrence.status,
          owner: this.$storage.get(this.$storage.keys.auth.user),
          productsReturning: !!this.createOccurrence.productsReturning,
          incomingProducts: this.createOccurrence.incomingProducts,
          replacementProducts: this.createOccurrence.replacementProducts,
        };

        this.occurrence = await OccurrenceService.create(request);

        this.$message.success('Ocorrência criada com sucesso!');
      } catch (e) {
        this.$message.error(e.message || 'Falha ao criar ocorrência');
      } finally {
        this.loading.createOccurrence = false;
        this.createOccurrenceDialogVisible = false;
      }
    },
    async doUpdateOccurrence() {
      try {
        await this.$refs.updateOccurrenceForm.validate();
      } catch (e) {
        throw new Error('Ocorrência inválida');
      }

      if (!this.$storage.get(this.$storage.keys.auth.user)) {
        this.$message.error('Refaça o login.');
        return;
      }

      this.loading.updateOccurrence = true;

      try {
        const request = {
          status: this.updateOccurrence.status,
          owner: this.$storage.get(this.$storage.keys.auth.user),
        };

        this.occurrence = await OccurrenceService.update(request, this.occurrence.id);

        this.$message.success('Ocorrência atualizada com sucesso!');
      } catch (e) {
        this.$message.error(e.message || 'Falha ao atualizar ocorrência');
      } finally {
        this.loading.updateOccurrence = false;
        this.updateOccurrenceDialogVisible = false;
      }
    },
    async onSearchPossibleReplacements() {
      this.occurrenceExchangeHint.loading = true;

      try {
        const { products: ids } = await ProductSelecionService
          .findReplacements(this.id, this.occurrenceExchangeHint.ammount);

        console.log(ids);

        const products = await Promise.all(ids.map(id => ProductService.getProductById(id)));
        this.occurrenceExchangeHint.results = products.map(product => ({
          id: product.id,
          name: product.name,
          marketPrice: `R$ ${this.fromCents(product.marketPrice)}`,
        }));
      } catch (e) {
        console.error(e);
        this.occurrenceExchangeHint.results = [];
        this.$message.error('Não foi possível encontrar essa quantidade de trocas, tente realizar a busca com menos.');
      } finally {
        this.occurrenceExchangeHint.loading = false;
      }
    },
    onChangeOccurenceType() {
      this.fillReturingProducts();
    },
    onHasProductsReturning() {
      if (this.createOccurrence.productsReturning) {
        this.fillReturingProducts();
      } else {
        this.createOccurrence.incomingProducts = [];
      }
    },
    fillReturingProducts() {
      if (this.createOccurrence.productsReturning && this.createOccurrence.type === 'BOX_WARRANTY') {
        this.createOccurrence.incomingProducts = this.order.productSelection.products;
      }
    },
    undesiredRowClassName({ row }) {
      if (this.undesiredProducts.some(product => product.id === row.id)) {
        return 'undesired-row';
      }

      return '';
    },
    async removeAdditionalProduct(productId) {
      this.loading.orderInfo = true;

      try {
        this.order = await SubscriptionBoxOrderService.removeAdditionalProduct(this.order.id, { productId });

        await this.loadProducts();
      } finally {
        this.loading.orderInfo = false;
      }
    },
    handleActionMenuCommand(command) {
      switch (command) {
        case 'cancel-order':
          this.cancelOrderDialogVisible = true;
          break;
        case 'create-occurrence':
          this.showCreateOccurenceDialog();
          break;
        case 'update-occurrence':
          this.showUpdateOccurrenceDialog();
          break;
        case 'recreate-order':
          this.recreateOrderDialogVisible = true;
          break;
        case 'add-to-production-batch':
          this.addToProductionBatchDialogVisible = true;
          break;
        case 'approve-fraud-review':
          this.showApproveFraudReviewMessageBox();
          break;
        default: break;
      }
    },
    showCreateOccurenceDialog() {
      this.createOccurrence = {};
      this.occurrenceExchangeHint = initialProductExchangeHint();
      this.createOccurrenceDialogVisible = true;
    },
    showUpdateOccurrenceDialog() {
      this.updateOccurrence = { status: this.occurrence.status };
      this.updateOccurrenceDialogVisible = true;
    },
    showAddAdditionalProductDialog() {
      this.addAdditionalProductDialogVisible = true;
    },
    showApproveFraudReviewMessageBox() {
      this.$confirm('Tem certeza que deseja aprovar a análise de fraude?', 'Aprovar análise de fraude', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning',
      }).then(() => {
        this.approveFraudReview();
      }).catch(() => {});
    },
    async approveFraudReview() {
      this.loading.orderInfo = true;

      try {
        this.order = await SubscriptionBoxOrderService.approveFraudReview(this.order.id);
      } catch (e) {
        console.error(e);
        this.$message.error(e.message || 'Falha ao aprovar análise de fraude');
      } finally {
        this.loading.orderInfo = false;
      }
    },
    reloadProductionBatches() {
      this.$refs.productionBatches.reload()
    },
    fromCents(value) {
      return value ? (value / 100).toFixed(2).replace('.', ',') : 0;
    },
    fromSystemDate(value) {
      return value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : null;
    },
    toPercentage(value) {
      return value ? `${value * 100}%` : '-';
    },
    back() {
      this.$router.back();
    },
    copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        window.clipboardData.setData('Text', text);
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.$message.success(this.$t('copied'));
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          this.$message.error(this.$t('copiedFailed'));
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.subscription-box-order-detail {
  font-size: 20px;

  .el-table .undesired-row {
    background: #f7a2393b;
  }

  .profile__question {
    margin-top: 30px;
    margin-bottom: 25px;
    text-align: center;

    .title {
      font-size: 24px;
      text-align: left;
      width: 410px;
      margin: auto;
      margin-bottom: 10px;
    }

    .option {
      display: block;
      text-align: left;
      width: 300px;
      margin: auto;

      .option__label {
        font-size: 18px;
      }
    }
  }

  .text-left-aligned {
    text-align: left;
  }
}
</style>
