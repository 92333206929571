<template lang="pug">
img.media-gallery-image(v-if="url", :src="url", draggable="false", :style="previewStyle")
</template>
<script setup>
import MediaService from '@/services/media/MediaService';
import { ref, computed, watch } from 'vue';

const STORAGE_PREFIX = process.env.VUE_APP_MEDIA_STORAGE_PREFIX;

const props = defineProps({
  mediaId: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    default: () => 200
  },
  height: {
    type: Number,
    default: () => 200
  },
  fit: {
    type: String,
    default: () => 'contain',
    validator: (value) => ['cover', 'contain', 'fill', 'none', 'scale-down'].indexOf(value) !== -1
  }
})

const media = ref()

const previewStyle = computed(() => {
  return {
    width: props.width ? props.width + 'px' : null,
    height: props.height ? props.height + 'px' : null,
    objectFit: props.fit
  }
})

const url = computed(() => {
  if (!media.value) return null

  const fileName = media.value.fileName
  const imageSize = props.width
  const aspectRatio = props.width / props.height

  const fitIn = props.fit == 'contain' ? 'fit-in/' : ''
  const fill = props.fit == 'contain' ? 'filters:fill(fff)/' : ''
  const w = imageSize
  const h = Math.round(imageSize / aspectRatio)

  return `https://images.boxmagenta.com.br/${fitIn}${w}x${h}/filters:quality(75)/filters:background_color(fff)/${fill}filters:format(jpeg)/${STORAGE_PREFIX + fileName}`
})

watch(() => props.mediaId, async (mediaId) => {
  media.value = await MediaService.find(mediaId)
}, { immediate: true })
</script>
<style lang="scss" scoped>
img.media-gallery-image {
  pointer-events: none;
  user-select: none;
}
</style>