<template>
  <div class="customer-list">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">{{ $t('homeTitle') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t('customer.list') }}</el-breadcrumb-item>
    </el-breadcrumb>

    <snowflake-search-list context="customer" :service="customerService">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" :label="$t('customer.id')" width="80" sortable></el-table-column>

        <!-- Name -->
        <el-table-column prop="name" :label="$t('customer.name')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ `${scope.row.firstName} ${scope.row.lastName}` }}</span>
          </template>
        </el-table-column>

        <!-- E-mail -->
        <el-table-column prop="email" :label="$t('customer.email')" :show-overflow-tooltip="true">
        </el-table-column>

        <!-- CPF -->
        <el-table-column prop="cpf" :label="$t('customer.cpf')"></el-table-column>

      </template>
    </snowflake-search-list>

  </div>
</template>

<script>
import CustomerService from '@/services/CustomerService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'customer-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      customerService: CustomerService,
    };
  },
};
</script>
