import { render, staticRenderFns } from "./CustomerRewards.vue?vue&type=template&id=23e13a2b&scoped=true&lang=pug&"
import script from "./CustomerRewards.vue?vue&type=script&setup=true&lang=js&"
export * from "./CustomerRewards.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CustomerRewards.vue?vue&type=style&index=0&id=23e13a2b&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e13a2b",
  null
  
)

export default component.exports