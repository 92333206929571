import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/stock/product-arrival-report', request)
      .then(result => result.response);
  },
  finalize(id) {
    const request = {
      status: 'DONE',
    };
    return apiPut(`/magenta/stock/product-arrival-report/${id}`, request)
      .then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/stock/product-arrival-report/search', query)
      .then(result => result.response);
  },
  getAllNew() {
    return apiGet('/magenta/stock/product-arrival-report?status=NEW')
      .then(result => result.response);
  },
};
