export default {
  homeTitle: 'Home',

  // Actions
  actions: 'Actions',
  save: 'Save',
  cancel: 'Cancel',
  logout: 'Logout',
  back: 'Back',

  copied: 'Copied!',
  copiedFailed: 'Copy failed',

  // Utils
  businessDays: 'business days',
  days: 'days',

  subscriptionStatus: {
    WAITING: 'Waiting for payment',
    ACTIVE: 'Active',
    PENDING: 'Pending payment',
    UNPAID: 'Unpaid',
    SUSPENDED: 'Suspended',
    CANCELED: 'Canceled',
    MIGRATED: 'Migrated',
  },

  orderStatus: {
    CANCELED: 'Canceled',
    NEW: 'New',
    PRODUCT_SELECTION_FAILED: 'Product selection failed',
    PRODUCTS_SELECTED: 'Products selected',
    IN_PRODUCTION: 'In production',
    READY_TO_POST: 'Ready to post',
    IN_TRANSIT: 'In transit',
    AWAITING_CUSTOMER_PICKUP: 'Awaiting customer pickup',
    DELIVERED: 'Delivered',
    RETURNED: 'Returned',
    MISPLACED: 'Misplaced',
  },

  invoiceStatus: {
    WAITING: 'Waiting',
    PENDING: 'Pending',
    PAID: 'Paid',
    FORGIVEN: 'Forgiven',
    UNPAID: 'Unpaid',
    DISPUTED: 'Disputed',
    REFUNDED: 'Refunded',
    CLOSED: 'Closed',
  },

  // Search Bar
  searchBar: {
    // Fields
    search: 'Search',
    new: 'New',

    // Messages
    message: {
      deleteConfirmation: 'Are you sure you want to delete {context} \'{itemName}\'?',
      deleteSuccess: '{context} successfully deleted!',
      deleteFail: 'Failed to delete {context}: {description}',
      loadingFailed: 'Failed to load {context}: {description}',
    },
  },

  // Tag Select
  tag: {
    add: 'Add tag',
    alreadyAdded: 'Tag {tag} already added.',
    loadFailed: 'Failed to load tags: {description}',
    noTag: 'No tag defined',
  },

  // Search Select
  searchSelect: {
    loadFailed: 'Failed to load list: {description}',
    findFailed: 'Failed to find item {id}: {description}',
  },

  // App errors
  error: {
    default: 'Something broke. Go tell Lucas or Diego about this so they can fix it :D',
    expiredSession: 'Your session has expired.',
    login: 'Hum, you need to login first.',
    unauthorized: 'You are not authorized to access this page!',
  },

  // Login
  login: {
    // Fields
    user: 'User',
    password: 'Password',

    // Errors
    error: {
      invalidInformation: 'User and/or password don\'t seem right.',
    },
  },

  // Menu
  menu: {
    customers: 'Customers',
    editions: 'Editions',
    productionBatches: 'Production Batches',
    productBrands: 'Brands',
    products: 'Products',
    stock: 'Stock',
    stockSummary: 'Stock Summary',
    coupon: 'Coupons',
    influencer: 'Influencers',
  },

  // Customer
  customer: {
    // Titles
    list: 'Customers',
    new: 'Add Customer',
    details: 'Customer Details',

    // Tabs
    basicInformation: 'Basic information',
    shipping: 'Shipping',
    profile: 'Profile',
    subscriptions: 'Subscriptions',
    orders: 'Orders',

    // Basic information fields
    id: 'ID',
    userId: 'User ID',
    name: 'Name',
    email: 'E-mail',
    cpf: 'CPF',
    active: 'Active',
    firstName: 'First Name',
    lastName: 'Last Name',
    birthdate: 'Birthdate',

    // Shipping fields
    postalCode: 'CEP',
    street: 'Street',
    number: 'Number',
    complement: 'Complement',
    district: 'District',
    city: 'City',
    state: 'State',

    // Subscription fields
    subscriptionId: 'ID',
    subscriptionPlan: 'Plan',
    subscriptionCreatedAt: 'Subscription date',
    subscriptionStatus: 'Status',

    // Order fields
    orderId: 'ID',
    orderEdition: 'Edition',
    orderStatus: 'Status',
    orderNf: 'NF',
    orderTracking: 'Tracking',

    // Messages
    message: {
      successfulSave: 'Successfully saved!',
      unsuccessfulSave: 'Failed to save :(',
    },

    // Customer errors
    error: {
      noProfile: 'This customer has no profile saved.',
      invalidBasicInformation: 'Invalid basic information.',
      invalidShipping: 'Invalid shipping.',

      // Form Validation
      firstNameRequired: 'First Name is required',
      lastNameRequired: 'Last Name is required',
      cpfRequired: 'CPF is required',
      emailRequired: 'E-mail is required',
      birthdateRequired: 'Birthdate is required',
      invalidBirthdate: 'Invalid date, use day/month/year (01/12/2000)',
      postalCodeRequired: 'Postal Code is required',
      streetRequired: 'Street is required',
      numberRequired: 'Number is required',
      districtRequired: 'District is required',
      cityRequired: 'City is required',
      stateRequired: 'State is required',
    },
  },

  subscription: {
    details: 'Subscription Information',

    // Actions
    actions: {
      addDiscount: 'Add Discount',
      changeBillingDate: 'Change Billing Date',
      cancel: 'Cancel Subscription',

      addDiscountValidation: {
        noType: 'Discount type required',
        noValue: 'Discount value required',
        percentageMax100: 'Percentage must no exceed 100%',
      },

      cancelValidation: {
        noCancelReason: 'Cancel reason required',
        noCancelComment: 'Cancellation commentary required',
      },
    },

    // Tabs
    tabs: {
      basicInformation: 'Basic Information',
      paymentProfile: 'Payment Profile',
      discounts: 'Discounts',
      invoices: 'Invoices',
    },

    // Basic Information Fields
    id: 'ID',
    customerId: 'Customer ID',
    planId: 'Plan',
    status: 'Status',
    shippingCost: 'Shipping Cost',
    startAt: 'Starts At',
    canceledAt: 'Canceled At',
    cancelReason: 'Cancel Reason',
    cancelReasonComment: 'Cancellation Commentary',

    // Payment Profile
    paymentProfile: {
      id: 'ID',
      creditCardBrand: 'Credit Card Company',
      creditCardNumber: 'Credit Card Number',
      creditCardHolder: 'Cardholder Name',
      creditCardExpiration: 'Expiration Date',
    },

    discount: {
      id: 'ID',
      coupon: 'Coupon',
      createdAt: 'Created At',
      amount: 'Amount (R$)',
      percentage: 'Percentage',
      cycles: 'Cycles',
      type: 'Discount Type',
      value: 'Discount Value',
    },

    invoice: {
      id: 'ID',
      status: 'Status',
      total: 'Total Amount',
      createdAt: 'Created At',
      paidAt: 'Paid At',
    },

    message: {
      successfulAddDiscount: 'Successfully added discount',
      unsuccessfulAddDiscount: 'Failed to add discount',
      successfulCancel: 'Successfully canceled subscription',
      unsuccessfulCancel: 'Failed to cancel subscription',
    },
  },

  // Editions
  edition: {
    // Titles
    list: 'Editions',
    new: 'New Edition',
    details: 'Edition Details',

    // Fields
    edition: 'Edition',
    basePrice: 'Base Price (R$)',
    requiredProducts: 'Required Products',

    // Messages
    message: {
      successfulSave: 'Successfully saved!',
    },
  },

  // Brands
  brand: {
    // Titles
    list: 'Brands',
    new: 'New Brand',
    details: 'Brand Details',

    // Fields
    id: 'ID',
    name: 'Name',

    // Messages
    message: {
      successfulSave: 'Successfully saved!',
    },
  },

  // Products
  product: {
    // Titles
    list: 'Products',
    new: 'New Product',
    details: 'Product Details',

    // Fields
    id: 'ID',
    barcode: 'Barcode',
    name: 'Name',
    baseScore: 'Base Score (R$)',
    cost: 'Cost (R$)',
    marketPrice: 'Market Price (R$)',
    perceivedValue: 'Perceived Value (R$)',
    brand: 'Brand',
    brandPlaceholder: 'Insert the product brand',

    // Messages
    message: {
      successfulSave: 'Successfully saved!',
    },
  },

  // Stock
  stock: {
    // Titles
    list: 'Stock',
    new: 'Add Stock Entry',
    details: 'Stock Entry Details',
  },

  // Order
  order: {
    // Titles
    details: 'Order details',

    // Tabs
    basicInformation: 'Basic Information',
    shipping: 'Shipping',
    profile: 'Profile',
    productSelection: 'Product Selection',
    invoice: 'Invoice',

    // Basic information fields
    id: 'ID',
    edition: 'Edition',
    status: 'Status',
    nf: 'NF',
    inProductionAt: 'In Production At',
    readyToPostAt: 'Ready to Post At',
    inTransitAt: 'In Transit At',
    deliveredAt: 'Delivered At',
    canceledAt: 'Canceled At',

    // Shipping fields
    trackingCode: 'Tracking Code',
    shippingCompany: 'Shipping Company',
    trackingUrl: 'Tracking URL',
    estimatedDeliveryTime: 'Est. Delivery Time',
    postalCode: 'CEP',
    street: 'Street',
    number: 'Number',
    complement: 'Complement',
    district: 'District',
    city: 'City',
    state: 'State',

    // Selected Products
    cost: 'Cost (R$)',
    marketPrice: 'Market Price (R$)',
    perceivedValue: 'Perceived Value (R$)',
    desiredProducts: 'Desired Products',
    desiredProductId: 'ID',
    desiredProductName: 'Name',
    desiredProductCost: 'Cost',
    desiredProductMarketPrice: 'Market Price',
    undesiredProducts: 'Undesired Products',
    undesiredProductId: 'ID',
    undesiredProductName: 'Name',
    undesiredProductCost: 'Cost',
    undesiredProductMarketPrice: 'Market Price',
    selectedProducts: 'Selected Products',
    selectedProductId: 'ID',
    selectedProductName: 'Name',
    selectedProductCost: 'Cost',
    selectedProductMarketPrice: 'Market Price',
    additionalProducts: 'Additional Products',
    additionalProductId: 'ID',
    additionalProductName: 'Name',

    // Invoice
    invoiceSection: {
      id: 'ID',
      status: 'Status',
      amount: 'Amount (R$)',
      total: 'Total (R$)',
      paidAmount: 'Paid Amount (R$)',
      dueAmount: 'Due Amount (R$)',
      chargeAt: 'Charge At',
      paidAt: 'Paid at',
      items: 'Items',
      sku: 'SKU',
      itemAmount: 'Amount (R$)',
      discounts: 'Discounts',
      coupom: 'Coupom',
      discountAmount: 'Amount (R$)',
      discountPercentage: 'Percentage',
    },

    emptyMessages: {
      noProfile: 'No product was selected yet, therefore no profile was defined for this order',
      noProducts: 'No product selected yet',
      noAdditionalProducts: 'No additional products',
      noInvoice: 'No invoice for this order yet',
    },

  },

  // Coupon
  coupon: {
    // Titles
    list: 'Coupon',
    new: 'New Coupon',
    details: 'Coupon Details',

    // Fields
    id: 'ID',
    amount: 'Amount (R$)',
    percentage: 'Percentage',
    active: 'Active',

    activeTrue: 'Yes',
    activeFalse: 'No',

    // Errors
    error: {
      idRequired: 'ID is required',
      amountPercentageRequired: 'Either the amount or the percetage is required.',
    },

    // Messages
    message: {
      successfulSave: 'Successfully saved!',
      failedToSave: 'Failed to save!',
    },
  },

  // Stock Entry
  stockEntry: {
    // Titles
    list: 'Stock Entries',
    new: 'New Stock Entry',
    details: 'Stock Entry Detail',

    // Fields
    id: 'ID',
    productName: 'Product',
    quantity: 'Quantity',
    type: 'Type',
    lastUpdated: 'Last Updated',

    // Errors
    error: {
      productRequired: 'Product is required',
      typeRequired: 'Type is required',
      quantityRequired: 'Quantity is required',
    },

    // Messages
    message: {
      successfulSave: 'Successfully saved!',
      failedToSave: 'Failed to save!',
      stockViolation: 'Unable to save due to stock violation.',
    },
  },

  // Stock Summary
  stockSummary: {
    // Titles
    list: 'Stock Summary',

    // Fields
    productId: 'Product ID',
    productName: 'Product',
    quantity: 'Quantity',
    lastUpdated: 'Last Updated',
  },

  // Influencer
  influencer: {
    list: 'Influencers',
    new: 'New Influencer',
    details: 'Influencer Detail',

    // Fields
    id: 'ID',
    promotedSku: 'Promoted SKU',
    name: 'Name',
    email: 'E-mail',
    cpf: 'CPF',
    customer: 'Customer',
    coupons: 'Coupons',
    coupon: 'Coupon',
    active: 'Active',
    affiliate: 'Affiliate',

    // Errors
    error: {
      customerRequired: 'Customer is required',
      promotedSkuRequired: 'Promoted SKU is required',
    },

    // Messages
    message: {
      successfulSave: 'Successfully saved!',
      failedToSave: 'Failed to save :(',
      noShippingAddress: 'Customer has no Shipping Address.',
    },
  },

  // Charges
  charge: {
    // Titles
    list: 'Charges',

    // Fields
    id: 'ID',
    customerName: 'Customer',
    amount: 'Amount (R$)',
    successful: 'Successful',
    invoicePaidAt: 'Paid At',

    successfulTrue: 'Yes',
    successfulFalse: 'No',
  },
};
