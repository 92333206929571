<template lang="pug">
.update-cart-item

  el-form(ref="form", :model="cartItem", :rules="rules", inline)

    el-form-item(prop="quantity", label="Quantidade", size="small")
      el-input-number(v-model="state.quantity", :min="1", :max="99")

    el-form-item(style="text-align: right", size="small")
      el-popconfirm(
        title="Tem certeza que deseja atualizar a quantidade do item?",
        @confirm="updateCartItem"
      )
        el-button(type="primary", slot="reference", :loading="updating")
          span Atualizar

</template>
<script setup>
import { reactive, ref } from 'vue'
import CartService from '@/services/ecommerce/cart/CartService'
import { useMessage } from '@/utils/Message';

const message = useMessage()

const props = defineProps({
  cartItem: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['itemUpdated'])

const form = ref()
const rules = ref({
  quantity: [
    { required: true, message: 'Informe a quantidade', trigger: 'change' }
  ]
})

const state = reactive({
  quantity: props.cartItem.quantity
})

const updating = ref(false)

const updateCartItem = async () => {
  try {
    form.value.validate()
  } catch (e) {
    return
  }

  try {
    updating.value = true

    const request = {
      quantity: state.quantity
    }

    const cartItem = await CartService.updateItem(props.cartItem.cartId, props.cartItem.id, request)

    message.success('Item atualizado com sucesso')

    emit('itemUpdated', cartItem)
  } catch (e) {
    message.error('Erro ao atualizar item')

    console.error(e)
  } finally {
    updating.value = false
  }
}

const reset = () => {
  form.value.resetFields()
}

defineExpose({
  reset
})
</script>