import EcommerceProductBrandList from "./product-brand/EcommerceProductBrandList.vue";
import EcommerceProductBrandDetail from "./product-brand/EcommerceProductBrandDetail.vue";

import EcommerceProductCategoryList from "./product-category/EcommerceProductCategoryList.vue";
import EcommerceProductCategoryDetail from "./product-category/EcommerceProductCategoryDetail.vue";

import EcommerceProductLineList from "./product-line/EcommerceProductLineList.vue";
import EcommerceProductLineDetail from "./product-line/EcommerceProductLineDetail.vue";

import EcommerceProductList from "./product/EcommerceProductList.vue";
import EcommerceProductDetail from "./product/EcommerceProductDetail.vue";

export default [
  // Product Brand
  {
    path: '/ecommerce/product/product-brands',
    name: 'ecommerce-product-brand-list',
    component: EcommerceProductBrandList,
  },
  {
    path: '/ecommerce/product/product-brands/new',
    name: 'ecommerce-product-brand-new',
    component: EcommerceProductBrandDetail,
  },
  {
    path: '/ecommerce/product/product-brands/:id',
    name: 'ecommerce-product-brand-detail',
    component: EcommerceProductBrandDetail,
  },
  // Product Category
  {
    path: '/ecommerce/product/product-categories',
    name: 'ecommerce-product-category-list',
    component: EcommerceProductCategoryList,
  },
  {
    path: '/ecommerce/product/product-categories/new',
    name: 'ecommerce-product-category-new',
    component: EcommerceProductCategoryDetail,
  },
  {
    path: '/ecommerce/product/product-categories/:id',
    name: 'ecommerce-product-category-detail',
    component: EcommerceProductCategoryDetail,
  },
  // Product Line
  {
    path: '/ecommerce/product/product-lines',
    name: 'ecommerce-product-line-list',
    component: EcommerceProductLineList,
  },
  {
    path: '/ecommerce/product/product-lines/new',
    name: 'ecommerce-product-line-new',
    component: EcommerceProductLineDetail,
  },
  {
    path: '/ecommerce/product/product-lines/:id',
    name: 'ecommerce-product-line-detail',
    component: EcommerceProductLineDetail,
  },
  // Product
  {
    path: '/ecommerce/product/products',
    name: 'ecommerce-product-list',
    component: EcommerceProductList,
  },
  {
    path: '/ecommerce/product/products/new',
    name: 'ecommerce-product-new',
    component: EcommerceProductDetail,
  },
  {
    path: '/ecommerce/product/products/:id',
    name: 'ecommerce-product-detail',
    component: EcommerceProductDetail,
  },
];