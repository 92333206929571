class ServiceError extends Error {
  constructor(status, message, error = null) {
    super();
    this.status = status;
    this.message = message;
    this.error = error;
  }
}

export default ServiceError;
