import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/stock/product-brand', request).then(result => result.response);
  },
  update(id, request) {
    return apiPut(`/magenta/stock/product-brand/${id}`, request).then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/stock/product-brand', query).then(result => result.response);
  },
  getProductBrandById(id) {
    return apiGet(`/magenta/stock/product-brand/${id}`).then(result => result.response);
  },
};
