<template lang="pug">
.product-variant-media
  h2 Imagens

  p Adicione e ordene as imagens deste produto. A primeira imagem será usada como capa:

  MediaGalleryInput.height-limited(
    filePrefix="product-variant/thumbnail/",
    :value="value.media",
    @input="setMedia",
    :width="200",
    :height="250",
    fit="cover",
  )
</template>
<script setup>
import { defineProps, defineEmits } from 'vue'

import MediaGalleryInput from '@/components/input/media/gallery/MediaGalleryInput.vue'

const props = defineProps({
  value: {
    type: Object,
    required: true,
  }
})

const emit = defineEmits(['input'])

const setMedia = (media) => {
  emit('input', { ...props.value, media })
}
</script>