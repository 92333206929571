import { apiPost, apiPut } from './_common';

export default {
  create(request) {
    return apiPost('/magenta/internal-note', request).then(result => result.response);
  },
  update(internalNoteId, request) {
    return apiPut(`/magenta/internal-note/${internalNoteId}`, request)
      .then(result => result.response);
  },
};
