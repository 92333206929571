import { apiGet, apiPatch, apiPost } from '../_common';

export default {
  searchRedeems(query) {
    return apiGet('/magenta/promotions/subscription-gift/coupon/redeem', query)
      .then(result => result.response);
  },
  statusDescription: {
    "REDEEMED": 'Resgatado',
    "RESERVED": "Reservado",
    "CANCELED": "Cancelado"
  }
};
