<template>
  <div>

    <!-- Search Bar -->
    <el-row v-if="showSearchBar" :gutter="8">
      <el-col :span="4">
        <el-select v-model="filter.values"
        v-if="filterable"
        :placeholder="filterMessage"
        multiple
        @input="onInput">
          <el-option
            v-for="(item, index) in filterData"
            :key="index"
            :label="filterLabel(item)"
            :value="filterValue(item)">
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="16 + (action ? 0 : 4) + (filterable ? 0 : 4)">
        <el-input
          :placeholder="$t('searchBar.search')"
          prefix-icon="el-icon-search"
          v-model="search"
          @input="onInput">
        </el-input>
      </el-col>

      <el-col v-if="action" :span="4">

        <slot name="action">
          <router-link :to="defaultActionLinkTo()">

          <el-button
            type="primary"
            :icon="actionIcon"
            class="stretched">{{actionTitle || $t('searchBar.new')}}</el-button>

          </router-link>
        </slot>

      </el-col>
    </el-row>

    <br>

    <el-row v-loading="loading">
      <el-col :span="24">
        <el-table border
          size="small"
          :highlight-current-row="highlightCurrentRow"
          @current-change="handleRowSelection"
          :data="tableData"
          :default-sort="defaultSort"
          :row-class-name="tableRowClassName"
          :row-key="rowKey"
          :expand-row-keys="expandRowKeys"
          v-on:row-click="goToDetails"
          v-on:sort-change="onSortChange"
          :height="tableHeight"
        >

          <template slot="empty">
            <slot name="emptyText"></slot>
          </template>

          <slot name="tableRows"></slot>

          <!-- Operations -->
          <el-table-column v-if="operations" :label="operationsText" width="140" align="center">

            <template slot-scope="scope">

              <slot :scope="scope"></slot>

              <router-link v-if="details" :to="defaultDetailLinkTo(scope)">

                <el-button
                  size="small"
                  type="primary"
                  plain
                  :icon="detailsIcon"></el-button>

              </router-link>

              <!-- Remove was removed (heh)
                while we do not define its logic (it is only active on publications list) -->

              <el-button v-if="remove && context === 'publication'"
                size="small"
                type="danger"
                plain
                :icon="removeIcon"
                v-on:click="triggerDelete($event, scope.row)"></el-button>
            </template>
          </el-table-column>

        </el-table>
      </el-col>
    </el-row>

    <slot name="summary"></slot>

    <br>

    <!-- Pagination -->
    <el-row v-if="paginate" justify="center">
      <el-col :span="24" style="text-align: center">
        <el-pagination
          class="centralized"
          layout="prev, pager, next"
          v-on:current-change="onCurrentPageChange"
          :total="totalCount"
          :current-page="currentPaginationPage"
          :page-size="pageSize">
        </el-pagination>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import Fuse from 'fuse.js';
import lodash from 'lodash';

export default {
  name: 'snowflake-search-list',
  props: {
    showSearchBar: {
      type: Boolean,
      default: true
    },
    context: {
      type: String,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
    setDataFunction: {
      type: Function,
    },
    setDataKeys: {
      type: Array,
    },
    defaultSort: {
      type: Object,
    },
    defaultSortColumn: {
      type: String,
      default: null,
    },
    defaultSortDirection: {
      type: String,
      default: null,
    },
    searchOptions: {},
    filterable: {
      type: Boolean,
      default: false,
    },
    filterData: {
      type: Array,
      required: false,
    },
    filterValue: {
      type: Function,
      required: false,
    },
    filterLabel: {
      type: Function,
      required: false,
    },
    filterField: {
      type: String,
      required: false,
    },
    filterMessage: {
      type: String,
      required: false,
    },
    keepStateOnUrl: {
      type: Boolean,
      required: false,
      default: true,
    },
    preSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    // TABLE PROPS
    tableRowClassName: {
      type: Function,
      default: () => 'clickable',
    },
    rowKey: {
      type: String,
      required: false,
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    },
    enableAutoRefresh: {
      type: Boolean,
      default: false,
    },
    defaultAutoRefresh: {
      type: Number,
      default: 5000,
    },
    handleRowSelection: {
      type: Function,
      required: false,
      default: () => {},
    },
    tableHeight: {
      type: String
    },
    // ACTION PROPS
    action: {
      type: Boolean,
      default: true,
    },
    actionTitle: {
      type: String,
    },
    actionIcon: {
      type: String,
      default: 'el-icon-plus',
    },
    actionLinkTo: {
      type: Function,
      default: null,
    },
    // OPERATIONS PROPS
    operations: {
      type: Boolean,
      default: true,
    },
    operationsText: {
      type: String,
      default: '',
    },
    removeIcon: {
      type: String,
      default: 'el-icon-delete',
    },
    detailsIcon: {
      type: String,
      default: 'el-icon-edit',
    },
    // DETAILS PROPS
    details: {
      type: Boolean,
      default: true,
    },
    detailLinkTo: {
      type: Function,
      default: null,
    },
    detailParamToRoute: {
      type: String,
      default: 'id',
    },
    // REMOVE PROPS
    remove: {
      type: Boolean,
      default: true,
    },
    removeItemName: {
      type: String,
      default: 'name',
    },
    deleteFunction: {
      type: Function,
      default: null,
    },
    // PAGINATION PROPS
    pagination: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      initialLoad: true,
      loading: false,
      data: null,
      filter: {
        field: null,
        values: [],
      },
      tableData: [],
      expandRowKeys: this.rowKey ? [] : null,
      currentPage: 1,
      totalCount: 0,
      search: '',
      isFilterable: false,
      sortColumn: this.defaultSortColumn,
      sortDirection: this.defaultSortDirection,
      paginate: this.pagination,
      fuseOptions: {
        threshold: 0.3,
        location: 0,
        distance: 50,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: this.setDataKeys,
      },
      autoRefresh: null,
    };
  },
  computed: {
    currentPaginationPage() {
      if (this.initialLoad && this.loading) {
        return null
      } else {
        return this.currentPage
      }
    }
  },
  watch: {
    searchOptions: function(val) {
      this.getData()
    }
  },
  methods: {
    reload() {
      this.getData()
    },
    onInput: lodash.debounce(function() {
      this.updateUrlQuery();

      this.currentPage = 1;

      if (this.setDataFunction) {
        this.loading = true;
        if (this.search.length > 0) {
          this.tableData = this.getData.search(this.search);
          this.totalCount = this.tableData.length;
        } else {
          this.tableData = this.data;
        }
        this.loading = false;
      } else {
        this.getData();
      }
    }, 500),
    onSortChange(event) {
      if (event.prop === null || event.order === null) {
        this.sortColumn = null;
        this.sortDirection = null;
      } else {
        this.sortColumn = event.prop;

        if (event.order === 'ascending') {
          this.sortDirection = 'ASC';
        } else {
          this.sortDirection = 'DESC';
        }
      }

      if (!this.setDataFunction) {
        this.getData();
      }

      this.updateUrlQuery();
    },
    triggerDelete(evt, item) {
      evt.stopPropagation();
      if (this.deleteFunction) {
        this.deleteFunction(item).then(() => {
          if (!this.setDataFunction) this.getData();
        });
      } else {
        this.onRemove(item);
      }
    },
    onRemove(item) {
      this.removing = true;

      const confirmationText = this.$t('searchBar.message.deleteConfirmation', {
        context: this.capitalizedContext(),
        itemName: item[this.removeItemName],
      });
      this.$confirm(confirmationText)
        .then(() => {
          this.deleteContent(item.id);
        })
        .catch(() => {});
    },
    onCurrentPageChange(page) {
      this.currentPage = page;

      this.getData();

      this.updateUrlQuery();
    },
    loadData() {
      this.loading = true;

      this.setDataFunction().then((result) => {
        this.initialLoad = false;
        this.loading = false;
        this.data = result[`${lodash.camelCase(this.context)}s`]
          .map(it => ({ loading: false, ...it }));
        this.tableData = this.data;
        this.getData = Fuse(this.data, this.fuseOptions);
      })
        .catch((error) => {
          this.loading = false;

          const errorMessage = this.$t('searchBar.message.loadingFailed', {
            context: this.context,
            description: error.description,
          });
          this.$message.error(errorMessage);
        });
    },
    searchContent() {
      this.loading = true;

      let options = {
        query: this.search.length > 0 ? this.search : null,
        orderBy: this.sortColumn,
        sortDirection: this.sortDirection,
        page: this.currentPage - 1,
        pageSize: this.pageSize,
      };

      lodash.assign(options, this.searchOptions);

      if (this.isFilterable && this.filter.values.length) {
        if (!options.filters) {
          lodash.assign(options, { filters: [this.filter] });
        } else {
          options.filters = options.filters.filter(filter => filter.field !== this.filter.field);
          options.filters.push(this.filter);
        }
      }

      options = lodash.pickBy(options, lodash.identity);

      // e.q $contextService
      return this.service.search(options)
        .then((response) => {
          this.tableData = response.results;
          this.totalCount = response.totalCount;

          if (this.expandRowKeys) {
            this.expandRowKeys = [...this.expandRowKeys];
          }

          this.loading = false;

          return Promise.resolve(response);
        })
        .catch((error) => {
          const errorMessage = this.$t('searchBar.message.loadingFailed', {
            context: this.capitalizedContext(),
            description: error.description,
          });
          this.$message.error(errorMessage);

          this.loading = false;

          return Promise.reject(error);
        });
    },
    deleteContent(id) {
      this.loading = true;

      this.service.delete(id)
        .then(() => {
          this.loading = false;

          this.$message(this.$t('searchBar.message.deleteSuccess', { context: this.capitalizedContext() }));

          if (this.setDataFunction) {
            this.loadData();
          } else {
            this.getData();
          }
        })
        .catch((error) => {
          this.loading = false;

          const errorMessage = this.$t('searchBar.message.deleteFail', {
            context: this.capitalizedContext(),
            description: error.description,
          });
          this.$message.error(errorMessage);
        });
    },
    defaultActionLinkTo() {
      return this.actionLinkTo ? this.actionLinkTo() : { name: `${this.context}-new` };
    },
    goToDetails(row) {
      if (!this.details) {
        return;
      }
      const param = {};
      param[this.detailParamToRoute] = row[this.detailParamToRoute];

      const dest = this.detailLinkTo
        ? this.detailLinkTo()
        : { name: `${this.context}-detail`, params: param };
      this.$router.push(dest);
    },
    defaultDetailLinkTo(scope) {
      return this.detailLinkTo
        ? this.detailLinkTo(scope.row)
        : { name: `${this.context}-detail`, params: this.getParams(scope) };
    },
    getParams(scope) {
      const params = {};
      params[this.detailParamToRoute] = scope.row[this.detailParamToRoute];
      return params;
    },
    capitalizedContext() {
      return lodash.capitalize(this.context.replace('-', ' '));
    },
    autoRefreshFunction() {
      return setInterval(() => {
        this.getData();
      }, this.defaultAutoRefresh);
    },
    removeAutoRefreshFunction() {
      if (this.autoRefresh) {
        clearInterval(this.autoRefresh);
      }
    },
    validateFilterable() {
      if (this.filterable) {
        this.isFilterable = (!!this.filterData && !!this.filterValue
          && !!this.filterLabel && !!this.filterField && !!this.filterMessage);
      }
    },
    updateUrlQuery() {
      if (this.keepStateOnUrl) {
        this.$router.replace(
          {
            query: {
              search: this.search,
              page: this.currentPage,
              'sort-column': this.sortColumn,
              'sort-direction': this.sortDirection,
            },
          },
        );
      }
    },
  },
  mounted() {
    this.loading = true;
    this.validateFilterable();

    this.search = this.$route.query.search || this.search;
    this.currentPage = parseInt(this.$route.query.page || '1', 10);
    this.sortColumn = this.$route.query['sort-column'] || this.sortColumn;
    this.sortDirection = this.$route.query['sort-direction'] || this.sortDirection;

    // Do not kink shame me!
    setTimeout(() => {
      this.currentPage = parseInt(this.$route.query.page || '1', 10);
    }, 300);

    if (this.isFilterable) {
      this.filter.field = this.filterField;
    }

    if (this.setDataFunction) {
      this.paginate = false;

      this.loadData();
    } else {
      this.getData = lodash.debounce(
        this.searchContent, 200,{ leading: true }
      );

      if (this.preSearch || (this.keepStateOnUrl && this.search)) {
        this.searchContent();
      } else {
        this.loading = false;
      }

      if (this.enableAutoRefresh) {
        this.autoRefresh = this.autoRefreshFunction();
      }
    }
  },
  beforeDestroy() {
    this.removeAutoRefreshFunction();
  },
};
</script>
