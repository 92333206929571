import { apiPost } from '../_common';

export default {
  generateBillOfMaterials(title, orderIds) {
    const request = {
      title,
      orderIds
    };

    return apiPost('/magenta/fulfillment/bill-of-materials', request)
      .then(result => result.response);
  },
};
