import { render, staticRenderFns } from "./SubscriptionBoxOrderList.vue?vue&type=template&id=c499b58a&scoped=true&lang=pug&"
import script from "./SubscriptionBoxOrderList.vue?vue&type=script&setup=true&lang=js&"
export * from "./SubscriptionBoxOrderList.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./SubscriptionBoxOrderList.vue?vue&type=style&index=0&id=c499b58a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c499b58a",
  null
  
)

export default component.exports