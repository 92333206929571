import OrderProductionBatchDetail from './order-production-batch/OrderProductionBatchDetail.vue'
import OrderProductionBatchList from './order-production-batch/OrderProductionBatchList.vue'

export default [
  // Order Production Batch
  {
    path: '/ecommerce/order/production-batch',
    name: 'ecommerce-order-production-batch-list',
    component: OrderProductionBatchList,
  },
  {
    path: '/ecommerce/order/production-batch/new',
    name: 'ecommerce-order-production-batch-new',
    component: OrderProductionBatchDetail,
  },
  {
    path: '/ecommerce/order/production-batch/:id',
    name: 'ecommerce-order-production-batch-detail',
    component: OrderProductionBatchDetail,
  }
]