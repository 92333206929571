export const capitalize = string => string.toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase());

export const sanitize = string => string.replace(/([^a-zA-Z0-9_\-.\s]+)/gi, '');

export const textToSlug = (text) => {
  return text.normalize("NFD")
    .toLowerCase()
    .replace(/&/g, "e")
    .replace(/[^a-z0-9_-\s]/g, "")
    .trim()
    .replace(/\s/g, "-")
    .replace(/(-+)/g, "-");
}