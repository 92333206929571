import { apiPost } from '../_common';

export default {
  generatePackingList(orderIds) {
    const request = {
      orderIds,
    };

    return apiPost('/magenta/fulfillment/packing-list', request)
      .then(result => result.response);
  },
};
