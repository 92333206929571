const keys = {
  auth: {
    role: 'auth.role',
    roles: 'auth.roles',
    token: 'auth.token',
    user: 'auth.user',
  },
  locale: 'locale',
};

function fallbackStorage() {
  this.storage = {};

  return {
    setItem: (key, value) => {
      this.storage[key] = value;
    },
    getItem: key => this.storage[key],
    removeItem: (key) => {
      this.storage[key] = null;
    },
  };
}

const storage = window.localStorage || fallbackStorage();

const add = (key, value, serializer) => {
  storage.setItem(key, serializer && value ? serializer(value) : value);
};

const remove = key => storage.removeItem(key);

const get = (key, deserializer) => {
  const value = storage.getItem(key);
  return deserializer && value ? deserializer(value) : value;
};

const flatten = (obj, acc = []) => {
  Object.values(obj).forEach((item) => {
    if (!item) return;

    if (item instanceof Object) {
      flatten(item, acc);
    } else {
      acc.push(item);
    }
  });

  return acc;
};
const removeAll = () => flatten(keys).forEach(key => remove(key));

export default {
  keys,
  add,
  get,
  remove,
  removeAll,
};
