<template>
  <div class="subscription-detail" v-if="!loading.subscription && !loading.cancellationInfo">
    <el-header v-if="showHeader">
      <el-col :span="16">
        <el-page-header @back="back" :content="`Assinatura #${subscription.id}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-dropdown trigger="click" @command="handleActionMenuCommand">
          <el-button type="primary" size="medium" class="el-dropdown-link">
            Ações
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :disabled="!isActive" command="add-discount">
              Adicionar desconto
            </el-dropdown-item>
            <el-dropdown-item divided :disabled="isCanceled" command="migrate-subscription">
              Migrar assinatura
            </el-dropdown-item>
            <el-dropdown-item :disabled="isCanceled" command="cancel-renewal">
              Cancelar renovação
            </el-dropdown-item>
            <el-dropdown-item :disabled="isCanceled" command="interrupt-subscription">
              Interromper assinatura
            </el-dropdown-item>
            <el-dropdown-item :disabled="!isCanceled" command="reactivate-subscription">
              Reativar assinatura
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-header>

    <el-tabs v-model="activeTab" :stretch="true" @tab-click="tabChanged" :tab-position="tabPosition">
      <!-- Subscription -->
      <el-tab-pane label="Informações básicas" :name="tabs.BASIC_INFORMATION" v-loading="loading.subscription">

        <el-form ref="subscriptionForm" :model="subscription" label-width="180px">
          <!-- ID and plan -->
          <el-row>
            <!-- ID -->
            <el-col :span="8">
              <el-form-item label="ID" prop="id">
                <el-input :value="subscription.id" disabled></el-input>
              </el-form-item>
            </el-col>

            <!-- Plano -->
            <el-col :span="16">
              <el-form-item label="Plano" prop="planId">
                <el-input :value="subscription.planId" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Customer -->
          <el-form-item label="Cliente" prop="customerId">
            <RemoteSearchSelect
              v-model="subscription.customerId"
              :search-function="CustomerService.search"
              :find-function="CustomerService.getCustomerById"
              :label-function="(customer) => `${customer.firstName} ${customer.lastName} (#${customer.id})`"
              :value-function="(customer) => customer.id"
              disabled
            ></RemoteSearchSelect>
          </el-form-item>

          <!-- Status -->
          <el-form-item label="Status" prop="status">
            <el-input :value="status" disabled></el-input>
          </el-form-item>

          <!-- Shipping Cost -->
          <el-form-item label="Custo de envio" prop="shippingCost">
            <el-input :value="`R$${(subscription.shippingCost / 100).toFixed(2)}`" disabled></el-input>
          </el-form-item>

          <!-- Start at and end at-->
          <el-row>
            <!-- Start At -->
            <el-col :span="12">
              <el-form-item label="Data de início" prop="startAt">
                <el-input :value="fromSystemDate(subscription.startAt)" disabled></el-input>
              </el-form-item>
            </el-col>

            <!-- End At -->
            <el-col :span="12">
              <el-form-item label="Data de fim" prop="endAt">
                <el-input :value="fromSystemDate(subscription.endAt)" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Cancel reason and canceled at-->
          <el-row>
            <!-- Cancel Reason -->
            <el-col :span="12">
              <el-form-item label="Razão do cancelamento" prop="cancelReason">
                <el-input :value="subscription.cancelReason" disabled></el-input>
              </el-form-item>
            </el-col>

            <!-- Canceled At -->
            <el-col :span="12">
              <el-form-item label="Data de cancelamento" prop="canceledAt">
                <el-input :value="fromSystemDate(subscription.canceledAt)" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Cancel Reason Comment -->
          <el-form-item label="Comentário" prop="cancelReasonComment">
            <el-input type="textarea" rows="3" :value="subscription.cancelReasonComment" disabled></el-input>
          </el-form-item>

        </el-form>

      </el-tab-pane>

      <el-tab-pane label="Endereço de entrega" :name="tabs.SHIPPING_ADDRESS" v-loading="loading.shippingAddress">

        <el-form ref="shippingAddressForm" :model="shippingAddress" label-width="180px">

          <!-- ID -->
          <el-form-item label="ID" prop="id">
            <el-input :value="shippingAddress.id" disabled></el-input>
          </el-form-item>

          <!-- Postal Code -->
          <el-form-item label="CEP" prop="postalCode">
            <el-input :value="shippingAddress.postalCode" disabled></el-input>
          </el-form-item>

          <el-row>

            <el-col :span="16">
              <!-- Street -->
              <el-form-item label="Rua" prop="street">
                <el-input :value="shippingAddress.street" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <!-- Number -->
              <el-form-item label="Número" prop="number">
                <el-input :value="shippingAddress.number" disabled></el-input>
              </el-form-item>
            </el-col>

          </el-row>

          <!-- Complement -->
          <el-form-item label="Complemento" prop="complement">
            <el-input :value="shippingAddress.complement" disabled></el-input>
          </el-form-item>

          <!-- District -->
          <el-form-item label="Bairro" prop="district">
            <el-input :value="shippingAddress.district" disabled></el-input>
          </el-form-item>

          <el-row>
            <el-col :span="16">
              <!-- City -->
              <el-form-item label="Cidade" prop="city">
                <el-input :value="shippingAddress.city" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <!-- State -->
              <el-form-item label="Estado" prop="state">
                <el-input :value="shippingAddress.state" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>

      </el-tab-pane>

      <!-- Payment Profile -->
      <el-tab-pane label="Perfil de pagamento" :name="tabs.PAYMENT_PROFILE" v-loading="loading.profile">
        <div>
          <el-form :model="paymentProfile" label-width="180px">
            <!-- ID -->
            <el-form-item label="ID" prop="id">
              <el-input :value="paymentProfile.paymentProfileId" disabled></el-input>
            </el-form-item>

            <div v-if="paymentProfile.vindiPaymentType === 'CREDIT_CARD'">
              <!-- Credit Card Brand -->
              <el-form-item label="Bandeira" prop="creditCardBrand">
                <el-input :value="paymentProfile.vindiCreditCardInformation.brand" disabled></el-input>
              </el-form-item>

              <!-- Credit Card Number -->
              <el-form-item label="Número do cartão" prop="creditCardNumber">
                <el-input :value="paymentProfile.vindiCreditCardInformation.displayNumber" disabled></el-input>
              </el-form-item>

              <!-- Credit Card Holder -->
              <el-form-item label="Proprietário" prop="creditCardHolder">
                <el-input :value="paymentProfile.vindiCreditCardInformation.holderName" disabled></el-input>
              </el-form-item>

              <!-- Credit Card Expiration -->
              <el-form-item label="Data de expiração" prop="creditCardExpiration">
                <el-input :value="paymentProfile.vindiCreditCardInformation.expiration" disabled></el-input>
              </el-form-item>
            </div>
            <div class="bank-slip-text" v-else>
              Boleto bancário
            </div>
          </el-form>
        </div>

      </el-tab-pane>

      <!-- Discounts -->
      <el-tab-pane label="Descontos" :name="tabs.DISCOUNTS" v-loading="loading.discounts">
        <el-table border :data="discounts" style="margin-bottom: 22px">
          <!-- ID -->
          <el-table-column prop="id" label="ID"></el-table-column>

          <!-- Coupon -->
          <el-table-column label="Cupom">
            <template slot-scope="scope">
              {{discounts[scope.$index].couponId ? discounts[scope.$index].couponId.toUpperCase() : '' }}
            </template>
          </el-table-column>

          <!-- Created At -->
          <el-table-column label="Data da criação">
            <template slot-scope="scope">
              {{fromSystemDate(discounts[scope.$index].createdAt)}}
            </template>
          </el-table-column>

          <!-- Amount -->
          <el-table-column label="Valor">
            <template slot-scope="scope">
              {{fromCents(discounts[scope.$index].amount)}}
            </template>
          </el-table-column>

          <!-- Percentage -->
          <el-table-column label="Porcentagem">
            <template slot-scope="scope">
              {{toPercentage(discounts[scope.$index].percentage)}}
            </template>
          </el-table-column>

          <!-- Cycles -->
          <el-table-column prop="cycles" label="Ciclos"></el-table-column>

        </el-table>
      </el-tab-pane>

      <!-- Gifts -->
      <el-tab-pane label="Presentes de assinatura" :name="tabs.GIFTS">
        <SubscriptionGiftCouponRedeem :subscriptionId="id"></SubscriptionGiftCouponRedeem>
      </el-tab-pane>

      <!-- Invoices -->
      <el-tab-pane label="Invoices" :name="tabs.INVOICES" v-loading="loading.invoices">
        <el-table border :data="invoices" style="margin-bottom: 22px">
          <el-table-column prop="id" label="ID" width="96" align="center">
          </el-table-column>
          <el-table-column label="Status">
            <template slot-scope="scope">
              {{invoiceStatus[invoices[scope.$index].status]}}
            </template>
          </el-table-column>
          <el-table-column label="Total">
            <template slot-scope="scope">
              {{`R$${(invoices[scope.$index].total / 100).toFixed(2)}`}}
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="Data da criação">
            <template slot-scope="scope">
              {{fromSystemDate(invoices[scope.$index].createdAt)}}
            </template>
          </el-table-column>
          <el-table-column prop="paidAt" label="Data do pagamento">
            <template slot-scope="scope">
              {{invoices[scope.$index].paidAt ? fromSystemDate(invoices[scope.$index].paidAt) : ''}}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <!--- Dialogs -->
    <!-- Dialog - Migrate Subscription -->
    <MigrateSubscription
      v-if="cancellationInfo"
      ref="migrateSubscriptionDialog"
      :subscription="subscription"
      :cancellationInfo="cancellationInfo"
      @migrated="load">
    </MigrateSubscription>

    <!--- Dialog - Cancel Renewal -->
    <CancelRenewal
      v-if="cancellationInfo"
      ref="cancelRenewalDialog"
      :subscription="subscription"
      :cancellationInfo="cancellationInfo"
      @canceled="load">
    </CancelRenewal>

    <!--- Dialog - Interrupt subscription -->
    <InterruptSubscription
      v-if="cancellationInfo"
      ref="interruptSubscriptionDialog"
      :subscription="subscription"
      :cancellationInfo="cancellationInfo"
      @interrupted="load">
    </InterruptSubscription>

    <!-- Dialog - Add Discount -->
    <el-dialog class="text-left-aligned" title="Adicionar Desconto" :visible.sync="addDiscountDialogVisible"
      v-loading="loading.addDiscount">

      <el-form ref="addDiscountForm" label-width="180px" label-position="top" :rules="addDiscountRules"
        :model="addDiscount">

        <!-- Type -->
        <el-form-item label="Tipo" prop="type">
          <el-radio-group v-model="addDiscount.type">
            <el-radio-button label="percentage">{{ "Porcentagem" }}</el-radio-button>
            <el-radio-button label="amount">{{ "Valor Fixo" }}</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <!-- Value -->
        <el-form-item label="Valor" prop="value">
          <el-input-number v-model="addDiscount.value" controls-position="right" :precision="2" :step="0.1" :min="0"
            class="stretched" style="text-align: left;">
          </el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDiscountDialogVisible = false">{{ "Voltar" }}</el-button>
        <el-button type="primary" @click="doAddDiscount">
          {{ "Adicionar Desconto" }}
        </el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import moment from 'moment';

import CustomerService from '@/services/CustomerService';
import SubscriptionService from '@/services/billing/SubscriptionService';
import VindiService from '@/services/billing/VindiService';
import CancelRenewal from './actions/CancelRenewal.vue';
import InterruptSubscription from './actions/InterruptSubscription.vue';
import MigrateSubscription from './actions/MigrateSubscription.vue';
import ShippingAddressService from '@/services/ShippingAddressService';

import RemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue';

import SubscriptionGiftCouponRedeem from './tabs/SubscriptionGiftCouponRedeem.vue';

const tabs = {
  BASIC_INFORMATION: 'basic-information',
  SHIPPING_ADDRESS:  'shipping-address',
  PAYMENT_PROFILE: 'payment-profile',
  DISCOUNTS: 'discounts',
  INVOICES: 'invoices',
};

const subscriptionStatus = {
  WAITING: 'Esperando pagamento',
  ACTIVE: 'Ativa',
  PENDING: 'Pagamento pendente',
  UNPAID: 'Inadimplente',
  SUSPENDED: 'Suspensa',
  CANCELED: 'Cancelada',
  MIGRATED: 'Migrada',
};

const invoiceStatus = {
  WAITING: 'Aguardando cobrança',
  PENDING: 'Aguardando pagamento',
  PAID: 'Paga pelo cliente',
  FORGIVEN: 'Paga pela Magenta',
  UNPAID: 'Não paga',
  DISPUTED: 'Disputada',
  REFUNDED: 'Ressarcida',
  CLOSED: 'Fechada',
};

export default {
  name: "subscription-detail",
  components: {
    InterruptSubscription,
    CancelRenewal,
    MigrateSubscription,
    RemoteSearchSelect,
    SubscriptionGiftCouponRedeem
  },
  props: {
    id: {
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    tabPosition: {
      type: String,
      default: 'top',
    },
  },
  data() {
    return {
      loading: {
        subscription: false,
        shippingAddress: false,
        paymentProfile: false,
        discounts: false,
        invoices: false,
        cancellationInfo: false,
        addDiscount: false,
        cancelSubscription: false,
      },
      subscription: {},
      shippingAddress: {},
      paymentProfile: { vindiCreditCardInformation: {} },
      discounts: [],
      invoices: [],
      cancellationInfo: {},
      activeTab: tabs.BASIC_INFORMATION,
      tabs,
      subscriptionStatus,
      invoiceStatus,
      addDiscountDialogVisible: false,
      addDiscountRules: {
        type: { required: true, message: "Tipo obrigatório" },
        value: [
          { required: true, message: "Valor obrigatório" },
          {
            validator: (rule, value, callback) => {
              if (this.addDiscount.type === "percentage" && value > 100) {
                callback(new Error(rule.message));
              }
              else {
                callback();
              }
            },
            message: "Máximo de 100%.",
          },
        ],
      },
      addDiscount: {},
      cancelRenewalDialogVisible: false,
      cancelSubscription: {},
      CustomerService
    };
  },
  async mounted() {
    this.activeTab = this.$route.query['subscriptionTab'] || this.activeTab;

    await this.load();
  },
  computed: {
    isActive() {
      switch (this.subscription.status) {
        case "ACTIVE":
        case "WAITING":
        case "PENDING":
        case "UNPAID":
          return true;
        default: return false;
      }
    },
    isCanceled() {
      return this.subscription.status == "CANCELED" ||
        this.subscription.endAt != null ||
        this.subscription.canceledAt != null;
    },
    status() {
      if (!this.subscription) {
        return null;
      }

      return this.subscriptionStatus[this.subscription.status] + (this.subscription.status == 'ACTIVE' && this.subscription.endAt ? " (renovação cancelada)" : "");
    }
  },
  methods: {
    tabChanged() {
      this.$router.replace({ query: { subscriptionTab: this.activeTab } })
    },
    back() {
      this.$router.back();
    },
    async load() {
      await this.loadBasicInformation();
      this.loadShippingAddress();
      this.loadPaymentProfile();
      this.loadDiscounts();
      this.loadInvoices();
      this.loadCancellationInfo();
    },
    async loadBasicInformation() {
      this.loading.subscription = true;
      const subscription = await SubscriptionService.getSubscriptionById(this.id);
      this.subscription = subscription;
      this.loading.subscription = false;
    },
    async loadShippingAddress() {
      this.loading.shippingAddress = true;
      this.shippingAddress = await ShippingAddressService.getShippingAddressById(this.subscription.shippingAddressId);
      this.loading.shippingAddress = false;
    },
    async loadPaymentProfile() {
      this.loading.paymentProfile = true;
      this.paymentProfile = await VindiService.getVindiPaymentProfile(this.subscription.billingPlatformId, this.subscription.paymentProfileId);
      if (this.paymentProfile.vindiPaymentType === "CREDIT_CARD") {
        this.paymentProfile.vindiCreditCardInformation.expiration = `${this.paymentProfile.vindiCreditCardInformation.month.toString().padStart(2, "0")}/${this.paymentProfile.vindiCreditCardInformation.year}`;
      }
      this.loading.paymentProfile = false;
    },
    async loadDiscounts() {
      this.loading.discounts = true;
      this.discounts = await SubscriptionService.getDiscounts(this.id);
      this.loading.discounts = false;
    },
    async loadInvoices() {
      this.loading.invoices = true;
      this.invoices = await SubscriptionService.getInvoices(this.id);
      this.loading.invoices = false;
    },
    async loadCancellationInfo() {
      this.loading.cancellationFee = true;
      try {
        this.cancellationInfo = await SubscriptionService.getCancellationInfo(this.id);
      }
      catch (e) {
        this.cancellationInfo = null;
      }
      this.loading.cancellationFee = false;
    },
    // Actions
    handleActionMenuCommand(command) {
      switch (command) {
        case "add-discount":
          this.showAddDiscountDialog();
          break;
        case "migrate-subscription":
          this.$refs.migrateSubscriptionDialog.open();
          break;
        case "cancel-renewal":
          this.$refs.cancelRenewalDialog.open();
          break;
        case "interrupt-subscription":
          this.$refs.interruptSubscriptionDialog.open();
          break;
        case "reactivate-subscription":
          this.reactivateSubscription();
          break;
        default: break;
      }
    },
    showAddDiscountDialog() {
      this.addDiscountDialogVisible = true;
      this.addDiscount = { type: "percentage", value: 0 };
    },
    async doAddDiscount() {
      try {
        await this.$refs.addDiscountForm.validate();
      }
      catch (e) {
        throw new Error("Problema ao adicionar desconto. Verifique os campos.");
      }
      this.loading.addDiscount = true;
      try {
        const request = {
          percentage: (this.addDiscount.type === "percentage" ? this.addDiscount.value / 100 : null),
          amount: (this.addDiscount.type === "amount" ? this.addDiscount.value * 100 : null),
          cycles: 1,
        };
        await VindiService.addDiscount(this.subscription.billingPlatformId, this.subscription.id, request);
        this.$message.success("Desconto adicionado com sucesso.");
      }
      catch (e) {
        this.$message.error(e.message || "Falha ao adicionar desconto.");
      }
      finally {
        this.loading.addDiscount = false;
        this.addDiscountDialogVisible = false;
        this.loadDiscounts();
      }
    },
    reactivateSubscription() {
      this.$confirm("Você tem certeza de que deseja reativar esta assinatura?", "Reativar assinatura", {
        confirmButtonText: "Sim, reativar assinatura",
        cancelButtonText: "Não, não reativar",
        type: "warning",
      }).then(() => {
        this.doReactivateSubscription();
      });
    },
    async doReactivateSubscription() {
      try {
        this.loading.subscription = true;

        await SubscriptionService.reactivate(this.subscription.id);

        this.$message.success("Assinatura reativada com sucesso.");
      } catch (e) {
        this.$message.error("Falha ao reativar assinatura: " + e.message);
      } finally {
        this.load();
      }
    },
    fromCents(value) {
      return value ? (value / 100).toFixed(2).replace(".", ",") : 0;
    },
    fromSystemDate(value) {
      return value ? moment(value, "YYYY-MM-DD").format("DD/MM/YYYY") : null;
    },
    toPercentage(value) {
      return value ? `${value * 100}%` : "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-detail {
  font-size: 20px;

  .information__row {
    margin-top: 30px;

    .name {
      font-size: 25px;
    }

    label {
      font-size: 16px;
    }

    .text {
      font-size: 23px;
    }
  }

  .text-left-aligned {
    text-align: left;
  }

  .bank-slip-text {
    font-size: 15px;
    font-style: italic;
  }
}
</style>
