<template>
  <section v-if="!loading" v-loading="loading">
    <el-header>
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isNew ? 'Novo influencer' : `Influencer #${influencer.id}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save" :loading="saving">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-form ref="form" :model="influencer" :rules="rules" label-width="160px">

      <!-- Active -->
      <el-form-item label="Ativo" prop="active" style="text-align: left;">
        <el-switch v-model="influencer.active"></el-switch>
      </el-form-item>

      <!-- Customer -->
      <el-form-item label="Cliente" prop="customerId">
        <snowflake-remote-search-select
            v-model="influencer.customerId"
            placeholder="Customer"
            :search-function="customerService.search"
            :find-function="customerService.getCustomerById"
            :value-function="item => item.id"
            :label-function="item => `${item.firstName} ${item.lastName} (${item.email})`"
            :disabled="!isNew">
          </snowflake-remote-search-select>
      </el-form-item>

      <!-- Promoted SKU -->
      <el-form-item label="SKU promovido" prop="promotedSkuId">
        <snowflake-remote-search-select
            v-model="influencer.promotedSkuId"
            placeholder="Promoted SKU"
            :search-function="skuService.search"
            :find-function="skuService.getSkuById"
            :value-function="item => item.id"
            :label-function="item => item.name">
          </snowflake-remote-search-select>
      </el-form-item>

      <!-- Coupon -->
      <el-form-item label="Cupom" prop="couponId">
        <snowflake-remote-search-select
            v-model="influencer.couponId"
            placeholder="Coupon"
            :search-function="couponService.search"
            :find-function="couponService.getCouponById"
            :value-function="item => item.id"
            :label-function="item => item.id">
          </snowflake-remote-search-select>
      </el-form-item>


      <!-- Affiliate -->
      <el-form-item label="Afiliado" prop="active" style="text-align: left;">
        <el-switch v-model="influencer.affiliate"></el-switch>
      </el-form-item>

    </el-form>

  </section>
</template>
<script>
import InfluencerService from '@/services/influencer/InfluencerService';
import CustomerService from '@/services/CustomerService';
import CouponService from '@/services/billing/CouponService';
import SkuService from '@/services/billing/SkuService';

import SnowflakeRemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue';

export default {
  name: 'influencer-detail',
  components: {
    SnowflakeRemoteSearchSelect,
  },
  data() {
    return {
      rules: {
        customerId: [
          { required: true, message: this.$t('influencer.error.customerRequired'), trigger: 'blur' },
        ],
        promotedSkuId: [
          { required: true, message: this.$t('influencer.error.promotedSkuRequired'), trigger: 'blur' },
        ],
      },
      customerService: CustomerService,
      couponService: CouponService,
      skuService: SkuService,
      showError: false,
      loading: true,
      saving: false,
      influencer: {
        active: true,
      },
    };
  },
  computed: {
    isNew() {
      return this.$route.name === 'influencer-new';
    },
  },
  async created() {
    if (this.isNew) {
      this.influencer = {
        active: true,
      };
      this.loading = false;

      return;
    }

    this.influencer = await InfluencerService.getInfluencerById(this.$route.params.id);
    this.influencer.couponId = this.influencer.coupons[0] || null;

    this.loading = false;
  },
  methods: {
    async save() {
      this.saving = true;

      try {
        await this.$refs.form.validate();

        if (this.isNew) {
          this.influencer = await this.create();

          this.$router.replace({ name: 'influencer-detail', params: { id: this.influencer.id } });
        } else {
          this.influencer = await this.update();
        }

        this.influencer.couponId = this.influencer.coupons[0] || null;

        this.$message.success(this.$t('influencer.message.successfulSave'));
      } catch (e) {
        this.$message.error(e || this.$t('influencer.message.failedToSave'));
      } finally {
        this.saving = false;
      }
    },
    cancel() {
      this.$router.back();
    },
    async create() {
      const [shippingAddress] = await CustomerService.getCustomerShippingAddress(this.influencer.customerId);
      if (!shippingAddress || !shippingAddress.id) {
        throw Error(this.$t('influencer.message.noShippingAddress'));
      }

      this.influencer.shippingAddressId = shippingAddress.id;
      this.influencer.coupons = this.influencer.couponId ? [this.influencer.couponId] : [];

      return InfluencerService.create(this.influencer);
    },
    update() {
      this.influencer.coupons = this.influencer.couponId ? [this.influencer.couponId] : [];

      return InfluencerService.update(this.influencer.id, this.influencer);
    },
  },
};
</script>
