import Storage from '../storage'
import { computed, getCurrentInstance } from 'vue'

export function useUser() {
  const inst = getCurrentInstance();

  if (!inst) {
      console.warn("Out of Vue scope");

      return undefined;
  }

  return {
    user: computed(() => Storage.get(Storage.keys.auth.user)),
    roles: computed(() => Storage.get(Storage.keys.auth.roles)),
    token: computed(() => Storage.get(Storage.keys.auth.token))
  }
}