<template lang="pug">
.multi-product-variant-edit
  el-dialog(
    top="0",
    title="Edição em massa",
    :visible="visible",
    @update:visible="setVisible",
  )

    .editor-title(slot="title")
      | Edição em massa

      el-radio-group(:value="productVariant.status", @input="setStatus")
        el-radio-button.grey(key="UNLISTED", label="UNLISTED") Não listada
        el-radio-button.green(key="ACTIVE", label="ACTIVE") Listada

    el-tabs(tab-position="left", v-model="selectedTab")
      el-tab-pane(name="basic-info", label="Info. básicas")
        .tab-pane-content
          ProductVariantBasicInfo(
            ref="basicInfoForm",
            :value="productVariant",
            @input="updateProductVariant",
            multi-edit
          )

      el-tab-pane(name="price", label="Precificação")
        .tab-pane-content
          ProductVariantPricing(
            ref="priceForm",
            :value="productVariant",
            @input="updateProductVariant"
          )

      el-tab-pane(name="shipping", label="Envio")
        .tab-pane-content
          ProductVariantShipping(
            ref="shippingForm",
            :value="productVariant",
            @input="updateProductVariant"
          )

      el-tab-pane(name="attributes", label="Atributos")
        .tab-pane-content
          h2 Atributos

          p Selecione as opções que descrevem as características do produto:

          ProductAttributeForm.height-limited(
            :value="productVariant.attributes",
            :attributes="productCategoryAttributes",
            @input="setAttributes"
          )

      el-tab-pane(name="media", label="Imagens", :lazy="true")
        .tab-pane-content
          ProductVariantMedia(:value="productVariant", @input="updateProductVariant")

    .editor-footer(slot="footer")
      el-button(title="Cancelar", @click="setVisible(false)") Cancelar
      el-button(title="Aplicar edição", type="primary", @click="apply") Aplicar
</template>
<script setup>
import { computed, ref, watch } from 'vue'

import ProductAttributeForm from '@/components/input/ecommerce/product-attribute/ProductAttributeForm.vue'
import ProductVariantBasicInfo from './tabs/ProductVariantBasicInfo.vue'
import ProductVariantMedia from './tabs/ProductVariantMedia.vue'
import ProductVariantPricing from './tabs/ProductVariantPricing.vue'
import ProductVariantShipping from './tabs/ProductVariantShipping.vue'

const emptyProductVariant = {
  status: null,
  barcodes: [],
  price: null,
  subscriberPrice: null,
  comparedPrice: null,
  weight: null,
  attributes: {},
  media: [],
}

const props = defineProps({
  productCategory: {
    type: Object
  },
  visible: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['apply', 'update:visible'])

const selectedTab = ref('basic-info')
const productVariant = ref({ ...emptyProductVariant })
const dirtyAttributes = ref(false)

const productCategoryAttributes = computed(() => {
  if (!props.productCategory) return []

  return props.productCategory.hierarchyAttributes
})

const updateProductVariant = (value) => {
  productVariant.value = value
}

const setAttributes = (attributes) => {
  updateProductVariant({ ...productVariant.value, attributes })
  dirtyAttributes.value = true
}

const setStatus = (value) => {
  updateProductVariant({ ...productVariant.value, status: value })
}

const setVisible = (value) => {
  emit('update:visible', value)
}

const reset = () => {
  selectedTab.value = 'basic-info'
  dirtyAttributes.value = false
  productVariant.value = { ...emptyProductVariant }
}

const apply = () => {
  const data = {
    status: productVariant.value.status || undefined,
    description: productVariant.value.description || undefined,
    htmlDescription: productVariant.value.htmlDescription || undefined,
    price: productVariant.value.price || undefined,
    subscriberPrice: productVariant.value.subscriberPrice || undefined,
    comparedPrice: productVariant.value.comparedPrice || undefined,
    weight: productVariant.value.weight || undefined,
    attributes: dirtyAttributes.value ? productVariant.value.attributes : undefined,
    media: productVariant.value.media.length > 0 ? productVariant.value.media : undefined
  }

  for (var i in data) {
    if (data[i] === undefined) {
      delete data[i]
    }
  }

  emit('apply', data)

  setVisible(false)
}

watch(() => props.visible, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    reset()
  }
})
</script>
<style lang="sass">
.multi-product-variant-edit

  .el-radio-button

    &.green
      $green-color: #67c23a

      .el-radio-button__inner:hover
        color: $green-color

      .el-radio-button__orig-radio:checked + .el-radio-button__inner
        box-shadow: -1px 0 0 0 $green-color

      &.is-active
        .el-radio-button__inner
          background-color: $green-color
          border-color: $green-color
          color: #fff

    &.grey
      $grey-color: #C0CCDA

      .el-radio-button__inner:hover
        color: $grey-color

      &.is-active
        .el-radio-button__inner
          background-color: $grey-color
          border-color: $grey-color
          color: #fff

</style>