import VueI18n from 'vue-i18n';

import en from './locales/en';
import pt from './locales/pt';

let instance;

export default {
  getInstance() {
    if (!instance) {
      throw new Error('Not initialized');
    }

    return instance;
  },
  initialize() {
    instance = new VueI18n({
      locale: 'pt',
      fallbackLocale: 'en',
      sync: true,
      messages: {
        pt,
        en,
      },
    });

    return instance;
  },
};
