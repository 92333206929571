import { apiGet, apiPatch, apiPost } from '../../_common';

export default {
  create(request) {
    return apiPost('/magenta/ecommerce/order/production-batch', request).then(result => result.response);
  },
  get(id) {
    return apiGet(`/magenta/ecommerce/order/production-batch/${id}`)
      .then(result => result.response);
  },
  update(id, request) {
    return apiPatch(`/magenta/ecommerce/order/production-batch/${id}`, request)
      .then(result => result.response);
  },
  addPackages(id, request) {
    return apiPost(`/magenta/ecommerce/order/production-batch/${id}/add-packages`, request)
      .then(result => result.response);
  },
  addPackage(id, orderId) {
    return apiPost(`/magenta/ecommerce/order/production-batch/${id}/add-package`, { orderId })
      .then(result => result.response);
  },
  removePackage(id, request) {
    return apiPost(`/magenta/ecommerce/order/production-batch/${id}/remove-package`, request)
      .then(result => result.response);
  },
  getPackages(id) {
    return apiGet(`/magenta/ecommerce/order/production-batch/${id}/packages`)
      .then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/ecommerce/order/production-batch', query).then(result => result.response);
  },

  // Acessory methods
  getAvailablePackagesChartUrl() {
    return apiGet('/magenta/ecommerce/order/production-batch/available-packages-chart')
      .then(result => result.response);
  },
  generateNFes(request) {
    return apiPost('/magenta/ecommerce/order/production-batch/nfe', request)
      .then(result => result.response);
  },
  syncNFes(request) {
    return apiPost('/magenta/ecommerce/order/production-batch/nfe/sync', request)
      .then(result => result.response);
  },
  generateDANFes(request) {
    return apiPost('/magenta/ecommerce/order/production-batch/nfe/danfe', request)
      .then(result => result.response);
  },
  generateBillOfMaterials(request) {
    return apiPost('/magenta/ecommerce/order/production-batch/bill-of-materials', request)
      .then(result => result.response);
  },
  generateShippingLabels(request) {
    return apiPost('/magenta/ecommerce/order/production-batch/shipping-labels', request)
      .then(result => result.response);
  }
};
