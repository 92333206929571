<template lang="pug">
.subscription-action-cancel-renewal
  el-dialog(
    top="0",
    class="text-left-aligned"
    title="Cancelar renovação"
    :visible.sync="isVisible")

    el-form(ref="form", :rules="rules", :model="data", label-position="top", label-width="120px")

      p
        | A assinatura não será mais renovada, porém o período atual de assinatura será concluído.&#32;
        br
        br
        | Caso a assinatura ainda não esteja no fim do seu período,&#32;
        | mais boxes serão geradas e novas cobranças ainda podem ser realizadas.

      el-card(shadow="never")

        h3 Dados da assinatura

        el-row
          el-col(:span="8")
            el-statistic(title="Mês do período atual")
              template(slot="formatter")
                span.small {{ cancellationInfo.monthsIntoPeriod }}ᵒ mês de {{ cancellationInfo.monthsPerPeriod }}&#32;
                  | {{ cancellationInfo.monthsPerPeriod == 1 ? "mês" : "meses" }}

          el-col(:span="8")
            el-statistic(title="Última renovação")
              template(slot="formatter")
                span.small {{ fromSystemDate(cancellationInfo.lastRenewalAt) }}

          el-col(:span="8")
            el-statistic(title="Próxima renovação")
              template(slot="formatter")
                span.small {{ fromSystemDate(cancellationInfo.nextRenewalAt) }}

      el-row
        el-col

          h3 Razão de cancelamento

          el-form-item(label="Razão de cancelamento", prop="cancelReason", size="small")
            el-select(v-model="data.cancelReason")
                el-option(
                  v-for="reason in reasons"
                  :label="reason.title"
                  :value="reason.id"
                )

          el-form-item(label="Comentário sobre cancelamento", prop="cancelReasonComment", size="small")
            el-input(type="textarea" v-model="data.cancelReasonComment")

    .footer(slot="footer")
      el-button(@click="close") Voltar

      el-button(type="primary", @click="promptCancellation", :loading="isCancelling") Cancelar renovação

</template>
<script setup>
import SubscriptionService from '@/services/billing/SubscriptionService';
import { useMessage } from '@/utils/Message';
import { useConfirm } from '@/utils/MessageBox';
import moment from 'moment';

import { computed, nextTick, ref } from 'vue';

const reasons = [
  { id: 'TOO_MANY_PRODUCTS', title: 'Acúmulo de produtos' },
  { id: 'SINGLE_BOX', title: 'Box Avulsa/Presente' },
  { id: 'TRAVEL_OR_TRIP', title: 'Mudança/Viagem' },
  { id: 'DID_NOT_AUTHORIZE_RENEWAL', title: 'Não autorizei a renovação automática' },
  { id: 'DID_NOT_RECEIVE_GIFT', title: 'Não recebi o presente da promoção/cupom' },
  { id: 'LOGISTICAL_ISSUE', title: 'Questões logísticas' },
  { id: 'PRODUCT_SELECTION_ISSUE', title: 'Questões com a seleção de produtos' },
  { id: 'HEALTH_PROBLEMS', title: 'Questões de saúde' },
  { id: 'FINANCIAL_PROBLEMS', title: 'Questões financeiras' },
  { id: 'TRY_COMPETITOR', title: 'Testar outras assinaturas' },
  { id: 'NOT_INTERESTED', title: 'Não tenho mais interesse' },
  { id: 'OTHER', title: 'Outra razão' },
]

const confirm = useConfirm()
const message = useMessage()

const props = defineProps({
  subscription: {
    type: Object,
    required: true
  },
  cancellationInfo: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['canceled'])

const form = ref()
const rules = ref({
  cancelReason: [ { required: true, trigger: 'change', message: "Campo obrigatório" }],
  cancelReasonComment: [ { required: true, trigger: 'change', message: "Campo obrigatório" }],
})

const isVisible = ref(false)
const isCancelling = ref(false)

const data = ref({})

const fromSystemDate = (value) => {
  return value ? moment(value, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
}

const open = () => {
  data.value = {
    cancelReason: null,
    cancelReasonComment: ""
  }

  isVisible.value = true

  nextTick(() => {
    form.value.clearValidate()
  })
}

const close = () => {
  isVisible.value = false
}

const promptCancellation = async () => {
  var isValid = false

  try {
    await form.value.validate()

    isValid = true
  } catch(e) {
    message.warning("Verifique os dados preenchidos")

    return
  }

  confirm("Você tem certeza de que deseja cancelar esta assinatura? Esta operação não pode ser desfeita", "Cancelar assinatura", {
    confirmButtonText: 'Sim, cancelar assinatura',
    cancelButtonText: 'Não, não cancelar assinatura',
    type: 'warning'
  })
  .then(() => {
    return cancel()
  })
}

const cancel = async () => {
  isCancelling.value = true

  try {
    await SubscriptionService.cancel(props.subscription.id, {
      cancelReason: data.value.cancelReason,
      cancelReasonComment: data.value.cancelReasonComment.trim(),
      cancelImmediately: false,

      refundAllPeriod: false,
      refundRemainingPeriod: false,
      dueAmountAlreadyPaid: true
    })

    emit('canceled')

    message.success(`Assinatura cancelada com sucesso!`)
  } catch(e) {
    message.error(`Houve um problema ao cancelar esta assinatura: ${e}`)

    console.error(e)
  } finally {
    isCancelling.value = false

    close()
  }
}

defineExpose({
  open
})
</script>
<style lang="scss" scoped>
p {
  word-break: break-word
}
</style>
<style lang="scss">
  .subscription-action-cancel-renewal {

    .delete-confirm {
      .el-button {
        margin-left: 10px;
      }
    }

    .el-dialog__wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 48px;

      .el-dialog {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-height: 98vh;
        overflow: auto;
        width: 1056px;

        .el-dialog__header, .el-dialog__footer {
          flex: 0;
        }

        .el-dialog__body {
          flex: 1;
          overflow: auto;
          padding: 16px 20px;

          .el-card__body {
            h3 {
              margin-top: 0;
            }
          }

          span.small {
            font-size: 14px;
          }
        }

        .el-dialog__footer {
          padding: 10px 20px;
        }

        .editor-footer {
          .page {
            padding: 0 8px;
            font-size: 14px;
          }
        }
      }
    }
  }
  </style>
