import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/fulfillment/occurrence', request).then(result => result.response);
  },
  update(request, occurenceId) {
    return apiPut(`/magenta/fulfillment/occurrence/${occurenceId}`, request)
      .then(result => result.response);
  },
  findByBoxOrderId(boxOrderId) {
    return apiGet(`/magenta/fulfillment/occurrence/subscription-box-order/${boxOrderId}`)
      .then(result => result.response);
  },
};
