import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/fulfillment/subscription-box-edition', request).then(result => result.response);
  },
  update(id, request) {
    return apiPut(`/magenta/fulfillment/subscription-box-edition/${id}`, request).then(result => result.response);
  },
  find(id) {
    return apiGet(`/magenta/fulfillment/subscription-box-edition/${id}`).then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/fulfillment/subscription-box-edition', query).then(result => result.response);
  },
  getSubscriptionBoxEditionById(id) {
    return apiGet(`/magenta/fulfillment/subscription-box-edition/${id}`).then(result => result.response);
  },
};
