<template lang="pug">
.product-variant-basic-info

  h2 Informações básicas

  p Informações básicas sobre o produto:

  el-form(ref="form", :rules="formRules", :model="productVariant", label-width="90px", label-position="top")

    el-form-item(v-if="!multiEdit", label="ID", prop="id")

      el-input(v-model="productVariant.id", disabled)
        template(slot="prepend")
          span(v-if="productVariant.id") #

    el-form-item(v-if="!multiEdit", label="Cód. EAN (código de barras)", prop="barcodes")
      el-input(@keyup.enter.native="addBarcode" v-model="barcodeInput")
        el-button(
          slot="append"
          icon="el-icon-plus"
          @click="addBarcode"
        )

      .barcodes-container
        .barcodes(v-if="productVariant.barcodes && productVariant.barcodes.length > 0")
          el-tag(
            v-for="(barcode, index) in productVariant.barcodes"
            class="spaced"
            type="primary"
            :key="barcode"
            :disable-transitions="true"
          )
            | {{ barcode }}
            i.el-tag__close.el-icon-close(@click.stop="removeBarcode(index)")

        .no-barcodes(v-else) Nenhum código registrado

    el-form-item(label="Descrição", prop="description")
      HtmlEditor(
        ref="descriptionEditor",
        :value="productVariant.htmlDescription", 
        @input="htmlDescriptionChanged"
      )


</template>
<script setup>
import { computed, defineProps, defineEmits, ref, nextTick } from 'vue'
import HtmlEditor from '@/components/input/HtmlEditor.vue'

const props = defineProps({
  multiEdit: {
    type: Boolean,
    default: false
  },
  value: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['input'])

const productVariant = computed(() => props.value)

const form = ref()
const formRules = ref({})

// Barcode
const barcodeInput = ref('')

const addBarcode = () => {
  const barcode = barcodeInput.value.trim().toUpperCase()

  if (!barcode) return

  const barcodes = productVariant.value.barcodes.slice()
  if (barcodes.indexOf(barcode) >= 0) {
    this.$message.warning('Código de barras já inserido.')
  } else {
    barcodes.push(barcode)
  }

  barcodeInput.value = null

  emit('input', { ...productVariant.value, barcodes })
}

const removeBarcode = (index) => {
  const barcodes = productVariant.value.barcodes.slice()
  barcodes.splice(index, 1)

  emit('input', { ...productVariant.value, barcodes })
}

// Description
const descriptionEditor = ref()
const htmlDescriptionChanged = (value) => {
  const text = descriptionEditor.value.getText()
  emit('input', { ...productVariant.value, description: text, htmlDescription: value })
}

// Validate
const validate = async () => {
  await form.value.validate()
}


// Expose
defineExpose({
  validate
})
</script>
<style lang="scss" scoped>

.product-variant-basic-info {
  .barcodes-container {
    text-align: left;
    border: #c1c1c1 dashed 1.5px;
    border-radius: 4px;
    margin-top: 8px;
    padding: 8px;

    div.no-barcodes {
      text-align: center;
      font-size: 12;
      line-height: 40px;
      color: #5e7382;
    }

    .el-tag {
      margin: 4px;
    }
  }
}
</style>