import { apiGet, apiPost } from '../_common';

export default {
  getVindiPaymentProfile(billingPlatformId, paymentProfileId) {
    return apiGet(`/magenta/billing/${billingPlatformId}/vindi/payment-profile/${paymentProfileId}`)
      .then(result => result.response);
  },
  searchVindiPaymentProfile(request) {
    return apiGet(`/magenta/billing/vindi/payment-profile`, request)
      .then(result => result.response);
  },
  addDiscount(billingPlatformId, subscriptionId, request) {
    return apiPost(`/magenta/billing/${billingPlatformId}/vindi/subscription/${subscriptionId}/discount`, request)
      .then(result => result.response);
  },
};
