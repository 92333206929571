<template lang="pug">
.customer-cart(v-loading="loading")

  el-descriptions(:column="3", size="medium", border)

    el-descriptions-item
      template(slot="label") Total de itens
      | {{ cart?.items?.length ?? 0 }}

    el-descriptions-item
      template(slot="label") Valor total
      | R$ {{ ((cart?.totalValue ?? 0) / 100.0).toFixed(2) }}

    el-descriptions-item
      template(slot="label") Última atualização
      | {{ dateTimeToLocaleDateTime(cart?.updatedAt) }}

  el-divider

  el-tabs(type="border-card")

    el-tab-pane(label="Itens")

      SearchList(
        v-if="cart",
        ref="cartItemList",
        context="cart-item",
        :service="{ search: searchCartItems }",
        :showSearchBar="false",
        :keepStateOnUrl="false",
        :action="false",
        :details="false",
        :operations="false",
      )
        template(slot="tableRows")

          el-table-column(prop="name", label="Produto", show-overflow-tooltip)
            template(slot-scope="scope")
              span {{ productVariantName(scope.row.productVariant) }}&#32;

              router-link(:to="productVariantLink(scope.row.productVariant)", target='_blank')
                i.el-icon-edit-outline.link

          el-table-column(prop="quantity", label="Quantidade", width="140")
            template(slot-scope="scope")

              .quantity(style="display: flex; align-items: center; justify-content: space-between")
                div
                  span {{ scope.row.quantity }}&#32;

                  span(v-if="scope.row.quantity == 1") unidade
                  span(v-else) unidades

                el-popover(
                  placement="left",
                  width="324",
                  trigger="click",
                )
                  EditCartItem(
                    :cartItem="scope.row",
                    @itemUpdated="cartItemUpdated"
                  )

                  el-button(
                    slot="reference",
                    plain,
                    size="mini",
                    icon="el-icon-edit",
                    round
                  )

          el-table-column(prop="unitPrice", label="Preço unitário", width="120")
            template(slot-scope="scope")
              span R$ {{ (scope.row.productVariant.price / 100.0).toFixed(2) }}

          el-table-column(prop="totalPrice", label="Preço total", width="120")
            template(slot-scope="scope")
              span R$ {{ ((scope.row.quantity * scope.row.productVariant.price) / 100.0).toFixed(2) }}

          el-table-column(prop="inventoryAvailability", label="Disponibilidade", width="120")
            template(slot-scope="scope")
              span(v-if="scope.row.inventoryAvailability == 'IN_STOCK'") Disponível
              span(v-if="scope.row.inventoryAvailability == 'OUT_OF_STOCK'", style="color: #F56C6C") Indisponível

          el-table-column(label="Ações", width="68")
            template(slot="header")
              el-button(type="primary", plain, size="small", icon="el-icon-plus", @click="openAddCartItemDialog")

            template(slot-scope="scope")

              el-popconfirm(
                title="Tem certeza que deseja remover este item do carrinho?",
                @confirm="removeCartItem(scope.row.id)"
              )
                el-button(slot="reference", type="danger", plain, size="small", icon="el-icon-delete")

    el-tab-pane(label="Movimentação", :lazy="true")

      h3 Movimentação do carrinho

      //- SearchList(
      //-   context="transaction",
      //-   :showSearchBar="false",
      //-   :service="transactionSearchService",
      //-   :searchOptions="searchOptions",
      //-   :action="false",
      //-   :details="false"
      //-   :operations="false",
      //-   :keepStateOnUrl="false"
      //- )

      //-   template(slot="tableRows")

      //-     el-table-column(prop="executedAt" label="Realizada em", width="160")
      //-       template(slot-scope="scope")
      //-         span {{ dateTimeToLocaleDateTime(scope.row.executedAt) }}

      //-     el-table-column(label="Descrição")
      //-       template(slot-scope="scope")
      //-         span {{ transactionDescription(scope.row) }}

      //-     el-table-column(prop="type" label="Tipo", width="80")

      //-     el-table-column(prop="quantity", label="Quantidade" width="120")


  el-dialog(title="Adicionar novo item ao carrinho", :visible.sync="addCartItemDialogVisible")
    AddCartItem(ref="addCartItemDialog", :cartId="cart?.id", @itemAdded="cartItemAdded")

</template>
<script setup>
import CartService from '@/services/ecommerce/cart/CartService';
import { useMessage } from '@/utils/Message';
import { ref, watch } from 'vue';
import { dateTimeToLocaleDateTime } from '@/utils/DateTimeUtils';

import SearchList from '@/components/list/SearchList.vue';
import AddCartItem from '../actions/AddCartItem.vue';
import EditCartItem from '../actions/EditCartItem.vue';

const message = useMessage()

const props = defineProps({
  customerId: {
    type: Number,
    required: true
  }
})

const loading = ref(false)

const cartItemList = ref()

const cart = ref(null)

const addCartItemDialog = ref()
const addCartItemDialogVisible = ref(false)

const cartItemAdded = async () => {
  addCartItemDialogVisible.value = false

  await load()
  cartItemList.value?.reload()
}

const cartItemUpdated = async () => {
  await load()
  cartItemList.value?.reload()
}

const searchCartItems = async (searchOptions) => {
  return await CartService.searchCartItems(cart.value.id, searchOptions)
}

const load = async () => {
  loading.value = true

  try {
    cart.value = await CartService.getByCustomer(props.customerId)
  } catch (error) {
    message.error("Falha ao carregar carrinho")

    console.error(error)
  } finally {
    loading.value = false
  }
}

const openAddCartItemDialog = () => {
  addCartItemDialog.value?.reset()
  addCartItemDialogVisible.value = true
}

const removeCartItem = async (cartItemId) => {
  loading.value = true

  try {
    await CartService.removeItem(cart.value.id, cartItemId)

    await load()

    cartItemList.value?.reload()
  } catch (error) {
    message.error("Falha ao remover item do carrinho")

    console.error(error)
  } finally {
    loading.value = false
  }
}

const productVariantName = (productVariant) => {
  return `${productVariant.product.productBrand.name} - ${productVariant.product.name} ${productVariant.name} (#${productVariant.id})`
}

const productVariantLink = (productVariant) => {
  return `/ecommerce/product/products/${productVariant.product.id}?product-variant-id=${productVariant.id}`
}

watch(() => props.customerId, async () => {
  await load()
}, { immediate: true })
</script>
<style lang="sass">
.customer-cart

  i.link
    font-size: 14px
    color: #606266
    text-decoration: none
    vertical-align: middle
</style>