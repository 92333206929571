<template>
  <section class="subscription-box-edition-list">
    <snowflake-search-list context="subscription-box-edition" :service="subscriptionBoxEditionService">
      <template slot="tableRows">

        <!-- Edition -->
        <el-table-column prop="id" :label="$t('edition.edition')" sortable></el-table-column>

      </template>
    </snowflake-search-list>

  </section>
</template>

<script>
import SubscriptionBoxEditionService from '@/services/fulfillment/SubscriptionBoxEditionService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'subscription-box-edition-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      subscriptionBoxEditionService: SubscriptionBoxEditionService,
    };
  },
};
</script>
