<template>
  <div class="snowflake-header">
    <div class="menu">
      <router-link :to="{ name: 'logout' }">
        <el-button type="text">{{ 'Logout' }}</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'snowflake-header',
  props: {
    title: {
      type: String,
      default: 'Magenta',
    },
  },
};
</script>

<style lang="scss" scoped>
.snowflake-header {
  height: 60px;
  line-height: 60px;
  position: relative;
  box-sizing: border-box;

  .title {
    font-size: 30px;
    display: inline;
    margin-left: 15px;
    position: absolute;
  }

  .menu {
    display: inline;
    position: absolute;
    right: 0px;
    padding-right: 32px;
  }
}
</style>
