<template>
  <div>
    <el-select
      ref="select"
      class="stretched"
      v-model="searchValue"
      value-key="name"
      filterable
      remote
      :disabled="disabled"
      :placeholder="placeholder"
      @change="onChange"
      @focus="() => search(' ')"
      :filter-method="search"
      :loading="loading"
      :default-first-option="true">
      <el-option
        v-for="item in searchItems"
        :key="idFunction(item)"
        :label="labelFunction(item)"
        :value="valueFunction(item)">
      </el-option>
    </el-select>

    <div class="items-container">
      <div v-if="selectedItems.length > 0">
        <el-tag
          v-for="(item, index) in selectedItems"
          class="spaced"
          type="primary"
          :key="idFunction(item)">
          {{ labelFunction(item) }}
          <i
            class="el-tag__close el-icon-close"
            v-if="!disabled"
            @click.stop="removeItem(index)">
          </i>
        </el-tag>
      </div>
      <div v-else>
        <div class="no-items">Nada selecionado</div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'snowflake-remote-search-multi-select',
  props: {
    value: {
      type: Array,
      required: false,
    },
    searchFunction: {
      type: Function,
      required: true,
    },
    findFunction: {
      type: Function,
      required: true,
    },
    idFunction: {
      type: Function,
      default: item => item.id,
    },
    labelFunction: {
      type: Function,
      default: item => item.name,
    },
    valueFunction: {
      type: Function,
      default: item => item,
    },
    orderBy: String,
    sortDirection: {
      type: String,
      validator: value => value === 'ASC' || value === 'DESC',
      default: 'ASC',
    },
    customOptions: {
      type: Object,
    },
    maxResultSize: {
      type: Number,
      default: 30,
    },
    disabled: Boolean,
    placeholder: String,
  },
  data() {
    return {
      searchValue: null,
      searchItems: [],
      loading: false,
      selectedItems: [],
    };
  },
  watch: {
    value(val) {
      if (Array.isArray(val)) {
        this.find(val);
      }
    },
  },
  methods: {
    onChange(value) {
      if (!value) return;

      if (this.selectedItems.filter(item => this.idFunction(item) === this.idFunction(value)).length > 0) {
        this.$message('Item já selecionado');
      } else {
        this.selectedItems.push(value);
      }

      this.searchValue = null;
      this.searchItems = [];

      this.emitInput();

      this.$refs.select.focus()
    },
    removeItem(index) {
      this.selectedItems.splice(index, 1);

      this.emitInput();
    },
    search(query) {
      if (!query) {
        setTimeout(() => { this.searchItems = []; });

        return;
      }

      const queryOptions = {
        query,
        pageSize: this.maxResultSize,
        orderBy: this.orderBy !== null ? this.orderBy : undefined,
        sortDirection: this.sortDirection,
      };

      lodash.merge(queryOptions, this.customOptions);

      this.loading = true;

      this.searchFunction(queryOptions)
        .then((response) => {
          this.loading = false;

          this.searchItems = response.results;
        })
        .catch((error) => {
          this.loading = false;

          this.$message
            .error(`Falha ao buscar itens: ${error.description}`);
        });
    },
    find(ids) {
      this.loading = true;

      Promise.all(ids.map(id => this.findFunction(id)))
        .then((items) => {
          this.loading = false;
          this.selectedItems = items.map(item => this.valueFunction(item));
        }).catch((error) => {
          this.loading = false;

          this.$message
            .error(`Falha ao buscar itens: ${error.description}`);
        });
    },
    focus() {
      if (this.$refs.select) {
        this.$refs.select.focus();
      }
    },
    emitInput() {
      this.$emit('input', this.selectedItems.map(item => this.idFunction(item)));
      this.$emit('change', this.selectedItems.map(item => this.idFunction(item)));
    },
  },
  mounted() {
    if (this.value) {
      this.find(this.value);
    }
  },
};
</script>

<style scoped lang="scss">
i.el-icon-close {
  color: inherit;
}
div.items-container {
  text-align: left;
  border: #c1c1c1 dashed 1.5px;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px;

  div.no-items {
    text-align: center;
    font-size: 12;
    color: #5e7382;
  }

  .el-tag {
    margin: 4px;
  }
}
</style>
