import { apiPost, apiPut, apiGet } from './_common';

export default {
  getProfileByIdAndRevision(id, revision) {
    return apiGet(`/magenta/profile/${id}/revision/${revision}`).then(result => result.response);
  },
  create(request) {
    return apiPost('/magenta/profile', request).then(result => result.response);
  },
  update(profileId, request) {
    return apiPut(`/magenta/profile/${profileId}`, request)
      .then(result => result.response);
  },
};
