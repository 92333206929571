import { apiGet } from '../_common';

export default {
  search(query) {
    return apiGet('/magenta/billing/sku', query).then(result => result.response);
  },
  getSkuById(id) {
    return apiGet(`/magenta/billing/sku/${id}`).then(result => result.response);
  },
};
