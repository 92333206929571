<template lang="pug">
.edit-product-variant-option

  p Configure como está opção será exibida no produto:

  el-form(label-width="64px")

    el-row

      el-col(:span="12")
        el-form-item(label="Imagem")
          MediaInput(
            file-prefix="product-variant-option-thumbnail/",
            :value="value.image",
            @input="setImage",
            :width="96",
            :height="96",
            fit="cover",
            clearable
          )

      el-col(:span="12")
        el-form-item(label="Cor")
          el-color-picker(
            :value="value.color",
            @change="setColor"
          )
</template>
<script setup>
import MediaInput from '@/components/input/media/MediaInput.vue';
import { computed, ref, nextTick, defineProps, defineEmits, defineExpose } from 'vue'

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['input'])

const setColor = (color) => {
  emit('input', { ...props.value, color })
}

const setImage = (imageId) => {
  emit('input', { ...props.value, image: imageId })
}
</script>