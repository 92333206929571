<template>
  <section v-if="!loading" v-loading="saving">

    <el-header>
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isNew ? 'Nova entrada de estoque' : `Entrada de estoque #${stockEntry.id}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-form ref="form" :model="stockEntry" :rules="rules" label-width="120px">
      <!-- ID -->
      <el-form-item :label="$t('stockEntry.id')" prop="id">
        <el-input v-model="stockEntry.id" disabled></el-input>
      </el-form-item>

      <!-- Product -->
      <el-form-item :label="$t('stockEntry.productName')" prop="productId">
        <snowflake-remote-search-select
            v-model="stockEntry.productId"
            placeholder="Product"
            :search-function="productService.search"
            :find-function="productService.getProductById"
            :value-function="item => item.id"
            :disabled="!isNew">
          </snowflake-remote-search-select>
      </el-form-item>

      <!-- Type -->
      <el-form-item :label="$t('stockEntry.type')" prop="type">
        <snowflake-remote-search-select
            v-model="stockEntry.type"
            :placeholder="$t('stockEntry.type')"
            :search-function="stockEntryTypeService.search"
            :idFunction="item => item"
            :labelFunction="item => item"
            :find-function="typeResolver">
          </snowflake-remote-search-select>
      </el-form-item>

      <!-- Quantity -->
      <el-form-item :label="$t('stockEntry.quantity')" prop="quantity">
        <el-input-number
          v-model="stockEntry.quantity"
          controls-position="right"
          :step="1"
          :min="1"
          class="stretched"
          style="text-align: left;">
        </el-input-number>
      </el-form-item>

    </el-form>

  </section>
</template>
<script>
import StockEntryService from '@/services/stock/StockEntryService';
import ProductService from '@/services/stock/ProductService';
import StockEntryTypeService from '@/services/stock/StockEntryTypeService';

import SnowflakeRemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue';
import ServiceError from '@/errors/ServiceError';

export default {
  name: 'stock-entry-detail',
  components: {
    SnowflakeRemoteSearchSelect,
  },
  data() {
    return {
      productService: ProductService,
      stockEntryTypeService: StockEntryTypeService,
      rules: {
        productId: [
          { required: true, trigger: 'blur', message: this.$t('stockEntry.error.productRequired') },
        ],
        type: [
          { required: true, trigger: 'blur', message: this.$t('stockEntry.error.typeRequired') },
        ],
        quantity: [
          { required: true, trigger: 'blur', message: this.$t('stockEntry.error.quantityRequired') },
        ],
      },
      loading: true,
      saving: false,
      stockEntry: {},
    };
  },
  computed: {
    isNew() {
      return this.$route.name === 'stock-entry-new';
    },
  },
  async created() {
    if (this.isNew) {
      this.stockEntry = {};
      this.loading = false;

      return;
    }

    this.stockEntry = await StockEntryService.getStockEntryById(this.$route.params.id);
    this.loading = false;
  },
  methods: {
    async save() {
      this.saving = true;

      try {
        await this.$refs.form.validate();

        if (this.isNew) {
          this.stockEntry = await this.create();

          this.$router.push({ name: 'stock-entry-detail', params: { id: this.stockEntry.id } });
        } else {
          this.stockEntry = await this.update();
        }

        this.$message.success(this.$t('stockEntry.message.successfulSave'));
      } catch (e) {
        if (e instanceof ServiceError && e.status === 400) {
          this.$message.error(this.$t('stockEntry.message.stockViolation'));
        } else {
          this.$message.error(this.$t('stockEntry.message.failedToSave'));
        }
      } finally {
        this.saving = false;
      }
    },
    async typeResolver(value) {
      return value;
    },
    cancel() {
      this.$router.back();
    },
    create() {
      return StockEntryService.create(this.stockEntry);
    },
    update() {
      return StockEntryService.update(this.stockEntry.id, this.stockEntry);
    },
  },
};
</script>
