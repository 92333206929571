import { render, staticRenderFns } from "./PaymentProfileList.vue?vue&type=template&id=13593341&lang=pug&"
import script from "./PaymentProfileList.vue?vue&type=script&setup=true&lang=js&"
export * from "./PaymentProfileList.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./PaymentProfileList.vue?vue&type=style&index=0&id=13593341&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports