<template lang="pug">
.customer--add-additional-product

  el-dialog(
    title="Acrescentar produto adicional"
    :visible="visible"
    @update:visible="updateVisible($event)",
  )

    el-form(ref="form", :rules="rules", :model="data")

      p Selecione o produto adicional que deseja acrescentar:

      el-form-item(prop="productId")
        RemoteSearchSelect(
          v-model="data.productId"
          placeholder="Produto adicional"
          :search-function="ProductService.search"
          :find-function="ProductService.getProductById"
          :value-function="(item) => item.id",
          :label-function="(item) => `${item.name} (#${item.id})`"
        )

      p
        b Atenção: nenhuma validação ou operação de estoque é feita ao acrescentar este produto.

    .footer(slot="footer")
      el-popconfirm(title="Tem certeza de que deseja acrescentar este produto adicional?", @confirm="save")
        el-button(
          slot="reference",
          title="Acrescentar produto adicional",
          type="primary",
          icon="el-icon-plus",
          :loading="loading"
        ) Acrescentar

</template>
<script setup>
import ProductService from '@/services/stock/ProductService';
import { ref, watch } from 'vue';

import RemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue'
import { useMessage } from '@/utils/Message';
import SubscriptionBoxOrderAdditionalRulesService from '@/services/fulfillment/SubscriptionBoxOrderAdditionalRulesService';

const props = defineProps({
  additionalRulesId: {
    type: Number,
    required: true
  },
  visible: Boolean
})

const emit = defineEmits(['update:visible', 'executed'])

const updateVisible = (value) => {
  emit('update:visible', value)
}

const message = useMessage()

const data = ref({ productId: null })
const form = ref()
const rules = ref({
  productId: {
    required: true, message: "Escolha um produto"
  }
})

const loading = ref(false)

const save = async () => {
  loading.value = true

  try {
    await form.value.validate()
  } catch(w) {
    loading.value = false
    return
  }

  try {
    await SubscriptionBoxOrderAdditionalRulesService.addPendingAdditionalProduct(
      props.additionalRulesId,
      { productId: data.value.productId }
    )

    message.success(`Produto #${data.value.batchId} acrescentado com sucesso`)

    updateVisible(false)

    emit('executed')
  } catch (e) {
    message.error("Falha ao acrescentar produto adicional. Por favor, tente novamente")

    throw e
  } finally {
    loading.value = false
  }
}

watch(() => props.visible, (value) => {
  if (!value) {
    data.value = { productId: null }
    loading.value = false
    form.value.resetFields()
  }
})
</script>