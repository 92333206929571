import { apiGet, apiPatch, apiPost } from '../_common';

export default {
  getBalance(customerId) {
    return apiGet(`/magenta/customer/${customerId}/promotions/rewards/balance`)
      .then(result => result.response);
  },
  createReward(request) {
    return apiPost('/magenta/promotions/rewards', request)
    .then(result => result.response);
  },
  updateReward(rewardId, request) {
    return apiPatch(`/magenta/promotions/rewards/${rewardId}`, request)
    .then(result => result.response);
  },
  findReward(rewardId) {
    return apiGet(`/magenta/promotions/rewards/${rewardId}`)
      .then(result => result.response);
  },
  searchRewards(query) {
    return apiGet('/magenta/promotions/rewards', query)
      .then(result => result.response);
  },
  searchRedeems(query) {
    return apiGet('/magenta/promotions/rewards/redeem', query)
      .then(result => result.response);
  },
  searchTransactions(query) {
    return apiGet('/magenta/promotions/rewards/transaction', query)
      .then(result => result.response);
  },
  createTransaction(request) {
    return apiPost('/magenta/promotions/rewards/transaction', request)
    .then(result => result.response);
  },
  rewardTypeTranslation(type) {
    switch(type) {
      case "DISCOUNT":
        return "Desconto"
      case "PRODUCT_CREDIT":
        return "Produto extra"
      case "PRODUCT":
        return "Produto"
      default:
        return type
    }
  }
};
