<template>
  <section class="order-production-batch-list">

    <snowflake-search-list context="ecommerce-order-production-batch"
      :service="orderProductionBatchService"
      defaultSortColumn="id"
      defaultSortDirection="DESC">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>

        <!-- Name -->
        <el-table-column prop="name" label="Nome" :show-overflow-tooltip="true" sortable></el-table-column>

        <!-- Pedidos -->
        <el-table-column prop="orders" label="Nº de Pedidos" width="160">
          <template slot-scope="scope">
            {{ scope.row.orderPackages.length }}
          </template>
        </el-table-column>

        <!-- Created At -->
        <el-table-column prop="createdAt" label="Data de Criação" width="160" sortable>
          <template slot-scope="scope">
            <span>{{ fromSystemDate(scope.row.createdAt) }}</span>
          </template>
        </el-table-column>

      </template>
    </snowflake-search-list>


    <iframe
        :src="availablePackagesChartUrl"
        frameborder="0"
        height="320"
        style="width: 100%; margin-top: 32px"
        allowtransparency
    ></iframe>

  </section>
</template>

<script>
import moment from 'moment';

import OrderProductionBatchService from '@/services/ecommerce/order/OrderProductionBatchService';

import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'ecommerce-order-production-batch-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      orderProductionBatchService: OrderProductionBatchService,

      availablePackagesChartUrl: null,
    };
  },
  async created() {
    // this.availablePackagesChartUrl = (await OrderProductionBatchService.getAvailablePackagesChartUrl()).url;
  },
  methods: {
    fromSystemDate(value) {
      return value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : null;
    },
  },
};
</script>
