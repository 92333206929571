import moment from 'moment'
import 'moment-timezone'

export const dateToLocaleDate = (dateString) => {
  if (dateString == null || dateString == undefined) {
    return ''
  }

  return moment.utc(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY')
}

export const dateTimeToLocaleDateTime = (dateTimeString) => {
  if (dateTimeString == null || dateTimeString == undefined) {
    return ''
  }

  return moment.utc(dateTimeString, 'YYYY-MM-DDTHH:mm:ss').tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')
}

export function fromSystemDate(value) {
  return value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
}
