import { apiPost } from './_common';

export const signIn = async (email, password) => {
  const payload = {
    emailPassword: {
      email,
      password,
    },
  };

  return apiPost('/magenta/auth/sign-in', payload).then(result => result.response);
};

export default signIn;
