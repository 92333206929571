<template lang="pug">
.internal-notes(v-loading="loading")
  div(style="text-align: center")
    el-button(icon="el-icon-plus" circle @click="openInternalNoteDialog()")

  el-timeline.timeline
    el-timeline-item(
      v-for="internalNote in internalNotes"
      :key="internalNote.id"
      :timestamp="fromSystemDate(internalNote.occurredAt)"
      placement="top"
    )
      el-card(shadow="hover" class="note-item" @click.native="openInternalNoteDialog(internalNote)")
        .note {{internalNote.note}}
        .creator {{internalNoteCreatorText(internalNote)}}

  // Dialog - Internal Note
  el-dialog(
    class="text-left-aligned"
    :title="internalNoteData && internalNoteData.id ? 'Editar anotação' : 'Incluir anotação'"
    :visible.sync="internalNoteDialogVisible"
    v-loading="saving"
  )

    el-form(
      ref="internalNoteForm"
      label-width="180px"
      label-position="left"
      :rules="internalNoteFormRules"
      :model="internalNoteData"
    )

      // Occurred At
      el-form-item(label="Data da anotação" prop="occurredAt")
        el-date-picker(
          v-model="internalNoteData.occurredAt"
          placeholder="Data da anotação"
        )

      // Note
      el-form-item(label="Anotação" prop="note")
        el-input(
          type="textarea"
          :rows="6"
          resize="none"
          placeholder="Descreva a anotação"
          v-model="internalNoteData.note"
        )

    span.dialog-footer(slot="footer")
      el-button(@click="closeInternalNoteDialog", :disabled="saving") {{ 'Voltar' }}
      el-button(type="primary" @click="saveInternalNote", :loading="saving") {{ 'Salvar' }}

</template>
<script setup>
import moment from 'moment';
import { onMounted, ref } from 'vue';

import AdminService from '@/services/AdminService';
import CustomerService from '@/services/CustomerService';
import InternalNoteService from '@/services/InternalNoteService'
import { useMessage } from '@/utils/Message';
import { fromSystemDate } from '@/utils/DateTimeUtils';

const props = defineProps({
  customerId: { type: Number, required: true },
});

const message = useMessage();

const loading = ref(false);
const saving = ref(false);

const internalNotes = ref([]);
const indexedAdmins = ref({})

const internalNoteForm = ref();
const internalNoteData = ref({});
const internalNoteFormRules = {
  occurredAt: { required: true, message: 'Informe uma data para a anotação.' },
  note: { required: true, message: 'Descreva a anotação.' },
};

const internalNoteDialogVisible = ref(false);

const internalNoteCreatorText = (internalNote) => {
  const creator = indexedAdmins.value[internalNote.createdBy];
  const creatorName = creator ? `${creator.firstName} ${creator.lastName}` : 'Sistema';
  const date = moment.utc(internalNote.updatedAt).local().format('DD/MM/YYYY HH:mm');

  return `${creatorName} em ${date}`;
}

const closeInternalNoteDialog = () => {
  internalNoteDialogVisible.value = false;
}

const openInternalNoteDialog = (internalNote) => {
  internalNoteForm.value?.resetFields();

  internalNoteData.value = internalNote ? { ...internalNote } : { occurredAt: moment().local().startOf('day').toDate() };

  console.log(internalNote, internalNoteData.value);

  internalNoteDialogVisible.value = true;
}

const loadInternalNotes = async () => {
  loading.value = true;
  internalNotes.value = await CustomerService.getCustomerInternalNotes(props.customerId);

  const adminIds = new Set();
  internalNotes.value.forEach((internalNote) => {
    if (internalNote.createdBy) {
      adminIds.add(internalNote.createdBy);
    }
  });

  const promises = [...adminIds].map(adminId => AdminService.getById(adminId));
  const admins = await Promise.all(promises);

  indexedAdmins.value = Object.fromEntries(
    admins.map(admin => [admin.id, admin]),
  );

  loading.value = false;
}

const saveInternalNote = async () => {
  try {
    await internalNoteForm.value.validate();

    saving.value = true;

    if (internalNoteData.value.id) {
      const request = {
        note: internalNoteData.value.note,
        occurredAt: internalNoteData.value.occurredAt,
      };
      await InternalNoteService.update(internalNoteData.value.id, request);
    } else {
      const request = {
        customerId: props.customerId,
        note: internalNoteData.value.note,
        occurredAt: internalNoteData.value.occurredAt,
      };
      await InternalNoteService.create(request);
    }

    message.success('Interação salva com sucesso!');
    internalNoteDialogVisible.value = false;

    loadInternalNotes();
  } catch (e) {
    console.error(e);
    message.error('Falha ao registrar interação');
  } finally {
    saving.value = false;
  }
}

onMounted(async () => {
  await loadInternalNotes();
});
</script>
<style lang="scss">
.internal-notes {
  .timeline {
    padding: 5px;
    text-align: left;

    .note {
      font-weight: 700;
      white-space: pre;
    }

    .creator {
      margin-top: 15px;
      font-size: 12px;
      color: #8f8f8f;
    }

    .note-item:hover {
      cursor: pointer;
    }
  }
}
</style>
