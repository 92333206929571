import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/fulfillment/subscription-box-order-additional-rules', request)
      .then(result => result.response);
  },
  update(id, request) {
    return apiPut(`/magenta/fulfillment/subscription-box-order-additional-rules/${id}`, request)
      .then(result => result.response);
  },
  getById(id) {
    return apiGet(`/magenta/fulfillment/subscription-box-order-additional-rules/${id}`)
      .then(result => result.response);
  },
  getByCustomerId(customerId) {
    return apiGet(`/magenta/fulfillment/subscription-box-order-additional-rules/customer/${customerId}`)
      .then(result => result.response);
  },
  addPendingAdditionalProduct(id, request) {
    return apiPost(`/magenta/fulfillment/subscription-box-order-additional-rules/${id}/pending-additional-products/add`, request)
      .then(result => result.response);
  },
  removePendingAdditionalProduct(id, request) {
    return apiPost(`/magenta/fulfillment/subscription-box-order-additional-rules/${id}/pending-additional-products/remove`, request)
      .then(result => result.response);
  },
};
