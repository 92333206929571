<template lang="pug">
section.product-category-edit(v-if="!loading", v-loading="saving")
  el-header
    el-col(:span="16")
      el-page-header(@back="back", :content="productCategory && productCategory.name ? productCategory.name : 'Nova categoria de produtos'")

    el-col(:span="8", style="text-align: right")
      el-button(@click="save", type="primary", :loading="saving") Salvar

  el-form(ref="form", :model="productCategory", :rules="rules", label-width="160px")
    el-form-item(label="ID", prop="id")
      el-input(v-model="productCategory.id", disabled)
        template(slot="prepend")
          span(v-if="productCategory.id") #

    el-form-item(label="Subcategoria de", prop="parentProductCategoryId")
      RemoteSearchSelect(
        v-model="productCategory.parentProductCategoryId",
        placeholder="",
        :search-function="productCategoryService.search"
        :find-function="findParentCategory"
        :value-function="(item) => item.id",
        :label-function="(item) => item.canonicalName",
        :disabled="!isNew",
        @itemChanged="parentProductCategoryChanged",
        clearable
      )

    el-form-item(label="Nome", prop="name")
      el-input(v-model="productCategory.name", maxlength="250", show-word-limit)

    el-form-item(label="Descrição", prop="description")
      HtmlEditor(
        v-model="productCategory.htmlDescription",
        @input:text="value => productCategory.description = value",
      )

  el-divider(contentPosition="left") Atributos

  EcommerceProductCategoryAttributeList(ref="attributeList", :inheritedAttributes="inheritedAttributes", v-model="productCategory.attributes")


</template>
<script setup>
import RemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue'

import productCategoryService from '@/services/ecommerce/product/ProductCategoryService'
import { onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useMessage } from '@/utils/Message'
import EcommerceProductCategoryAttributeList from './product-category-attribute/EcommerceProductCategoryAttributeList.vue';
import HtmlEditor from '@/components/input/HtmlEditor.vue';

// Utils
const route = useRoute()
const router = useRouter()
const message = useMessage()

// Form
const form = ref()
const rules = ref({
  name: [ { required: true, trigger: 'blur', message: 'Nome obrigatório' } ],
})

// Entity data
const attributeList = ref()
const productCategory = ref({ attributes: [] })
const parentProductCategory = ref(null)

const categoryId = computed(() => route.params["id"])
const isNew = computed(() => !categoryId.value)

const inheritedAttributes = computed(() => {
  if (parentProductCategory.value) {
    return parentProductCategory.value.hierarchyAttributes
  } else {
    return []
  }
})

// UI state
const loading = ref(true)
const saving = ref(false)

// UI actions
const parentProductCategoryChanged = (value) => {
  parentProductCategory.value = value
}

const loadCategory = async () => {
  if (!categoryId.value) {
    loading.value = false

    return
  }

  loading.value = true

  try {
    productCategory.value = (await productCategoryService.find(categoryId.value)) ?? {}
  } catch(e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const findParentCategory = async (id) => {
  const searchResult = await productCategoryService.search({ filters: `id:${id}`, pageSize: 1 })

  if (searchResult.count == 1) {
    return searchResult.results[0]
  } else {
    return null
  }
}

const save = async () => {
  try {
    await form.value.validate()
    await attributeList.value.validate()
  } catch(e) {
    message.warning("Verifique os dados")

    return
  }

  saving.value = true

  try {
    if (isNew.value) {
      productCategory.value = await productCategoryService.create({
        parentProductCategoryId: productCategory.value.parentProductCategoryId,
        name: productCategory.value.name,
        description: productCategory.value.description,
        htmlDescription: productCategory.value.htmlDescription,
        attributes: productCategory.value.attributes
      })

      router.replace({ name: "ecommerce-product-category-detail", params: { id: productCategory.value.id }})
    } else {
      productCategory.value = await productCategoryService.update(categoryId.value, {
        name: productCategory.value.name,
        description: productCategory.value.description,
        htmlDescription: productCategory.value.htmlDescription,
        attributes: productCategory.value.attributes
      })
    }

    message.success("Salvo com sucesso")
  } catch (e) {
    message.error(`Falha ao salvar: ${e}`)
  } finally {
    saving.value = false;
  }
}

const back = () => {
  router.push({ name: "ecommerce-product-category-list" })
}

onMounted(async () => {
  loadCategory();
})
</script>