import { apiPost } from '../_common';

export default {
  generateNFe(orderIds, next) {
    const request = {
      orderIds,
      next,
    };

    return apiPost('/magenta/fulfillment/nfe', request)
      .then(result => result.response);
  },
  generateDANFe(shipmentId, orderIds) {
    const request = {
      shipmentId,
      orderIds,
    };

    return apiPost('/magenta/fulfillment/nfe/danfe', request)
      .then(result => result.response);
  },
};
