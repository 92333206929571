<template lang="pug">
.subscription-box-order-fraud-review(v-if="!loading", :class="{ embedded: !showActions }")

  el-header(v-if="showActions")
    el-row
      el-col(:span="18")
        el-page-header(:content="title", @back="back")

      el-col(:span="6", style="text-align: right")
        el-button-group
          el-button(type="success", @click="approve") Aprovar
          el-button(type="danger", @click="reject") Reprovar

  el-row(v-else)
    el-col(:span="24")
      h3 {{ title }}

  el-row(v-if="showActions")
    el-col(:span="24", style="text-align: right;")
      a(:href="kondutoUrl", target="_blank")
        el-button(type="text", size="small") Ver na Konduto

  el-tabs(tab-position="left")

    el-tab-pane(label="Dados do pedido", lazy)
      .tab-content
        SubscriptionBoxOrderDetail(:id="orderId", :show-header="false", tab-position="top")

    el-tab-pane(label="Dados do cliente", v-if="customerId", lazy)
      .tab-content
        CustomerDetail(:id="customerId", :show-header="false", tab-position="top")

    el-tab-pane(label="Dados da assinatura", v-if="subscriptionId", lazy)
      .tab-content
        SubscriptionDetail(:id="subscriptionId", :show-header="false", tab-position="top")

</template>
<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import CustomerDetail from '@/views/customer/Detail.vue'
import SubscriptionDetail from '@/views/billing/subscription/Detail.vue'
import SubscriptionBoxOrderDetail from '../SubscriptionBoxOrderDetail.vue'
import SubscriptionBoxOrderService from '@/services/fulfillment/SubscriptionBoxOrderService'
import InvoiceService from '@/services/billing/InvoiceService'
import CustomerService from '@/services/CustomerService'
import { useMessage } from '@/utils/Message'
import { useConfirm } from '@/utils/MessageBox'

const router = useRouter()

const message = useMessage()
const confirm = useConfirm()

const props = defineProps({
  id: {
    required: true,
  },
  showActions: {
    type: Boolean,
    default: false,
  },
})

const orderId = computed(() => props.id)

const loading = ref(true)
const customerId = ref()
const customer = ref()
const subscriptionId = ref()

const title = computed(() => {
  if (customer.value) {
    return `${customer.value.firstName} ${customer.value.lastName} - Pedido #${orderId.value}`
  }
  return `Revisão do pedido #${orderId.value}`
})

const kondutoUrl = computed(() => {
  return `https://app.konduto.com/orders/48416/${orderId.value}`
})

const approve = () => {
  confirm('Deseja aprovar o pedido?', 'Aprovar pedido', {
    confirmButtonText: 'Aprovar',
    cancelButtonText: 'Cancelar',
    type: 'success',
  }).then(async () => {
    try {
      await SubscriptionBoxOrderService.approveFraudReview(orderId.value)

      message.success('Pedido aprovado com sucesso')
      back()
    } catch(e) {
      message.error('Falha ao aprovar pedido')
    }
  })
}

const reject = () => {
confirm('Deseja reprovar o pedido?', 'Reprovar pedido', {
    confirmButtonText: 'Reprovar',
    cancelButtonText: 'Cancelar',
    type: 'danger',
  }).then(async () => {
    try {
      await SubscriptionBoxOrderService.rejectFraudReview(orderId.value)
      message.success('Pedido reprovado com sucesso')
      back()
    } catch(e) {
      message.error('Falha ao reprovar pedido')
    }
  })
}

const back = () => {
  router.push({ name: 'subscription-box-order-fraud-review-list' })
}

onMounted(async () => {
  try {
    const order = await SubscriptionBoxOrderService.getSubscriptionBoxOrderById(props.id)
    const invoice = order.invoiceId ? await InvoiceService.getInvoiceById(order.invoiceId) : null

    customerId.value = order.customerId
    subscriptionId.value = invoice?.subscriptionId

    if (customerId.value) {
      customer.value = await CustomerService.getCustomerById(customerId.value)
    }
  } catch(e) {
    message.error('Falha ao carregar dados do pedido')

    router.back()
  } finally {
    loading.value = false
  }
})
</script>
<style lang="sass" scoped>
.subscription-box-order-fraud-review

  &.embedded
    position: relative
    height: calc(100vh - 48px)
    padding: 32px 16px 16px 16px

    .tab-content
      max-height: 606px
      overflow: auto

  .tab-content
    padding: 0 16px
</style>
<style lang="sass">
.subscription-box-order-fraud-review.embedded

  .profile-questionnaire
    position: relative
    max-height: 552px
    overflow: auto
</style>
