<template>
  <section v-if="!loading" v-loading="saving">
    <el-header>
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isNew ? 'Novo cupom de desconto' : `Cupom de desconto ${coupon.id}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-form ref="form" :model="coupon" :rules="rules" label-width="160px">

      <!-- ID -->
      <el-form-item :label="$t('coupon.id')" prop="id">
        <el-input v-model="coupon.id" :disabled="!isNew"></el-input>
      </el-form-item>

      <!-- Amount -->
      <el-form-item
        :label="$t('coupon.amount')"
        prop="amount"
        :error="showError ? amountPercentageRequired : undefined"
      >
        <el-input-number
          v-model="coupon.amount"
          controls-position="right"
          :precision="2"
          :step="0.1"
          :min="0"
          class="stretched"
          :disabled="!isNew"
        ></el-input-number>
      </el-form-item>

      <!-- Percentage -->
      <el-form-item
        :label="$t('coupon.percentage')"
        prop="percentage"
        :error="showError ? amountPercentageRequired : undefined"
      >
        <el-input-number
          v-model="coupon.percentage"
          controls-position="right"
          :step="1"
          :min="0"
          :max="100"
          class="stretched"
          :disabled="!isNew"
        ></el-input-number>
      </el-form-item>

      <!-- Active -->
      <el-form-item :label="$t('coupon.active')" prop="active" style="text-align: left;">
        <el-switch v-model="coupon.active"></el-switch>
      </el-form-item>

    </el-form>

  </section>
</template>
<script>
import CouponService from '@/services/billing/CouponService';

export default {
  name: 'coupon-detail',
  data() {
    return {
      rules: {
        id: [
          { required: true, trigger: 'blur', message: this.$t('coupon.error.idRequired') },
        ],
      },
      showError: false,
      loading: true,
      saving: false,
      coupon: {
        active: true,
      },
    };
  },
  watch: {
    'coupon.id': function couponIdWatcher(value) {
      this.coupon.id = value.replace(/[\W_]/g, '').toUpperCase();
    },
  },
  computed: {
    isNew() {
      return this.$route.name === 'coupon-new';
    },
    amountPercentageRequired() {
      if ((!this.coupon.amount && !this.coupon.percentage)
        || (this.coupon.amount && this.coupon.percentage)) {
        return this.$t('coupon.error.amountPercentageRequired');
      }

      return null;
    },
  },
  async created() {
    if (this.isNew) {
      this.coupon = {
        active: true,
      };
      this.loading = false;

      return;
    }

    const savedCoupon = await CouponService.getCouponById(this.$route.params.id);
    this.coupon = this.toCoupon(savedCoupon);

    this.loading = false;
  },
  methods: {
    async save() {
      this.saving = true;

      try {
        await this.$refs.form.validate();

        if (this.amountPercentageRequired) {
          this.showError = true;
          throw Error(this.$t('coupon.error.amountPercentageRequired'));
        }

        if (this.isNew) {
          const savedCoupon = await this.create();
          this.coupon = this.toCoupon(savedCoupon);

          this.$router.push({ name: 'coupon-detail', params: { id: this.coupon.id } });
        } else {
          const savedCoupon = await this.update();
          this.coupon = this.toCoupon(savedCoupon);
        }

        this.$message.success(this.$t('coupon.message.successfulSave'));
      } catch (e) {
        this.$message.error(this.$t('coupon.message.failedToSave'));
      } finally {
        this.saving = false;
      }
    },
    toCoupon(value) {
      return {
        ...value,
        amount: value.amount / 100,
        percentage: value.percentage * 100,
      };
    },
    cancel() {
      this.$router.back();
    },
    create() {
      const payload = {
        ...this.coupon,
        currency: 'BRL',
        amount: this.coupon.amount ? Math.round(this.coupon.amount * 100) : undefined,
        percentage: this.coupon.percentage ? (this.coupon.percentage / 100).toFixed(2) : undefined,
      };
      return CouponService.create(payload);
    },
    update() {
      const payload = {
        active: this.coupon.active,
      };
      return CouponService.update(this.coupon.id, payload);
    },
  },
};
</script>
