<template>
  <el-aside class="side-menu" :width="collapse ? '65px': '240px'">
    <el-menu
      class="menu"
      background-color="#409EFF"
      text-color="#fff"
      active-text-color="#ffd04b"
      :collapse="collapse"
      :collapse-transition="false">

      <!-- Customer -->
      <router-link
        v-if="shouldShow(['financial', 'customer-happiness', 'operations-lead'])"
          :to="{ name: 'customer-list' }"
        >
        <el-menu-item index="1">
          <i class="icon">
            <font-awesome-icon icon="users" />
          </i>
          <span slot="title">{{ 'Clientes' }}</span>
        </el-menu-item>
      </router-link>

      <!-- Edition -->
      <router-link v-if="shouldShow(['financial'])" :to="{ name: 'subscription-box-edition-list' }">
        <el-menu-item index="2">
          <i class="icon">
            <font-awesome-icon icon="list-ol" />
          </i>
          <span slot="title">{{ 'Edições' }}</span>
        </el-menu-item>
      </router-link>

      <el-submenu
        v-if="shouldShow(['financial', 'warehouse-reception', 'operations-lead'])"
        index="subscription-box-fulfillment"
      >
        <template slot="title">
          <i class="icon">
            <font-awesome-icon icon="industry"></font-awesome-icon>
          </i>
          <span>Produção</span>
        </template>

        <!-- Production Batch -->
        <router-link
          v-if="shouldShow(['financial', 'warehouse-reception', 'operations-lead'])"
          :to="{ name: 'subscription-box-production-batch-list' }"
        >
          <el-menu-item index="subscription-box-production-batch-list">
            <span slot="title">Lotes de montagem</span>
          </el-menu-item>
        </router-link>


        <!-- Subscription Box Order -->
        <router-link
          v-if="shouldShow(['financial', 'warehouse-reception', 'operations-lead'])"
          :to="{ name: 'subscription-box-order-list' }"
        >
          <el-menu-item index="subscription-box-order-list">
            <span slot="title">Pedidos</span>
          </el-menu-item>
        </router-link>

      <!-- Stock Physical Address Search -->
      <router-link
        v-if="shouldShow(['warehouse-reception', 'operations-lead'])"
        :to="{ name: 'stock-physical-address' }"
      >
        <el-menu-item index="10">
          <span slot="title">Controle de Estoque Físico</span>
        </el-menu-item>
      </router-link>


      <!-- Register Return -->
      <router-link v-if="shouldShow(['warehouse-reception', 'operations-lead'])" :to="{ name: 'register-return' }">
        <el-menu-item index="10">
          <span slot="title">Registrar Retorno</span>
        </el-menu-item>
      </router-link>

      </el-submenu>

      <!-- Product Catalog -->
      <el-submenu
        v-if="shouldShow(['financial', 'customer-happiness', 'marketing'])"
        index="catalog">

        <template slot="title">
          <i class="icon">
            <font-awesome-icon icon="swatchbook"></font-awesome-icon>
          </i>
          <span>Catálogo</span>
        </template>

        <router-link v-if="shouldShow(['financial'])" :to="{ name: 'product-brand-list' }">
          <el-menu-item index="3">
            <span slot="title">{{ 'Marcas' }}</span>
          </el-menu-item>
        </router-link>

        <!-- Products -->
        <router-link v-if="shouldShow(['financial', 'customer-happiness', 'marketing'])" :to="{ name: 'product-list' }">
          <el-menu-item index="4">
            <span slot="title">{{ 'Produtos' }}</span>
          </el-menu-item>
        </router-link>

      </el-submenu>

      <EcommerceSideMenu v-if="ecommerceEnabled && shouldShow(['financial'])"></EcommerceSideMenu>

      <!-- Submenu for stock entries and stock summary -->
      <el-submenu
        v-if="shouldShow(['financial', 'customer-happiness', 'operations-lead'])"
        index="stock"
      >
        <template slot="title">
          <i class="icon">
            <font-awesome-icon icon="layer-group"></font-awesome-icon>
          </i>
          <span>Estoque</span>
        </template>

        <!-- Stock Entries -->
        <router-link v-if="shouldShow(['financial', 'operations-lead'])" :to="{ name: 'stock-entry-list' }">
          <el-menu-item index="5">
            <span slot="title">{{ 'Entradas de Estoque' }}</span>
          </el-menu-item>
        </router-link>

        <!-- Stock Summary -->
        <router-link
          v-if="shouldShow(['financial', 'customer-happiness', 'operations-lead'])"
          :to="{ name: 'stock-summary' }"
        >
          <el-menu-item index="6">
            <span slot="title">{{ 'Sumário de Estoque' }}</span>
          </el-menu-item>
        </router-link>


        <!-- Bling -->
        <router-link
          v-if="shouldShow(['financial'])"
          :to="{ name: 'bling-purchase-order-exporter' }"
        >
          <el-menu-item>
            <span slot="title">Bling - Pedido de Compra</span>
          </el-menu-item>
        </router-link>

      </el-submenu>

      <!-- Marketing -->
      <MarketingSideMenu v-if="shouldShow(['marketing', 'operations-lead'])"></MarketingSideMenu>

      <!-- Product Arrival Report -->
      <router-link
        v-if="shouldShow(['warehouse-reception', 'financial', 'operations-lead'])"
        :to="{ name: 'product-arrival-report-list' }"
      >
        <el-menu-item index="11">
          <i class="icon">
            <font-awesome-icon icon="shipping-fast" />
          </i>
          <span slot="title">Notificações de Chegada</span>
        </el-menu-item>
      </router-link>

      <!-- Charge -->
      <router-link
        v-if="shouldShow(['financial', 'customer-happiness'])"
          :to="{ name: 'charge-list' }"
        >
        <el-menu-item index="12">
          <i class="icon">
            <font-awesome-icon icon="file-invoice-dollar" />
          </i>
          <span slot="title">{{ 'Cobranças' }}</span>
        </el-menu-item>
      </router-link>

    </el-menu>

    <div class="collapse">
      <el-button
        :icon="collapse ? 'el-icon-d-arrow-right': 'el-icon-d-arrow-left'"
        @click="toggleCollapse">
      </el-button>
    </div>
  </el-aside>
</template>

<script>
import EcommerceSideMenu from '@/views/ecommerce/EcommerceSideMenu.vue';
import MarketingSideMenu from '@/views/marketing/MarketingSideMenu.vue';
import Storage from '../storage';

const ecommerceEnabled = process.env.VUE_APP_ECOMMERCE_ENABLED === 'true';

const COLLAPSE_STORAGE_KEY = "side-menu.collapse";

export default {
  name: 'snowflake-side-menu',
  data() {
    return {
      collapse: true,
    };
  },
  components: {
    EcommerceSideMenu,
    MarketingSideMenu
  },
  computed: {
    ecommerceEnabled() {
      return ecommerceEnabled;
    },
  },
  methods: {
    toggleCollapse() {
      this.collapse = !this.collapse;

      Storage.add(COLLAPSE_STORAGE_KEY, this.collapse);
    },
    authRoles() {
      const encodedRole = Storage.get(Storage.keys.auth.role);
      const encodedRoles = Storage.get(Storage.keys.auth.roles);

      return encodedRoles ? JSON.parse(atob(encodedRoles)) : (encodedRole ? [atob(encodedRole)] : []);
    },
    shouldShow(authorizedRoles = []) {
      const roles = this.authRoles();

      if (roles.includes('owner') || roles.includes('admin')) return true;

      for (var i = 0; i < roles.length; i++) {
        if (authorizedRoles.includes(roles[i])) return true;
      }

      return false;
    },
  },
  beforeMount() {
    this.collapse = Storage.get(COLLAPSE_STORAGE_KEY) == "true";
  }
};
</script>

<style lang="scss" scoped>
.side-menu {
  background-color: #409EFF;
  transition: width .1s;
  position: relative;

  .menu {
    box-sizing: border-box;
    border-right: none;

    .el-menu-item {
      .icon {
        margin-right: 8px;
        display: inline-block;
        width: 20px;
        text-align: center;
        color: #fff;
      }
    }
  }

  .collapse {
    position: absolute;
    bottom: 13px;
    right: 5px;

    .el-button {
      background: none;
      color: #fff;
      border: none;
    }
  }

}
</style>
<style lang="scss">
.side-menu {
  a {
    text-decoration: none;
    color: #acd;
  }

  .el-submenu {
    .el-submenu__title i {
      color: white;

      &.icon {
        margin-right: 8px;
        display: inline-block;
        width: 20px;
        text-align: center;
        color: #fff;
      }
    }
  }
}

.el-menu--popup a {
  text-decoration: none;
}
</style>