import { apiGet, apiPost } from '../_common';

export default {
  search(query) {
    return apiGet('/magenta/fulfillment/subscription-box-order', query)
      .then(result => result.response);
  },
  getSubscriptionBoxOrderById(id) {
    return apiGet(`/magenta/fulfillment/subscription-box-order/${id}`)
      .then(result => result.response);
  },
  getSubscriptionBoxOrderProducts(id) {
    return apiGet(`/magenta/fulfillment/subscription-box-order/${id}/products`)
      .then(result => result.response);
  },
  cancelSubscriptionBoxOrder(id) {
    return apiPost(`/magenta/fulfillment/subscription-box-order/${id}/cancel`)
      .then(result => result.response);
  },
  recreateSubscriptionBoxOrder(id, request) {
    return apiPost(`/magenta/fulfillment/subscription-box-order/${id}/recreate`, request)
      .then(result => result.response);
  },
  approveFraudReview(id) {
    return apiPost(`/magenta/fulfillment/subscription-box-order/${id}/fraud-review/approve`)
      .then(result => result.response);
  },
  rejectFraudReview(id) {
    return apiPost(`/magenta/fulfillment/subscription-box-order/${id}/fraud-review/reject`)
      .then(result => result.response);
  },
  declineFraudReview(id) {
    return apiPost(`/magenta/fulfillment/subscription-box-order/${id}/fraud-review/decline`)
      .then(result => result.response);
  },
  sync(orderIds) {
    return apiPost('/magenta/fulfillment/subscription-box-order/sync', { orderIds })
      .then(result => result.response);
  },
  getCountAvailableForNextBatch() {
    return apiGet('/magenta/fulfillment/subscription-box-order/available-for-batch')
      .then(result => result.response);
  },
  findProductionBatches(id) {
    return apiGet(`/magenta/fulfillment/subscription-box-order/${id}/production-batches`)
      .then(result => result.response);
  },
  addAdditionalProduct(id, request) {
    return apiPost(`/magenta/fulfillment/subscription-box-order/${id}/additional-products/add`, request)
      .then(result => result.response);
  },
  removeAdditionalProduct(id, request) {
    return apiPost(`/magenta/fulfillment/subscription-box-order/${id}/additional-products/remove`, request)
      .then(result => result.response);
  },
  statusTranslation: {
    CANCELED: 'Cancelado',
    NEW: 'Novo',
    PRODUCT_SELECTION_FAILED: 'Seleção de produtos falhou',
    PRODUCTS_SELECTED: 'Produtos selecionados',
    IN_PRODUCTION: 'Em produção',
    READY_TO_POST: 'Pronto para postagem',
    IN_TRANSIT: 'Em trânsito',
    AWAITING_CUSTOMER_PICKUP: 'Aguardando cliente coletar',
    DELIVERED: 'Entregue',
    RETURNED: 'Retornado',
    MISPLACED: 'Extraviado',
    FRAUD: 'Fraude',
    SUSPECTED_FRAUD: 'Suspeita de Fraude - Aguardando Análise Manual',
  }
};
