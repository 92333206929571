<template>
  <section class="product-arrival-report-list">

    <el-tabs v-model="activeTab" tab-position="right">
      <!-- New tab -->
      <el-tab-pane label="Pendentes" name="new" class="pending-reports" v-loading="loading.newTab">

        <div style="text-align: center">
          <el-button
            icon="el-icon-plus"
            circle
            v-on:click="openAddReportDialog()"
            style="margin-bottom: 15px;"
          />
        </div>

        <div v-if="newReports.length === 0" class="nothing-new">
          Nenhuma notificação de chegada nova.
        </div>

        <div v-else>
          <el-card class="report" v-for="report in newReports" :key="report.id">

            <div slot="header" class="header">
              <el-row :gutter="10">
                <el-col :span="20" class="report-company">
                  {{ report.companyName }}
                </el-col>

                <el-col :span="4" class="action">
                  <el-popconfirm
                    title="Deseja realmente finalizar?"
                    confirm-button-text="Sim"
                    cancel-button-text="Não"
                    @confirm="doFinalize(report.id)"
                    v-if="verifyRole(['financial'])"
                  >
                    <el-button slot="reference" type="text">Finalizar</el-button>
                  </el-popconfirm>
                </el-col>
              </el-row>
            </div>

            <div class="body">

              <!-- NFe Info -->
              <el-row :gutter="10">
                <el-col :span="6">
                  <span class="label">Número</span>
                  <div class="value">{{ report.nfeNumber }}</div>
                </el-col>
                <el-col :span="18">
                  <span class="label">Chave de acesso</span>
                  <div class="value">{{ report.nfeAccessKey }}</div>
                </el-col>
              </el-row>

              <!-- Comments -->
              <el-row :gutter="10">
                <el-col :span="24">
                  <span class="label">Comentários</span>
                  <div class="value comments" v-if="!!report.comments">{{ report.comments }}</div>
                  <div class="value no-comment" v-else>Sem comentários</div>
                </el-col>
              </el-row>

              <!-- Reporter -->
              <div class="reporter">
                {{reporterText(report)}}
              </div>

            </div>
          </el-card>
        </div>

      </el-tab-pane>

      <!-- Done tab -->
      <el-tab-pane label="Finalizadas" name="done">

        <snowflake-search-list
          v-if="!loading.newTab"
          style="width: 685px; margin: auto;"
          context="productArrivalReport"
          :service="productArrivalReportService"
          :action="false"
          :operations="false"
          :details="false"
          :highlightCurrentRow="false"
          :tableRowClassName="() => {}"
          :keepStateOnUrl="false"
          :searchOptions="{ status: 'DONE' }"
        >
          <template slot="tableRows">

            <!-- ID -->
            <el-table-column prop="id" label="Id" width="80" sortable></el-table-column>

            <!-- Company Name -->
            <el-table-column prop="companyName" label="Nome da Empresa" sortable></el-table-column>

            <!-- NFe Number -->
            <el-table-column prop="nfeNumber" label="Número da NF" sortable></el-table-column>

          </template>
        </snowflake-search-list>

      </el-tab-pane>

    </el-tabs>

    <!--- Dialogs -->
    <!-- Dialog - Add Report -->
    <el-dialog
      class="text-left-aligned"
      title="Notificar Chegada"
      :visible.sync="addReportDialogVisible"
      v-loading="loading.addReportDialog">

      <el-form
          ref="addReportForm"
          label-width="180px"
          label-position="top"
          :rules="addReportFormRules"
          :model="addReportForm" >

        <!-- Company Name -->
        <el-form-item label="Empresa" prop="companyName">
          <el-input v-model="addReportForm.companyName"></el-input>
        </el-form-item>

        <!-- NFe Number -->
        <el-form-item label="Número NFe" prop="nfeNumber">
          <el-input v-model="addReportForm.nfeNumber"></el-input>
        </el-form-item>

        <!-- NFe Access Key -->
        <el-form-item label="Chave de Acesso" prop="nfeAccessKey">
          <el-input v-model="addReportForm.nfeAccessKey"></el-input>
        </el-form-item>

        <!-- Note -->
        <el-form-item label="Comentários" prop="comments">
          <el-input
            type="textarea"
            :rows="6"
            resize="none"
            v-model="addReportForm.comments">
          </el-input>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button v-on:click="closeAddReportDialog">{{ 'Voltar' }}</el-button>
        <el-button
          type="primary"
          @click="doSaveReport">
          {{ 'Salvar' }}
        </el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import moment from 'moment';

import Storage from '@/storage';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

import AdminService from '@/services/AdminService';
import ProductArrivalReportService from '@/services/stock/ProductArrivalReportService';

export default {
  name: 'product-arrival-report-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      productArrivalReportService: ProductArrivalReportService,

      activeTab: 'new',

      loading: {
        newTab: false,
        addReportDialog: false,
      },

      addReportDialogVisible: false,
      addReportFormRules: {
        companyName: { required: true, message: 'Informe a empresa.' },
        nfeNumber: { required: true, message: 'Informe o número da Nota Fiscal.' },
        nfeAccessKey: { required: true, message: 'Informe a chave de acesso da Nota Fiscal.' },
      },

      addReportForm: {},

      newReports: [],
      indexedAdmins: [],
    };
  },
  async created() {
    await this.loadNewReports();
  },
  methods: {
    authRoles() {
      const encodedRole = Storage.get(Storage.keys.auth.role);
      const encodedRoles = Storage.get(Storage.keys.auth.roles);

      return encodedRoles ? JSON.parse(atob(encodedRoles)) : (encodedRole ? [atob(encodedRole)] : []);
    },
    async loadNewReports() {
      this.loading.newTab = true;

      this.newReports = await ProductArrivalReportService.getAllNew();

      const adminIds = new Set();
      this.newReports.forEach((report) => {
        if (report.reporter) {
          adminIds.add(report.reporter);
        }
      });

      const promises = [...adminIds].map(adminId => AdminService.getById(adminId));
      const admins = await Promise.all(promises);

      this.indexedAdmins = Object.fromEntries(
        admins.map(admin => [admin.id, admin]),
      );

      this.loading.newTab = false;
    },
    async doFinalize(id) {
      this.loading.newTab = true;

      try {
        await ProductArrivalReportService.finalize(id);

        this.$message.success('Finalizado com sucesso!');

        this.loadNewReports();
      } catch (e) {
        console.log(e);
        this.$message.error('Falha ao finalizar notificação.');
      }

      this.loading.newTab = false;
    },
    async doSaveReport() {
      try {
        await this.$refs.addReportForm.validate();

        this.loading.addReportDialog = true;

        const request = {
          companyName: this.addReportForm.companyName,
          nfeAccessKey: this.addReportForm.nfeAccessKey,
          nfeNumber: this.addReportForm.nfeNumber,
          comments: this.addReportForm.comments,
        };
        await ProductArrivalReportService.create(request);

        this.$message.success('Salvo com sucesso!');
        this.addReportDialogVisible = false;

        this.loadNewReports();
      } catch (e) {
        console.log(e);
        this.$message.error('Falha ao registrar notificação.');
      }

      this.loading.addReportDialog = false;
    },
    closeAddReportDialog() {
      this.addReportForm = {};
      this.$refs.addReportForm.resetFields();

      this.addReportDialogVisible = false;
    },
    openAddReportDialog() {
      this.addReportForm = {};
      if (this.$refs.addReportForm) {
        this.$refs.addReportForm.resetFields();
      }

      this.addReportDialogVisible = true;
    },
    reporterText(report) {
      const creator = this.indexedAdmins[report.reporter];
      const creatorName = creator ? `${creator.firstName} ${creator.lastName}` : 'Sistema';
      const date = moment.utc(report.createdAt).local().format('DD/MM/YYYY HH:mm');

      return `${creatorName} em ${date}`;
    },
    verifyRole(allowedRoles) {
      const roles = this.authRoles();

      if (roles.includes('admin') || roles.includes('owner')) return true;

      for (var i = 0; i < roles.length; i++) {
        if (allowedRoles.includes(roles[i])) return true;
      }

      return false
    },
  },
};
</script>

<style lang="scss" scoped>
.product-arrival-report-list {

  .pending-reports {

    .nothing-new {
      font-size: 15px;
      font-style: italic;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .report {
      width: 685px;
      margin: auto;
      margin-bottom: 15px;
      text-align: left;

      .header {
        .report-company {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .action {
          text-align: center;

          button {
            padding: 0;
          }
        }
      }

      .body {
        .el-row {
          margin-bottom: 20px;
        }

        .label {
          font-weight: 700;
          margin-bottom: 5px;
          display: block;
        }

        .value {
          padding-left: 8px;
        }

        .comments {
          white-space: pre;
        }

        .no-comment {
          font-size: 13px;
          font-style: italic;
        }

        .reporter {
          margin-top: 15px;
          font-size: 12px;
          color: #8f8f8f;
        }
      }
    }
  }

  .text-left-aligned {
    text-align: left;
  }
}
</style>
