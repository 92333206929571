<template>
  <section class="register-return" v-loading="loading">
    <el-steps :active="activeStep" align-center finish-status="success">
      <el-step title="Tipo"></el-step>
      <el-step title="Identificando Caixa"></el-step>
      <el-step title="Identificando Produtos"></el-step>
      <el-step title="Mapa do Estoque"></el-step>
    </el-steps>

    <!-- Step 1 -->
    <template v-if="activeStep === 0">

      <el-form ref="typeForm" :model="typeForm" :rules="typeFormRules" class="step step-1">

        <!-- Type -->
        <el-form-item class="options" prop="type">
          <el-radio v-model="typeForm.type" label="FAILED_DELIVERY" border>Falha na entrega</el-radio>
          <el-radio v-model="typeForm.type" label="EXCHANGE" border>Devolução</el-radio>
        </el-form-item>

      </el-form>

    </template>

    <!-- Step 2 -->
    <template v-else-if="activeStep === 1">

      <snowflake-search-list
        class="step step-2 search-order-table"
        context="subscriptionBoxOrder"
        :service="subscriptionBoxOrderService"
        :action="false"
        :operations="false"
        :details="false"
        :keepStateOnUrl="false"
        :preSearch="false"
        :handleRowSelection="handleOrderSelecion">

        <template slot="emptyText">Nada aqui. Insira informações no campo acima.</template>

        <template slot="tableRows">

          <!-- ID -->
          <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>

          <!-- Customer Name -->
          <el-table-column prop="customerName" label="Nome do Cliente"></el-table-column>

          <!-- NFe -->
          <el-table-column prop="nfe" label="Nota Fiscal"></el-table-column>

        </template>
      </snowflake-search-list>

    </template>

    <!-- Step 3 -->
    <template v-else-if="activeStep === 2">
      <div class="step step-3">

        <h1 class="table-label">Produtos presentes na caixa</h1>

        <el-table
          class="products-table"
          :data="products"
          @selection-change="handleReturningProductsSelection">

          <el-table-column type="selection" />

          <el-table-column property="id" label="ID" />

          <el-table-column>
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
              <el-tooltip
                v-if="scope.row.additionalProduct"
                class="item"
                effect="dark"
                content="Produto Adicional"
                placement="top">
                <i
                  class="el-icon-circle-plus"
                  style="margin-left: 10px;color: #32d41d;"
                />
              </el-tooltip>
            </template>
          </el-table-column>

        </el-table>

        <h1 class="table-label">Produtos que podem ser utilizados</h1>

        <el-table
          class="products-table"
          :data="products"
          @selection-change="handleUsableProductsSelection">

          <el-table-column type="selection" />

          <el-table-column property="id" label="ID" />

          <el-table-column>
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
              <el-tooltip
                v-if="scope.row.additionalProduct"
                class="item"
                effect="dark"
                content="Produto Adicional"
                placement="top">
                <i
                  class="el-icon-circle-plus"
                  style="margin-left: 10px;color: #32d41d;"
                />
              </el-tooltip>
            </template>
          </el-table-column>

        </el-table>

      </div>
    </template>

    <!-- Step 4 -->
    <template v-else-if="activeStep === 3">

      <div class="step step-4">

        <el-table stripe :data="usableProducts">

          <el-table-column type="selection" />

          <el-table-column property="name" label="Nome" align="center" />

          <el-table-column property="physicalAddress.area" label="Area" width="80" align="center" />

          <el-table-column property="physicalAddress.street" label="Rua" width="80" align="center" />

          <el-table-column property="physicalAddress.side" label="Lado" width="80" align="center" />

          <el-table-column property="physicalAddress.module" label="Coluna" width="80" align="center" />

          <el-table-column property="physicalAddress.level" label="Andar" width="80" align="center" />

          <el-table-column property="physicalAddress.sequence" label="Sequencia" width="90" align="center" />

        </el-table>

      </div>
    </template>

    <div class="step-control">
      <el-button v-if="activeStep !== 0 && activeStep !== 3" @click="previousStep">Anterior</el-button>
      <el-button @click="nextStep">
        <span v-if="activeStep === 3">
          Finalizar
        </span>
        <span v-else>
          Próximo
        </span>
      </el-button>
    </div>
  </section>
</template>

<script>
import SubscriptionBoxOrderService from '@/services/fulfillment/SubscriptionBoxOrderService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';
import ProductService from '@/services/stock/ProductService';
import SubscriptionBoxOrderReturnService from '@/services/fulfillment/SubscriptionBoxOrderReturnService';

export default {
  name: 'register-return',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      loading: false,
      subscriptionBoxOrderService: SubscriptionBoxOrderService,
      activeStep: 0,
      typeForm: {
        type: null,
      },
      selectedOrder: null,
      products: [],
      returningProducts: [],
      usableProducts: [],
      typeFormRules: {
        type: [
          { required: true, message: 'Por favor, informe o tipo', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    activeStep(newStep) {
      switch (newStep) {
        case 1:
          this.selectedOrder = null;
          break;
        default:
      }
    },
  },
  methods: {
    previousStep() {
      if (this.activeStep !== 0) {
        this.activeStep -= 1;
      }
    },
    async validateStepOne() {
      await this.$refs.typeForm.validate();
    },
    validateStepTwo() {
      if (!this.selectedOrder) {
        this.$message.error('Encontre o pedido antes de continuar.');
        throw new Error('No order selected.');
      }
    },
    async loadOrderProducts() {
      try {
        const order = await SubscriptionBoxOrderService.getSubscriptionBoxOrderById(this.selectedOrder);
        const productsPromises = order.allProducts.map(productId => ProductService.getProductById(productId));

        const products = await Promise.all(productsPromises);

        this.products = products.map(product => ({
          ...product,
          additionalProduct: order.additionalProducts.indexOf(product.id) !== -1,
        }));
      } catch (e) {
        this.$message.error(`Falha ao carregar informações do pedido. ${e}`);
        throw e;
      }
    },
    async createSubscriptionBoxOrderReturn() {
      try {
        const request = {
          boxOrderId: this.selectedOrder,
          type: this.typeForm.type,
          status: 'NEW',
          returningProducts: this.returningProducts.map(product => product.id),
          usableProducts: this.usableProducts.map(product => product.id),
        };

        await SubscriptionBoxOrderReturnService.create(request);
      } catch (e) {
        console.log(e);
        this.$message.error(`Falha ao cadastrar devolução. ${e}`);
        throw e;
      }
    },
    async nextStep() {
      this.loading = true;

      try {
        switch (this.activeStep) {
          case 0:
            await this.validateStepOne();
            this.selectedOrder = null;
            break;
          case 1:
            this.validateStepTwo();
            await this.loadOrderProducts();
            break;
          case 2:
            await this.createSubscriptionBoxOrderReturn();
            break;
          default:
        }

        if (this.activeStep === 3) {
          this.activeStep = 0;
          this.typeForm.type = null;
          this.selectedOrder = null;
          this.products = [];
          this.returningProducts = [];
          this.usableProducts = [];
        } else {
          this.activeStep += 1;
        }
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    handleOrderSelecion(selectedOrder) {
      this.selectedOrder = selectedOrder.id;
    },
    handleReturningProductsSelection(val) {
      this.returningProducts = val;
    },
    handleUsableProductsSelection(val) {
      this.usableProducts = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-return {

  .step {
    min-height: 300px;
  }

  .step-1 {
    position: relative;

    .options {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .el-radio:first-child {
      margin-right: 35px;
    }
  }

  .step-2.search-order-table {
    padding: 15px;
  }

  .step-3 {

    .table-label {
      text-align: left;
      margin-top: 50px;
      margin-bottom: 5px;
    }
  }

  .step-4 {

    .el-table {
      margin-top: 60px;
    }
  }

  .step-control {
    margin-top: 12px;
    text-align: center;
  }
}
</style>
