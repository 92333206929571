import { render, staticRenderFns } from "./ProductVariantOptions.vue?vue&type=template&id=4d154b06&lang=pug&"
import script from "./ProductVariantOptions.vue?vue&type=script&setup=true&lang=js&"
export * from "./ProductVariantOptions.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ProductVariantOptions.vue?vue&type=style&index=0&id=4d154b06&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports