<template>
  <div class="login">
    <div v-if="loading" class="loading-ring"></div>
    <div v-else>
      <form @submit.prevent="login">
        <button type="submit" style="display: none"></button>
        <el-input
          class="user"
          :placeholder="$t('login.user')"
          v-model="form.username"
          @keyup.enter="login"
        />
        <el-input
          class="user"
          :placeholder="$t('login.password')"
          type="password"
          v-model="form.password"
          @keyup.enter="login"
        />
        <el-button icon="el-icon-arrow-right" circle v-on:click="login"></el-button>
      </form>
    </div>
  </div>
</template>

<script>
import { signIn } from '../services/AuthService';

export default {
  name: 'snowflake-login',
  data() {
    return {
      loading: false,
      form: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        const { role, roles, token } = await signIn(this.form.username, this.form.password);
        const userId = JSON.parse(atob(token.split('.')[1])).user_id;

        this.$storage.removeAll();

        if (roles) {
          this.$storage.add(this.$storage.keys.auth.roles, btoa(JSON.stringify(roles)));
        }

        if (role) {
          this.$storage.add(this.$storage.keys.auth.role, btoa(role));
        }

        this.$storage.add(this.$storage.keys.auth.token, token);
        this.$storage.add(this.$storage.keys.auth.user, userId);

        this.loading = false;
        this.$router.push({ path: '/' });
      } catch (e) {
        this.loading = false;
        this.$message({
          message: this.$t('login.error.invalidInformation'),
          center: true,
          showClose: true,
        });
      }
    },
  },
  created() {
    if (this.$router.currentRoute.name === 'logout') {
      this.$storage.removeAll();
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;

  .el-input {
    margin-bottom: 15px;
  }

  .el-button {
    float: right;
  }

  .loading-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 64px;
    height: 64px;
  }

  .loading-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #CB264E;
    border-color: #CB264E transparent #CB264E transparent;
    animation: loading-ring 1.2s linear infinite;
  }

  @keyframes loading-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
