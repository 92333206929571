<template lang="pug">
section.charge-list
  h1 Cobranças

  SnowflakeSearchList(
      context="charge",
      class="charge-search-result",
      :service="chargeService",
      :action="false",
      :operations="false",
      :details="false",
      :keepStateOnUrl="true",
      :preSearch="false",
      :handleRowSelection="handleChargeSelection",
      defaultSortColumn="id",
      defaultSortDirection="DESC")

    template(slot="emptyText")
      | Nada aqui. Insira informações no campo acima.

    template(slot="tableRows")

      el-table-column(prop="id", :label="$t('charge.id')", width="80", sortable)

      el-table-column(prop="customerName", :label="$t('charge.customerName')", :show-overflow-tooltip="true", sortable)

      el-table-column(prop="amount", :label="$t('charge.amount')", width="160", sortable)
        template(slot-scope="scope")
          | {{fromCents(scope)}}

      el-table-column(prop="successful", :label="$t('charge.successful')", width="120", sortable)
        template(slot-scope="scope")
          | {{fromBool(scope)}}

      el-table-column(prop="invoicePaidAt", :label="$t('charge.invoicePaidAt')", width="160", sortable)
        template(slot-scope="scope")
          | {{ fromSystemDate(scope.row.invoicePaidAt) }}
</template>

<script>
import moment from 'moment';

import ChargeService from '@/services/billing/ChargeService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'charge-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      chargeService: ChargeService,
    };
  },
  methods: {
    fromCents(value) {
      const { amount } = value.store.table.data[value.$index];
      return amount ? `R$ ${(amount / 100).toFixed(2).replace('.', ',')}` : '-';
    },
    fromBool(value) {
      const { successful } = value.store.table.data[value.$index];
      return successful ? this.$t('charge.successfulTrue') : this.$t('charge.successfulFalse');
    },
    fromSystemDate(value) {
      return value ? moment(value, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : null;
    },

    handleChargeSelection(selectedCharge) {
      this.$router.push({ name: 'customer-detail', params: { id: selectedCharge.customerId } });
    },
  },
};
</script>

<style scoped>
  .charge-search-result::v-deep(.clickable) {
    cursor: pointer;
  }
</style>
