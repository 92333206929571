import { apiPost } from '../_common';

export default {
  selectProducts(orderIds, next, dryRun) {
    const request = {
      orderIds,
      next,
      dryRun,
    };

    return apiPost('/magenta/fulfillment/product-selection', request)
      .then(result => result.response);
  },
  findReplacements(boxOrderId, ammount) {
    const request = {
      orderId: boxOrderId,
      numberOfReplacements: ammount,
    };

    return apiPost('/magenta/fulfillment/product-selection/replacement', request)
      .then(result => result.response);
  },
  revertProductSelection(orderIds) {
    const request = {
      orderIds
    };

    return apiPost('/magenta/fulfillment/product-selection/revert', request)
      .then(result => result.response);
  }
};
