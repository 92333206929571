<template>
  <div v-loading="loading">
    <el-select
      v-model="selectTag"
      @change="addTag"
      filterable
      :placeholder="placeholder"
      :filter-method="remote ? search : undefined"
      class="stretched"
    >
      <el-option
        v-for="tag in remainingTags"
        :key="idFunction(tag)"
        :label="labelFunction(tag)"
        :value="tag">
      </el-option>
    </el-select>

    <div class="tag-container">
      <div v-if="currentSelectedTags.length > 0">
        <el-tag
          v-for="(tag, index) in currentSelectedTags"
          class="spaced"
          type="primary"
          :key="idFunction(tag)"
          disable-transitions
        >
          {{ labelFunction(tag) }}
          <i class="el-tag__close el-icon-close"
          @click.stop="removeTag(index)"></i>
        </el-tag>
      </div>
      <div v-else>
        <div class="no-tags">{{ noTagText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'snowflake-tag-select',
  props: {
    value: Array,
    remote: Boolean,
    searchFunction: {
      type: Function,
      required: true,
    },
    findFunction: {
      type: Function,
    },
    idFunction: {
      type: Function,
      default: item => item.id,
    },
    labelFunction: {
      type: Function,
      default: item => item.name,
    },
    valueFunction: {
      type: Function,
      default: item => item,
    },
    placeholder: {
      type: String,
      default: 'Adicione uma tag',
    },
    noTagText: {
      type: String,
      default: 'Nenhuma tag selecionada',
    },
    maxResultSize: {
      type: Number,
      default: 50,
    },
    orderBy: String,
    sortDirection: {
      type: String,
      validator: value => value === 'ASC' || value === 'DESC',
      default: 'ASC',
    },
  },
  data() {
    return {
      tags: [],
      currentSelectedTags: [],
      selectTag: null,
      loading: false,
    };
  },
  computed: {
    remainingTags() {
      return lodash.differenceWith(this.tags, this.value, (tag, value) => this.valueFunction(tag) === value);
    },
  },
  methods: {
    search: lodash.debounce(function (query) {
      const queryOptions = {
        query,
        pageSize: this.maxResultSize,
        orderBy: this.orderBy !== null ? this.orderBy : undefined,
        sortDirection: this.sortDirection,
      };

      this.searchFunction(queryOptions)
        .then((response) => {
          this.tags = response.results;
        })
        .catch((error) => {
          this.$message.error(this.$t('tag.loadFailed', { description: error.description }));
        });
    }, 500),
    addTag(tag) {
      if (!tag) return;

      if (lodash.indexOf(this.currentSelectedTags, tag) > -1) {
        this.$message(this.$t('tag.alreadyAdded', { tag }));
      } else {
        this.currentSelectedTags.push(tag);
      }

      this.selectTag = null;

      this.emitInput();
    },
    removeTag(tagIndex) {
      this.currentSelectedTags.splice(tagIndex, 1);

      this.emitInput();
    },
    loadTags() {
      return this.searchFunction()
        .then((response) => {
          this.tags = response.results;
        })
        .catch((error) => {
          this.$message.error(this.$t('tag.loadFailed', { description: error.description }));
        });
    },
    emitInput() {
      this.$emit('input', this.currentSelectedTags.map(tag => this.valueFunction(tag)));
    },
    loadCurrentSelected(selectedValues) {
      if (Array.isArray(selectedValues)) {
        if (!this.findFunction) {
          this.currentSelectedTags = this.tags.filter(tag => selectedValues.includes(this.valueFunction(tag)));
        } else {
          this.currentSelectedTags = [];

          selectedValues.forEach((value) => {
            this.findFunction(value)
              .then((response) => {
                this.currentSelectedTags.push(response);
              })
              .catch((error) => {
                this.$message.error(this.$t('tag.loadFailed', { description: error.description }));
              });
          });
        }
      }
    }
  },
  watch: {
    value(newValue) {
      this.loadCurrentSelected(newValue);
    },
  },
  async created() {
    this.loading = true;

    await this.loadTags();
    await this.loadCurrentSelected(this.value);

    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
i.el-icon-close {
  color: inherit;
}
div.tag-container {
  text-align: left;
  border: #c1c1c1 dashed 1.5px;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px;

  div.no-tags {
    text-align: center;
    font-size: 12;
    color: #5e7382;
  }

  .el-tag {
    margin: 4px;
  }
}
</style>
