<template>
  <section class="product-list">
    <snowflake-search-list context="product" :service="productService">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" :label="$t('product.id')" width="80" sortable></el-table-column>

        <!-- Name -->
        <el-table-column prop="name" :label="$t('product.name')" :show-overflow-tooltip="true" sortable></el-table-column>

        <!-- Base Score -->
        <el-table-column prop="baseScore" :label="$t('product.baseScore')" sortable>
          <template slot-scope="scope">
            <span style="margin-left: 10px">R$&nbsp;{{ (scope.row.baseScore / 100).toFixed(2) }}</span>
          </template>
        </el-table-column>

        <!-- Cost -->
        <el-table-column prop="cost" :label="$t('product.cost')" sortable>
          <template slot-scope="scope">
            <span style="margin-left: 10px">R$&nbsp;{{ (scope.row.cost / 100).toFixed(2) }}</span>
          </template>
        </el-table-column>

        <!-- Market Price -->
        <el-table-column prop="marketPrice" :label="$t('product.marketPrice')" sortable>
          <template slot-scope="scope">
            <span style="margin-left: 10px">R$&nbsp;{{ (scope.row.marketPrice / 100).toFixed(2) }}</span>
          </template>
        </el-table-column>

        <!-- Perceived Value -->
        <el-table-column prop="perceivedValue" :label="$t('product.perceivedValue')" sortable>
          <template slot-scope="scope">
            <span style="margin-left: 10px">R$&nbsp;{{ (scope.row.perceivedValue / 100).toFixed(2) }}</span>
          </template>
        </el-table-column>

      </template>
    </snowflake-search-list>

  </section>
</template>

<script>
import ProductService from '@/services/stock/ProductService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'product-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      productService: ProductService,
    };
  },
};
</script>
