<template>
  <div>
    <el-select
      ref="select"
      class="stretched"
      v-model="currentValue"
      :value-key="objectValue ? 'name' : 'value'"
      filterable
      remote
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="onChange"
      :filter-method="search"
      :loading="loading"
      :default-first-option="defaultFirstOption">
      <el-option
        v-for="item in items"
        :key="idFunction(item)"
        :label="labelFunction(item)"
        :value="valueFunction(item)">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'snowflake-remote-search-select',
  props: {
    objectValue: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object],
      required: false,
    },
    searchFunction: {
      type: Function,
      required: true,
    },
    findFunction: {
      type: Function,
      required: true,
    },
    idFunction: {
      type: Function,
      default: item => item.id,
    },
    labelFunction: {
      type: Function,
      default: item => item.name,
    },
    valueFunction: {
      type: Function,
      default: item => item,
    },
    orderBy: String,
    sortDirection: {
      type: String,
      validator: value => value === 'ASC' || value === 'DESC',
      default: 'ASC',
    },
    customOptions: {
      type: Object,
    },
    maxResultSize: {
      type: Number,
      default: 30,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: () => false,
    },
    disabled: Boolean,
    placeholder: String,
    defaultFirstOption: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentItem: null,
      currentValue: null,
      items: [],
      loading: false,
    };
  },
  watch: {
    value(val) {
      if (val === this.currentValue) return;

      if (val && val !== this.currentValue && val !== this.getId(this.currentValue)) {
        const id = this.getId(val);
        if (id === null || id === undefined) {
          this.find(val);
        } else {
          this.find(this.getId(val));
        }
      } else {
        this.currentValue = null;
        this.items = [];
      }

      this.resetInitialSearch();
    },
  },
  methods: {
    onChange(value) {
      if (!value) {
        value = null
      }

      if (this.clearOnSelect) {
        this.currentItem = null;
        this.currentValue = null;
        this.items = [];
      } else {
        const item = this.items.find((i) => this.valueFunction(i) == value) ?? null;

        this.currentItem = item;
        this.currentValue = value;
      }

      this.$emit('input', value);
      this.$emit('change', value);
      this.$emit('itemChanged', this.currentItem);

      if (!value) {
        this.resetInitialSearch()
      }
    },
    doSearch: function (query, prependedItem = null) {
      const prepend = prependedItem ? [ prependedItem ] : [];

      if (!query) {
        setTimeout(() => { this.items = prepend.concat([]); });

        return;
      }

      const queryOptions = {
        query,
        pageSize: this.maxResultSize,
        orderBy: this.orderBy !== null ? this.orderBy : undefined,
        sortDirection: this.sortDirection,
      };

      lodash.merge(queryOptions, this.customOptions);

      this.loading = true;

      return this.searchFunction(queryOptions)
        .then((response) => {
          this.loading = false;

          this.items = prepend.concat(
            response.results.filter((item) => {
              return !prependedItem || this.valueFunction(item) != this.valueFunction(prependedItem)
            }));

          return this.items;
        })
        .catch((error) => {
          this.loading = false;

          this.$message
            .error(this.$t('searchSelect.loadFailed', { description: error.description }));
        });
    },
    resetInitialSearch() {
      return this.search(' ', this.currentItem)
    },
    find(id) {
      this.loading = true;

      return this.findFunction(id)
        .then((response) => {
          this.loading = false;

          this.currentItem = response;
          this.currentValue = this.valueFunction(response);

          this.$emit('itemChanged', this.currentItem);

          return this.currentItem;
        })
        .catch((error) => {
          this.loading = false;

          this.$message
            .error(this.$t('searchSelect.findFailed', {
              id,
              description: error.description,
            }));
        });
    },
    getId(val) {
      try {
        return this.idFunction(val);
      } catch (e) {
        return null;
      }
    },
    focus() {
      if (this.$refs.select) {
        this.$refs.select.focus();
      }
    },
  },
  created() {
    const doSearch = this.doSearch;
    this.search = lodash.debounce(function (query, prependedItem = null) { return doSearch(query, prependedItem) }, 500);
  },
  mounted() {
    if (this.value) {
      this.find(this.value)
        .then((item) => {
          this.items = [ item ];

          return this.resetInitialSearch();
        })
    } else {
      this.resetInitialSearch();
    }
  },
};
</script>
