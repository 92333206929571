<template lang="pug">
.subscription-gift-coupon-redeem

  el-table(border :data="gifts" style="margin-bottom: 22px")
    //- ID
    el-table-column(prop="id", label="ID", width="80")


    //- Coupon code
    el-table-column(prop="status", label="Status", width="160", :show-overflow-tooltip="true")
      template(slot-scope="scope")
        | {{ statusDescription(scope.row.status) }}

    //- Coupon code
    el-table-column(prop="couponCode", label="Cupom de presente", width="200", :show-overflow-tooltip="true")

    //- Gift description
    el-table-column(prop="giftDescription", label="Presente", :show-overflow-tooltip="true")

    //- Reserved at
    el-table-column(prop="reservedAt", label="Reservado em", width="140")
      template(slot-scope="scope")
        span {{ toDate(scope.row.reservedAt) }}

    //- Redeemed at
    el-table-column(prop="redeemedAt", label="Resgatado em", width="140")
      template(slot-scope="scope")
        span {{ toDate(scope.row.redeemedAt) }}

</template>
<script setup>
import { defineProps, ref, onMounted } from 'vue'

import SubscriptionGiftCouponService from '@/services/promotions/SubscriptionGiftCouponService'

import { dateTimeToLocaleDateTime } from '@/utils/DateTimeUtils'

const props = defineProps({
  subscriptionId: {
    required: true,
  },
})

const gifts = ref([])

const fetchGifts = async () => {
  gifts.value = (await SubscriptionGiftCouponService.searchRedeems({ filters: [ `subscriptionId:${props.subscriptionId}` ] })).results
}

const statusDescription = (status) => {
  return SubscriptionGiftCouponService.statusDescription[status] ?? status
}

const toDate = dateTimeToLocaleDateTime

onMounted(fetchGifts)
</script>
