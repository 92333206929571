<template lang="pug">
.reward-detail(v-if="!loading", v-loading="saving")
  el-header
    el-col(:span="16")
      el-page-header(@back="back", :content="isNew ? 'Novo prêmio' : `#${reward.id} | ${reward.title}`")

    el-col(:span="8", style="text-align: right")
      el-select(v-model="reward.unlisted", style="margin-right: 10px")

        el-option(label="Não listado", :value="true")
          span
            i.el-icon-edit
            | &#32;
            | Não listado

        el-option(label="Listado", :value="false")
          span
            i.el-icon-check
            | &#32;
            | Listado

      el-dropdown(split-button, @click="save(false)", @command="save(true)", type="primary", :loading="saving") Salvar
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item Salvar e criar outro

  el-form(ref="form", :model="reward", :rules="rules" label-width="180px")

    el-form-item(label="Tipo", prop="type")
      el-radio-group(v-model="reward.type")
        el-radio-button(label="PRODUCT") Produtos
        el-radio-button(label="PRODUCT_CREDIT") Produtos extra
        el-radio-button(label="DISCOUNT") Desconto

    el-form-item(label="Título", prop="title")
      el-input(v-model="reward.title")

    el-form-item(label="Imagem", prop="imageUrl")
      MediaInput(
        :filePrefix="thumbnailPrefix",
        :thumbnailUrl="reward.imageUrl",
        :beforeUpload="beforeImageUpload",
        @update:media="onImageChange"
      )

    el-form-item(label="Descrição", prop="description")
      HtmlEditor(v-model="reward.description")

    el-row
      el-col(:span="12")
        el-form-item(label="Preço", prop="price")
          el-input(v-model="reward.price" type="number" min="0")
            template(slot="append") pontos

      el-col(:span="12")
        el-form-item(label="Preço comparado", prop="comparedPrice")
          el-input(v-model="reward.comparedPrice" type="number" min="0")
            template(slot="append") pontos

    el-row
      el-col(:span="12")
        el-form-item(label="Permitir vários resgates", prop="allowMultiplePerOrder")
          el-radio-group(v-model="reward.allowMultiplePerOrder", size="small")
            el-radio-button(:label="false") Não
            el-radio-button(:label="true") Sim

      el-col(v-if="reward.type == 'PRODUCT'", :span="12")
        el-form-item(label="Estoque", prop="stock")
          el-input(v-model="reward.stock")
            template(slot="append") unidades

    el-divider(content-position="left") Detalhes

    el-form(
      v-show="reward.type == 'PRODUCT'",
      ref="productForm",
      :model="detailState",
      :rules="productFormRules",
      label-width="180px",
      style="margin-top: 10px"
    )
      el-form-item(label="Produtos", prop="productIds")
        RemoteSearchMultiSelect(
          v-model="productIds"
          placeholder="Produto"
          :search-function="ProductService.search"
          :find-function="ProductService.getProductById"
          :label-function="(item) => `${item.name} (#${item.id})`"
        )

    el-form(
      v-show="reward.type == 'PRODUCT_CREDIT'",
      ref="productCreditForm",
      :model="details",
      :rules="productCreditFormRules",
      label-width="180px",
      style="margin-top: 10px"
    )
      el-form-item(label="Produtos extras", prop="numberOfCredits")
        el-input-number(v-model="productCredits", controls-position="right", :min="1")

    el-form(
      v-show="reward.type == 'DISCOUNT'",
      ref="discountForm",
      :model="details",
      :rules="discountFormRules",
      label-width="180px",
      style="margin-top: 10px"
    )
      el-form-item(label="Desconto (R$)", prop="discountAmount")
        el-input-number(v-model="discount", controls-position="right", :precision="2", :step="0.1", :min="0.01")

</template>
<script setup>
import HtmlEditor from '@/components/input/HtmlEditor.vue';
import MediaInput from '@/components/input/media/MediaInput.vue';
import RemoteSearchMultiSelect from '@/components/input/RemoteSearchMultiSelect.vue';

import ProductService from '@/services/stock/ProductService'
import RewardService from '@/services/promotions/RewardService'

import { onMounted, ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useMessage } from '@/utils/Message'

const STORAGE_PREFIX = process.env.VUE_APP_MEDIA_STORAGE_PREFIX;

const route = useRoute()
const router = useRouter()

const message = useMessage()

const reward = ref({})
const loading = ref(false)
const saving = ref(false)

const rewardId = computed(() => route.params["id"])
const isNew = computed(() => !rewardId.value)

const thumbnailPrefix = ref("rewards/")

const form = ref()
const rules = {
  title: [ { required: true, trigger: 'blur', message: "Título obrigatório" } ],
  price: [ { required: true, trigger: 'blur', message: "Preço obrigatório" } ],
  description: [ { required: true, trigger: 'blur', message: "Descrição obrigatória" } ],
  type: [ { required: true, trigger: 'blur', message: "Tipo de prêmio obrigatório" } ],
  stock: [ { required: true, trigger: 'blur', message: "Estoque obrigatório" } ],
  imageUrl: [ { required: true, trigger: 'blur', message: "Imagem obrigatória" } ],
  allowMultiplePerOrder: [ { required: true, trigger: 'blur', message: "Permitir múltiplos resgates obrigatório" } ],
}

const productForm = ref()
const productFormRules = {
  productIds: [ { required: true, trigger: 'blur', message: "Produtos obrigatórios" } ],
}

const productCreditForm = ref()
const productCreditFormRules = {
  numberOfCredits: [ { required: true, trigger: 'blur', message: "Produtos extras obrigatórios" } ],
}

const discountForm = ref()
const discountFormRules = {
  discountAmount: [ { required: true, trigger: 'blur', message: "Desconto obrigatório" } ],
}

const productIds = ref([])
const productCredits = ref(1)
const discount = ref(0)

const detailState = ref(
  {
    productIds: productIds,
    numberOfCredits: productCredits,
    discountAmount: discount,
  }
)

const discountInCents = computed(() => {
  return Math.round(discount.value * 100)
})

const resetDetails = () => {
  productIds.value = []
  productCredits.value = 1
  discount.value = 0

  if (!reward.value.details) {
    return
  }

  switch(reward.value.type) {
    case "PRODUCT": {
      const productIdsString = reward.value.details['productIds'];

      productIds.value = JSON.parse(productIdsString);
      break;
    }
    case "PRODUCT_CREDIT": {
      const productCreditsString = reward.value.details['numberOfCredits'];

      productCredits.value = JSON.parse(productCreditsString);
      break;
    }
    case "DISCOUNT": {
      const discountString = reward.value.details['discountAmount'];

      discount.value = JSON.parse(discountString) / 100;
      break;
    }
  }
}

const details = computed(() => {
  switch(reward.value.type) {
    case "PRODUCT": {
      return { "productIds" : productIds.value ? JSON.stringify(productIds.value) : null }
    }
    case "PRODUCT_CREDIT": {
      return { "numberOfCredits" : productCredits.value ? JSON.stringify(productCredits.value) : null }
    }
    case "DISCOUNT": {
      return { "discountAmount" : discountInCents.value ? JSON.stringify(discountInCents.value) : null }
    }
  }

  return null
})

const beforeImageUpload = async (file) => {
  const { width, height } = await (new Promise((resolve) => {
    const image = new Image()
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height
      })

      URL.revokeObjectURL(image.src)
    }

    image.src = URL.createObjectURL(file)
  }))

  if (width < 720 && height < 720) {
    message.error(`Imagem deve ter pelo menos 720px de altura ou largura`)

    throw new Error(`Imagem deve ter pelo menos 720px de altura ou largura`)
  }

  return true
}

const onImageChange = (media) => {
  reward.value.imageUrl =
    `https://images.boxmagenta.com.br/fit-in/720x720/filters:quality(75)/filters:background_color(fff)/filters:format(jpeg)/${STORAGE_PREFIX + media.fileName}`
}

const loadReward = async () => {
  if (!rewardId.value) {
    reward.value = {
      type: "PRODUCT",
      unlisted: true,
      imageUrl: null
    }

    resetDetails()

    return
  }

  loading.value = true

  try {
    reward.value = (await RewardService.findReward(rewardId.value)) ?? {}

    resetDetails()
  } catch(e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const validateDetails = async () => {
  switch(reward.value.type) {
    case "PRODUCT": {
      return await productForm.value.validate()
    }
    case "PRODUCT_CREDIT": {
      return await productCreditForm.value.validate()
    }
    case "DISCOUNT": {
      return await discountForm.value.validate()
    }
  }
}

const save = async (createOther = false) => {
  try {
    await Promise.all([form.value.validate(), validateDetails()]);
  } catch(e) {
    message.warning("Verifique os dados")

    console.error(e)

    return
  }

  saving.value = true

  try {
    const request = {
      type: reward.value.type,
      title: reward.value.title,
      description: reward.value.description,
      imageUrl: reward.value.imageUrl,
      price: reward.value.price,
      comparedPrice: reward.value.comparedPrice,
      stock: reward.value.type == "PRODUCT" ? reward.value.stock : null,
      allowMultiplePerOrder: reward.value.allowMultiplePerOrder,
      details: details.value,
      unlisted: reward.value.unlisted
    }

    if (isNew.value) {
      reward.value = await RewardService.createReward(request)

      if (createOther) {
        router.push({ name: "reward-new" })
      } else {
        router.push({ name: "reward-detail", params: { id: reward.value.id }})
      }
    } else {
      reward.value = await RewardService.updateReward(rewardId.value, request)

      if (createOther) {
        router.push({ name: "reward-new" })
      }
    }

    message.success(`Prêmio #${reward.value.id} salvo com sucesso`)
  } catch (e) {
    message.error(`Falha ao salvar: ${e}`)
  } finally {
    saving.value = false
  }
}

const back = () => {
  router.back()
}

onMounted(async () => {
  loadReward()
})

watch(() => route.params.id, loadReward)
</script>
<style lang="scss" scoped>
.reward-detail {
  .status-icon {
    display: inline-block;
    vertical-align: bottom;
    margin: 8px 20px 0 0;
    padding: 4px;
    border-radius: 11px;
    height: 22px;
    width: 22px;
    box-sizing: border-box;

    &.active {
      background-color: #67C23A;
      color: white;
    }

    &.draft {
      background-color: #E6A23C;
      color: white;
    }
  }
}
</style>