<template>
  <section class="influencer-list">
    <h1>Influencers</h1>

    <snowflake-search-list context="influencer" :service="influencerService">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" :label="$t('influencer.id')" width="60" sortable></el-table-column>

        <!-- Name -->
        <el-table-column prop="name" :label="$t('influencer.name')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ `${scope.row.firstName} ${scope.row.lastName}` }}</span>
          </template>
        </el-table-column>

        <!-- E-mail -->
        <el-table-column prop="email" :label="$t('influencer.email')" :show-overflow-tooltip="true" sortable>
        </el-table-column>

        <!-- CPF -->
        <el-table-column prop="cpf" :label="$t('influencer.cpf')" :show-overflow-tooltip="true">
        </el-table-column>

        <!-- Active -->
        <el-table-column prop="active" :label="$t('influencer.active')" width="120" sortable>
          <template slot-scope="scope">
            {{fromBool(scope.row.active)}}
          </template>
        </el-table-column>

        <!-- Affiliate -->
        <el-table-column prop="affiliate" :label="$t('influencer.affiliate')" width="120" sortable>
          <template slot-scope="scope">
            {{fromBool(scope.row.affiliate)}}
          </template>
        </el-table-column>

      </template>
    </snowflake-search-list>

  </section>
</template>

<script>
import InfluencerService from '@/services/influencer/InfluencerService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'influencer-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      influencerService: InfluencerService,
    };
  },
  methods: {
    fromBool(value) {
      return value ? this.$t('coupon.activeTrue') : this.$t('coupon.activeFalse');
    },
  }
};
</script>
