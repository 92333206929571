<template lang="pug">
.product-variant-price

  h2 Precificação

  p Informações sobre a precificação do produto:

  el-form(ref="form", :rules="rules", :model="productVariant", label-position="top")
    el-row(:gutter="20")
      el-col(:span="12")
        el-form-item(label="Preço (R$)", prop="price")
          el-input-number.stretched(
            :value="price",
            @change="setPrice",
            type="number",
            :precision="2",
            :step="0.1",
            :min="0",
            :controls="false"
          )

      el-col(:span="12")
        el-form-item(label="Preço comparativo (R$)", prop="comparedPrice")
          el-input-number.stretched(
            :value="comparedPrice",
            @change="setComparedPrice",
            type="number",
            :precision="2",
            :step="0.1",
            :min="0",
            :controls="false"
          )

            template(slot="append", :open-delay="1000")
              el-tooltip(content="Caso o produto esteja em promoção, este valor aparecerá como comparativo. Deixe vazio, caso contrário")
                span
                  i.el-icon-question

    el-row(:gutter="20")
      el-col(:span="12")
        el-form-item(label="Preço para assinantes (R$)", prop="subscriberPrice")
          el-input-number.stretched(
            :value="subscriberPrice",
            @change="setSubscriberPrice",
            type="number",
            :precision="2",
            :step="0.1",
            :min="0",
            :controls="false"
          )
            template(slot="append", :open-delay="1000")
              el-tooltip(content="Caso o produto tenha um preço diferenciado para assinantes, este valor será aplicado. Deixe vazio, caso contrário")
                span
                  i.el-icon-question

</template>
<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['input'])

const productVariant = computed(() => props.value)

const form = ref()
const rules = ref({
  price: [
    { required: true, message: 'O preço é obrigatório', trigger: 'blur' }
  ]
})

const price = computed(() => productVariant.value.price ? productVariant.value.price / 100.0 : undefined)
const subscriberPrice = computed(() => productVariant.value.subscriberPrice ? productVariant.value.subscriberPrice / 100.0 : undefined)
const comparedPrice = computed(() => productVariant.value.comparedPrice ? productVariant.value.comparedPrice / 100.0 : undefined)

const setPrice = (value) => {
  emit('input', {
    ...productVariant.value,
    price: value ? Math.round(value * 100) : null
  })
}

const setSubscriberPrice = (value) => {
  emit('input', {
    ...productVariant.value,
    subscriberPrice: value ? value * 100 : null
  })
}

const setComparedPrice = (value) => {
  emit('input', {
    ...productVariant.value,
    comparedPrice: value ? value * 100 : null
  })
}

const validate = async () => {
  await form.value.validate()
}

defineExpose({
  validate
})
</script>