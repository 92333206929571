<template lang="pug">
.product-variant-list

  el-row
    el-table(
      ref="table",
      :data="productVariants",
      @selection-change="onSelectionChange",
      border,
      max-height="960",
      size="small",
      :row-key="productVariantName"
    )

      el-table-column(type="selection", reserve-selection)

      el-table-column(label="ID", prop="id", width="80")
        template(slot-scope="props")
          | {{ props.row.id ?? 'Novo' }}

      el-table-column(label="Variante", prop="option", :show-overflow-tooltip="true")
        template(slot-scope="props")
          | {{ productVariantName(props.row) }}

      el-table-column(label="Preço", prop="price", width="120")
        template(slot-scope="props")
          | R$ {{ (props.row.price / 100.0).toFixed(2) }}

      el-table-column(label="Preço para assinantes", prop="price", width="160")
        template(slot-scope="props")
          span(v-if="props.row.subscriberPrice") R$ {{ (props.row.subscriberPrice / 100.0).toFixed(2) }}
          span(v-else) R$ --,--

      el-table-column(label="Preço comparado", prop="comparedPrice", width="140")
        template(slot-scope="props")
          span(v-if="props.row.comparedPrice") R$ {{ (props.row.comparedPrice / 100.0).toFixed(2) }}
          span(v-else) R$ --,--

      el-table-column(label="Listada", prop="status", width="64")
        template(slot-scope="props")
          el-switch(
            :value="props.row.status == 'ACTIVE'",
            @input="value => setProductVariantStatus(props.$index, value ? 'ACTIVE' : 'UNLISTED')",
            active-color="#13ce66"
          )

      el-table-column(width="66")
        template(slot="header")
          el-button(icon="el-icon-edit", type="primary", size="small", @click="openMultiEditDialog", :disabled="selection.length == 0")

        template(slot-scope="props")
          el-button(title="Editar", plain, type="primary", size="small", icon="el-icon-edit", @click="editProductVariant(props.$index)")


  MultiProductVariantEdit(
    ref="multiEditDialog",
    :productCategory="productCategory",
    :visible.sync="multiEditDialogVisible",
    @apply="applyMultiEdit"
  )

  el-dialog(
    top="0",
    :class="{ hidden: validatingAll }"
    :title="productVariantDialogTitle",
    :visible.sync="productVariantDialogVisible",
    @closed="onEditorClosed",
    :modal="!validatingAll"
  )
    .editor-title(slot="title")
      | {{ productVariantDialogTitle }}

      el-radio-group(
        v-if="editingProductVariantIndex != null",
        :value="productVariants[editingProductVariantIndex].status",
        @input="value => setProductVariantStatus(editingProductVariantIndex, value)"
      )
        el-radio-button.grey(key="UNLISTED", label="UNLISTED") Não listada
        el-radio-button.green(key="ACTIVE", label="ACTIVE") Listada

    ProductVariant(
      v-if="editingProductVariantIndex != null",
      ref="editor",
      :productBrand="productBrand",
      :productCategory="productCategory",
      :value="productVariants[editingProductVariantIndex]",
      @input="updateProductVariant",
    )

    .editor-footer(slot="footer")
      el-button(title="Anterior", type="text", icon="el-icon-arrow-left", @click="editPrevious", :disabled="!hasPrevious")

      span.page
        | {{ `${editingProductVariantIndex + 1} / ${productVariants.length}` }}

      el-button(title="Próxima", type="text", icon="el-icon-arrow-right", @click="editNext", :disabled="!hasNext")

</template>
<script setup>
/* eslint-disable vue/no-mutating-props */
import { useMessage } from '@/utils/Message';
import { computed, onMounted, ref, nextTick, watch } from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import ProductVariant from './ProductVariant.vue';
import MultiProductVariantEdit from './MultiProductVariantEdit.vue';

const router = useRouter()
const route = useRoute()

const replaceRoute = (productVariantId) => {
  if (productVariantId && route.query["product-variant-id"] != productVariantId) {
    router.replace({ name: route.name, query: { "product-variant-id": productVariantId } })
  } else if (!productVariantId && route.query["product-variant-id"] != undefined) {
    router.replace({ name: route.name })
  }
}

const message = useMessage()

const props = defineProps({
  value: {
    type: Array,
    default: () => []
  },
  product: {
    type: Object,
    required: true
  },
  productBrand: {
    type: Object
  },
  productCategory: {
    type: Object
  },
  productOptions: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['input'])

const productVariants = computed(() => props.value)

const table =  ref()
const editor = ref()

const productVariantDialogVisible = ref(false)
const editingProductVariantIndex = ref(null)
const validatingAll = ref(false)

const multiEditDialogVisible = ref(false)
const selection = ref([])

const editingProductVariant = computed(() => {
  if (editingProductVariantIndex.value != null) {
    return productVariants.value[editingProductVariantIndex.value]
  }

  return null
})

const hasPrevious = computed(() => {
  const index = editingProductVariantIndex.value
  return index != null && index > 0;
})

const hasNext = computed(() => {
  const index = editingProductVariantIndex.value

  return index != null && index < productVariants.value.length - 1
})

const productVariantDialogTitle = computed(() => {
  const productVariant = editingProductVariant.value

  if (productVariant != null) {
    return productVariantName(productVariant)
  }

  return "Nova variante do produto"
})

const onSelectionChange = (value) => {
  selection.value = value.map((productVariant) => {
    return productVariants.value.findIndex((pv) => Object.is(productVariant, pv))
  })
}

const updateProductVariant = (productVariant) => {
  if (editingProductVariantIndex.value != null) {
    productVariant.dirty = true
    const newProductVariants = productVariants.value.slice()
    newProductVariants.splice(editingProductVariantIndex.value, 1, productVariant)

    emit('input', newProductVariants)
  }
}

const setProductVariantStatus = (index, value) => {
  const status = value
  const productVariant = { ...productVariants.value[index], status, dirty: true }

  const newProductVariants = productVariants.value.slice()
  newProductVariants.splice(index, 1, productVariant)

  emit('input', newProductVariants)

}

const openMultiEditDialog = () => {
  multiEditDialogVisible.value = true
}

const applyMultiEdit = (data) => {
  const newProductVariants = productVariants.value.slice()

  selection.value.forEach((index) => {
    const productVariant = newProductVariants[index]
    const newProductVariant = { ...productVariant, ...data, dirty: true }
    newProductVariants.splice(index, 1, newProductVariant)
  })

  emit('input', newProductVariants)
}

const editProductVariant = (i) => {
  const index = parseInt(i)
  const productVariant = productVariants.value[index]

  editingProductVariantIndex.value = index
  productVariantDialogVisible.value = true

  replaceRoute(productVariant.id)
}

const editPrevious = async () => {
  if (await editor.value.validate()) {
    editProductVariant(editingProductVariantIndex.value - 1)
  } else {
    message.warning("Verifique os dados")
  }
}

const editNext = async () => {
  if (await editor.value.validate()) {
    editProductVariant(editingProductVariantIndex.value + 1)
  } else {
    message.warning("Verifique os dados")
  }
}

const validate = async (done) => {
  if (await editor.value.validate()) {
    done()
  } else {
    message.warning("Verifique os dados")
  }
}

const validateAll = async () => {
  validatingAll.value = true
  productVariantDialogVisible.value = true

  for (var index in productVariants.value) {
    editingProductVariantIndex.value = index

    try {
      await nextTick()

      if (!await editor.value.validate()) {
        throw Error('Invalid product variant')
      }
    } catch (e) {
      productVariantDialogVisible.value = false

      await nextTick()

      setTimeout(() => {
        validatingAll.value = false
        editProductVariant(index)
      }, 300)

      throw e
    }
  }

  productVariantDialogVisible.value = false
  editingProductVariantIndex.value = null

  await nextTick()

  setTimeout(() => {
    validatingAll.value = false
  }, 350)

  return true
}

const onEditorClosed = () => {
  editingProductVariantIndex.value = null

  replaceRoute()
}

const productVariantName = (productVariant) => {
  const scent = productVariant.option.SCENT
  const color = productVariant.option.COLOR
  const size = productVariant.option.SIZE

  if (scent || color || size) {
    const name =
      [
        scent ? `Aroma: ${scent}` : null,
        color ? `Cor: ${color}` : null,
        size ? `Tamanho: ${size}` : null
      ]
        .filter((property) => property)
        .join(" / ")

    return name
  } else {
    return 'Única opção'
  }
}

onMounted(() => {
  const queryProductVariantIdParam = route.query["product-variant-id"]

  if (queryProductVariantIdParam) {
    const productVariantIndex = productVariants.value.findIndex((productVariant) => productVariant.id == queryProductVariantIdParam)

    if (productVariantIndex >= 0) {
      editProductVariant(productVariantIndex)
    }
  }
})

defineExpose({
  validateAll
})
</script>
<style lang="sass">
.product-variant-list

  .delete-confirm
    .el-button
      margin-left: 10px

  .el-dialog__wrapper
    display: flex
    justify-content: center
    align-items: center

    &.hidden
      display: none !important

    .el-dialog
      display: flex
      flex-direction: column
      margin: 0
      height: 96vh
      max-height: 960px
      overflow: auto
      width: 96vw
      max-width: 1400px

      .el-dialog__header, .el-dialog__footer
        flex: 0

      .el-dialog__body
        flex: 1
        overflow: auto
        padding: 16px 20px
        word-break: break-word


      .el-dialog__footer
        padding: 10px 20px


      .editor-title
        display: flex
        justify-content: space-between
        padding-right: 48px

        .el-radio-button
          &.green
            $green-color: #67c23a

            .el-radio-button__inner:hover
              color: $green-color


            .el-radio-button__orig-radio:checked + .el-radio-button__inner
              box-shadow: -1px 0 0 0 $green-color

            &.is-active
              .el-radio-button__inner
                background-color: $green-color
                border-color: $green-color
                color: #fff

          &.grey
            $grey-color: #C0CCDA

            .el-radio-button__inner:hover
              color: $grey-color

            &.is-active
              .el-radio-button__inner
                background-color: $grey-color
                border-color: $grey-color
                color: #fff

      .editor-footer
        .page
          padding: 0 8px
          font-size: 14px
</style>