<template>
  <section class="subscription-box-production-batch-detail" v-if="!loading.productionBatch" v-loading="saving || printing">

    <el-header>
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isNew ? 'Novo lote de montagem' : `Lote de montagem #${subscriptionBoxProductionBatch.id}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-form
      ref="form"
      :model="subscriptionBoxProductionBatch"
      :rules="rules"
       label-width="160px">

      <!-- ID -->
      <el-form-item label="ID" prop="id">
        <el-input
          v-model="subscriptionBoxProductionBatch.id"
          :disabled="true"
          class="stretched">
        </el-input>
      </el-form-item>

      <!-- Name -->
      <el-form-item label="Nome" prop="name">
        <el-input
          v-model="subscriptionBoxProductionBatch.name"
          placeholder="Name"
          class="stretched">
        </el-input>
      </el-form-item>

      <!-- Edition -->
      <el-form-item label="Edição" prop="edition">
        <snowflake-remote-search-select
          v-model="subscriptionBoxProductionBatch.edition"
          placeholder="Edição"
          orderBy="id"
          sortDirection="DESC"
          :disabled="!isNew"
          :search-function="subscriptionBoxEditionService.search"
          :find-function="subscriptionBoxEditionService.find"
          :value-function="(item) => item.id"
        >
        </snowflake-remote-search-select>
      </el-form-item>

    </el-form>

    <div>
      <el-tag type="warning" style="margin-right:15px;">
        Caixas com produtos fora do perfil: {{ numberOfUndesiredOrders }}
      </el-tag>
      <el-tag type="danger">Caixas com falha: {{ numberOfFailedOrders }}</el-tag>
    </div>

    <!-- Orders -->
    <el-table
      ref="ordersTable"
      max-height="480"
      :data="orders"
      style="margin-bottom: 22px"
      v-loading="loading.orders"
      @selection-change="onOrderSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>

      <el-table-column width="20">
        <template slot-scope="scope">
          <div class="selection-state">
            <div
              v-if="scope.row.status === 'PRODUCT_SELECTION_FAILED' || failedOrderIds.includes(scope.row.id)"
              class="selection-failed"
            />
            <div v-else-if="!scope.row.productSelection" class="nothing-selected" />
            <div v-else-if="scope.row.hasUndesired" class="has-undesired" />
            <div v-else class="no-undesired" />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="id"
        label="ID"
        sortable
        width="96"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
          <router-link :to="`/subscription-box-orders/${scope.row.id}`" target='_blank'>
              <i class="el-icon-edit-outline link"></i>
            </router-link>
        </template>
      </el-table-column>

      <el-table-column
        prop="customerId"
        label="ID Cliente"
        sortable
        width="120"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.customerId }}
          <router-link :to="`/customers/${scope.row.customerId}`" target='_blank'>
            <i class="el-icon-edit-outline link"></i>
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
        prop="edition"
        label="Edição"
        sortable
        width="96"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="status"
        label="Status"
        width="200"
        :filters="orderStatusFilter"
        :filter-method="filterHandler"
        sortable
      >
        <template slot-scope="scope">
          {{ orderStatus[scope.row.status] }}
        </template>
      </el-table-column>

      <el-table-column prop="nfe" width="120" label="NF" sortable></el-table-column>

      <el-table-column
        prop="shippingCompany"
        label="Transportadora"
        :filters="orderShippingCompanyFilter"
        :filter-method="filterHandler"
        sortable>
      </el-table-column>

      <el-table-column
        prop="trackingCode"
        label="Cód. de Rastreamento"
        :show-overflow-tooltip="true">
      </el-table-column>

      <el-table-column width="140" align="center">
        <!-- eslint-disable-next-line -->
        <template slot="header" slot-scope="scope">
          <el-dropdown trigger="click" @command="handleOrderMenuCommand">
            <el-button size="medium" class="el-dropdown-link">
              {{ "Ações" }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">

              <el-dropdown-item command="add-orders" :disabled="isNew">
                Adicionar Pedidos
              </el-dropdown-item>

              <el-dropdown-item command="select-products" divided :disabled="isNew">
                Selecionar Produtos
              </el-dropdown-item>

              <el-dropdown-item command="generate-nfe" divided :disabled="isNew">
                Gerar Notas Fiscais
              </el-dropdown-item>

              <el-dropdown-item command="sync-order" divided :disabled="isNew">
                Sincronizar Pedidos (NFe e Rastreamento)
              </el-dropdown-item>

              <el-dropdown-item command="print-bom" divided :disabled="isNew">
                Imprimir Lista de Materiais
              </el-dropdown-item>

              <el-dropdown-item command="print-danfes-correios" divided :disabled="isNew">
                Correios - Imprimir Notas Fiscais
              </el-dropdown-item>

              <el-dropdown-item command="print-shipping-labels-correios" :disabled="isNew">
                Correios - Imprimir Etiquetas de Transporte
              </el-dropdown-item>

              <el-dropdown-item command="print-danfes-loggi" divided :disabled="isNew">
                Loggi - Imprimir Notas Fiscais
              </el-dropdown-item>

              <el-dropdown-item command="print-shipping-labels-loggi" :disabled="isNew">
                Loggi - Imprimir Etiquetas de Transporte
              </el-dropdown-item>

              <el-dropdown-item command="revert-product-selection" divided :disabled="isNew">
                Reverter seleção de produtos
              </el-dropdown-item>

              <el-dropdown-item command="print-letters" divided :disabled="isNew">
                Imprimir Cartas
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <el-popconfirm
            title="Deseja realmente remover esse pedido?"
            confirm-button-text="Sim"
            cancel-button-text="Não"
            @confirm="doRemoveOrder(scope.$index, scope.row)"
          >
            <el-button slot="reference" size="mini" type="danger">Remover</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center">{{ orderSelection.length}} de {{ orders.length }} pedidos selecionados</div>

    <br/>

    <!--- Dialogs -->
    <!-- Dialog - Add Orders -->
    <el-dialog
      class="text-left-aligned"
      title="Adicionar Pedidos"
      :visible.sync="addOrdersDialogVisible"
      v-loading="loading.addOrders">

      <el-form
        ref="addOrdersForm"
        label-width="180px"
        label-position="top"
        :rules="addOrdersRules"
        :model="addOrders" >

        <!-- Number of orders -->
        <el-form-item label="Quantidade de pedidos a adicionar" prop="next">
          <el-input-number
            v-model="addOrders.next"
            controls-position="right"
            :precision="0"
            :step="1"
            :min="1"
            :max="1000"
            class="stretched"
            style="text-align: left;">
          </el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrdersDialogVisible = false">{{ "Voltar" }}</el-button>
        <el-button
          type="primary"
          @click="doAddOrders">
          {{ 'Adicionar Pedidos' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- Dialog - Select Products -->
    <el-dialog
      class="text-left-aligned"
      title="Selecionar Produtos"
      :visible.sync="selectProductsDialogVisible">

      <span v-if="!loading.selectProducts">
        Tem certeza de que deseja selecionar os produtos para os pedidos selecionados?
      </span>

      <div class="text-center-aligned" v-else>

       <el-progress type="circle" :percentage="selectProductsProgress" :status="selectProductsStatus" ></el-progress>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="selectProductsDialogVisible = false">Fechar</el-button>
        <el-button
          type="primary"
          :disabled="loading.selectProducts"
          @click="doSelectProducts">
          {{ 'Selecionar Produtos' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- Dialog - Generate NFes -->
    <el-dialog
      class="text-left-aligned"
      title="Gerar Notas Fiscais"
      :visible.sync="generateNFeDialogVisible">

      <span v-if="!loading.generateNFe">
        Tem certeza de que deseja gerar notas fiscais para os pedidos selecionados?
      </span>

      <div class="text-center-aligned" v-else>

       <el-progress type="circle" :percentage="generateNFeProgress" :status="generateNFeStatus" ></el-progress>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="generateNFeDialogVisible = false">Fechar</el-button>
        <el-button
          type="primary"
          :disabled="loading.generateNFe"
          @click="doGenerateNFe">
          {{ 'Gerar Notas Fiscais' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- Dialog - Sync Orders -->
    <el-dialog
      class="text-left-aligned"
      title="Sincronizar Pedidos"
      :visible.sync="syncOrderDialogVisible">

      <span v-if="!loading.syncOrder">
        Tem certeza de que deseja gerar notas fiscais para os pedidos selecionados?
      </span>

      <div class="text-center-aligned" v-else>

       <el-progress type="circle" :percentage="syncOrderProgress" :status="syncOrderStatus" ></el-progress>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="syncOrderDialogVisible = false">Fechar</el-button>
        <el-button
          type="primary"
          :disabled="loading.syncOrder"
          @click="doSyncOrder">
          {{ 'Sincronizar Pedidos' }}
        </el-button>
      </span>
    </el-dialog>

    <!-- iFrame -->
    <iframe id="printing-frame" ref="printingFrame" :srcdoc="printingFrameSrcDoc"></iframe>

  </section>
</template>
<script>
import lodash from 'lodash';
import BillOfMaterialsService from '@/services/fulfillment/BillOfMaterialsService';
import SubscriptionBoxEditionService from '@/services/fulfillment/SubscriptionBoxEditionService';
import LetterService from '@/services/fulfillment/LetterService';
import NFeService from '@/services/fulfillment/NFeService';
import PackingListService from '@/services/fulfillment/PackingListService';
import ProductSelectionService from '@/services/fulfillment/ProductSelectionService';
import ShippingLabelService from '@/services/fulfillment/ShippingLabelService';
import SubscriptionBoxOrderService from '@/services/fulfillment/SubscriptionBoxOrderService';
import SubscriptionBoxProductionBatchService from '@/services/fulfillment/SubscriptionBoxProductionBatchService';

import SnowflakeRemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue';

import MagentaIcon from '@/assets/logo.png'

const orderStatus = {
  CANCELED: 'Cancelado',
  NEW: 'Novo',
  PRODUCT_SELECTION_FAILED: 'Seleção de produtos falhou',
  PRODUCTS_SELECTED: 'Produtos selecionados',
  IN_PRODUCTION: 'Em produção',
  READY_TO_POST: 'Pronto para postagem',
  IN_TRANSIT: 'Em trânsito',
  AWAITING_CUSTOMER_PICKUP: 'Aguardando cliente coletar',
  DELIVERED: 'Entregue',
  RETURNED: 'Retornado',
  MISPLACED: 'Extraviado',
};

export default {
  name: 'subscription-box-production-batch-detail',
  components: {
    SnowflakeRemoteSearchSelect,
  },
  data() {
    return {
      orderStatus,

      rules: {
        name: [
          { required: true, trigger: 'blur' },
        ],
        edition: [
          { required: true, trigger: 'blur' },
        ],
      },

      loading: {
        productionBatch: false,
        orders: false,
        addOrders: false,
        selectProducts: false,
        generateNFe: false,
        syncOrder: false,
      },

      saving: false,
      printing: false,

      subscriptionBoxProductionBatch: {},

      orders: [],
      orderSelection: [],
      orderStatusFilter: [
        { text: orderStatus.CANCELED, value: 'CANCELED' },
        { text: orderStatus.SCHEDULED, value: 'SCHEDULED' },
        { text: orderStatus.NEW, value: 'NEW' },
        { text: orderStatus.PRODUCT_SELECTION_FAILED, value: 'PRODUCT_SELECTION_FAILED' },
        { text: orderStatus.PRODUCTS_SELECTED, value: 'PRODUCTS_SELECTED' },
        { text: orderStatus.IN_PRODUCTION, value: 'IN_PRODUCTION' },
        { text: orderStatus.READY_TO_POST, value: 'READY_TO_POST' },
        { text: orderStatus.IN_TRANSIT, value: 'IN_TRANSIT' },
        { text: orderStatus.AWAITING_CUSTOMER_PICKUP, value: 'AWAITING_CUSTOMER_PICKUP' },
        { text: orderStatus.DELIVERED, value: 'DELIVERED' },
        { text: orderStatus.RETURNED, value: 'RETURNED' },
        { text: orderStatus.MISPLACED, value: 'MISPLACED' },
      ],
      orderShippingCompanyFilter: [
        { text: 'Correios', value: 'CORREIOS' },
        { text: 'Loggi', value: 'LOGGI' },
        { text: 'Total Express', value: 'TOTAL_EXPRESS' },
      ],

      addOrders: { next: 10 },
      addOrdersDialogVisible: false,
      addOrdersRules: {
        next: [
          { required: true, trigger: 'blur' },
        ],
      },

      selectProductsDialogVisible: false,
      selectProductsProgress: 0,
      selectProductsStatus: null,

      generateNFeDialogVisible: false,
      generateNFeProgress: 0,
      generateNFeStatus: null,

      syncOrderDialogVisible: false,
      syncOrderProgress: 0,
      syncOrderStatus: null,

      printingFrameSrcDoc: '',

      failedOrderIds: [],

      subscriptionBoxEditionService: SubscriptionBoxEditionService,
      showNotifications: false
    };
  },
  async created() {
    if (this.$route.name === 'subscription-box-production-batch-new') {
      this.subscriptionBoxProductionBatch = {};
      this.loading.productionBatch = false;

      return;
    }

    this.loading.productionBatch = true;

    this.subscriptionBoxProductionBatch = await SubscriptionBoxProductionBatchService.get(this.$route.params.id);
    await this.loadOrders();

    this.loading.productionBatch = false;

    if (Notification.permission == "default") {
      (await Notification.requestPermission()) == "granted"
    }

    this.showNotifications = Notification.permission == "granted"
  },
  computed: {
    isNew() {
      return this.$route.name === 'subscription-box-production-batch-new';
    },
    numberOfUndesiredOrders() {
      return this.orders.filter(o => o.hasUndesired).length;
    },
    numberOfFailedOrders() {
      return this.orders.filter(o => o.status === 'PRODUCT_SELECTION_FAILED').length;
    },
    printingTitle() {
      return `#${this.subscriptionBoxProductionBatch.id} - ${this.subscriptionBoxProductionBatch.name}`;
    }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) return;

      this.saving = true;

      if (this.$route.name === 'subscription-box-production-batch-new') {
        this.subscriptionBoxProductionBatch = await this.create();

        this.$router.push(
          {
            name: 'subscription-box-production-batch-detail',
            params: { id: this.subscriptionBoxProductionBatch.id },
          },
        );
        this.saving = false;
      } else {
        this.subscriptionBoxProductionBatch = await this.update();
        this.saving = false;
      }

      this.$message.success('Lote salvo com sucesso.');
    },
    cancel() {
      this.$router.back();
    },
    create() {
      return SubscriptionBoxProductionBatchService.create(this.subscriptionBoxProductionBatch);
    },
    update() {
      return SubscriptionBoxProductionBatchService.update(
        this.subscriptionBoxProductionBatch.id,
        this.subscriptionBoxProductionBatch,
      );
    },
    async loadOrders() {
      this.loading.orders = true;

      const selectedOrderIds = lodash.map(this.orderSelection, o => o.id);

      const orders = await SubscriptionBoxProductionBatchService.getOrders(this.subscriptionBoxProductionBatch.id);
      const enhancedOrders = orders.map(order => ({
        ...order,
        hasUndesired: this.orderHasUndesired(order),
      }));

      this.orders = lodash.sortBy(
        enhancedOrders,
        [
          o => o.status !== 'PRODUCT_SELECTION_FAILED',
          o => o.hasUndesired === false,
          o => o.status !== 'NEW',
          o => o.status !== 'PRODUCTS_SELECTED',
          o => o.status !== 'IN_PRODUCTION',
          'status',
          'id',
        ],
      );

      setTimeout(() => {
        const selectedOrders = lodash.filter(this.orders, o => selectedOrderIds.includes(o.id));
        this.$refs.ordersTable.clearSelection();
        selectedOrders.forEach(o => this.$refs.ordersTable.toggleRowSelection(o, true));
      }, 100);

      this.loading.orders = false;
    },
    orderHasUndesired(order) {
      if (!order.productSelection || order.productSelection.products.length <= 0) {
        return false;
      }

      return order.productSelection.products
        .some(product => order.productSelection.undesiredProducts.includes(product));
    },
    onOrderSelectionChange(val) {
      this.orderSelection = val;
    },
    // Actions
    handleOrderMenuCommand(command) {
      switch (command) {
        case 'add-orders':
          this.showAddOrdersDialog();
          break;
        case 'select-products':
          this.showSelectProductsDialog();
          break;
        case 'revert-product-selection':
          this.revertProductSelection();
          break;
        case 'generate-nfe':
          this.showGenerateNFeDialog();
          break;
        case 'sync-order':
          this.showSyncOrderDialog();
          break;
        case 'print-bom':
          this.printBillOfMaterials();
          break;
        case 'print-danfes-loggi':
          this.printDANFes('LOGGI');
          break;
        case 'print-shipping-labels-loggi':
          this.printShippingLabels('LOGGI');
          break;
        case 'print-danfes-correios':
          this.printDANFes('CORREIOS');
          break;
        case 'print-shipping-labels-correios':
          this.printShippingLabels('CORREIOS');
          break;
        case 'print-letters':
          this.printLetters();
          break;
        default: break;
      }
    },
    showAddOrdersDialog() {
      this.addOrdersDialogVisible = true;
      this.addOrders = { next: 50 };
    },
    async doAddOrders() {
      try {
        await this.$refs.addOrdersForm.validate();
      } catch (e) {
        throw new Error('Selecione uma quantidade válida de pedidos a serem adicionados');
      }

      this.loading.addOrders = true;

      try {
        const oldProductionBatch = this.subscriptionBoxProductionBatch;

        this.subscriptionBoxProductionBatch = await SubscriptionBoxProductionBatchService.addOrders(
          this.subscriptionBoxProductionBatch.id,
          { next: this.addOrders.next },
        );

        const addedOrdersCount = this.subscriptionBoxProductionBatch.orders.length - oldProductionBatch.orders.length;

        this.$message.success(`${addedOrdersCount} pedidos adicionados com sucesso!`);
      } catch (e) {
        this.$message.error(`Falha ao adicionar pedidos: ${e.message}`);
      } finally {
        this.loading.addOrders = false;
        this.addOrdersDialogVisible = false;
        this.loadOrders();
      }
    },
    async doRemoveOrder(index, order) {
      this.saving = true;

      this.subscriptionBoxProductionBatch = await SubscriptionBoxProductionBatchService.removeOrder(
        this.$route.params.id,
        { orderId: order.id },
      );
      await this.loadOrders();

      this.$message.success('Caixa removida.');

      this.saving = false;
    },
    showSelectProductsDialog() {
      this.selectProductsProgress = 0;
      this.selectProductsStatus = null;
      this.loading.selectProducts = false;
      this.selectProductsDialogVisible = true;
    },
    async doSelectProducts() {
      this.selectProductsProgress = 0;
      this.loading.selectProducts = true;

      const selectedOrders = this.orderSelection.filter(order => order.status === 'NEW');
      const chunks = lodash.chunk(selectedOrders, 5);
      var processed = 0;

      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
          const orderIds = chunk.map(order => order.id);

          // eslint-disable-next-line no-await-in-loop
          await ProductSelectionService.selectProducts(orderIds, null, false);

          processed += chunk.length;
          this.selectProductsProgress = Math.round((processed / selectedOrders.length) * 100);
        }

        this.selectProductsProgress = 100;
        this.selectProductsStatus = 'success';

        this.notify('Produtos selecionados com sucesso');
      } catch (e) {
        this.selectProductsStatus = 'exception';
        this.$message.error(`Falha ao selecionar produtos: ${e.message}`);

        this.notify('Falha ao selecionar produtos');
      } finally {
        this.loadOrders();
      }
    },
    revertProductSelection() {
      this.$confirm(
        'Realmente deseja reverter a seleção de produtos dos pedidos selecionados? Esta ação é irreversível.',
        'Reverter seleção de produtos', {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
          type: 'warning',
        },
      ).then(this.doRevertProductSelection)
    },
    async doRevertProductSelection() {
      try {
        this.loading.orders = true;

        const selectedOrders =
          this.orderSelection.filter(order => order.status === 'PRODUCTS_SELECTED' || order.status === 'PRODUCT_SELECTION_FAILED');

        const chunks = lodash.chunk(selectedOrders, 5);

        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
          const orderIds = chunk.map(order => order.id);

          // eslint-disable-next-line no-await-in-loop
          await ProductSelectionService.revertProductSelection(orderIds);
        }


        this.notify('Seleção de produtos revertida com sucesso');
      } catch(e) {
        this.$message.error(`Falha ao reverter seleção de produtos: ${e.message}`);
      } finally {
        await this.loadOrders();
        this.loading.orders = false;
      }
    },
    showGenerateNFeDialog() {
      const allProductsSelected = this.orderSelection
        .every(order => order.productSelection && (order.productSelection.products.length > 0 || order.additionalProducts.length > 0));

      if (!allProductsSelected) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem produtos para emissão de nota fiscal.
                      <br/>
                      <strong>Selecione os produtos para esses pedidos para continuar</strong>
                    </span>`,
          center: true,
        });
        return;
      }

      this.generateNFeProgress = 0;
      this.generateNFeStatus = null;
      this.loading.generateNFe = false;
      this.generateNFeDialogVisible = true;
    },
    async doGenerateNFe() {
      this.generateNFeProgress = 0;
      this.loading.generateNFe = true;

      const selectedOrders = this.orderSelection.filter(order => order.status === 'PRODUCTS_SELECTED');
      const chunks = lodash.chunk(selectedOrders, 10);
      var processed = 0;

      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
          const orderIds = chunk.map(order => order.id);

          // eslint-disable-next-line no-await-in-loop
          const results = await NFeService.generateNFe(orderIds, null);
          const failedResults = lodash.filter(results, r => !r.successful);
          this.failedOrderIds = failedResults.map(f => f.order.id);

          if (failedResults.length > 0) {
            this.generateNFeStatus = 'exception';
            this.$message.error(`Falha ao gerar notas fiscais: ${failedResults[0].failureCause}`);

            return;
          }

          processed += chunk.length;
          this.generateNFeProgress = Math.round((processed / selectedOrders.length) * 100);
        }

        this.generateNFeProgress = 100;
        this.generateNFeStatus = 'success';

        this.notify('Notas fiscais geradas com sucesso');
      } catch (e) {
        this.generateNFeStatus = 'exception';
        this.$message.error(`Falha ao gerar notas fiscais: ${e.message}`);

        this.notify('Falha ao gerar notas fiscais');
      } finally {
        this.loadOrders();
      }
    },
    showSyncOrderDialog() {
      this.syncOrderProgress = 0;
      this.syncOrderStatus = null;
      this.loading.syncOrder = false;
      this.syncOrderDialogVisible = true;
    },
    async doSyncOrder() {
      this.syncOrderProgress = 0;
      this.loading.syncOrder = true;

      const selectedOrders = this.orderSelection.filter(order => order.status !== 'NEW');
      const chunks = lodash.chunk(selectedOrders, 10);
      var processed = 0;

      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
          const orderIds = chunk.map(order => order.id);

          // eslint-disable-next-line no-await-in-loop
          await SubscriptionBoxOrderService.sync(orderIds);

          processed += chunk.length;
          this.syncOrderProgress = Math.round((processed / selectedOrders.length) * 100);
        }

        this.syncOrderProgress = 100;
        this.syncOrderStatus = 'success';
      } catch (e) {
        this.syncOrderStatus = 'exception';
      } finally {
        this.loadOrders();
      }
    },
    async printBillOfMaterials() {
      this.printingFrameSrcDoc = '';

      const allProductsSelected = this.orderSelection
        .every(order => order.productSelection && (order.productSelection.products.length > 0 || order.additionalProducts.length > 0));

      if (!allProductsSelected) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem produtos para emissão de lista de materiais.
                      <br/>
                      <strong>Selecione os produtos para esses pedidos para continuar</strong>
                    </span>`,
          center: true,
        });
        return;
      }

      const selectedOrderIds = this.orderSelection
        .filter(order => order.productSelection)
        .map(order => order.id);

      try {
        this.printing = true;
        const billOfMaterials = await BillOfMaterialsService.generateBillOfMaterials(this.printingTitle, selectedOrderIds);

        this.printHtml(billOfMaterials.html);
      } catch (e) {
        this.$message.error(`Falha ao imprimir lista de materiais: ${e.message}`);
      } finally {
        this.printing = false;
      }
    },
    async printDANFes(shippingCompany) {
      this.printingFrameSrcDoc = '';

      const allNFeAccessKeysPresent = this.orderSelection.every(order => order.nfeAccessKey);
      if (!allNFeAccessKeysPresent) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem chave de acesso para NFe.
                      <br/>
                      <strong>Gere as notas fiscais e sincronize esses pedidos</strong>
                    </span>`,
          center: true,
        });

        return;
      }

      const selectedOrderIds = this.orderSelection
        .filter(order => order.nfeAccessKey && (!shippingCompany || order.shippingCompany === shippingCompany))
        .map(order => order.id);

      try {
        this.printing = true;

        const shipmentId = `#${this.subscriptionBoxProductionBatch.id}`;
        const danfe = await NFeService.generateDANFe(shipmentId, selectedOrderIds);

        this.printHtml(danfe.html);
      } catch (e) {
        this.$message.error(`Falha ao imprimir notas fiscais: ${e.message}`);
      } finally {
        this.printing = false;
      }
    },
    async printPackingList(shippingCompany) {
      this.printingFrameSrcDoc = '';

      const allNFePresent = this.orderSelection.every(order => order.nfe);
      if (!allNFePresent) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem NFe.
                      <br/>
                      <strong>Gere as notas fiscais e sincronize esses pedidos</strong>
                    </span>`,
          center: true,
        });

        return;
      }

      const selectedOrderIds = this.orderSelection
        .filter(order => (!shippingCompany || order.shippingCompany === shippingCompany))
        .map(order => order.id);

      try {
        this.printing = true;
        const packingList = await PackingListService.generatePackingList(selectedOrderIds);

        this.printHtml(packingList.html);
      } catch (e) {
        this.$message.error(`Falha ao imprimir romaneio: ${e.message}`);
      } finally {
        this.printing = false;
      }
    },
    async printShippingLabels(shippingCompany) {
      this.printingFrameSrcDoc = '';

      const allNFePresent = this.orderSelection.every(order => order.nfe);
      const allTrackingCodePresent = this.orderSelection.every(order => order.trackingCode);
      if (!allNFePresent) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem NFe.
                      <br/>
                      <strong>Gere as notas fiscais e sincronize esses pedidos</strong>
                    </span>`,
          center: true,
        });

        return;
      }

      if (!allTrackingCodePresent) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem código de rastreamento.
                      <br/>
                      <strong>Sincronize esses pedidos</strong>
                    </span>`,
          center: true,
        });

        return;
      }

      const selectedOrderIds = this.orderSelection
        .filter(order => (!shippingCompany || order.shippingCompany === shippingCompany))
        .map(order => order.id);

      try {
        this.printing = true;

        const shipmentId = `#${this.subscriptionBoxProductionBatch.id}`;
        const shippingLabels =
          await ShippingLabelService.generateShippingLabels(
            shipmentId,
            selectedOrderIds
          );

        this.printHtml(shippingLabels.html);
      } catch (e) {
        this.$message.error(`Falha ao imprimir etiquetas de transporte: ${e.message}`);
      } finally {
        this.printing = false;
      }
    },
    async printLetters() {
      this.printingFrameSrcDoc = '';

      const allNFePresent = this.orderSelection.every(order => order.nfe);
      if (!allNFePresent) {
        this.$message({
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `<span>
                      Alguns pedidos selecionados ainda não possuem NFe.
                      <br/>
                      <strong>Gere as notas fiscais e sincronize esses pedidos</strong>
                    </span>`,
          center: true,
        });

        return;
      }

      const selectedOrderIds = this.orderSelection
        .map(order => order.id);

      try {
        this.printing = true;
        const letters = await LetterService.generateLetters(selectedOrderIds);

        this.printHtml(letters.html);
      } catch (e) {
        this.$message.error(`Falha ao imprimir cartas: ${e.message}`);
      } finally {
        this.printing = false;
      }
    },
    printHtml(html) {
      this.printingFrameSrcDoc = html;
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
    notify(message) {
      if (this.showNotifications && !document.hasFocus()) {
        const notification = new Notification(message, { icon: '/favicon-96x96.png' })
        notification.onclick = () => {
          window.focus()
          window.parent.focus()
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#printing-frame {
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: 1px;
  height: 1px;
}

.subscription-box-production-batch-detail {
  .text-left-aligned {
    text-align: left;
  }

  .text-center-aligned {
    text-align: center;
  }

  .selection-state {
    div::before {
      content: ' \25CF';
      height: 8px;
      width: 8px;
    }

    .nothing-selected {
      color: #FFF;
    }

    .has-undesired {
      color: orange;
    }

    .no-undesired {
      color: green;
    }

    .selection-failed {
      color: red;
    }
  }

  i.link {
    color: #606266;
    text-decoration: none;
  }
}
</style>
