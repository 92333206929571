import { apiGet, apiPost, apiPut } from '../_common';

export default {
  create(request) {
    return apiPost('/magenta/influencer', request).then(result => result.response);
  },
  update(id, request) {
    return apiPut(`/magenta/influencer/${id}`, request).then(result => result.response);
  },
  search(query) {
    return apiGet('/magenta/influencer', query).then(result => result.response);
  },
  getInfluencerById(id) {
    return apiGet(`/magenta/influencer/${id}`).then(result => result.response);
  },
};
