import { apiGet, apiPost, apiPut } from '../../_common';

export default {
  find(productVariantId) {
    return apiGet(`/magenta/ecommerce/inventory/${productVariantId}`).then(result => result.response);
  },
  updateAddress(productVariantId, address) {
    return apiPut(`/magenta/ecommerce/inventory/${productVariantId}/address`, address).then(result => result.response);
  }
};
