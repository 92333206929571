<template lang="pug">
.product-variant-option

  el-form(ref="form", label-width="124px", :model="value", :rules="rules")

    el-form-item(label="Tipo", prop="type", size="small")
      el-select(:value="optionType.type", @input="setType", :disabled="readonly", filterable)
        el-option(v-for="optionType in availableOptionTypes", :value="optionType.value", :label="optionType.label")

    el-form-item(label="Opções", prop="values", size="small")
      .options
        .tag(v-for="(option, index) in optionType.options", :key="option.name")

          span.text {{ option.name }}

          i.el-icon-edit(@click="openEditor(index)")

          el-popconfirm(v-if="option.new", title="Tem certeza de que deseja excluir?", @confirm="removeOption(index)")
            i.el-icon-close(slot="reference")

        el-button.new-option-button(v-show="!optionInputVisible", @click="showNewOptionInput") + Nova opção

        el-input.new-option-input(v-show="optionInputVisible", ref="optionInput", v-model="newOptionName" @blur="includeOption", @keyup.enter.native="includeOption(false)")

  el-dialog(
    :title="productVariantOptionDialogTitle",
    :visible.sync="productVariantOptionDialogVisible",
    width="480px"
    @closed="onEditorClosed"
  )
    EditProductVariantOption(
      v-if="editingOption",
      :value="editingOption",
      @input="setOption"
    )

</template>
<script setup>
import { computed, ref, nextTick, watch } from 'vue'
import EditProductVariantOption from './EditProductVariantOption.vue'

const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  readonly: {
    type: Boolean,
    default: () => false
  },
  validateImmediately: {
    type: Boolean,
    default: () => false
  },
  optionTypes: {
    type: Function,
    required: true
  }
})

const optionType = computed(() => props.value)

const productVariantOptionDialogVisible = ref(false)
const editingOptionIndex = ref(null)
const editingOption = computed(() => {
  if (optionType.value && editingOptionIndex.value !== null) {
    return optionType.value.options[editingOptionIndex.value]
  }

  return null
})

const openEditor = (index) => {
  editingOptionIndex.value = index

  productVariantOptionDialogVisible.value = true
}

const onEditorClosed = () => {
  editingOptionIndex.value = null
}

const productVariantOptionDialogTitle = computed(() => {
  const editingOpt = editingOption.value

  if (editingOpt) {
    return `Editando opção ${editingOpt.name}`
  }

  return ""
})

const availableOptionTypes = computed(() => {
  return props.optionTypes(optionType.value.type)
})

const emit = defineEmits([ 'input' ])

const setType = (type) => {
  emit('input', { ...props.value, type: type })
}

const setOption = (option) => {
  if (!editingOption.value) {
    return
  }

  const options = optionType.value.options.slice()
  options.splice(editingOptionIndex.value, 1, option)

  emit('input', { ...optionType.value, options })
}

const form = ref()
const rules = ref({
  type: [
    { required: true, message: "Obrigatório", trigger: "blur" }
  ],
  options: [
    { required: true, message: "Adicione opções deste tipo", trigger: "blur" }
  ]
})

const optionInput = ref()
const optionInputVisible = ref(false)
const newOptionName = ref("")

const showNewOptionInput = () => {
  optionInputVisible.value = true;

  nextTick(() => {
    optionInput.value.$refs.input.focus()
  })
}
const includeOption = (includeAndClose = true) => {
  if (newOptionName.value) {
    const name = newOptionName.value.trim()

    if (!optionType.value.options.find((option) => option.name == name)) {
      emit('input', { ...props.value, options: [ ...optionType.value.options, { name, new: true } ] })
    }
  }

  newOptionName.value = ""
  optionInputVisible.value = !includeAndClose
}

const removeOption = (index) => {
  const options = optionType.value.options.slice()
  options.splice(index, 1)

  emit('input', { ...props.value, options })
}

watch(() => [props.validateImmediately, props.value], () => {
  if (props.validateImmediately) {
    nextTick(async () => {
      try {
        await form.value.validate()
      } catch(e) {
        console.warn(e)
      }
    })
  }
}, { immediate: true, deep: true })
</script>
<style lang="scss">
.product-variant-option {

  .tag {
    background-color: #ecf5ff;
    border-color: #d9ecff;
    color: #409eff;
    display: inline-block;
    height: 32px;
    padding: 0 0 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #409EFF;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    margin-right: 10px;

    span.text {
      margin-right: 8px;
    }

    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 32px;
      border-left: 1px solid #d9ecff;
      vertical-align:middle;
      cursor: pointer;

      &:hover {
        background-color: #e4f1ff;
      }
    }
  }

  .new-option-input {
    width: 106px;
    vertical-align: bottom;
  }

}
</style>