<template lang="pug">
.ecommerce-product-variant-inventory(v-loading="loading")
  h2 Estoque

  el-form(:model="inventory", label-position="top", ref="form")

    el-row(:gutter="8")

      el-col(:span="24")

        el-form-item(prop="address", label="Endereço no estoque")
          el-select.stretched(
            :value="inventoryAddress",
            @input="setAddress",
            filterable,
            clearable,
            :disabled="!productVariant.id"
          )
            el-option(
              v-for="address in addresses",
              :key="address",
              :label="address",
              :value="address"
            )

  el-divider

  h3 Movimentações de estoque

  SearchList(
    ref="inventoryTransactionList",
    context="inventoryTransaction",
    :showSearchBar="true",
    :service="InventoryTransactionService",
    :searchOptions="inventorySearchOptions",
    :details="false"
    :operations="false",
    :keepStateOnUrl="false",
    tableHeight="320"
  )

    template(slot="action")
      el-button.stretched(
        type="primary",
        icon="el-icon-plus",
        @click="openInventoryTransactionDialog()",
        :disabled="!productVariant.id"
      ) Nova movimentação

    template(slot="tableRows")

      el-table-column(label="Movimentação de estoque")

        el-table-column(prop="createdAt" label="Data", width="140")
          template(slot-scope="scope")
            span {{ dateTimeToLocaleDateTime(scope.row.createdAt) }}

        el-table-column(prop="quantity" label="Quantidade")
          template(slot-scope="scope")
            span(v-if="scope.row.type == 'INBOUND'" style="color: #67C23A") + {{ scope.row.quantity }} {{ unitsWord(scope.row.quantity) }}
            span(v-else style="color: #F56C6C") - {{ scope.row.quantity }} {{ unitsWord(scope.row.quantity) }}

        el-table-column(prop="unitCost" label="Custo Unit.", width="120", sortable)
          template(slot-scope="scope")
            span R$ {{ (scope.row.unitCost / 100.0).toFixed(2) }}

        el-table-column(prop="description", label="Descrição", showOverflowTooltip)

        el-table-column(prop="reference", label="Origem", showOverflowTooltip)

        el-table-column(v-if="deleteInventoryTransactionEnabled", width="80")
          template(slot-scope="scope")
            el-popconfirm.delete-confirm(title="Tem certeza de que deseja excluir?", @confirm="deleteTransaction(scope.row.id)")
              el-button(title="Excluir", slot="reference", plain, type="danger", size="small", icon="el-icon-delete")

    template(slot="summary")

      el-descriptions(:column="2", border)

        el-descriptions-item
          template(slot="label") Saldo de estoque

          | {{ inventory.balance ?? 0 }} {{ unitsWord(inventory.balance) }}

        el-descriptions-item
          template(slot="label") Custo unit. médio

          span(v-if="inventory.averageUnitCost >= 0") R$ {{ (inventory.averageUnitCost / 100.0).toFixed(2) }}
          span(v-else) Não definido


  el-dialog(
    title="Nova movimentação de estoque",
    :visible.sync="inventoryTransactionDialogVisible",
    top="3vh",
    append-to-body
  )
    InventoryTransaction(
      ref="inventoryTransactionForm",
      v-if="productVariant.id",
      :productVariantId="productVariant.id",
      @created="inventoryTransactionCreated"
    )

</template>
<script setup>
import SearchList from '@/components/list/SearchList.vue'
import InventoryTransaction from '../../../../inventory/InventoryTransaction.vue'

import InventoryService from '@/services/ecommerce/inventory/InventoryService'
import InventoryTransactionService from '@/services/ecommerce/inventory/InventoryTransactionService'

import { useMessage } from '@/utils/Message';
import { dateTimeToLocaleDateTime } from '@/utils/DateTimeUtils';
import { computed, ref, watch } from 'vue';

const deleteInventoryTransactionEnabled = false

const message = useMessage()

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['input'])

const productVariant = computed(() => props.value)

const loading = ref(false)
const saving = ref(false)

const inventoryTransactionDialogVisible = ref(false)
const inventoryTransactionList = ref()
const inventoryTransactionForm = ref()

const inventory = ref({})

const inventoryAddress = computed(() => {
  if (Object.prototype.hasOwnProperty.call(productVariant.value, 'inventoryAddress')) {
    return productVariant.value.inventoryAddress
  } else {
    return inventory.value.address
  }
})

const inventorySearchOptions = computed(() => ({
  filters: [ `productVariantId:${productVariant.value.id ?? -1}` ]
}))

const unitsWord = (quantity) => quantity == -1 || quantity == 1 ? 'unidade' : 'unidades'

const load = async () => {
  if (!productVariant.value.id) {
    inventory.value = {}

    return
  }

  loading.value = true

  try {
    inventory.value = (await InventoryService.find(productVariant.value.id))
  } catch(e) {
    message.error(`Falha ao carregar dados do estoque: ${e}`)
  } finally {
    loading.value = false
  }
}

const setAddress = (value) => {
  emit('input', { ...productVariant.value, inventoryAddress: value })
}

const openInventoryTransactionDialog = () => {
  inventoryTransactionForm.value?.reset()
  inventoryTransactionDialogVisible.value = true
}

const inventoryTransactionCreated = async () => {
  inventoryTransactionDialogVisible.value = false

  reload()
}

const deleteTransaction = async (id) => {
  try {
    await InventoryTransactionService.delete(id)

    message.success("Movimentação de estoque excluída com sucesso")

    reload()
  } catch(e) {
    message.error("Falha ao excluir movimentação de estoque")
  }
}

const reload = async () => {
  inventoryTransactionList.value.reload()

  await load()
}

const addresses = computed(() => {
  const values = [];

  ['A', 'B', 'C', 'D', 'E', 'F', 'H', 'I', 'J', 'K', 'L', 'M'].forEach((letter) => {
    return [1, 2, 3, 4].forEach((column) => {
      return [1, 2, 3, 4, 5, 6].forEach((level) => {
        values.push(`${letter}${column}${level}`)
      })
    })
  })

  return values
})

watch(() => productVariant.value.id, async () => {
  await load()
}, { immediate: true })
</script>