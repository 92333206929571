<template lang="pug">
.reward-list
  h1 Prêmios do programa de pontos

  SearchList(
    context="reward", 
    :service="service",
    :action="isAllowedOperations",
    :operations="isAllowedOperations",
    :details="isAllowedOperations"
  )
    template(slot="tableRows")

      el-table-column(prop="id", label="ID", width="80", sortable)
        template(scope="scope") #
          |{{ scope.row.id }}

      el-table-column(prop="type", label="Tipo", width="100", sortable)
        template(scope="scope")
          | {{ RewardService.rewardTypeTranslation(scope.row.type) }}

      el-table-column(prop="title", label="Título", sortable)

      el-table-column(prop="price", label="Preço", width="100", sortable)
        template(scope="scope")
          | {{ scope.row.price.toLocaleString() }} pontos

      el-table-column(prop="stock", label="Estoque", width="100", sortable)

      el-table-column(prop="unlisted", label="Listado", width="100", sortable)
        template(scope="scope")
          span(v-if="scope.row.unlisted" style="color: #F56C6C") Não listado
          span(v-else style="color: #67C23A") Listado

</template>
<script setup>
import SearchList from '@/components/list/SearchList.vue';
import Storage from '../../../storage'
import RewardService from '@/services/promotions/RewardService';
import { ref, computed } from 'vue';

const service = ref({
  search: RewardService.searchRewards
})

const isAllowedOperations = computed(() => checkAuthorization(['marketing']))

const authRoles = () => {
  const encodedRole = Storage.get(Storage.keys.auth.role)
  const encodedRoles = Storage.get(Storage.keys.auth.roles)

  return encodedRoles ? JSON.parse(atob(encodedRoles)) : (encodedRole ? [atob(encodedRole)] : [])
}

const checkAuthorization = (authorizedRoles = []) => {
  const roles = authRoles()

  if (roles.indexOf('owner') >= 0 || roles.indexOf('admin') >= 0) return true

  if (authorizedRoles) {
    for (var i = 0; i < authorizedRoles.length; i += 1) {
      if (roles.indexOf(authorizedRoles[i]) >= 0) return true
    }
  }

  return false
}
</script>