import { apiGet, apiPost, apiPut } from './_common';

export default {
  getShippingAddressById(id) {
    return apiGet(`/magenta/shipping-address/${id}`).then(result => result.response);
  },
  getShippingCost(id) {
    return apiGet(`/magenta/shipping-address/${id}/shipping-cost`).then(result => result.response);
  },
  search(request) {
    return apiGet('/magenta/shipping-address', request).then(result => result.response);
  },
  create(request) {
    return apiPost('/magenta/shipping-address', request).then(result => result.response);
  },
  update(shippingAddressId, request) {
    return apiPut(`/magenta/shipping-address/${shippingAddressId}`, request)
      .then(result => result.response);
  },
};
