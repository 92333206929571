<template>
  <section class="stock-entry-list">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">{{ $t('homeTitle') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t('stockEntry.list') }}</el-breadcrumb-item>
    </el-breadcrumb>

    <snowflake-search-list context="stock-entry" :service="stockEntryService">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" :label="$t('stockEntry.id')" width="60" sortable></el-table-column>

        <!-- Product Name -->
        <el-table-column prop="productName" :label="$t('stockEntry.productName')" :show-overflow-tooltip="true" sortable></el-table-column>

        <!-- Quantity -->
        <el-table-column prop="quantity" :label="$t('stockEntry.quantity')" sortable></el-table-column>

        <!-- Type -->
        <el-table-column prop="type" :label="$t('stockEntry.type')" sortable></el-table-column>

        <!-- Last updated -->
        <el-table-column prop="updatedAt" :label="$t('stockEntry.lastUpdated')" sortable>
          <template slot-scope="scope">
            {{parseDate(scope)}}
          </template>
        </el-table-column>

      </template>
    </snowflake-search-list>
  </section>
</template>

<script>
import moment from 'moment';

import StockEntryService from '@/services/stock/StockEntryService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'stock-entry-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      stockEntryService: StockEntryService,
    };
  },
  methods: {
    parseDate(value) {
      const { updatedAt } = value.store.table.data[value.$index];
      return moment(updatedAt).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>
