import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faCoins,
  faListOl,
  faSignature,
  faSwatchbook,
  faTruck,
  faUsers,
  faTicketAlt,
  faClipboardList,
  faComments,
  faPeopleCarry,
  faThumbsUp,
  faThumbsDown,
  faExchangeAlt,
  faIndustry,
  faSearchLocation,
  faLayerGroup,
  faShippingFast,
  faStore,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCopy as farCopy,
} from '@fortawesome/free-regular-svg-icons';

export default {
  install(Vue) {
    library.add(
      faCoins,
      faListOl,
      faSignature,
      faSwatchbook,
      faTruck,
      faUsers,
      farCopy,
      faTicketAlt,
      faClipboardList,
      faComments,
      faPeopleCarry,
      faThumbsUp,
      faThumbsDown,
      faExchangeAlt,
      faIndustry,
      faSearchLocation,
      faLayerGroup,
      faShippingFast,
      faStore,
      faFileInvoiceDollar,
    );

    Vue.component('font-awesome-icon', FontAwesomeIcon);
  },
};
