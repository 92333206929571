<template>
  <div id="app">

    <router-view/>

    <LocaleChanger />
  </div>
</template>

<script>
import LocaleChanger from './components/LocaleChanger.vue';

export default {
  name: 'app',
  components: { LocaleChanger },
};
</script>

<style lang="scss">
html, body, #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: left;
}

h1, h2, h3, h4, h5 {
  color: #606266;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stretched {
  width: 100% !important;
}

.centered {
  text-align: center;
}

.el-input-number input.el-input__inner {
  text-align: left;
}

.el-breadcrumb {
  margin-bottom: 20px;
}
</style>
