<template lang="pug">
.subscription-box-order-production-batches

  el-row
    el-col(:span="24")

      el-table(:data="batches" border v-loading="loading")
        el-table-column(label="Lotes de montagem contendo este pedido")
          el-table-column(prop="id" label="ID")
          el-table-column(prop="name" label="Nome")
          el-table-column(prop="edition" label="Edição")

          el-table-column(width="120")
            template(slot-scope="scope")
              router-link(:to="{ path: `/subscription-box-production-batch/${scope.row.id}` }")
                el-button(title="Ver lote" size="small" type="primary" plain icon="el-icon-more")

              | &#32;

              el-popconfirm(title="Tem certeza de que deseja remover este pedido deste lote?", @confirm="removeFromBatch(scope.row.id)")
                el-button(slot="reference" title="Remover pedido do lote" size="small" type="danger" plain icon="el-icon-delete")

</template>
<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue'

import SubscriptionBoxOrderService from '@/services/fulfillment/SubscriptionBoxOrderService';
import SubscriptionBoxProductionBatchService from '@/services/fulfillment/SubscriptionBoxProductionBatchService';
import { useMessage } from '@/utils/Message';

const props = defineProps({
  subscriptionBoxOrderId: {
    type: Number,
    required: true
  }
})

const message = useMessage()

const loading = ref(true)
const batches = ref([])

const loadBatches = async () => {
  loading.value = true

  try {
    batches.value = await SubscriptionBoxOrderService.findProductionBatches(props.subscriptionBoxOrderId)
  } catch(e) {
    message.error('Falha ao carregar lotes de montagem. Por favor, tente novamente')

    throw e
  } finally {
    loading.value = false
  }
}

const removeFromBatch = async (batchId) => {
  loading.value = true

  try {
    await SubscriptionBoxProductionBatchService.removeOrder(
      batchId,
      { orderId: props.subscriptionBoxOrderId },
    );

    message.success(`Pedido removido do lote de montagem #${batchId}`);
  } catch(e) {
    message.error(`Falha ao remover pedido do lote de montagem #${batchId}. Por favor, tente novamente`)

    throw e
  } finally {
    await loadBatches()

    loading.value = false
  }
}

onMounted(async () => {
  await loadBatches()
})

defineExpose({
  reload: loadBatches
})
</script>