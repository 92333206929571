<template>
  <section class="coupon-list">
    <h1>Cupons de desconto</h1>

    <snowflake-search-list context="coupon" :service="couponService">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" :label="$t('coupon.id')" sortable></el-table-column>

        <!-- Amount -->
        <el-table-column prop="amount" :label="$t('coupon.amount')" sortable>
          <template slot-scope="scope">
            {{fromCents(scope)}}
          </template>
        </el-table-column>

        <!-- Percentage -->
        <el-table-column prop="percentage" :label="$t('coupon.percentage')" sortable>
          <template slot-scope="scope">
            {{toPercent(scope)}}
          </template>
        </el-table-column>

        <!-- Active -->
        <el-table-column prop="active" :label="$t('coupon.active')" width="80">
          <template slot-scope="scope">
            {{fromBool(scope)}}
          </template>
        </el-table-column>

      </template>
    </snowflake-search-list>

  </section>
</template>

<script>
import CouponService from '@/services/billing/CouponService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'coupon-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      couponService: CouponService,
    };
  },
  methods: {
    toPercent(value) {
      const { percentage } = value.store.table.data[value.$index];
      return percentage ? `${percentage * 100}%` : '-';
    },
    fromCents(value) {
      const { amount } = value.store.table.data[value.$index];
      return amount ? `R$ ${(amount / 100).toFixed(2).replace('.', ',')}` : '-';
    },
    fromBool(value) {
      const { active } = value.store.table.data[value.$index];
      return active ? this.$t('coupon.activeTrue') : this.$t('coupon.activeFalse');
    },
  },
};
</script>
