<template lang="pug">
.ecommerce-product-category-attribute-list
  el-row
    el-col(:span="24")
      el-table(ref="table", :data="attributes", border, max-height="960", size="small")
        el-table-column(type="expand")
          template(slot-scope="props")
            EcommerceProductCategoryAttributeItem(:attribute="props.row", :readonly="props.row.inherited", :validateImmediately="validateImmediately")

        el-table-column(label="Atributo", prop="name")
          template(slot-scope="props")
            span {{ props.row.name }}
            span(v-if="props.row.inherited") &#32;(atributo herdado)

        el-table-column(label="Opções", prop="options", width="200")
          template(slot-scope="props")
            span(v-if="props.row.options.length == 0") Nenhuma opção
            span(v-else-if="props.row.options.length == 1") {{ props.row.options.length }} opção
            span(v-else) {{ props.row.options.length }} opções

        el-table-column(width="65")
          template(slot="header")
            el-button(title="Adicionar novo atributo", type="primary", size="small", icon="el-icon-plus", @click="appendAtribute")

          template(slot-scope="props")
            el-popconfirm(v-if="props.row.new", title="Tem certeza de que deseja excluir?", @confirm="deleteNewAttribute(props.$index)")
              el-button(slot="reference", plain, type="danger", title="Excluir", size="small", icon="el-icon-delete")

            el-button(
              v-if="!props.row.new",
              plain,
              type="danger",
              title="Não é possível excluir um atributo já salvo",
              size="small",
              icon="el-icon-delete",
              :disabled="true"
            )
</template>
<script setup>
import { computed, ref, nextTick, defineComponent } from 'vue';

import EcommerceProductCategoryAttributeItem from './EcommerceProductCategoryAttributeItem.vue';

const props = defineProps({
  inheritedAttributes: {
    type: Array,
    required: true,
    default: () => []
  },
  value: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['input'])

const table = ref()
const validateImmediately = ref(false)

const attributes = computed(() => {
  return props.inheritedAttributes.map((a) => ({ ...a, inherited: true })).concat(props.value)
})

const appendAtribute = () => {
  const row = { slug: "", name: "", options: [], new: true }

  emit("input", [ ...props.value, row ])

  nextTick(() => {
    table.value.toggleRowExpansion(row, true)
  })
}

const deleteNewAttribute = (tableIndex) => {
  const arrayIndex = tableIndex - props.inheritedAttributes.length
  const newValue = props.value.slice()

  newValue.splice(arrayIndex, 1)

  emit("input", newValue)
}

const validate = async () => {
  const validationErrors = props.value.map((attribute) => {
    if (!attribute.name) {
      table.value.toggleRowExpansion(attribute, true)

      return true
    }

    return false

  })

  const hasErrors = validationErrors.find((b) => b == true) != undefined

  if (hasErrors) {
    validateImmediately.value = true

    throw new Error("Has errors on attribute list")
  } else {
    validateImmediately.value = false
  }
}

defineExpose({
  validate
})
</script>
