import { apiPost } from '../_common';

export default {
  generateLetters(orderIds) {
    const request = {
      orderIds,
    };

    return apiPost('/magenta/fulfillment/letter', request)
      .then(result => result.response);
  },
};
