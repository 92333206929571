<template lang="pug">
.product-variant-shipping
  h2 Envio

  p Informações para o envio do produto:

  el-form(ref="form", :rules="rules", :model="productVariant", label-position="top")
    el-row
      el-col(:span="12")
        el-form-item(label="Peso (gramas)", prop="weight")
            el-input-number.stretched(
              :value="weight",
              @change="setWeight",
              type="number",
              :precision="0",
              :step="1",
              :min="1",
              :controls="false"
            )
</template>
<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  value: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['input'])

const productVariant = computed(() => props.value)

const form = ref()
const rules = ref({
  weight: [
    { required: true, message: 'O peso é obrigatório', trigger: 'blur' }
  ]
})

const weight = computed(() => productVariant.value.weight ? productVariant.value.weight : undefined)

const setWeight = (value) => {
  emit('input', {
    ...productVariant.value,
    weight: value ? parseInt(value) : null
  })
}

const validate = async () => {
  await form.value.validate()
}

defineExpose({
  validate
})
</script>