/* eslint-disable */
const questions = [
{
  "id": "skin_color",
  "type": "SINGLE_CHOICE",
  "title": "Qual seu tom de pele?",
  "options": [{
      "name": "Muito Clara",
      "value": "type_1"
    },
    {
      "name": "Clara",
      "value": "type_2"
    },
    {
      "name": "Média Clara",
      "value": "type_3"
    },
    {
      "name": "Média",
      "value": "type_4"
    },
    {
      "name": "Média Escura",
      "value": "type_5"
    },
    {
      "name": "Escura",
      "value": "type_6"
    }
  ]
},
{
  "id": "facial_skin_oilness",
  "type": "SINGLE_CHOICE",
  "title": "Sua pele do rosto é:",
  "options": [{
      "name": "Seca",
      "value": "dry"
    },
    {
      "name": "Normal",
      "value": "normal"
    },
    {
      "name": "Mista",
      "value": "mixed"
    },
    {
      "name": "Oleosa",
      "value": "oily"
    }
  ]
},
{
  "id": "body_skin_oilness",
  "type": "SINGLE_CHOICE",
  "title": "Sua pele do corpo é:",
  "options": [{
      "name": "Seca",
      "value": "dry"
    },
    {
      "name": "Normal",
      "value": "normal"
    },
    {
      "name": "Oleosa",
      "value": "oily"
    }
  ]
},
{
  "id": "skin_issue",
  "type": "MULTIPLE_CHOICES",
  "title": "O que mais te incomoda na sua pele?",
  "options": [{
      "name": "Ressecamento",
      "value": "dryness"
    },
    {
      "name": "Oleosidade Excessiva",
      "value": "excessive_oilness"
    },
    {
      "name": "Acne",
      "value": "acne"
    },
    {
      "name": "Sensibilidade",
      "value": "sensitivity"
    },
    {
      "name": "Rugas",
      "value": "wrinkles"
    },
    {
      "name": "Manchas na pele",
      "value": "skin_stains"
    },
    {
      "name": "Olheiras",
      "value": "dark_circles_around_yes"
    }
  ]
},
{
  "id": "skin_preferred_scent",
  "type": "MULTIPLE_CHOICES",
  "title": "Qual seu aroma preferido?",
  "options": [{
      "name": "Fresco",
      "value": "fresh"
    },
    {
      "name": "Amadeirado",
      "value": "woody"
    },
    {
      "name": "Cítrico",
      "value": "citrus"
    },
    {
      "name": "Doce",
      "value": "sweet"
    },
    {
      "name": "Frutal",
      "value": "fruity"
    },
    {
      "name": "Floral",
      "value": "floral"
    }
  ]
},
{
  "id": "hair_color",
  "type": "SINGLE_CHOICE",
  "title": "Qual a cor do seu cabelo?",
  "options": [{
      "name": "Loiro",
      "value": "blond"
    },
    {
      "name": "Grisalho",
      "value": "ashen"
    },
    {
      "name": "Castanho",
      "value": "brown"
    },
    {
      "name": "Preto",
      "value": "black"
    },
    {
      "name": "Ruivo",
      "value": "red"
    },
    {
      "name": "Colorido",
      "value": "colored"
    }
  ]
},
{
  "id": "hair_type",
  "type": "SINGLE_CHOICE",
  "title": "Seu cabelo é:",
  "options": [{
      "name": "Liso",
      "value": "straight"
    },
    {
      "name": "Ondulado",
      "value": "wavy"
    },
    {
      "name": "Cacheado",
      "value": "curly"
    },
    {
      "name": "Crespo",
      "value": "coily"
    }
  ]
},
{
  "id": "hair_oilness",
  "type": "SINGLE_CHOICE",
  "title": "Seu cabelo é:",
  "options": [{
      "name": "Seco",
      "value": "dry"
    },
    {
      "name": "Normal",
      "value": "normal"
    },
    {
      "name": "Oleoso",
      "value": "oily"
    }
  ]
},
{
  "id": "hair_chemical",
  "type": "SINGLE_CHOICE",
  "title": "Seu cabelo é:",
  "options": [{
      "name": "Natural",
      "value": "natural"
    },
    {
      "name": "Quimicamente tratado",
      "value": "chemically_treated"
    }
  ]
},
{
  "id": "hair_issue",
  "type": "MULTIPLE_CHOICES",
  "title": "O que mais te incomoda no seu cabelo?",
  "options": [{
      "name": "Frizz",
      "value": "frizz"
    },
    {
      "name": "Pontas Duplas",
      "value": "split_ends"
    },
    {
      "name": "Ressecamento",
      "value": "dryness"
    },
    {
      "name": "Oleosidade excessiva",
      "value": "excessive_oilness"
    },
    {
      "name": "Pouco volume",
      "value": "too_little_volume"
    },
    {
      "name": "Muito volume",
      "value": "too_much_volume"
    }
  ]
},
{
  "id": "lipstick_color",
  "type": "MULTIPLE_CHOICES",
  "title": "Quais cores de batom você gosta?",
  "options": [{
      "name": "Nudes",
      "value": "nude"
    },
    {
      "name": "Rosas",
      "value": "pink"
    },
    {
      "name": "Vermelhos",
      "value": "red"
    },
    {
      "name": "Roxos e Vinhos",
      "value": "purple"
    },
    {
      "name": "Tons exóticos",
      "value": "exotic_colors"
    }
  ]
},
{
  "id": "shadow_color",
  "type": "MULTIPLE_CHOICES",
  "title": "Você prefere sombras:",
  "options": [{
      "name": "Neutras",
      "value": "neutral"
    },
    {
      "name": "Coloridas",
      "value": "colorful"
    }
  ]
},
{
  "id": "eyeliner_color",
  "type": "MULTIPLE_CHOICES",
  "title": "Quais cores de lápis de olho e delineador você gosta?",
  "options": [{
      "name": "Bege",
      "value": "beige"
    },
    {
      "name": "Marrom",
      "value": "brown"
    },
    {
      "name": "Preto",
      "value": "black"
    },
    {
      "name": "Coloridos",
      "value": "colorful"
    }
  ]
},
{
  "id": "desired_makeup_product",
  "type": "MULTIPLE_CHOICES",
  "title": "Quais itens de maquiagem você usa ou gostaria de experimentar?",
  "options": [{
      "name": "Batom em bala",
      "value": "regular_lipstick"
    },
    {
      "name": "Batom líquido",
      "value": "liquid_lipstick"
    },
    {
      "name": "Gloss",
      "value": "gloss"
    },
    {
      "name": "Lip Tint",
      "value": "lip_tint"
    },
    {
      "name": "Base",
      "value": "foundation"
    },
    {
      "name": "Corretivo",
      "value": "concealer"
    },
    {
      "name": "Primer",
      "value": "primer"
    },
    {
      "name": "Blush",
      "value": "blush"
    },
    {
      "name": "Pó",
      "value": "face_powder"
    },
    {
      "name": "Pincel",
      "value": "brush"
    },
    {
      "name": "Esponja para acabamento",
      "value": "makeup_sponge"
    },
    {
      "name": "Iluminador",
      "value": "illuminator"
    },
    {
      "name": "Bronzer",
      "value": "bronzer"
    },
    {
      "name": "Lápis de olho",
      "value": "pencil_eyeliner"
    },
    {
      "name": "Delineador",
      "value": "eyeliner"
    },
    {
      "name": "Sombra",
      "value": "eyeshadow"
    },
    {
      "name": "Máscara de cílios",
      "value": "mascara"
    },
    {
      "name": "Itens para sobrancelha",
      "value": "eyebrow_products"
    }
  ]
},
{
  "id": "desired_hair_product",
  "type": "MULTIPLE_CHOICES",
  "title": "Quais itens para cuidados com o cabelo você usa ou gostaria de experimentar?",
  "options": [{
      "name": "Shampoo",
      "value": "shampoo"
    },
    {
      "name": "Condicionador",
      "value": "conditioner"
    },
    {
      "name": "Shampoo à Seco",
      "value": "dry_shampoo"
    },
    {
      "name": "Máscara",
      "value": "capillary_mask"
    },
    {
      "name": "Óleo Reparador",
      "value": "repairing_oil"
    },
    {
      "name": "Leave-in",
      "value": "leave_in"
    }
  ]
},
{
  "id": "desired_skincare_product",
  "type": "MULTIPLE_CHOICES",
  "title": "Quais itens de skincare você usa ou gostaria de experimentar?",
  "options": [{
      "name": "Sabonete líquido facial",
      "value": "liquid_facial_soap"
    },
    {
      "name": "Sabonete em barra facial",
      "value": "solid_facial_soap"
    },
    {
      "name": "Sabonete líquido corporal",
      "value": "liquid_body_soap"
    },
    {
      "name": "Sabonete em barra corporal",
      "value": "solid_body_soap"
    },
    {
      "name": "Creme de mãos",
      "value": "hand_lotion"
    },
    {
      "name": "Hidratante corporal",
      "value": "body_lotion"
    },
    {
      "name": "Hidratante facial",
      "value": "face_moisturizer"
    },
    {
      "name": "Hidratante labial",
      "value": "lip_moisturizer"
    },
    {
      "name": "Óleo Corporal",
      "value": "body_oil"
    },
    {
      "name": "Esfoliante corporal",
      "value": "body_exfoliator"
    },
    {
      "name": "Esfoliante facial",
      "value": "facial_exfoliator"
    },
    {
      "name": "Tônico facial",
      "value": "facial_toner"
    },
    {
      "name": "Serum facial",
      "value": "facial_serum"
    },
    {
      "name": "Protetor solar",
      "value": "sunscreen"
    },
    {
      "name": "Esponja de limpeza facial",
      "value": "facial_cleansing"
    },
    {
      "name": "Máscaras faciais e para os olhos",
      "value": "facial_mask"
    },
    {
      "name": "Tratamento para a área dos olhos",
      "value": "eye_area_treatment"
    },
    {
      "name": "Demaquilante",
      "value": "makeup_remover"
    },
    {
      "name": "Fragrância",
      "value": "fragrance"
    },
    {
      "name": "Desodorante",
      "value": "deodorant"
    }
  ]
}];

export default {
  getCurrentQuestionnaire() {
    return new Promise((resolve) => {
      resolve({
        id: 1,
        questions,
      });
    });
  },
};
