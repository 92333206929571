import { apiGet, apiPost, apiPut } from './_common';

export default {
  search(query) {
    return apiGet('/magenta/customer', query).then(result => result.response);
  },
  create(request) {
    return apiPost('/magenta/customer', request).then(result => result.response);
  },
  update(customerId, request) {
    return apiPut(`/magenta/customer/${customerId}`, request).then(result => result.response);
  },
  getCustomerById(customerId) {
    return apiGet(`/magenta/customer/${customerId}`).then(result => result.response);
  },
  getCustomerProfile(customerId) {
    return apiGet(`/magenta/customer/${customerId}/profile/latest`).then(result => result.response);
  },
  getCustomerShippingAddress(customerId) {
    return apiGet(`/magenta/customer/${customerId}/shipping-address`)
      .then(result => result.response);
  },
  getCustomerSubscriptions(customerId) {
    return apiGet(`/magenta/customer/${customerId}/subscriptions`)
      .then(result => result.response);
  },
  getCustomerOrders(customerId) {
    return apiGet(`/magenta/customer/${customerId}/box-orders`)
      .then(result => result.response);
  },
  getCustomerInternalNotes(customerId) {
    return apiGet(`/magenta/customer/${customerId}/internal-notes`)
      .then(result => result.response);
  },
  getAvailableShippingComapanies(customerId) {
    return apiGet(`/magenta/customer/${customerId}/available-shipping-companies`)
      .then(result => result.response);
  },
};
