import { apiGet, apiPatch, apiPost, apiDelete } from '../../_common';

export default {
  find(id) {
    return apiGet(`/magenta/ecommerce/cart/${id}`).then(result => result.response);
  },
  getByCustomer(customerId) {
    return apiGet(`/magenta/customer/${customerId}/ecommerce/cart`).then(result => result.response);
  },
  create(request) {
    return apiPost(`/magenta/ecommerce/cart`, request).then(result => result.response);
  },
  update(cartId, request) {
    return apiPatch(`/magenta/ecommerce/cart/${cartId}`, request).then(result => result.response);
  },
  searchCartItems(cartId, searchOptions) {
    return apiGet(`/magenta/ecommerce/cart/${cartId}/item`, searchOptions).then(result => result.response);
  },
  addItem(cartId, request) {
    return apiPost(`/magenta/ecommerce/cart/${cartId}/item`, request).then(result => result.response);
  },
  updateItem(cartId, cartItemId, request) {
    return apiPatch(`/magenta/ecommerce/cart/${cartId}/item/${cartItemId}`, request).then(result => result.response);
  },
  removeItem(cartId, cartItemId) {
    return apiDelete(`/magenta/ecommerce/cart/${cartId}/item/${cartItemId}`).then(result => result.response);
  }
}
