<template lang="pug">
.product-line-edit(v-if="!loading", v-loading="saving")
  el-header
    el-col(:span="16")
      el-page-header(@back="back", :content="productLine && productLine.name ? productLine.name : 'Nova linha de produtos'")

    el-col(:span="8", style="text-align: right")
      el-button(@click="save", type="primary", :loading="saving") Salvar

  el-form(ref="form", :model="productLine", :rules="rules" label-width="160px")
    el-form-item(label="ID", prop="id")
      el-input(v-model="productLine.id", disabled)
        template(slot="prepend")
          span(v-if="productLine.id") #

    el-form-item(label="Marca", prop="productBrandId")
      RemoteSearchSelect(
        v-model="productLine.productBrandId"
        placeholder="Marca"
        :search-function="productBrandService.search"
        :find-function="productBrandService.find"
        :value-function="(item) => item.id",
        :disabled="!isNew",
        @itemChanged="productBrandChanged"
      )

    el-form-item(label="Nome", prop="name")
      el-input(v-model="productLine.name", maxlength="250", show-word-limit)

    el-form-item(label="Descrição", prop="description")
      HtmlEditor(
        v-model="productLine.htmlDescription"
        @input:text="value => productLine.description = value",
      )

    el-form-item(label="Thumbnail", prop="thumbnail")
      MediaInput(
        v-model="productLine.thumbnail",
        filePrefix="product-line/thumbnail/",
        :beforeUpload="beforeThumbnailUpload"
      )

</template>
<script setup>
import RemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue'
import HtmlEditor from '@/components/input/HtmlEditor.vue';
import MediaInput from '@/components/input/media/MediaInput.vue';

import productBrandService from '@/services/ecommerce/product/ProductBrandService'
import productLineService from '@/services/ecommerce/product/ProductLineService'

import { onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useMessage } from '@/utils/Message'

const route = useRoute()
const router = useRouter()

const message = useMessage()

const productLine = ref({})
const loading = ref(false)
const saving = ref(false)

const productBrand = ref(null)

const productBrandChanged = (i) => {
  productBrand.value = i
}

const lineId = computed(() => route.params["id"])
const isNew = computed(() => !lineId.value)

const form = ref()
const rules = {
  productBrandId: [ { required: true, trigger: 'blur', message: 'Marca obrigatória' } ],
  name: [ { required: true, trigger: 'blur', message: 'Nome obrigatório' } ],
}

const beforeThumbnailUpload = async (file) => {
  const { width, height } = await (new Promise((resolve) => {
    const image = new Image()
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height
      })

      URL.revokeObjectURL(image.src)
    }

    image.src = URL.createObjectURL(file)
  }))

  if (width < 500 && height < 500) {
    message.error(`Imagem deve ter pelo menos 500px de altura ou largura`)

    throw new Error(`Imagem deve ter pelo menos 500px de altura ou largura`)
  }

  return true
}

const loadProductLine = async () => {
  if (!lineId.value) return

  loading.value = true

  try {
    productLine.value = (await productLineService.find(lineId.value)) ?? {}
  } catch(e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const save = async () => {
  try {
    await form.value.validate();
  } catch(e) {
    message.warning("Verifique os dados")

    return
  }

  saving.value = true

  try {
    if (isNew.value) {
      productLine.value = await productLineService.create({
        productBrandId: productLine.value.productBrandId,
        name: productLine.value.name,
        description: productLine.value.description,
        htmlDescription: productLine.value.htmlDescription,
        thumbnail: productLine.value.thumbnail
      })

      router.replace({ name: "ecommerce-product-line-detail", params: { id: productLine.value.id }})
    } else {
      productLine.value = await productLineService.update(lineId.value, {
        productBrandId: productLine.value.productBrandId,
        name: productLine.value.name,
        description: productLine.value.description,
        htmlDescription: productLine.value.htmlDescription,
        thumbnail: productLine.value.thumbnail
      })
    }

    message.success("Salvo com sucesso")
  } catch (e) {
    message.error(`Falha ao salvar: ${e}`)
  } finally {
    saving.value = false;
  }
}

const back = () => {
  router.push({ name: "ecommerce-product-line-list" })
}

onMounted(async () => {
  loadProductLine();
})
</script>