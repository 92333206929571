import InfluencerDetail from "./influencer/InfluencerDetail";
import InfluencerList from "./influencer/InfluencerList";

import CouponDetail from "./coupon/CouponDetail";
import CouponList from "./coupon/CouponList";

import RewardDetail from "./rewards/RewardDetail";
import RewardList from "./rewards/RewardList";

export default [
  // Coupon
  {
    path: '/coupons',
    name: 'coupon-list',
    meta: {
      authorizedRoles: ['marketing'],
    },
    component: CouponList,
  },
  {
    path: '/coupons/new',
    name: 'coupon-new',
    meta: {
      authorizedRoles: ['marketing'],
    },
    component: CouponDetail,
  },
  {
    path: '/coupons/:id',
    name: 'coupon-detail',
    meta: {
      authorizedRoles: ['marketing'],
    },
    component: CouponDetail,
  },
  // Influencer
  {
    path: '/influencers',
    name: 'influencer-list',
    component: InfluencerList,
    meta: {
      authorizedRoles: ['marketing'],
    },
  },
  {
    path: '/influencers/new',
    name: 'influencer-new',
    component: InfluencerDetail,
    meta: {
      authorizedRoles: ['marketing'],
    },
  },
  {
    path: '/influencers/:id',
    name: 'influencer-detail',
    component: InfluencerDetail,
    meta: {
      authorizedRoles: ['marketing'],
    },
  },
  // Reward
  {
    path: '/marketing/rewards/reward',
    name: 'reward-list',
    component: RewardList,
    meta: {
      authorizedRoles: ['marketing', 'operations-lead'],
    },
  },
  {
    path: '/marketing/rewards/reward/new',
    name: 'reward-new',
    component: RewardDetail,
    meta: {
      authorizedRoles: ['marketing'],
    },
  },
  {
    path: '/marketing/rewards/reward/:id',
    name: 'reward-detail',
    component: RewardDetail,
    meta: {
      authorizedRoles: ['marketing'],
    },
  },
];