<template lang="pug">
section.product-edit(v-if="!loading", v-loading="saving")
  el-header
    el-col(:span="20")
      el-page-header(@back="back", :content="product && product.name ? product.name : 'Novo produto'")

    el-col(:span="4", style="text-align: right")

      el-button(@click="save", type="primary", :loading="saving", :disabled="!dirty") Salvar

  el-form(ref="form", :model="product", :rules="rules", label-width="120px")
    el-form-item(label="ID", prop="id")
      el-input(:value="product.id", disabled)
      template(slot="prepend")
        span(v-if="product.id") #

    el-form-item(label="Categoria", prop="productCategoryId")
      RemoteSearchSelect(
        v-model="product.productCategoryId",
        placeholder="Categoria de produtos",
        :search-function="productCategoryService.search",
        :find-function="findProductCategory",
        :label-function="(item) => item.canonicalName",
        :value-function="(item) => item.id",
        :clearable="true",
        @itemChanged="productCategoryChanged",
        @input="setDirty"
        :disabled="!isNew"
      )

    el-row
      el-col(:span="12")
        el-form-item(label="Marca", prop="productBrandId")
          RemoteSearchSelect(
            v-model="product.productBrandId",
            placeholder="Marca",
            :search-function="productBrandService.search",
            :find-function="productBrandService.find",
            :value-function="(item) => item.id",
            @itemChanged="productBrandChanged",
            @input="setDirty"
            :disabled="!isNew"
          )

      el-col(:span="12")
        el-form-item(label="Linha", prop="productLineId")
          RemoteSearchSelect(
            ref="productLineSelect",
            v-model="product.productLineId",
            placeholder="Linha de produtos",
            :search-function="searchProductLine",
            :find-function="productLineService.find",
            :value-function="(item) => item.id",
            :clearable="true",
            :disabled="!product.productBrandId",
            @itemChanged="productLineChanged",
            @input="setDirty"
          )

    el-form-item(label="Nome", prop="name")
      el-input(v-model="product.name", maxlength="250", show-word-limit, @input="setDirty")

  el-divider(contentPosition="left") Opções disponíveis

  ProductVariantOptionList(
    ref="productVariantOptionsForm",
    v-model="productOptionTypes",
    @input="setDirty"
  )

  el-divider(contentPosition="left") Variantes do produto

  ProductVariantList(
    ref="productVariantList",
    :value="productVariants",
    :product="product",
    :productBrand="productBrand",
    :productCategory="productCategory",
    :productOptions="productOptionTypes",
    @input="setProductVariants"
  )

</template>
<script setup>
import _ from 'lodash'

import RemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue'
import HtmlEditor from '@/components/input/HtmlEditor.vue'

import productBrandService from '@/services/ecommerce/product/ProductBrandService'
import productCategoryService from '@/services/ecommerce/product/ProductCategoryService'
import productLineService from '@/services/ecommerce/product/ProductLineService'
import productService from '@/services/ecommerce/product/ProductService'
import productVariantService from '@/services/ecommerce/product/ProductVariantService'

import { onMounted, ref, computed, watch, onUnmounted } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useMessage } from '@/utils/Message'

import ProductVariantOptionList from './product-option/ProductVariantOptionList.vue'
import ProductVariantList from './product-variant/ProductVariantList.vue'

const route = useRoute()
const router = useRouter()

const message = useMessage()

// Form
const dirty = ref(false)
const loading = ref(false)
const saving = ref(false)

const productVariantOptionsForm = ref()
const productVariantList = ref()

const form = ref()
const rules = ref({
  productBrandId: [ { required: true, trigger: 'blur', message: 'Marca obrigatória' } ],
  name: [ { required: true, trigger: 'blur', message: 'Nome obrigatório' } ],
})

const setDirty = () => {
  dirty.value = true
}

// Product
const product = ref({ tags: [], options: {} })
const productOptionTypes = ref([])

// Product Category
const productCategory = ref(null)
const productCategoryChanged = (i) => {
  productCategory.value = i
}

const findProductCategory = async (id) => {
  const searchResult = await productCategoryService.search({ filters: `id:${id}`, pageSize: 1 })

  if (searchResult.count == 1) {
    return searchResult.results[0]
  } else {
    return null
  }
}

// Product Line
const productLine = ref(null)
const productLineSelect = ref()
const productLineChanged = (i) => {
  productLine.value = i
}

// Product Brand
const productBrand = ref(null)
const productBrandChanged = (i) => {
  productBrand.value = i

  productLineSelect.value.resetInitialSearch()

  if (productLine.value && productLine.value.productBrandId != productBrand.value.id) {
    product.value.productLineId = null;
  }
}

// Product Variants
const loadedVariants = ref(false)
const productVariants = ref([])

const searchProductLine = (query) => {
  if (product.value.productBrandId) {
    return productLineService.search({ ...query, filters: `productBrandId:${product.value.productBrandId}` })
  } else {
    return productLineService.search({ ...query })
  }
}

const productId = computed(() => route.params["id"])
const isNew = computed(() => !productId.value)

const loadProductVariants = async () => {
  if (!productId.value) return

  try {
    productVariants.value = await productVariantService.findByProductId(productId.value)

    loadedVariants.value = true
  } catch(e) {
    message.error(`Falha ao carregar produto: ${e}`)

    throw e
  }
}

const loadProduct = async () => {
  if (!productId.value) {
    loadedVariants.value = true

    return
  }

  loading.value = true

  try {
    product.value = (await productService.find(productId.value)) ?? {}
    setOptions(product.value)

    await loadProductVariants()

    syncVariantsWithOptions()
  } catch(e) {
    message.error(`Falha ao carregar produto: ${e}`)

    throw e
  } finally {
    loading.value = false
  }
}

const setProductVariants = (value) => {
  productVariants.value = value
  dirty.value = true
}

const setOptions = (product) => {
  const options = []

  var i = 0
  for (var t in product.options) {
    options.push({ key: i++, type: t, options: product.options[t] })
  }

  productOptionTypes.value = options
}

const getOptions = () => {
  const options = {}

  for (const option of productOptionTypes.value) {
    options[option.type] = option.options
  }

  return options
}

const save = async () => {
  saving.value = true

  try {
    await Promise.all([form.value.validate(), productVariantOptionsForm.value.validate(), productVariantList.value.validateAll()])
  } catch(e) {
    saving.value = false
    message.warning("Verifique os dados")

    return
  }

  try {
    const creating = isNew.value
    if (creating) {
      product.value = await productService.create({
        productBrandId: product.value.productBrandId,
        productLineId: product.value.productLineId,
        productCategoryId: product.value.productCategoryId,
        name: product.value.name,
        tags: product.value.tags,
        options: getOptions()
      })
    } else {
      product.value = await productService.update(productId.value, {
        productBrandId: product.value.productBrandId,
        productLineId: product.value.productLineId,
        productCategoryId: product.value.productCategoryId,
        name: product.value.name,
        tags: product.value.tags,
        options: getOptions()
      })
    }

    await saveProductVariants()

    dirty.value = false

    setOptions(product.value)

    message.success("Salvo com sucesso")

    if (creating) {
      router.push({ name: "ecommerce-product-detail", params: { id: product.value.id }})
    }
  } catch (e) {
    message.error(`Falha ao salvar: ${e}`)
  } finally {
    saving.value = false;
  }
}

const saveProductVariants = async () => {
  const allPromises = productVariants.value.map((productVariant) => {
    if (!productVariant.id) {
      return productVariantService.create({
        status: productVariant.status,
        productId: product.value.id,
        barcodes: productVariant.barcodes,
        option: productVariant.option,
        description: productVariant.description,
        htmlDescription: productVariant.htmlDescription,
        attributes: productVariant.attributes,
        media: productVariant.media,
        price: productVariant.price,
        subscriberPrice: productVariant.subscriberPrice,
        comparedPrice: productVariant.comparedPrice,
        weight: productVariant.weight,
        inventoryAddress: productVariant.inventoryAddress,
      })
    } else if (productVariant.dirty) {
      return productVariantService.update(productVariant.id, {
        status: productVariant.status,
        barcodes: productVariant.barcodes,
        option: productVariant.option,
        description: productVariant.description,
        htmlDescription: productVariant.htmlDescription,
        attributes: productVariant.attributes,
        media: productVariant.media,
        price: productVariant.price,
        subscriberPrice: productVariant.subscriberPrice,
        comparedPrice: productVariant.comparedPrice,
        weight: productVariant.weight,
        inventoryAddress: productVariant.inventoryAddress,
      })
    } else {
      return Promise.resolve(productVariant)
    }
  })

  productVariants.value = await Promise.all(allPromises)
}

const back = () => {
  router.push({ name: "ecommerce-product-list" })
}

const syncVariantsWithOptions = (oldOptions) => {
  if (!loadedVariants.value) return

  const optionCombinations = []

  function addOptionCombination(option, i) {
    if (productOptionTypes.value.length == i) {
      optionCombinations.push(option)
    } else {
      const productOptionType = productOptionTypes.value[i]

      if (productOptionType.type && productOptionType.options.length > 0) {
        productOptionType.options.forEach((value) => {
          const nextOption = { ...option }
          nextOption[productOptionType.type] = value.name

          addOptionCombination(nextOption, i + 1)
        })
      } else {
        addOptionCombination(option, i + 1)
      }
    }
  }

  // Generate all combinations of options
  addOptionCombination({}, 0)

  // Generate new product variants for each option combination
  const newProductVariants = []
  optionCombinations.forEach((option) => {
    // If a variant has the same or a more complete option, copy it removing the extra options.
    // Only pick the first that matches this condition
    const matchingProductVariant = _.find(productVariants.value, (productVariant) => {
      return _.isMatch(productVariant.option, option)
    })

    if (matchingProductVariant) {
      newProductVariants.push({ ...matchingProductVariant, id: null, new: true, option })

      return
    }

    // If a variant has a less complete option, copy it and add the missing options
    // Only pick the first that matches this condition
    const nearestProductVariant = _.find(productVariants.value, (productVariant) => {
      return _.isMatch(option, productVariant.option)
    })

    if (nearestProductVariant) {
      newProductVariants.push({ ...nearestProductVariant, id: null, new: true, option })

      return
    }

    // If no variant matches, create a new one
    newProductVariants.push({
      option,
      status: 'UNLISTED',
      barcodes: [],
      price: null,
      subscriberPrice: null,
      comparedPrice: null,
      weight: null,
      attributes: {},
      media: [],
      new: true
     })
  })

  // Transfer old ids to new variants
  productVariants.value.filter((productVariant) => productVariant.id).forEach((productVariant) => {
    const matchingNewProductVariant = _.find(newProductVariants, (newProducVariant) => {
      return _.isMatch(newProducVariant.option, productVariant.option)
    })

    if (matchingNewProductVariant) {
      matchingNewProductVariant.id = productVariant.id
      matchingNewProductVariant.new = productVariant.new

      return
    }

    const nearestProductVariant = _.find(newProductVariants, (newProducVariant) => {
      return _.isMatch(productVariant.option, newProducVariant.option)
    })

    if (nearestProductVariant) {
      nearestProductVariant.id = productVariant.id
      nearestProductVariant.new = productVariant.new

      return
    }

    for (var t in productVariant.option) {
      const matchingNewProductVariantIndex = _.findIndex(newProductVariants, (newProducVariant) => {
        return _.isMatch(newProducVariant.option, _.omit(productVariant.option, t))
      })

      if (matchingNewProductVariantIndex >= 0) {
        const matchingNewProductVariant = newProductVariants[matchingNewProductVariantIndex]

        newProductVariants.splice(matchingNewProductVariantIndex, 1, {
          ...productVariant,
          option: matchingNewProductVariant.option
        })

        return
      }
    }

    productOptionTypes.value = oldOptions

    message.error("Falha ao definir as variantes do produto com as opções definidas. Por favor, entre em contato com o Diego.")
    throw Error("Could not find a matching variant for " + JSON.stringify(productVariant))
  })

  productVariants.value = newProductVariants
}

const interrupLeave = (event) => {
  if (dirty.value) {
    event.preventDefault()
    event.returnValue = 'Unsaved changes'

    return "Você tem alterações não salvas. Tem certeza que deseja sair?"
  }
}

onMounted(async () => {
  loadProduct()

  watch(() => productOptionTypes.value, (newOptions, oldOptions) => {
    syncVariantsWithOptions(oldOptions)
  }, { deep: true, immediate: true })

  window.addEventListener("beforeunload", interrupLeave)
})

onUnmounted(() => {
  window.removeEventListener("beforeunload", interrupLeave)
})

onBeforeRouteLeave((to, from, next) => {
  if (dirty.value) {
    const answer = window.confirm(
      'Você tem alterações não salvas. Realmente deseja sair desta página?'
    )

    if (!answer) {
      next(false)
    } else {
      next()
    }
  } else {
    next()
  }
})
</script>
